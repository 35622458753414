import { GetUserProductSubscriptionPaymentUserCanDoResultQueryVariables, PaymentMethod, GetUserProductSubscriptionPaymentUserCanDoResultQuery, GetUserProductSubscriptionPaymentUserCanDoResult } from '@/graphql'
import { defineStore } from 'pinia'
import { usePayLoadingStore } from './loading'
import { usePayPriceStore } from './price'
import { apolloClient } from '@/plugins/apollo'
import { i18n } from '@/locales/setupI18n'


interface PayUserProductSubscriptionState {
  canDoUserSubscriptionPayment: boolean | null
  useUserProductSubscriptionPaymentIsDisabled: boolean
  useUserProductSubscriptionPayment: boolean | null
  useUserProductSubscriptionData: {
    userProductSubscriptionId: string
    subscriptionProductName: string
  } | null
}

export const usePayUserProductSubscriptionPaymentStore = defineStore({
  id: 'PayUserProductSubscriptionPaymentState',

  state: (): PayUserProductSubscriptionState => ({
    canDoUserSubscriptionPayment: null,
    useUserProductSubscriptionPaymentIsDisabled: false,
    useUserProductSubscriptionPayment: null,

    useUserProductSubscriptionData: null
  }),

  getters: {
    useUserProductSubscriptionId: state => {
      return state.useUserProductSubscriptionPayment
        ? state.useUserProductSubscriptionData?.userProductSubscriptionId
        : null
    },
  },

  actions: {

    async getCanDoUserSubscriptionPayment(source: string) {
      const initCanDoUserProductSubscriptionPayment = this.canDoUserSubscriptionPayment

      const payPriceStore = usePayPriceStore()

      if (!payPriceStore.priceData) throw new Error(i18n.t('common.staticTemp.errorMessagePriceData') as string) 

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('getCanDoUserSubscriptionPayment', true, {
        source,
        initCanDoUserProductSubscriptionPayment,
        useUserProductSubscriptionPayment: this.useUserProductSubscriptionPayment,
      })

      if (this.useUserProductSubscriptionPaymentIsDisabled) {
        payLoadingStore.set('getCanDoUserSubscriptionPayment', false, {
          useUserProductSubscriptionPaymentIsDisabled: this.useUserProductSubscriptionPaymentIsDisabled
        })
        return
      }

      const { data } = await apolloClient.query<GetUserProductSubscriptionPaymentUserCanDoResultQuery, GetUserProductSubscriptionPaymentUserCanDoResultQueryVariables>({
        query: GetUserProductSubscriptionPaymentUserCanDoResult,
        variables: {
          product: payPriceStore.priceData.productId
        }
      })

      this.canDoUserSubscriptionPayment = data.userProductSubscriptionPaymentUserCanDo.canDo
      this.useUserProductSubscriptionData = data.userProductSubscriptionPaymentUserCanDo.canDo ? {
        userProductSubscriptionId: data.userProductSubscriptionPaymentUserCanDo.userProductSubscriptionId!,
        subscriptionProductName: data.userProductSubscriptionPaymentUserCanDo.userProductSubscription!.subscriptionProduct.name
      } : null


      let hasChanged = true
      if (this.canDoUserSubscriptionPayment && initCanDoUserProductSubscriptionPayment && this.useUserProductSubscriptionPayment === true) hasChanged = false
      if (this.canDoUserSubscriptionPayment === false && initCanDoUserProductSubscriptionPayment === null) hasChanged = false
      if (this.canDoUserSubscriptionPayment === initCanDoUserProductSubscriptionPayment) hasChanged = false
      
      if (hasChanged) {
        await this.setUserProductSubscriptionPayment(this.canDoUserSubscriptionPayment, 'getCanDoUserSubscriptionPayment')
      }

      payLoadingStore.set('getCanDoUserSubscriptionPayment', false, {
        initCanDoUserProductSubscriptionPayment,
        canDoUserProductSubscriptionPayment: this.canDoUserSubscriptionPayment,
        useUserProductSubscriptionPayment: this.useUserProductSubscriptionPayment,
        hasChanged
      })

      return hasChanged
    },

    async setUserProductSubscriptionPayment(newStateCanDoUserSubscriptionPayment: boolean, source?: string) {
      const initState = this.useUserProductSubscriptionPayment
      const hasChanged = initState !== newStateCanDoUserSubscriptionPayment
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('setUserProductSubscriptionPayment', true, {
        source,
        newState: newStateCanDoUserSubscriptionPayment,
        initState,
        hasChanged
      })

      if (!this.canDoUserSubscriptionPayment) this.useUserProductSubscriptionPayment = false
      else this.useUserProductSubscriptionPayment = newStateCanDoUserSubscriptionPayment

      if (hasChanged) {
        const payPriceStore = usePayPriceStore()
        await payPriceStore.initCalcuatePrice('setUserProductSubscriptionPayment')
      }

      payLoadingStore.set('setUserProductSubscriptionPayment', false, {
        hasChanged
      })

      return hasChanged
    },

    resetUserProductSubscriptionPayment(disableUserProductSubscriptionPayment = false) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('resetUserProductSubscriptionPayment', true)
      this.useUserProductSubscriptionPayment = null
      this.useUserProductSubscriptionData = null
      this.canDoUserSubscriptionPayment = null

      if (disableUserProductSubscriptionPayment) this.useUserProductSubscriptionPaymentIsDisabled = true

      payLoadingStore.set('resetUserProductSubscriptionPayment', false)
    }
  }
})
