import { CustomRouteConfig } from '.'
import { UserRole } from '@/graphql'

const routes: CustomRouteConfig[] = [
  {
    name: 'Priceff',
    path: '/p',
    component: () => import('@/views/priceff/index.vue'),
    meta: {
      requireAuth: true,
      requireRole: UserRole['Priceff'],
      layout: 'Admin',
      title: ''
    },
    children: [

      /************
      * Root
      ************/
      {
        name: 'Priceff.Dashboard',
        path: 'dashboard',
        component: () => import('@/views/priceff/dashboard.vue'),
        meta: {
          title: 'Dashboard'
        }
      },



      {
        name: 'Priceff.1.Parameters',
        path: 'parameters',
        component: () => import('@/views/priceff/1-parameters.vue'),
        meta: {
          title: 'Parameters'
        }
      },
      {
        name: 'Priceff.2.ModelsConfigurator',
        path: 'models-configurator',
        component: () => import('@/views/priceff/2-modelsConfigurator.vue'),
        meta: {
          title: 'Models configurator'
        }
      },
      {
        name: 'Priceff.3.ResourceTypes',
        path: 'resource-types',
        component: () => import('@/views/priceff/3-resourceTypes.vue'),
        meta: {
          title: 'ResourceTypes'
        }
      },
      {
        name: 'Priceff.4.Prices',
        path: 'prices',
        component: () => import('@/views/priceff/4-prices.vue'),
        meta: {
          title: 'Prices'
        }
      },
      {
        name: 'Priceff.CreateModel',
        path: 'model-create',
        component: () => import('@/views/priceff/createModel.vue'),
        meta: {
          title: 'Create parameters'
        }
      },


      // * Analytic
      {
        name: 'Priceff.Analytic.KPI',
        path: 'analytic/kpi',
        component: () => import('@/views/priceff/analytic/kpi.vue'),
        meta: {
          title: 'KPI'
        }
      },
      {
        name: 'Priceff.Analytic.SummaryCharts',
        path: 'analytic/charts/summary',
        component: () => import('@/views/priceff/analytic/summaryCharts.vue'),
        meta: {
          title: 'Charts'
        }
      },
      {
        name: 'Priceff.Analytic.DynamicCharts',
        path: 'analytic/charts/dynamic',
        component: () => import('@/views/priceff/analytic/dynamicCharts.vue'),
        meta: {
          title: 'Charts'
        }
      },
      {
        name: 'Priceff.Analytic.Revenue',
        path: 'analytic/revenue',
        component: () => import('@/views/priceff/analytic/revenue.vue'),
        meta: {
          title: 'Revenue'
        }
      },
      {
        name: 'Priceff.Analytic.Utilisation',
        path: 'analytic/utilisation',
        component: () => import('@/views/priceff/analytic/utilization.vue'),
        meta: {
          title: 'Utilisation'
        }
      }
    ]
  }
]

export default routes
