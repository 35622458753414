import { sortBy, get } from 'lodash';

type SortByParams<T> = {
    locale: string;
    list: T[];
    key: string | string[];
    direction?: 'asc' | 'desc';
};

export function sortByKey<T extends Record<string, any>>({
    locale,
    list,
    key,
    direction = 'asc',
}: SortByParams<T>): T[] {
    
    if (!list.length) {
        return [...list];
    }
    
    const sortKeys = typeof key === 'string' ? [key] : key;
    
    let sortedList = sortBy(list, sortKeys.map(sortKey => (item: T) => {
        const value = get(item, sortKey);
        return typeof value === 'string' ? value.toLocaleLowerCase(locale) : value;
    }));
    
    if (direction === 'desc') {
        sortedList = sortedList.reverse();
    }
    
    return sortedList;
}
