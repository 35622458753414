export default {
  location: 'Location',
  selectLocation: 'Select a location',
  infoSelectLocation: 'To view times, please first select a location at the top.',
  controllIO: 'Controller IO log',
  all: 'All',
  noLog: 'No log',
  on: 'ON',
  off: 'OFF',
  changedBy: 'Changed by:',
  successfull: 'Successful:',
  controllUnit: 'Controller unit log',
  doorCodes: 'Door codes log',
  notUsed: 'Not used',
  notUsedYet: 'This code has not been used yet',
  courts: 'Courts',
  light: 'Light',
  loading: 'Loading',
  noCourts: 'There are no courts here...',
  id: 'ID:',
  cameraAdded: 'Camera added',
  lightAdded: 'Light controller added',
  addCamera: 'Add camera',
  removeCamera: 'Remove camera',
  addLight: 'Add light controller',
  updateLight: 'Update light controller',
  addLightControlls: 'Add light controller to resource',
  noControls: 'No controller has been added to this location',
  selectNoxArea: 'Select area from NOX',
  selectArea: 'Select an area...',
  selectNoxTimeProfile: 'Select a timeprofile from NOX',
  selectTimeprofile: 'Select a timeprofile...',
  main: 'Main...',
  updateLightToResource: 'Update light controller to resource',
  lightStatus303: 'The NOX API responds with status 303. The controller is busy or defaults to sending the respons to a webhook. POST requests are queued on the controller.',
  lightStatus303Title: 'Controller unavailable',
  addCameraToResource: 'Add camera to resource',
  selectCamera: 'Select a camera',
  devices: 'Devices',
  controller: 'Controller',
  createController: 'Create a controller',
  updateController: 'Update controller',
  noAddedController: 'No controller has been added',
  updateArea: 'Update areas',
  latestUpdate: 'Last updated',
  never: 'Never',
  noxAreaId: 'NOX area Id',
  noxAreaName: 'NOX area name',
  areaState: 'Current area state',
  addController: 'Add a controller',
  urlIp: 'Url / IP',
  ipPlaceholder: '192.168.?.?',
  type: 'Type',
  selectType: 'Select a type',
  noxLogin: 'NOX login (noxLogin - 14....)',
  noxLoginPlaceholder: 'abx####',
  noxVersion: 'NOX version',
  selectVersion: 'Select a version',
  add: 'Add',
  door: 'Doors',
  addDoor: 'Add a door',
  noControll: 'No controller added',
  addDoorControllUnit: 'Add a controller unit first to work with doors',
  noArmedDoors: 'No doors are armed',
  nothingForThis: 'There is nothing for this...',
  cameraServer: 'Camera server (Milestone)',
  createCameraServer: 'Create camera server',
  cameraServerUrl: 'Server url: ',
  updateServer: 'Update server (and thumbnails)',
  noCameras: 'No cameras',
  milestoneCamera: 'Milestone camera id',
  showCamera: 'Show camera',
  updateCamera: 'Update camera',
  addCameraServer: 'Add camera server',
  password: 'Password',
  username: 'Username',
  filURL: 'FIL URL (e.g., http://kolding.padelclub.dk:8686/admin)',
  serverURL: 'SERVER URL (e.g., http://kolding.padelclub.dk:8081)',
  nox: 'Nox',
  r7: 'R7',
  r8: 'R8',
  restApi: 'RestApi',
  updatedCamera: 'The camera has been updated',
  addedServer: 'The server has been added',
  updatedServer: 'The server has been updated',
  updatedInfo: 'Your information has been updated',
  errorMessageId: 'No location ID was included...',
  timeProfileId: 'timeProfileId',
  company: 'Company',
  information: 'Information',
  log: 'Log',
  enableLights: 'Enable lights for future bookings',
  syncAccessCodes: 'Create access codes for future bookings',
  successEnableLights: 'Succesfully enabled lights for future bookings!',
  successSyncAccessCodes: 'Succesfully created access codes for future bookings!',
  titleSyncFutureBookings: 'Manual synchronization',
  descriptionSyncFutureBookings: 'If there are existing bookings at the location, the synchronization of access codes and lights for those bookings must be manually triggered. The buttons below can be used to trigger the synchronization. Before syncing, make sure that: ',
  listSyncFutureBookings: ['Light controllers are set up on the courts', 'Doors have been added to the User Default door group']
  
}
