<template lang="pug">
div
	div(v-if="!payCouponStore.selectedCouponSlug")
		b-link.m-0.text-primary.d-flex.align-content-center(
			:disabled="payStore.paymentIsStarted"
			@click="payCouponStore.showCouponModal = true")
			i.fe.fe-plus-circle.mr-2
			span {{ $t('components.app.pay.coupon.haveCouponButton') }}
				span(v-if="payCouponStore.userAutoCoupons.length") &nbsp;({{payCouponStore.userAutoCoupons.length}})

	div.d-flex.align-items-center.justify-center(v-else)
		span.m-0.text-muted.mr-3 {{ payCouponStore.selectedCouponSlug }}
		b-button(
			:disabled="payStore.paymentIsStarted"
			@click="payCouponStore.removeCoupon()"
			size="sm"
			variant="outline-danger")
			i.fe.fe-x-circle


	b-modal(
		:title="$t('components.app.pay.coupon.addCouponModal.title')"
		ref="add-coupon-modal"
		v-model="payCouponStore.showCouponModal"
		title-tag="h4"
		centered
		hide-footer
		size="sm")

		div(v-if="payCouponStore.userAutoCoupons.length")
			p.text-muted {{ $t('components.app.pay.coupon.autoCouponsModal.text') }}

			.cards-container
				b-card.card-bg-inverted.card-small-padding.cursor-pointer.hover-scale(
					v-for="(autoCoupon, index) in payCouponStore.userAutoCoupons"
					:key="index"
					@click="payCouponStore.setCouponSlug(autoCoupon.coupon.slug, false)")
					b-row
						b-col
							p.m-0 {{ autoCoupon.coupon.slug }}

						b-col(cols="auto")
							p.text-muted.m-0 -{{ currencyFormatter(autoCoupon.amountWithoutCoupon - autoCoupon.amountWithCoupon, locationStore.userLocation.currency) }}

			hr

		b-input(
			:placeholder="$t('components.app.pay.coupon.addCouponModal.codePlaceholder')"
			v-model="payCouponStore.couponSlugInput"
			:disabled="payCouponStore.loading")

		b-button.mt-4(
			:disabled="(payCouponStore.couponSlugInput && payCouponStore.couponSlugInput.length < 1) || payCouponStore.loading"
			@click="payCouponStore.setCouponSlug(payCouponStore.couponSlugInput)"
			block
			variant="primary"
			size="lg")
			span(v-if="payCouponStore.loading")
				b-spinner.button-loading(small type="grow")
				span {{ $t('components.app.pay.coupon.addCouponModal.addLoadingButton') }}
			span(v-else) {{ $t('components.app.pay.coupon.addCouponModal.addButton') }}

		b-link.d-block.m-0.mt-3.text-danger.text-center(@click="payCouponStore.showCouponModal = false") {{ $t('components.app.pay.coupon.addCouponModal.cancel') }}

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { usePayCouponStore } from '@/store/modules/pay/coupon'
import { usePayStore } from '@/store/modules/pay/pay'
import { currencyFormatter } from '@/utils/currencyFormatter'
import { useLocationStore } from '@/store/modules/location'

@Component({
	methods: {
		currencyFormatter
	}
})
export default class CompanyUsersUserView extends Vue {
	payCouponStore = usePayCouponStore()
	payStore = usePayStore()
	locationStore = useLocationStore()
}
</script>

<style lang="sass" scoped>

</style>
