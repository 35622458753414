import { CustomRouteConfig } from './'
import { UserRole } from '@/graphql'

const routes: CustomRouteConfig[] = [
  {
    name: 'Technician',
    path: '/t',
    component: () => import('@/views/technician/index.vue'),
    meta: {
      requireAuth: true,
      requireRole: UserRole['Technician'],
      layout: 'Admin',
      title: ''
    },
    children: [

      /************
      * Root
      ************/
      {
        name: 'Technician.Dashboard',
        path: 'dashboard',
        component: () => import('@/views/technician/dashboard.vue'),
        meta: {
          title: 'Startside'
        }
      },

      /************
      * Locations
      ************/
      {
        name: 'Technician.Locations',
        path: 'locations',
        component: () => import('@/views/technician/locations/locations.vue'),
        meta: {
          title: 'Lokationer'
        }
      },
      {
        name: 'Technician.Location',
        path: 'locations/:id',
        component: () => import('@/views/technician/locations/location.vue'),
        meta: {
          title: 'Lokation'
        }
      }
    ]
  }
]

export default routes
