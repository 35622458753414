export default {
  errorVerify: 'A mistake happened...',
  goToSignIn: 'Go to sign in',
  missingToken: 'A token is missing...',

  verifyingEmail: 'Approves email...',
  verified: {
    title: 'Your email has been approved',
    description: 'You can now log in to the platform',
    signIn: 'Log in to your account'
  }
}