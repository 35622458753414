export default {
  subHeader: 'Access control code',

  kpi: {
    timesUsed: 'Number of times used'
  },

  usage: {
    title: 'Usage',
    table: {
      none: {
        title: 'Not used',
        text: 'This code has not been used yet'
      }
    }
  },

  times: {
    title: 'Access times',
    table: {
      access: 'Access',
      none: {
        title: 'No access times',
        text: 'This code does not have any access times'
      },
      type: {
        resourceBookingAttendee: 'Booking participation',
        resourceBooking: 'Booking',
        workshop: 'Event'
      }
    }
  },

  infoList: {
    lastSyncAt: 'Latest sync',
  },

  fullAccessTo: {
    title: 'Full access to',
    changeButton: 'Change',
    doorGroups: 'Door groups',
    doors: 'Doors'
  },

  actions: {
    changeAccessCode: 'Change PIN code / information',
    avancedAccessCodeInfo: '(Advanced) Info about code on location code',
    connectToUser: 'Assign to user',
    delete: 'Delete code'
  },

  avancedAccessCodeInfoModal: {
    title: 'Info about code at locations',
    table: {
      locationName: 'Location',
      doors: 'Doors',
      startDate: 'Start',
      endDate: 'End',
      status: 'Status',
      activatedOnUnitAt: 'Activated',
      syncedToControllerInfo: 'Access code is synced to controller unit',
      notSyncedToControllerInfo: 'Access code is not synced to controller unit. The access code should be synced 1,5 hours before the booking start.',
      connectionErrorInfo: 'There is a delay in the connection to the controller unit. Try again in 30 seconds.'
    }
  },

  connectToUserModal: {
    title: 'Assign to user',
    connectButton: 'Assign to user',
    connectLoadingButton: 'Associates...'
  },

  changeAccessCodeModal: {
    title: 'Change code',
    updateButton: 'Update',
    updateLoadingButton: 'Updating...',

    successToast: 'The code is updated'
  },

  accessCodeFullAccessChangeModal: {
    title: 'Change full access',
    doorsLabel: 'Doors',
    groupsLabel: 'Groups',
    updateButton: 'Update',
    updateLoadingButton: 'Updating...'
  },

  deleteCode: {
    sure: {
      text: 'Are you sure you want to delete this code? If you press yes, the code will be deleted within the next few minutes',
      title: 'Delete code'
    },
    successToast: 'Code will be deleted within the next few minutes'
  }
}