<template>
  <Teleport to="body">
    <transition name="fade">
    <div v-if="visible" class="modal-overlay" @click.self="close">
      <div :class="['modal-content', { centered }]">
        <component :is="titleTag" class="modal-title">{{ title }}</component>
        <hr />
        <div :class="['modal-message', bodyClass]">{{ message }}</div>
        <hr :style="{ 'margin-bottom': 3 }" />
        <div :class="['modal-actions', footerClass]">
          <button @click="close" :class="['button-cancel', cancelVariant]">
            {{ cancelTitle }}
          </button>
          <button @click="confirm" :class="['button-confirm', resolvedOkVariant]">
            {{ okTitle }}
          </button>
        </div>
      </div>
    </div>
    </transition>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick, computed } from "vue";
// @ts-ignore
import Teleport from "vue2-teleport"; // TO-DO: Once migrated to Vue3: use https://vuejs.org/guide/built-ins/teleport.html

export default defineComponent({
  name: "ConfirmModal",
  components: {
    Teleport,
  },
  props: {
    title: {
      type: String,
      default: "Confirm Action",
    },
    message: {
      type: String,
      required: true,
    },
    titleTag: {
      type: String,
      default: "h4",
    },
    centered: {
      type: Boolean,
      default: true,
    },
    bodyClass: {
      type: String,
      default: "text-muted",
    },
    footerClass: {
      type: String,
      default: "py-2",
    },
    okVariant: {
      type: String,
      default: "btn-danger",
    },
    cancelVariant: {
      type: String,
      default: "btn-outline-secondary",
    },
    okTitle: {
      type: String,
      default: "Yes",
    },
    cancelTitle: {
      type: String,
      default: "Cancel",
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    let resolvePromise: ((value: boolean) => void) | null = null;

    const open = async (): Promise<boolean> => {
      visible.value = true;
      await nextTick();

      return new Promise<boolean>((resolve) => {
        resolvePromise = resolve;
      });
    };

    const resolvedOkVariant = computed(() => {
      const variantMapping: { [key: string]: string } = {
        success: 'btn-success',
        danger: 'btn-danger'
      };
      return variantMapping[props.okVariant] || props.okVariant;
    });

    const close = () => {
      visible.value = false;
      if (resolvePromise) resolvePromise(false);
      emit("close");
    };


    const confirm = () => {
      visible.value = false;
      if (resolvePromise) resolvePromise(true);
      emit("confirm");
    };

    return {
      visible,
      open,
      close,
      confirm,
      resolvedOkVariant
    };
  },
});
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: left;
  max-width: 600px;
  margin: 1em;
}

.modal-content.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-title {
  margin: 0;
  font-size: 0.9375rem;
  font-weight: bold;
}

.modal-message.text-muted {
  color: #6c757d;
  margin-bottom: 5px;
  margin-top: 5px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
}

.button-confirm {
  padding: 8px 16px;
  margin-left: 1em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.button-confirm.btn-danger {
  background-color: #d9534f;
  color: #fff;
}

.button-confirm.primary {
  background-color: #2C2B2C;
  color: #fff;
}

.button-cancel {
  padding: 8px 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.button-cancel.btn-outline-secondary {
  background-color: transparent;
  color: #6c757d;
  border: 1px solid #6c757d;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
