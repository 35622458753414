export default {
  
  daysCount: {
    day: 'day',
    days: 'days',

    when: '',
    fromTime: 'from',
    to: 'to'
  },
  share: 'Share',
  participants: 'Participants',
}