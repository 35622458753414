export default {
    title: "Update payment status",
    description: "Use the form below to update the payment status. Select the desired status from the dropdown and confirm your action by clicking the button.",
    select: {
        label: "Payment status"
    },
    actions: {
        update: "Update payment",
        close: "Close"
    }
}