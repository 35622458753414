export default{
    openingHours: 'Opening hours',
    weekday: 'Weekday',
    weekend: 'Weekend',
    price: 'Price',
    aPriceInterval: 'A price intervals',
    weekdays: 'Weekdays',
    weekends: 'Weekends',
    shortName: 'Short name',
    updatePrice: 'Update price',
    handleDates: 'Handle dates',
    handlePriceDates: 'Handle price dates',
    label: 'Label',
    updatingPrice: 'Updating price',
    createType: 'Create type',
    creatingNewType: 'You are now creating a new type. Fill out the fields below.',
    removeLogo: 'Remove logo',
    logoRemoved: 'Logo will be removed',
    logoInformation: 'Please note:\n* Max logo width is 70px\n* Max logo height is 30px\n* The logo will be scaled',
    courtUpdated: 'Court has been updated',
    updatedPrice: 'Price is updated',
    addedDate: 'A date has been added',
    deletedDate: 'The date is deleted',
    usingDates: 'Is using dates',
    type: 'Type',
    special: 'Special',
    allDay: 'All day',
    shareBooking: "Share this booking",
}

