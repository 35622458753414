export default {
  subTitle: 'Point account',

  autoPoints: {
    title: 'Automatic points',
    settingsButton: 'Settings',
    notActive: {
      title: 'Not active',
      text: 'No automatic points have been added to this points account'
    },
    info: {
      automaticAddPointsAmount: 'Points',
      resetPointsOnAutomaticRecharge: 'Reset when filling',
      automaticPointsInsertTimesPrYear: 'Times a year',
      automaticPointsInsertOnTheFirstOfMonth: 'On the 1st of the month.'
    }
  },

  actions: {
    addOrRemove: 'Add / remove points'
  },

  info: {
    pointAmountOtOTrue: 'Number one to one',
    pointAmountOtOFalse: 'Number one to less',
    pointsAmount: 'Total',
    pointAmountOtOFalseRatio: 'Ratio one to less',
    pointAmountOtOMixRatio: 'Ratio mix'
  },

  transactionsTable: {
    title: 'Transactions',
    amount: 'Amount',
    notes: 'Notes',
    usedOnCompany: 'At',
    oto: 'OtO',
    manualTransactionBy: 'Created by',
    platformFeeAmount: 'Fee'
  },

  pointAccountSettingsModal: {
    title: 'Point account settingsr',
    text: 'Here you have the settings for automatic points for that point account',
    automaticPointsActive: 'Activate automatic points',
    automaticAddPointsAmount: 'Points amount',
    resetPointsOnAutomaticRecharge: 'Reset when filling',
    updatePointAccountButton: 'Update settings',
    updatePointAccountLoadingButton: 'Updating settings...',
    successToast: 'The information has been updated'
  }
}