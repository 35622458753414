import { PaymentMethod, PaymentUserAllowedPaymentTypesQuery } from '@/graphql'
import { defineStore } from 'pinia'
import { usePayLoadingStore } from './loading'
import { usePayPaymentMethodStore } from './paymentMethod'
import { i18n } from '@/locales/setupI18n'
 
interface PayPointsState {
  selectedPointAccountId: string | null
  availablePointAccounts: PaymentUserAllowedPaymentTypesQuery['paymentUserAllowedPaymentTypes']['pointAccounts']
  removeDiscountToDoPayment: boolean
}

export const usePayPointsStore = defineStore({
  id: 'PayPoints',

  state: (): PayPointsState => ({
    selectedPointAccountId: null,
    availablePointAccounts: [],
    removeDiscountToDoPayment: false
  }),

  getters: {
    usePointAccountId: state => {
      const payPaymentMethod = usePayPaymentMethodStore()
      return payPaymentMethod.selectedPaymentMethod === PaymentMethod['Points']
        ? state.selectedPointAccountId
        : null
    },

    availablePointAccountsIncludesOtherThanPrivate: (state): boolean => {
      return state.availablePointAccounts.some(x => !!x.userGroup)
    },

    availablePointAccountsForSelect: (state): { value: string, text: string }[] => {
      return state.availablePointAccounts.map(x => ({
        value: x.id,
        text: `${x.userGroup ? x.userGroup.name : 'Privat'} (${x.pointsAmount} points )` 
      }))
    }
  },

  actions: {

    setPointAccountId(pointAccountId: string | null) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('setPointAccountId', true, {
        pointAccountId
      })
      this.selectedPointAccountId = pointAccountId
      payLoadingStore.set('setPointAccountId', false)
    },

    setPointAccounts(data: PaymentUserAllowedPaymentTypesQuery['paymentUserAllowedPaymentTypes']) {
      this.availablePointAccounts = []

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('getPointAccounts', true)

      this.availablePointAccounts = data.pointAccounts
      this.removeDiscountToDoPayment = data.pointPaymentRemoveDiscountToDo

      if (this.selectedPointAccountId && !this.availablePointAccounts.find(x => x.id === this.selectedPointAccountId)) {
        this.setPointAccountId(null)
      }
      else if (this.availablePointAccounts.length && !this.selectedPointAccountId) {
        this.setPointAccountId(this.availablePointAccounts[0].id)
      }

      payLoadingStore.set('getPointAccounts', false, {
        availablePointAccounts: this.availablePointAccounts,
        removeDiscountToDoPayment: this.removeDiscountToDoPayment
      })
    },

    resetPoints() {
      this.selectedPointAccountId = null
      this.availablePointAccounts = []
    }
  }
})
