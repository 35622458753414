<template lang="pug">

div(v-if="shopStore.termsAndConditions" v-html="shopStore.termsAndConditions")

</template>
    
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { useShopStore } from '@/store/modules/ecom'


@Component({
  components: {},
  apollo: {
  }
})
export default class CompanyUsersUserView extends Vue {

  shopStore = useShopStore()

  created() {
    this.shopStore.getTermsAndConditions()
  }

}
</script>
  
<style lang="sass" scoped>
  
</style>