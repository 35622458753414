<template>
	<div class="input-group input-group-merge only-prepend">
		<b-form-select
			class="form-control-prepended"
			id="lang-switcher"
			v-model="selectedLang"
			:options="langOptions"
			@change="updateLocale"
		/>
		<div class="input-group-prepend">
			<div class="input-group-text">
				<span class="fe fe-globe"></span>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { ref, computed, watch, defineComponent, onMounted } from "vue";
import { LocaleType, useLocaleStore } from "@/store/modules/locale";
import { i18n } from "@/locales/setupI18n";
import { useUserStore } from "@/store/modules/user";

export default defineComponent({
	name: "LanguageSwitcher",

	setup(props, { emit }) {
		const userStore = useUserStore();
		const localeStore = useLocaleStore();

		const selectedLang = ref<null | LocaleType>(null);

		const langOptions = computed(() => [
			{
				text: i18n.t("common.staticTemp.localeType.danishDk"),
				value: LocaleType.DaDk,
			},
			{
				text: i18n.t("common.staticTemp.localeType.englishGb"),
				value: LocaleType.EnGb,
			},
			{
				text: i18n.t("common.staticTemp.localeType.germanDe"),
				value: LocaleType.DeDe,
			},
			{
				text: i18n.t("common.staticTemp.localeType.finnishFi"),
				value: LocaleType.FiFi,
			},
		]);

		async function updateLocale(locale: LocaleType) {
			localeStore.setI18nLocale(locale);
		}

		watch(
			() => i18n.locale as LocaleType,
			(newLocale: LocaleType) => {
				selectedLang.value = newLocale;
				emit("selectedLang", newLocale);
			},
			{ immediate: true }
		);

		return {
			selectedLang,
			langOptions,
			updateLocale,
		};
	},
});
</script>

<style lang="scss" scoped></style>
