import Vue from "vue";
import VueI18n from "vue-i18n";
import { getBrowserLocale } from "./getBrowserLocale";
import { Currency } from "@/graphql";
import { numberFormats } from "./lang/numberFormats";
import { Preferences } from "@capacitor/preferences";
import { LocaleType, useLocaleStore } from "@/store/modules/locale";
import pinaStore from "@/store/index";
import enLocale from "./lang/en-GB";

// This is specific for @fullcalendar
import deLocale from "@fullcalendar/core/locales/de";
import daLocale from "@fullcalendar/core/locales/da";
import fiLocale from "@fullcalendar/core/locales/fi";
import { LocaleInput } from "@fullcalendar/core";

interface LangModule {
	message: Recordable;
}

export const LOCALE_STORE_KEY = "la";
const defaultLocale: LocaleType = LocaleType.EnGb;

Vue.use(VueI18n);
export const i18n = new VueI18n({
	messages: {
		"en-GB": enLocale.message,
	},
	silentTranslationWarn: true,
	fallbackLocale: "en-GB",
});

export async function initLocale() {
	const Locale = useLocaleStore(pinaStore);

	const { value: storageLocale } = (await Preferences.get({
		key: LOCALE_STORE_KEY,
	})) as { value: LocaleType };
	const browserLocale = getBrowserLocale() as null | LocaleType;
	let currentLocale: LocaleType | null = null;

	if (storageLocale && Locale.availableLocales.includes(storageLocale)) {
		currentLocale = storageLocale;
	} else if (browserLocale && Locale.availableLocales.includes(browserLocale)) {
		currentLocale = browserLocale;
	} else {
		currentLocale = defaultLocale;
	}

	await changeLocale(currentLocale);
}

const loadedLanguages: LocaleType[] = [];

function setI18nLanguage(locale: LocaleType) {
	i18n.locale = locale.replace("_", "-");
	document.querySelector("html")!.setAttribute("lang", locale);
	return locale;
}

export async function changeLocale(locale: LocaleType) {
	// If the same language
	if (i18n.locale === locale) {
		return Promise.resolve(setI18nLanguage(locale));
	}

	// If the language was already loaded
	if (loadedLanguages.includes(locale)) {
		return Promise.resolve(setI18nLanguage(locale));
	}

	const langModule = (
		(await import(`./lang/${locale.replace("_", "-")}.ts`)) as any
	).default as LangModule;

	if (!langModule) return;

	const { message } = langModule;

	i18n.setLocaleMessage(locale, message);
	loadedLanguages.push(locale);

	await Preferences.set({ key: LOCALE_STORE_KEY, value: locale });

	return setI18nLanguage(locale);
}

// This is specific for @fullcalendar
export function setLocaleInput(locale: string) {
	switch (locale) {
		case LocaleType.DaDk:
			return [daLocale] as LocaleInput[];
		case LocaleType.DeDe:
			return [deLocale] as LocaleInput[];
		case LocaleType.FiFi:
			return [fiLocale] as LocaleInput[];
		default:
			return [] as LocaleInput[];
	}
}

export function changeCurrency(currency: Currency) {
	i18n.setNumberFormat(i18n.locale, numberFormats[currency]);
}
