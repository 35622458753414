import { UserRole } from '@/graphql'

export interface DefaultRoutesPath {
  home: string
  settings: string
}

type DefaultRoutes = {
  [role in UserRole]: DefaultRoutesPath
}

const defaultRoutes: DefaultRoutes = {
  'Admin': {
    home: 'Admin.Dashboard',
    settings: 'Admin.Settings'
  },
  'Technician': {
    home: 'Technician.Dashboard',
    settings: 'Technician.Settings'
  },
  'Priceff': {
    home: 'Priceff.Dashboard',
    settings: 'Priceff.Settings'
  },
  'User': {
    home: 'User.Home',
    settings: 'User.Settings'
  },
  'Company': {
    home: 'Company.Dashboard',
    settings: 'Company.Settings'
  },
  'Franchise': {
    home: 'Franchise.Dashboard',
    settings: 'Franchise.Settings'
  },
  'System': {
    home: '',
    settings: ''
  }
}

export default defaultRoutes
