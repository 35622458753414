export default {
    cancel: 'Cancel purchase',
  
    productInfo: {
      info: 'You are about to remove a participant with a discount from your booking, at a price of',
    },
    
    done: {
      title: 'Your purchase has been approved',
      text: 'The participant has been removed from your booking. You can add new participants on your booking.',
      backToStart: 'Back to start'
    },
  
    error: {
      title: 'A error happened...',
      cancel: 'Cancel'
    }
  }