<template lang="pug">

b-overlay.cards-container(
  :show="Auth.userPlatformLoading"
  spinner-variant="primary"
  variant="transparent")
  b-card.card-small-padding.card-no-border.cursor-pointer.mb-3.bg-light.shadow-none(
    v-bind:class="{ 'hover-scale': !Auth.currentPlatformConnection, 'cursor-pointer': !Auth.currentPlatformConnection, 'card-bg-inverted': useInvertedBg }"
    @click="handleUserClick()")
    b-row(align-v="center")
      b-col
        h4.mb-0 {{ $t('common.userRole.user') }}

      b-col(cols="auto")
        div(v-if="Auth.currentPlatformConnection")
          b-button(
            size="md"
            :disabled="signedInAsUser"
            @click="Auth.signInPlatform({userPlatformConnectionId: locationUserDefault.company.userPlatformConnection.id, refreshPage: reloadPageOnChange})"
            :variant="signedInAsUser ? 'outline-secondary' : 'outline-primary'")
            span(v-if="signedInAsUser") {{ $t('components.auth.userPlatformConnections.isSignedIn') }}
            span(v-else) {{ $t('components.auth.userPlatformConnections.signIn') }}
        div(v-else)
          i.fe.fe-chevron-right


  b-card.card-small-padding.card-no-border.cursor-pointer.mb-3.bg-light.shadow-none(
    v-for="platform in platformConnections"
    :key="platform.id"
    v-bind:class="{ 'hover-scale': !Auth.currentPlatformConnection, 'cursor-pointer': !Auth.currentPlatformConnection, 'card-bg-inverted': useInvertedBg }"
    @click="Auth.currentPlatformConnection ? undefined : Auth.signInPlatform({userPlatformConnectionId: platform.id, refreshPage: reloadPageOnChange})")
    b-row(align-v="center")
      b-col
        div(v-if="platform.role === 'Company'")
          h4.mb-0
            span(v-if="!userOnlyHaveUserRoles") {{ $t('common.userRole.company') }} - 
            span {{ platform.company.name }}
          p.text-muted.m-0.mt-1 {{ platform.company.streetName }} - {{ platform.company.zipCode }} {{ platform.company.city }}

        div(v-if="platform.role === 'Franchise'")
          h4.mb-0
            span(v-if="!userOnlyHaveUserRoles") {{ $t('common.userRole.franchise') }} - 
            span {{ platform.franchise.name }}
          p.text-muted.m-0.mt-1 {{ platform.franchise.streetName }} - {{ platform.franchise.zipCode }} {{ platform.franchise.city }}

        div(v-if="platform.role === 'Admin'")
          h4.mb-0 {{ $t('common.userRole.admin') }}

        div(v-if="platform.role === 'Technician'")
          h4.mb-0 {{ $t('common.userRole.technician') }}

        div(v-if="platform.role === 'Priceff'")
          h4.mb-0 {{ $t('common.userRole.priceff') }}

      b-col(cols="auto")
        div(v-if="Auth.currentPlatformConnection")
          b-button(
            size="md"
            :disabled="platform.id === Auth.currentPlatformConnection.id"
            @click="Auth.signInPlatform({userPlatformConnectionId: platform.id, refreshPage: reloadPageOnChange})"
            :variant="platform.id === Auth.currentPlatformConnection.id ? 'outline-secondary' : 'outline-primary'")
            span(v-if="platform.id === Auth.currentPlatformConnection.id") {{ $t('components.auth.userPlatformConnections.isSignedIn') }}
            span(v-else) {{ $t('components.auth.userPlatformConnections.signIn') }}
        div(v-else)
          i.fe.fe-chevron-right

  ChangeUserDefaultLocation(
    :returnToHome="true"
    :showChangeLink="false"
    :title="$t('pages.auth.signIn.changeUserDefaultLocation.title')"
    :description="$t('pages.auth.signIn.changeUserDefaultLocation.description')"
    ref="changeUserDefaultLocation"
  )
  

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetLocationUserDefault, GetLocationUserDefaultQuery } from '@/graphql'
import { useAuthStore } from '@/store/modules/auth'
import ChangeUserDefaultLocation from '@/components/app/changeUserDefaultLocation.vue'

@Component({
  components: {
    ChangeUserDefaultLocation,
  },  
  apollo: {
    locationUserDefault: {
      query: GetLocationUserDefault
    }
  }
})
export default class CompanyUsersUserView extends Vue {

  @Prop({ required: false, default: true }) private useInvertedBg!: boolean

  Auth = useAuthStore()

  locationUserDefault: GetLocationUserDefaultQuery['locationUserDefault'] = null

  get abc() {
    return this.locationUserDefault!.company.userPlatformConnection!.id
  }

  @Prop({ required: false, default: false }) private reloadPageOnChange!: boolean

  get userOnlyHaveUserRoles() {
    const check = this.Auth.userPlatformConnections.find(x => x.role !== 'User')
    return check ? false : true
  }

  get platformConnections() {
    return this.Auth.userPlatformConnections.filter(x => x.role !== 'User')
  }

  get signedInAsUser() {
    return this.Auth.currentPlatformConnection!.role === 'User'
  }

  handleUserClick() {
    if (!this.locationUserDefault) {
      //@ts-ignore
      this.$refs['changeUserDefaultLocation'].show()
    } else if (!this.locationUserDefault.company.userPlatformConnection) {
      //@ts-ignore
      this.$refs['changeUserDefaultLocation'].show()
    } else {
      this.Auth.signInPlatform({ userPlatformConnectionId: this.locationUserDefault.company.userPlatformConnection.id, refreshPage: this.reloadPageOnChange })
    }
  }

}
</script>

<style lang="sass" scoped>

</style>