export default {
  title: 'Add attendee',
  close: 'Close',

  text: {
    one: 'To this booking you can add up to',
    two: 'other users. You have chosen',
    three: 'out of',
    four: 'possible.'
  },

  backInPoints: "If you add a participant who has a membership, you will get the participant's discount back in points.",

  addNewFriendButton: 'Add new friend',
  maxAttendees: 'You can not add more friends ({0} out of {1})',

  friendSeachPlaceholder: 'Search friends (name or phone number)',

  noFriends: {
    title: 'You have not added any friends',
    text: 'No persons were found on this phone number. Try another.'
  },
  notFound: {
    title: 'No friends found',
    text: 'No persons were found on this phone number. Try another.'
  },

  friendAction: {
    isAdded: 'Is added',
    add: 'Add'
  }
}