export default {
  subTitle: 'Welcome',
  pageTitle: 'Home',

  refreshHover: 'Update page',
  controlHover: 'Go to court control',

  control: {
    title: 'Control',
    courtsTitle: 'Courts',
    doorsTitle: 'Doors',
    location: {
      title: 'Location',
      noLocationName: 'Location name unknown'
    }
  }
  
}