export default {
  title: 'Add friend',
  closeWindow: 'Cancel',
  description: 'Here you have the opportunity to find your friends who you will then be able to add to your booking. Search by email or mobile number below:',
  seachPlaceholder: 'Write e-mail or mobile number',

  seachNotValid: {
    title: 'Write e-mail or mobile number',
    text: 'Once you have written an e-mail or mobile number, the result will be displayed here.'
  },
  
  notFound: {
    title: 'No users found',
    text: 'No persons were found on this phone number. Try another phone number or check for typos.',
  },

  addFriendButton: 'Add',
  youAreFriends: 'You are friends',

  createFriendshipSuccessToast: 'Your friend has been added.'
}