<template>
    <b-alert
        v-model="showBanner"
        class="floating-alert"
        variant="danger"
        dismissible
        @dismissed="dismissBanner"
    >
        <h3>{{ $t('components.shared.announcementBanner.title') }}</h3>
        <p>{{ $t('components.shared.announcementBanner.description', { date: maintenanceDate, startTime: maintenanceStartTime, endTime: maintenanceEndTime }) }}</p>
    </b-alert>
</template>
<script lang="ts">
export default {
    data() {
        return {
            showBanner: import.meta.env.VITE_SHOW_MAINTENANCE_BANNER === 'true',
            maintenanceDate: import.meta.env.VITE_MAINTENANCE_DATE || 'TBD',
            maintenanceStartTime: import.meta.env.VITE_MAINTENANCE_START_TIME || 'TBD',
            maintenanceEndTime: import.meta.env.VITE_MAINTENANCE_END_TIME || 'TBD',
        }
    },
    updated() {
        const path = window.location.pathname
        const isAllowedPath = path === '/' || /\/(c|u|f|t)\/|\/sign-in/.test(path)

        if (localStorage.getItem('bannerDismissed') === 'true' || !isAllowedPath) {
            this.showBanner = false
        }
    },
    methods: {
        dismissBanner() {
            this.showBanner = false
            localStorage.setItem('bannerDismissed', 'true')
        }
    }
}
</script>
<style lang="scss" scoped>
.floating-alert {
    position: fixed;
    top: 40px;
    left: 0;
    width: 100%;
    z-index: 9999;
    border-radius: 0;
}
</style>