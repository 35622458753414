export default {
  
  welcomeTitle: 'Welcome',
  youAreSignedInAt: 'You are logged in to',

  extendBooking: {
    title: 'You can continue your booking',
    description: 'Either you have a booking now or you just had. You have the opportunity to play on {0} for half an hour more.',
    submit: 'Continue booking'
  },

  companyMessage: {
    title: 'Message from {0}',
    lastUpdated: 'Last updated',

    viewFullTrue: 'Hide',
    viewFullFalse: 'Show',
    viewFullMessage: 'message'
  },

  cta: {
    createBooking: 'Create booking',
    viewWorkshops: 'View events',
    buyTraning: 'Buy training',
    friends: 'Friends'
  },

  bookingNext: {
    title: 'Your next booking',
    viewCalendar: 'View calendar'
  },

  pointAccount: {
    title: 'Points account',
    buyPointsOne: 'Buy',
    buyPointsTwo: 'points',
    pointCount: 'points'
  },

  userProductSubscription: {
    title: 'Membership',
    createSubscription: 'Create membership',
    viewSubscription: 'View membership'
  },

  friendsModal: {
    title: 'Your friends'
  },

  ranglistenLogin: {
    text: 'Log into Ranglisten'
  }

}