<template lang="pug">
div
  b-card.bg-light.shadow-none(v-if="!reversedOrderHistory.length") 
    span {{ $t("components.app.ecom.ecomOrderHistory.noOrderHistory") }}

  b-card.card-rows-wrapper.border-secondary.shadow-none.mt-4(v-else v-for="(order, index) in paginatedOrderHistory" :key="order.id")
    div
      .py-2
        .card-rows-wrapper.card-rows-wrapper--small-padding
          .card-rows-row
            div
              h5.mb-0 {{ order.createdUtc | moment('DD MMM YYYY')}}
            div.d-flex.align-items-center
              b-badge.ml-2(
                size="sm"
                :variant="HandlingStatus(order.handlingStatus).variant"
                style="line-height: normal")  {{ HandlingStatus(order.handlingStatus).label }}
    div
      .px-1.py-3
        .card-rows-wrapper.card-rows-wrapper--small-padding
          .card-rows-row.m-0
            h5.mb-0 {{ $t("components.app.ecom.ecomOrderHistory.order") }}
            div.d-flex.align-items-center
              h5.m-0
                span {{ $t("components.app.ecom.ecomOrderHistory.price.price") }}
          .card-rows-row(v-for="item in order.items" ).my-0 
            div.mr-2
              small.text-muted {{item.quantity}} x {{ item.name }}
            div.d-flex.align-items-center
              h5.m-0 {{ currencyFormatter(item.itemTotalSum, currency()) }}
          .card-rows-row.my-0 
            div
            div.d-flex.align-items-center
              h5.m-0 {{ $t("components.app.ecom.ecomOrderHistory.price.total") }}: {{ currencyFormatter(order.totalSum, currency()) }}

    div
      .px-1.py-3
        .card-rows-wrapper.card-rows-wrapper--small-padding(v-if="order.addressContainer.hasSeparateDeliveryAddress && order.addressContainer.deliveryAddress")
          .card-rows-row.m-0
            h5.mb-0 {{ $t("components.app.ecom.ecomOrderHistory.address.deliveryAddress") }}
            div.d-flex.align-items-center
              h5.m-0
                span {{ $t("components.app.ecom.ecomOrderHistory.address.billingAddress") }}
          .card-rows-row.my-0 
            div.mr-2
              small.text-muted {{ order.addressContainer.deliveryAddress.address1 }} <br/>
              small.text-muted {{ order.addressContainer.deliveryAddress.postCode }} {{ order.addressContainer.deliveryAddress.city }}, {{ order.addressContainer.deliveryAddress.country }}
            div.d-flex.align-items-center
              small.text-muted {{ order.addressContainer.billingAddress.address1 }} <br/> {{ order.addressContainer.billingAddress.postCode }} {{ order.addressContainer.billingAddress.city }}, {{ order.addressContainer.billingAddress.country }}

        .card-rows-wrapper.card-rows-wrapper--small-padding(v-else)
          .card-rows-row.m-0
            h5.mb-0 {{ $t("components.app.ecom.ecomOrderHistory.address.deliveryAddress") }}
          .card-rows-row.my-0 
            div.mr-2
              small.text-muted {{ order.addressContainer.billingAddress.address1 }}
              small.text-muted {{ order.addressContainer.billingAddress.postCode }} {{ order.addressContainer.billingAddress.city }}, {{ order.addressContainer.billingAddress.country }}
    
  div
    b-pagination(v-model="currentPage" :total-rows="reversedOrderHistory.length" :per-page="itemsPerPage")


</template>
  
    
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { } from '@/graphql';
import { useShopStore } from '@/store/modules/ecom';
import { currencyFormatter } from '@/utils/currencyFormatter';


@Component({
  components: {},
  methods: {
    currencyFormatter
  }
})

export default class CompanyUsersUserView extends Vue {

  @Prop({ required: true }) private orderHistory!: Array<any>

  currentPage = 1;
  itemsPerPage = 2;

  shopStore = useShopStore()

  get reversedOrderHistory() {
    return this.orderHistory?.slice().reverse()
  }

  get paginatedOrderHistory() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.reversedOrderHistory.slice(startIndex, endIndex);
  }

  currency() {
    return this.shopStore.currencyCode
  }

  HandlingStatus(type: string) {
    switch (type) {
      case 'OPEN':
        return {
          label: this.$t("components.app.ecom.ecomOrderHistory.deliveryInfo.open") as string,
          variant: 'info'
        }
      case 'COMPLETED':
        return {
          label: this.$t("components.app.ecom.ecomOrderHistory.deliveryInfo.completed") as string,
          variant: 'success'
        }
      case 'IN_PROGRESS':
        return {
          label: this.$t("components.app.ecom.ecomOrderHistory.deliveryInfo.inProgress") as string,
          variant: 'highlight'
        }
      case 'CANCELLED':
        return {
          label: this.$t("components.app.ecom.ecomOrderHistory.deliveryInfo.cancelled") as string,
          variant: 'secondary'
        }
      case 'ERROR':
        return {
          label: this.$t("components.app.ecom.ecomOrderHistory.deliveryInfo.error") as string,
          variant: 'danger'
        }
      default:
        return {
          label: '',
          variant: ''
        }
    }
  }
}
</script>
    
<style lang="sass" scoped>
    
</style>