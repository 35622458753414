import { defineStore } from 'pinia'
import { GetSubscriptionProductGiftCardValidate, GetSubscriptionProductGiftCardValidateQuery, GetSubscriptionProductGiftCardValidateQueryVariables } from '@/graphql'
import { i18n } from '@/locales/setupI18n'
import app from '@/main'
import { apolloClient } from '@/plugins/apollo'
import { usePayLoadingStore } from './loading'
import moment from 'moment-timezone'
import { usePayPriceStore } from './price'


interface PaySubscriptionProductGiftCardState {
  selectedSubscriptionProductId: string | null

  selectedGiftCardCode: string | null
  newSubscriptionStartDate: Date | null

  giftCardCodeInput: string | null
  showGiftCardModal: boolean
  
  validateLoading: boolean
}

export const usePaySubscriptionProductGiftCardStore = defineStore({
  id: 'PaySubscriptionProductGiftCard',

  state: (): PaySubscriptionProductGiftCardState => ({
    selectedSubscriptionProductId: null,

    selectedGiftCardCode: null,
    newSubscriptionStartDate: null,

    giftCardCodeInput: null,
    showGiftCardModal: false,

    validateLoading: false
  }),

  getters: {
    showSubscriptonProductGiftCardOption: state => state.selectedSubscriptionProductId,
    newSubscriptionStartDateFormated: state => state.newSubscriptionStartDate ? moment.utc(state.newSubscriptionStartDate).format('DD/MM/YYYY') : null
  },

  actions: {
    async setSubscriptionProductGiftCardCode(subscriptionProductGiftCardCode: string | null, validate = true) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('setSubscriptionProductGiftCardCode', true, {
        subscriptionProductGiftCardCode,
        validate
      })

      this.resetGiftCard(false)

      if (subscriptionProductGiftCardCode && validate) {
        const isValid = await this.subscriptionProductGiftCardCodeIsValid(subscriptionProductGiftCardCode)

        if (!isValid) {
          payLoadingStore.set('setSubscriptionProductGiftCardCode', false, {
            isNotVaild: true
          })
          return
        }
      }
      
      this.selectedGiftCardCode = subscriptionProductGiftCardCode

      if (this.selectedGiftCardCode) {
        // Calc price
        const payPriceStore = usePayPriceStore()
        payPriceStore.initCalcuatePrice('setSubscriptionProductGiftCardCode')
      }

      payLoadingStore.set('setSubscriptionProductGiftCardCode', false)
    },

    removeGiftCard() {
      this.setSubscriptionProductGiftCardCode(null, false)
    },

    resetGiftCard(resetSubscriptionProductId = true) {
      this.selectedGiftCardCode = null
      if (resetSubscriptionProductId) this.selectedSubscriptionProductId = null
      this.newSubscriptionStartDate = null
      this.giftCardCodeInput = null
      this.showGiftCardModal = false
    },

    async subscriptionProductGiftCardCodeIsValid(subscriptionProductGiftCardCode: string): Promise<boolean> {
      if (!this.selectedSubscriptionProductId) throw new Error(i18n.t('common.staticTemp.errorMessagePriceData') as string) 
      
      this.validateLoading = true

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('subscriptionProductGiftCardCodeIsValid', true)

      const { data } = await apolloClient.query<GetSubscriptionProductGiftCardValidateQuery, GetSubscriptionProductGiftCardValidateQueryVariables>({
        query: GetSubscriptionProductGiftCardValidate,
        variables: {
          subscriptionProduct: this.selectedSubscriptionProductId,
          subscriptionProductGiftCardCode
        }
      })

      const isValid = data.subscriptionProductGiftCardValidate ? true : false
      if (isValid) this.newSubscriptionStartDate = data.subscriptionProductGiftCardValidate

      if (!isValid) {
        app.$bvToast.toast(i18n.t('components.app.pay.subscriptionProductGiftCard.validate.notValid') as string, {
          title: i18n.t('components.app.pay.subscriptionProductGiftCard.validate.title') as string,
          variant: 'danger'
        })
      }

      this.validateLoading = false

      payLoadingStore.set('subscriptionProductGiftCardCodeIsValid', false, {
        result: data.subscriptionProductGiftCardValidate
      })
      return isValid
    }
  }
})
