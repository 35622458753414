import { GetPaymentToken, GetPaymentTokenQuery, GetPaymentTokenQueryVariables, PaymentTokenConfirm, PaymentTokenConfirmMutation, PaymentTokenConfirmMutationVariables, PaymentTokenCreate, PaymentTokenCreateMutation, PaymentTokenCreateMutationVariables, PaymentTokenCreateV2, PaymentTokenCreateV2Mutation, PaymentTokenCreateV2MutationVariables, PaymentTokenFaildReason, PaymentTokenFailed, PaymentTokenFailedMutation, PaymentTokenFailedMutationVariables, PaymentTokenPaymentType, PaymentTokenState, PaymentTokenSubscription, PaymentTokenSubscriptionSubscription, PaymentTokenSubscriptionSubscriptionVariables } from '@/graphql'
import { i18n } from '@/locales/setupI18n'
import { apolloClient } from '@/plugins/apollo'
import { defineStore } from 'pinia'
import { usePayLoadingStore } from './loading'
import { usePayStore } from './pay'
import { usePayPaymentMethodStore } from './paymentMethod'
import { usePayPointsStore } from './points'
import VueApp from '@/main'
import { usePayProductReservationStore } from './productReservation'
import { usePayPriceStore } from './price'
import { ObservableSubscription } from '@apollo/client/utilities'
import { usePayV2PaymentMethodStore } from '../payV2/paymentMethodV2'
import { usePayV2PointsStore } from '../payV2/pointsV2'
import { usePayV2Store } from '../payV2/payV2'
import { usePayV2StripeStore } from '../payV2/stripeV2'

interface PayPaymentTokenState {
  paymentToken: PaymentTokenCreateMutation['paymentTokenCreate'] | null
  paymentTokenSubscription: ObservableSubscription | null
}

export const usePayPaymentTokenStore = defineStore({
  id: 'PayPaymentToken',

  state: (): PayPaymentTokenState => ({
    paymentTokenSubscription: null,
    paymentToken: null
  }),

  getters: {
    paymentTokenState: (state): PaymentTokenState | null => {
      if (!state.paymentToken) return null
      return state.paymentToken.state || null
    },
    paymentTokenFailedReason: (state): PaymentTokenFaildReason | null => {
      if (!state.paymentToken) return null
      return state.paymentToken.failedReason || null
    }
  },

  actions: {

    async createPaymentToken(options?: { useNewPaymentApi?: boolean }) {
      const payPaymentMethodStore = usePayPaymentMethodStore()
      const payPaymentMethodStoreV2 = usePayV2PaymentMethodStore()
      const payStore = options?.useNewPaymentApi? usePayV2Store() : usePayStore()
      const payPointsStore = usePayPointsStore()
      const pointsV2Store = usePayV2PointsStore()
      const payProductReservation = usePayProductReservationStore()
      const payPriceStore = usePayPriceStore()
      const paymentType = options?.useNewPaymentApi ? payPaymentMethodStoreV2.selectedPaymentType : payPaymentMethodStore.selectedPaymentType

      if (!paymentType) throw new Error(i18n.t('common.staticTemp.paymentToken.errorMessagePayToken') as string) 
      if (!payStore.purchaseData) throw new Error(i18n.t('common.staticTemp.paymentToken.errorMessagePurchaseData') as string) 
      if (!payPriceStore.priceData) throw new Error(i18n.t('common.staticTemp.paymentToken.errorMessagePriceData') as string) 
      if (this.paymentToken) throw new Error(i18n.t('common.staticTemp.paymentToken.errorMessageAlreadyPayToken') as string)

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('createPaymentToken', true)

      let response
      try {
        if (options?.useNewPaymentApi) {
          response = await apolloClient.mutate<PaymentTokenCreateV2Mutation, PaymentTokenCreateV2MutationVariables>({
            mutation: PaymentTokenCreateV2,
            variables: {
              paymentType,
              data: {
                paymentPriceToken: payPriceStore.priceData.paymentPriceToken,
                resourceBookingRef: payProductReservation.reservedProductData!.resourceBooking?.id,
                workshopBookingRef: payProductReservation.reservedProductData!.workshopBooking?.id,
                pointAccountId: paymentType === PaymentTokenPaymentType.Points ? pointsV2Store.selectedPointAccount?.id : null
              }
            },
            errorPolicy: 'all'
          })
          this.paymentToken = response.data?.paymentTokenCreateV2!
        } else {
          response = await apolloClient.mutate<PaymentTokenCreateMutation, PaymentTokenCreateMutationVariables>({
            mutation: PaymentTokenCreate,
            variables: {
              paymentType,
              data: {
                paymentPriceToken: payPriceStore.priceData.paymentPriceToken,
                resourceBookingRef: payProductReservation.reservedProductData!.resourceBooking?.id,
                workshopBookingRef: payProductReservation.reservedProductData!.workshopBooking?.id,
                pointAccountId: payPointsStore.usePointAccountId
              }
            },
            errorPolicy: 'all'
          })
          this.paymentToken = response.data?.paymentTokenCreate!
        }

        if (response.errors && response.errors.length) {
          const errorMessage = response.errors[0].message
          VueApp.$bvToast.toast(errorMessage, {
            title: i18n.t('common.error') as string,
            variant: 'warning',
          })

          throw new Error(errorMessage)
        }

        if (this.paymentToken.state !== PaymentTokenState.Paid) {
          this.subscribePaymentToken()
        }
        
        this.handlePaymentTokenData()

      } catch (error: any) {
        console.log('createPaymentToken - error', error)
        payLoadingStore.set('createPaymentToken', false)
        throw new Error(error)
      }

      payLoadingStore.set('createPaymentToken', false)
    },

    async paymentTokenFailed(failedReason: PaymentTokenFaildReason, cancelReservation: boolean) {
      if (!this.paymentToken) throw new Error(i18n.t('common.staticTemp.paymentToken.errorMessagePayTokenFailed') as string) 
      
      await apolloClient.mutate<PaymentTokenFailedMutation, PaymentTokenFailedMutationVariables>({
        mutation: PaymentTokenFailed,
        variables: {
          id: this.paymentToken.id,
          failedReason,
          cancelReservation
        }
      })
    },

    //* Not in use
    async confimPaymentToken() {
      if (!this.paymentToken) throw new Error(i18n.t('common.staticTemp.paymentToken.errorMessageConfirmPayToken') as string) 

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('confimPaymentToken', true)

      const { data } = await apolloClient.mutate<PaymentTokenConfirmMutation, PaymentTokenConfirmMutationVariables>({
        mutation: PaymentTokenConfirm,
        variables: {
          id: this.paymentToken.id
        },
        fetchPolicy: 'network-only'
      })

      payLoadingStore.set('confimPaymentToken', false)
    },

    async refetchPaymentToken() {
      if (!this.paymentToken) return

      const { data } = await apolloClient.query<GetPaymentTokenQuery, GetPaymentTokenQueryVariables>({
        query: GetPaymentToken,
        variables: {
          id: this.paymentToken.id
        },
        fetchPolicy: 'network-only'
      })

      this.paymentTokenGotUpdate(data!.paymentToken, 'refetchPaymentToken')
    },

    subscribePaymentToken() {
      if (!this.paymentToken) throw new Error(i18n.t('common.staticTemp.paymentToken.errorMessageSubPayToken') as string) 

      const paymentTokenSubscription = apolloClient.subscribe<PaymentTokenSubscriptionSubscription, PaymentTokenSubscriptionSubscriptionVariables>({
        query: PaymentTokenSubscription,
        variables: {
          paymentToken: this.paymentToken.id
        }
      })

      this.paymentTokenSubscription = paymentTokenSubscription.subscribe({
        next: ({ data }) => this.paymentTokenGotUpdate(data!.paymentToken.paymentToken!, 'subscribePaymentToken')
      })
    },

    paymentTokenGotUpdate(paymentTokenData: PaymentTokenSubscriptionSubscription['paymentToken']['paymentToken'], source: string) {
      this.paymentToken = {
        ...this.paymentToken!,
        ...paymentTokenData
      }

      console.log(`paymentTokenGotUpdate - ${source}:`, this.paymentToken)

      this.handlePaymentTokenData()
    },

    handlePaymentTokenData() {
      if (!this.paymentToken) return
      const stripeV2Store = usePayV2StripeStore()
      const payStore = stripeV2Store.isStripePaymentElementsCompatible ? usePayV2Store() : usePayStore()
      if (this.paymentToken.state === PaymentTokenState.Paid) {
        payStore.paySuccess()
      } else if (this.paymentToken.state === PaymentTokenState.Faild) {
        payStore.payError()
      }
    },

    async clearPaymentToken(failedReason: PaymentTokenFaildReason = PaymentTokenFaildReason.Canceled, cancelReservation: boolean = true) {
      const stripeV2Store = usePayV2StripeStore()
      const payStore = stripeV2Store.isStripePaymentElementsCompatible ? usePayV2Store() : usePayStore()
      const payLoadingStore = usePayLoadingStore()

      if (!payStore.paymentIsSuccessful && this.paymentToken?.state === PaymentTokenState.Paid) {
        throw new Error(i18n.t('common.staticTemp.paymentToken.errorMessageNotCreate') as string) 
      }

      payLoadingStore.set('clearPaymentToken', true)

      if (this.paymentTokenSubscription) {
        this.paymentTokenSubscription.unsubscribe()
        this.paymentTokenSubscription = null
      }

      if (!payStore.paymentIsSuccessful) {
        await this.paymentTokenFailed(failedReason, cancelReservation)
      }

      this.paymentToken = null
      payLoadingStore.set('clearPaymentToken', false)
    },

    async resetPaymentToken(failedReason?: PaymentTokenFaildReason, cancelReservation?: boolean) {
      if (this.paymentToken) {
        return this.clearPaymentToken(failedReason, cancelReservation)
      }
    }
  }
})
