export default {
	id: "Id",
	user: "User",
	userGroup: "User groups",
	createdAt: "Created",
	connectedToUser: "Connected user",
	createdByUser: "Created by",
	name: "Name",
	email: "Email",
	streetName: "Street name",
	status: "Status",
	platform: "Platform",
	moneyValue: "Amount",
	stripeId: "Stripe id",
	customer: "Customer",
	product: "Product",
	pointBounty: "Point refund",
	coupon: "Discount code",
	payment: "Payment",
	phoneNumber: "Phone number",
	country: "Country",
	price: "Price",
	single: "Single",
	double: "Double",
	standard: "Standard",
	optional: "Optional",
	characters: "Characters",
};
