import accessories from '@/assets/img/shop/category/accessories.svg'
import bags from '@/assets/img/shop/category/bags.svg'
import balls from '@/assets/img/shop/category/balls.svg'
import clothes from '@/assets/img/shop/category/clothes.svg'
import promo from '@/assets/img/shop/category/promo.svg'
import racket from '@/assets/img/shop/category/racket.svg'
import shoes from '@/assets/img/shop/category/shoes.svg'
import tapes from '@/assets/img/shop/category/tapes.svg'
import popular from '@/assets/img/shop/category/popular.svg'
import pro from '@/assets/img/shop/category/pro.svg'
import beginner from '@/assets/img/shop/category/beginner.svg'

export const categoriesIconsMap = {
  18: racket,
  26: balls,
  103: shoes,
  130: promo,
  162: bags,
  210: promo,
  214: clothes,
  219: balls,
  220: tapes,
  221: accessories,
  252: promo,
  526000: popular,
  527000: beginner,
  529000: pro
}