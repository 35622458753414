import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";
import { changeLocale as setI18nLocale, i18n } from "@/locales/setupI18n";
import { UserUpdate } from "@/graphql";
import { apolloClient } from "@/plugins/apollo";
import { gql } from "@apollo/client/core";

export enum LocaleType {
	DaDk = "da-DK",
	DeDe = "de-DE",
	EnGb = "en-GB",
	FiFi = "fi-FI",
}

export const useLocaleStore = defineStore("Locale", () => {
	const changingLocaleLoading = ref(false);
	const availableLocales = ref<LocaleType[]>([
		LocaleType.DaDk,
		LocaleType.DeDe,
		LocaleType.EnGb,
		LocaleType.FiFi,
	]);

	const userId = ref<string>("");

	const selectedLocale = computed<LocaleType>(() => {
		return validateLocale(i18n.locale) ?? LocaleType.EnGb;
	});

	function validateLocale(inputLocale: string | LocaleType): LocaleType | null {
		// Normalize input locale and check if it's valid
		const normalizedLocale = inputLocale.replace("_", "-") as LocaleType;
		return availableLocales.value.includes(normalizedLocale)
			? normalizedLocale
			: null;
	}

	async function updateUserLanguageLocale(newLocale: LocaleType) {
		await apolloClient.mutate({
			mutation: UserUpdate,
			variables: {
				data: {
					language: newLocale.replace("-", "_"),
				},
			},
			fetchPolicy: "no-cache",
		});
	}

	async function fetchUserLanguageLocale(userId: string) {
		const { data } = await apolloClient.query({
			query: gql`
				query fetchUserLanguageLocale($userId: ID!) {
					user(id: $userId) {
						language
					}
				}
			`,
			variables: {
				userId,
			},
			fetchPolicy: "no-cache",
		});

		await setI18nLocale(data.user.language.replace("_", "-"));
	}

	async function updateLocale(newLocale: LocaleType) {
		changingLocaleLoading.value = true;
		try {
			// This updates i18n.locale
			await setI18nLocale(newLocale);

			if (userId.value) {
				await updateUserLanguageLocale(newLocale);
				await fetchUserLanguageLocale(userId.value);
			}
		} catch (error) {
			console.error("Error during locale update:", error);
			throw new Error(error as any);
		} finally {
			changingLocaleLoading.value = false;
		}
	}

	// We want this to run both when the userId is updated and when the selected locale is changed
	watch(
		[selectedLocale, userId],
		async ([newLocale]) => {
			if (userId.value) {
				await updateUserLanguageLocale(newLocale);
				await fetchUserLanguageLocale(userId.value);
			}
		},
		{ immediate: true }
	);

	return {
		changingLocaleLoading,
		availableLocales,
		updateLocale,
		selectedLocale,
		userId,
		setI18nLocale,
	};
});
