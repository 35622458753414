import { analytics } from '@/segment/analyticsSetup'
import { countryMapping } from './countryMapping';

export enum SegmentTypeOfEvent {
    BookingCompleted = 'Booking Completed',
    IdentifyUser = 'Identify Call Trait'
}

export function sendSegmentData(typeOfEvent : SegmentTypeOfEvent, data : any ) {

    if(analytics) {
        
        if(typeOfEvent === SegmentTypeOfEvent.BookingCompleted) {

            const translatedCountry = countryMapping[data.club_country];

            const dateString = data.booking_date.toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit'})
            
            analytics.track(SegmentTypeOfEvent.BookingCompleted, {
                booking_type: data.booking_type,
                duration_minutes: data.duration_minutes,
                booking_date: dateString,
                club_country: translatedCountry,
                club_id: data.club_id,
                club_name: data.club_name,
                email: data.email,
                booking_id: data.booking_id,
                booking_price: data.booking_price,
                booking_system: data.booking_system
            });
        
        } else if(typeOfEvent === SegmentTypeOfEvent.IdentifyUser) {

            const translatedCountry = countryMapping[data.country];
            
            analytics.identify({
                first_name: data.first_name,
                last_name: data.last_name,
                gender: data.gender,
                email: data.email,
                city: data.city,
                country: translatedCountry,
                phone_number: data.phone_number,
                booking_system: data.booking_system,
                zip_code: data.zip_code
            })
        }
    }
}