import { PaymentTokenPaymentType, PaymentType, PaymentUserAllowedPaymentTypesV2, PaymentUserAllowedPaymentTypesV2Query, PaymentUserAllowedPaymentTypesV2QueryVariables } from "@/graphql";
import { defineStore } from "pinia";
import { usePayPriceStore } from "../pay/price";
import { i18n } from "@/locales/setupI18n";
import { usePayLoadingStore } from "../pay/loading";
import { apolloClient } from "@/plugins/apollo";
import { usePayV2PointsStore } from "./pointsV2";
import { usePayV2Store } from "./payV2";
interface PaymentMethodV2State {
    selectedPaymentType: PaymentTokenPaymentType | null
    allowedPaymentTypes: PaymentType[],
}

export const usePayV2PaymentMethodStore = defineStore({
    id: 'PayV2PaymentMethod',
    state: (): PaymentMethodV2State => ({
        selectedPaymentType: null,
        allowedPaymentTypes: [],
    }),
    getters: {
        isPointsAllowedPaymentMethod: (state): boolean => {
            return state.allowedPaymentTypes.some(x => x.paymentType === PaymentTokenPaymentType.Points)
        },
    },
    actions: {  
        async setPaymentType(paymentType: PaymentTokenPaymentType, source: string): Promise<void> {
            const payLoadingStore = usePayLoadingStore()
            const payStore = usePayV2Store()

            const prevPaymentType = this.selectedPaymentType
            this.selectedPaymentType = paymentType
            const paymentTypeChanged = prevPaymentType !== paymentType

            payLoadingStore.set(`setPaymentType v2`, true, {
                source,
                prevPaymentType,
                newPaymentType: paymentType,
                paymentTypeChanged,
            })

            await payStore.initStartPayment('setPaymentType v2')

            payLoadingStore.set(`setPaymentType v2`, false, {
                paymentTypeChanged
            })


        },
        async getAllowedPaymentTypes(source: string): Promise<void> {
            const payPriceStore = usePayPriceStore()
            const payLoadingStore = usePayLoadingStore()
            const pointsV2Store = usePayV2PointsStore()

            if (!payPriceStore.priceData) {
                throw new Error(i18n.t('common.staticTemp.payMethod.errorMessagePriceData') as string) 
            }

            payLoadingStore.set('getAllowedPaymentTypesV2', true, {
                source,
            })

            const { data } = await apolloClient.query<PaymentUserAllowedPaymentTypesV2Query, PaymentUserAllowedPaymentTypesV2QueryVariables>({
                query: PaymentUserAllowedPaymentTypesV2,
                variables: {
                  paymentPriceToken: payPriceStore.priceData.paymentPriceToken         
                }
            })
        
            this.allowedPaymentTypes = data.paymentUserAllowedPaymentTypesV2.paymentTypes
            pointsV2Store.initPoints(data.paymentUserAllowedPaymentTypesV2.pointAccounts)
            this.setDefaultPaymentType('getAllowedPaymentTypes V2')

            payLoadingStore.set('getAllowedPaymentTypesV2', false, {
                source,
                allowedPaymentTypes: this.allowedPaymentTypes
            })
        },


        // Default settings for payment method
        setDefaultPaymentType(source: string): void {
            const payLoadingStore = usePayLoadingStore()
            const pointsV2Store = usePayV2PointsStore()
            const pointAccount = pointsV2Store.selectedPointAccount

            payLoadingStore.set('initSetPaymentType v2', true, {
                source
            })

            if (this.allowedPaymentTypes.length > 1) {
                let paymentType = null
                //  - If points are allowed and there is a point account
                //  - and the points can be combined with other discounts or there are no other discounts
                //  > default to points
                const defaultToPoints = pointAccount && pointAccount.enoughPointsForPayment && !pointAccount.removeDiscountToDoPayment && pointsV2Store.usePoints !== false
                if (defaultToPoints) {
                    paymentType = PaymentTokenPaymentType.Points
                // Else, default to Stripe Payment
                } else {
                    paymentType = PaymentTokenPaymentType.Stripe
                }
                const allowed = this.allowedPaymentTypes.find((x) => x.paymentType === paymentType)
                if (allowed) {
                    this.setPaymentType(paymentType, 'setDefaultPaymentType v2')
                }
            } else {
                // If there is only one allowed payment method, default to that payment method
                this.setPaymentType(this.allowedPaymentTypes[0].paymentType, 'setDefaultPaymentType v2')
            }

            payLoadingStore.set('initSetPaymentType v2', false, {
                source
            })
        },
        resetPaymentTypes(): void {
            this.allowedPaymentTypes = []
        },
        reset(): void {
            this.selectedPaymentType = null
            this.allowedPaymentTypes = []
        }
    }
})