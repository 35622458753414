export default {
  title: 'Add feedback',
  description: 'You are about to add feedback - thanks! :). Please fill the inputs below.',
  messagePlaceholder: 'Write your message...',
  sendMessageButton: 'Send message',
  cancele: 'Cancel',
  successToast: {
    message: 'Your message is sent. Thanks!',
    title: 'Thank you!'
  }
}