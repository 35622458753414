<template lang="pug">
	span
		b-badge(v-if="useIcon" :variant="res.variant") {{ res.label }}
		span(v-if="!useIcon") {{ res.label }}
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class PaymentStatus extends Vue {
	@Prop({ required: true, type: String }) status!: string
	@Prop({ required: false, type: Boolean, default: true }) useIcon!: boolean

	get res () {
	  switch (this.status) {
	    case 'Active':
	      return {
	        label: this.$t('components.admin.enumTransformers.userProductSubscriptionStatus.active') as string,
	        variant: 'success'
	      }

	    case 'ToCancele':
	      return {
	        label: this.$t('components.admin.enumTransformers.userProductSubscriptionStatus.toCancele') as string,
	        variant: 'warning'
	      }

	    case 'ToActive':
	      return {
	        label: this.$t('components.admin.enumTransformers.userProductSubscriptionStatus.toActive') as string,
	        variant: 'success'
	      }

	    case 'Canceled':
	      return {
	        label: this.$t('components.admin.enumTransformers.userProductSubscriptionStatus.canceled') as string,
	        variant: 'danger'
	      }

	    case 'Unpaid':
	      return {
	        label: this.$t('components.admin.enumTransformers.userProductSubscriptionStatus.unpaid') as string,
	        variant: 'warning'
	      }

			case 'PendingStatus':
	      return {
	        label: this.$t('components.admin.enumTransformers.userProductSubscriptionStatus.pendingStatus') as string,
	        variant: 'secondary'
	      }

	    default:
	      return {
	        label: this.status,
	        type: 'dark'
	      }
	  }
	}
}
</script>

<style lang="sass">

</style>
