export default {
  subTitle: 'Settings',
  pageTitle: 'Administrators',

  addNewAdmin: 'Add a new admin',

  tableTokens: {
    header: 'Administrator invitations',
    email: 'Email',
    createdAt: 'Created at'
  },

  tableAdmins: {
    header: 'Administrators',
    name: 'Name',
    email: 'E-mail'
  },

  createAdminModal: {
    title: 'Add admin Opret firma admin',
    description: 'You are now creating an administrator. Fill in the fields below.',
    createAdminButton: 'Create administrator',
    createAdminLoadingButton: 'Creates administrator...',
    createdToast: 'A creation email has been sent to "{0}".'
  },

  deleteAdmin: {
    text: 'Are you sure you want to delete this connection?'
  }
}