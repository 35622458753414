import { PaymentMethod, PaymentTokenPaymentType, PaymentUserAllowedPaymentTypesQuery, PaymentUserAllowedPaymentTypesQueryVariables, PaymentUserAllowedPaymentTypes, PaymentType, PaymentUserAllowedPaymentTypesV2Query, PaymentUserAllowedPaymentTypesV2QueryVariables, PaymentUserAllowedPaymentTypesV2 } from '@/graphql'
import { apolloClient } from '@/plugins/apollo'
import { svgIcons, SvgIconVariant } from '@/utils/svgIcons'
import { defineStore } from 'pinia'
import { usePayLoadingStore } from './loading'
import { usePayStore } from './pay'
import { usePayPointsStore } from './points'
import { usePayPriceStore } from './price'
import { usePayRestPointsStore } from './restPoints'
import { usePayStripeStore } from './stripe'
import { i18n } from '@/locales/setupI18n'
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'

interface PaymentTypeData {
  paymentMethodName: PaymentMethod
  paymentType: PaymentTokenPaymentType
  i18nKey: string
  icon?: (variant?: SvgIconVariant) => string
  buttonColor?: string
}


export const paymentTypesData: PaymentTypeData[] = [
  {
    paymentMethodName: PaymentMethod.Free,
    paymentType: PaymentTokenPaymentType.Free,
    i18nKey: 'components.app.pay.index.paymentOptions.free'
  },
  {
    paymentMethodName: PaymentMethod.UserProductSubscription,
    paymentType: PaymentTokenPaymentType.UserProductSubscription,
    i18nKey: 'components.app.pay.index.paymentOptions.subscription'
  },
  {
    paymentMethodName: PaymentMethod.Points,
    paymentType: PaymentTokenPaymentType.Points,
    i18nKey: 'components.app.pay.index.paymentOptions.points'
  },
  {
    paymentMethodName: PaymentMethod.Stripe,
    paymentType: PaymentTokenPaymentType.Stripe,
    i18nKey: 'Stripe'
  },
  {
    paymentMethodName: PaymentMethod.Stripe,
    paymentType: PaymentTokenPaymentType.StripeSavedCard,
    i18nKey: 'Saved Card',
    icon: (x) => svgIcons.cardSaved(x || 'White')
  },
  {
    paymentMethodName: PaymentMethod.PayPal,
    paymentType: PaymentTokenPaymentType.PayPal,
    i18nKey: 'components.app.pay.index.paymentOptions.payPal',
    icon: (x) => svgIcons.payPal(x || 'White')
  },
  {
    paymentMethodName: PaymentMethod.MobilePayInvoice,
    paymentType: PaymentTokenPaymentType.MobilePayInvoice,
    i18nKey: 'MobilePay',
    icon: (x) => svgIcons.mobilePay(x || 'White'),
    buttonColor: '#504678'
  },
  {
    paymentMethodName: PaymentMethod.Stripe,
    paymentType: PaymentTokenPaymentType.StripeApplePay,
    i18nKey: 'Apple Pay',
    icon: (x) => svgIcons.applePay(x || 'White'),
    buttonColor: 'black'
  },
  {
    paymentMethodName: PaymentMethod.Stripe,
    paymentType: PaymentTokenPaymentType.StripeGooglePay,
    i18nKey: 'Google Pay',
    icon: (x) => svgIcons.googlePay(x || 'White'),
    buttonColor: 'black'
  },
  {
    paymentMethodName: PaymentMethod.Stripe,
    paymentType: PaymentTokenPaymentType.StripeCard,
    i18nKey: 'components.app.pay.index.paymentOptions.creditCard',
    icon: (x) => svgIcons.card(x || 'White'),
  },
]

interface PaymentMethodState {
  selectedPaymentType: PaymentTokenPaymentType | null
  allowedPaymentTypes: PaymentType[]

  showSelectPaymentType: boolean
}

export const usePayPaymentMethodStore = defineStore({

  id: 'PayPaymentMethod',

  state: (): PaymentMethodState => ({
    selectedPaymentType: null,
    allowedPaymentTypes: [],
    showSelectPaymentType: false
  }),

  getters: {
    selectedPaymentMethod: (state): PaymentMethod | undefined => {
      return paymentTypesData.find(x => x.paymentType === state.selectedPaymentType)?.paymentMethodName
    },

    allowedPaymentTypesData: (state): PaymentTypeData[] => {
      return state.allowedPaymentTypes.map(x => paymentTypesData.find(y => y.paymentType === x.paymentType)!)
    },

    selectedPaymentTypeData(): PaymentTypeData {
      return paymentTypesData.find(x => x.paymentType === this.selectedPaymentType)!
    }
  },

  actions: {
    async setPaymentType(paymentType: PaymentTokenPaymentType | null, source: string) {
      if (paymentType && !this.allowedPaymentTypes.find(x => x.paymentType === paymentType)) {
        throw new Error(i18n.t('common.staticTemp.payMethod.errorMessagePayType') as string)
      }

      const initPaymentType = this.selectedPaymentType
      const paymentTypeChanged = initPaymentType !== paymentType
      this.selectedPaymentType = paymentType

      if (!this.selectedPaymentType && this.allowedPaymentTypes.length) {
        this.selectedPaymentType = this.allowedPaymentTypes[0].paymentType
      }

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set(`setPaymentType`, true, {
        source,
        initPaymentType,
        newPaymentType: paymentType,
        paymentTypeChanged,
      })

      this.showSelectPaymentType = false

      const payStore = usePayStore()
      const payRestPointsStore = usePayRestPointsStore()
      
      if (paymentTypeChanged || payRestPointsStore.canDoRestPointPayment === null) {
        if (await payRestPointsStore.getCanDoRestPointsPayment('setPaymentType')) {
          payLoadingStore.set(`setPaymentType`, false, {
            returnedGetCanDoRestPointsPayment: true,
            paymentTypeChanged,
            canDoRestPointPayment: payRestPointsStore.canDoRestPointPayment
          })
          return
        }
      }

      await payStore.initStartPayment('setPaymentType')

      payLoadingStore.set(`setPaymentType`, false, {
        paymentTypeChanged,
        canDoRestPointPayment: payRestPointsStore.canDoRestPointPayment
      })
    },

    async getAllowedPaymentTypes(source: string, initSetPaymentType = true) {
      const payPriceStore = usePayPriceStore()
      const payStripeStore = usePayStripeStore()
      const payPointsStore = usePayPointsStore()

      if (!payPriceStore.priceData) throw new Error(i18n.t('common.staticTemp.payMethod.errorMessagePriceData') as string) 

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('getAllowedPaymentTypes', true, {
        source,
        paymentPriceToken: payPriceStore.priceData.paymentPriceToken,
      })

      const response = await apolloClient.query<PaymentUserAllowedPaymentTypesQuery, PaymentUserAllowedPaymentTypesQueryVariables>({
        query: PaymentUserAllowedPaymentTypes,
        // fetchPolicy: 'network-only',
        variables: {
          paymentPriceToken: payPriceStore.priceData.paymentPriceToken,
          clientAvailabilities: {
            canUseApplePay: payStripeStore.canUseApplePay,
            canUseCardPayment: payStripeStore.canUseCardPayment,
            canUseGooglePay: payStripeStore.canUseGooglePay
          }
        }
      })
      const paymentUserAllowedPaymentTypes = response.data.paymentUserAllowedPaymentTypes

      // MobilePay requires app version 2.4 on iOS, filter it out for all versions below it
      const mobilePayOnIOSAvailableVersion = '2.4'
      if (Capacitor.getPlatform() === 'ios') {
        const appInfo = await App.getInfo()
        if (appInfo && appInfo.version < mobilePayOnIOSAvailableVersion) {
          paymentUserAllowedPaymentTypes.paymentTypes = paymentUserAllowedPaymentTypes.paymentTypes.filter((x) => x.paymentMethod !== PaymentMethod.MobilePayInvoice)
        }
      }

      this.allowedPaymentTypes = paymentUserAllowedPaymentTypes.paymentTypes
      payPointsStore.setPointAccounts(paymentUserAllowedPaymentTypes)

      if (initSetPaymentType) {
        await this.initSetPaymentType('getAllowedPaymentTypes')
      }

      payLoadingStore.set('getAllowedPaymentTypes', false, {
        allowedPaymentTypes: this.allowedPaymentTypes
      })
    },

    async initSetPaymentType(source: string) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initSetPaymentType', true, {
        source
      })

      let setPaymentTypeSetNumber = null

      if (this.selectedPaymentType && !this.allowedPaymentTypes.find(x => x.paymentType === this.selectedPaymentType)) {
        setPaymentTypeSetNumber = 1
        await this.setPaymentType(null, 'initSetPaymentType')
      }
      else if (this.selectedPaymentMethod) {
        setPaymentTypeSetNumber = 2
        await this.setPaymentType(this.selectedPaymentType, 'initSetPaymentType')
      }
      else if (this.allowedPaymentTypes.length) {
        setPaymentTypeSetNumber = 3
        await this.setPaymentType(this.allowedPaymentTypes[0].paymentType, 'initSetPaymentType')
      }
      else {
        setPaymentTypeSetNumber = 4
        console.log('initSetPaymentType - no allowedPaymentTypes')
      }

      payLoadingStore.set('initSetPaymentType', false, {
        allowedPaymentTypes: this.allowedPaymentTypes,
        setPaymentTypeSetNumber
      })
    },

    resetPaymentMethod() {
      this.allowedPaymentTypes = []
    }
  }
})
