
export type ThingType =
    'ResourceBooking'
  | 'Payment'
  | 'PointAccount'
  | 'User'
  | 'Workshop'
  | 'Company'
  | 'UserGroup'
  | 'MilestoneView'
  | 'CreateMessage'
  | 'AccessCode'
  | 'Coupon'
  | 'SubscriptionProduct'
  | 'UserProductSubscription'
  | 'StripeInvoice'
  | 'ResourceBookingRecurring'
  | 'ResourceTimeline'
  | 'Reference'
  | 'PriceffDynamicPriceResource'
  | 'PriceffParameter'
  | 'PriceffModel'
  | 'StripeReports'
  | 'StripeReport'
  | 'SupportTicketCreate'

export type ShowAs = 'CenterModal' | 'Route' | 'SideModal'

export interface Thing {
  type: ThingType
  fileName: string
  defaultModalSize?: 'sm' | 'md' | 'lg'
  baseUrl?: string
  moreInfoRoutePath?: string
  routeTitle?: string
}

export const things: Thing[] = [
  {
    type: 'ResourceBooking',
    fileName: 'resourceBooking.vue',
    moreInfoRoutePath: 'bookings/:id',
    routeTitle: 'Booking'
  },
  {
    type: 'Payment',
    fileName: 'payment.vue',
    moreInfoRoutePath: 'payments/:id',
    routeTitle: 'Betaling'
  },
  {
    type: 'PointAccount',
    fileName: 'pointAccount.vue',
    moreInfoRoutePath: 'point-account/:id',
    routeTitle: 'Point konto',
    defaultModalSize: 'md'
  },
  {
    type: 'StripeInvoice',
    fileName: 'stripeInvoice.vue',
    defaultModalSize: 'sm'
  },
  {
    type: 'StripeReports',
    fileName: 'stripeReports.vue',
    moreInfoRoutePath: 'stripe-reports',
    routeTitle: 'Stripe reports',
    defaultModalSize: 'md'
  },
  {
    type: 'StripeReport',
    fileName: 'stripeReport.vue',
    moreInfoRoutePath: 'stripe-report',
    routeTitle: 'Stripe report',
    defaultModalSize: 'md'
  },
  {
    type: 'UserGroup',
    fileName: 'userGroup.vue',
    defaultModalSize: 'sm',
    routeTitle: 'Brugergruppe',
    moreInfoRoutePath: 'user-group/:id',
  },
  {
    type: 'ResourceTimeline',
    fileName: 'resourceTimeline.vue',
    defaultModalSize: 'sm'
  },
  {
    type: 'UserProductSubscription',
    fileName: 'userProductSubscription.vue',
    moreInfoRoutePath: 'subscriptions/:id',
    routeTitle: 'Medlemskab'
  },
  {
    type: 'SubscriptionProduct',
    defaultModalSize: 'sm',
    fileName: 'subscriptionProduct.vue'
  },
  {
    type: 'Reference',
    defaultModalSize: 'sm',
    fileName: 'reference.vue'
  },
  {
    type: 'MilestoneView',
    fileName: 'milestoneView.vue'
  },
  {
    type: 'CreateMessage',
    fileName: 'createMessage.vue',
    defaultModalSize: 'sm',
  },
  {
    type: 'User',
    fileName: 'user.vue',
    moreInfoRoutePath: 'users/:id',
    routeTitle: 'Bruger',
  },
  {
    type: 'Company',
    fileName: 'company.vue',
    moreInfoRoutePath: 'companies/:id',
    routeTitle: 'Firma'
  },
  {
    type: 'AccessCode',
    fileName: 'accessCode.vue',
    moreInfoRoutePath: 'access-codes/:id',
    routeTitle: 'Adgangskontrol kode'
  },
  {
    type: 'ResourceBookingRecurring',
    fileName: 'resourceBookingRecurring.vue',
    moreInfoRoutePath: 'resource-booking-recurring/:id',
    routeTitle: 'Gentagende bookinger',
    defaultModalSize: 'md'
  },
  {
    type: 'PriceffDynamicPriceResource',
    fileName: 'priceffDynamicPriceResource.vue',
    moreInfoRoutePath: 'priceff-dynamic-price-resource/:id',
    routeTitle: 'Dynamic price',
    defaultModalSize: 'md'
  },
  {
    type: 'PriceffModel',
    fileName: 'priceffModel.vue',
    moreInfoRoutePath: 'priceff-model/:id',
    routeTitle: 'Priceff model',
    defaultModalSize: 'lg'
  },
  {
    type: 'PriceffParameter',
    defaultModalSize: 'sm',
    fileName: 'priceffParameter.vue'
  },
  {
    type: 'SupportTicketCreate',
    defaultModalSize: 'sm',
    fileName: 'supportTicketCreate.vue'
  },
  {
    type: 'Coupon',
    defaultModalSize: 'sm',
    fileName: 'coupon.vue'
  },
  {
    type: 'Workshop',
    fileName: 'workshop.vue',
    moreInfoRoutePath: 'workshops/:id',
    routeTitle: 'Arrangement'
  }
]
