export default {
  
  searchPlaceholder: 'Search',
  searchSubmit: 'Search',
  cancelSearch: 'Cancel search',

  noData: 'No data here...',
  loading: 'Loading...',

  pagination: {
    showing: 'Showing',
    outOff: 'out of'
  }
}