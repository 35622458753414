<template lang="pug">
div
  div.border-bottom.px-4(v-if="headerText" style="padding-bottom: 20px; padding-top: 20px") 
    b-row(align-v="center")
      b-col
        h4.m-0 {{ headerText }}

      b-col(cols="auto")
        slot(name="header-action")

  .border-bottom.py-3.px-4(v-if="useSearch")
    b-row(align-v="center")
      b-col
        b-row(align-v="center" v-if="useSearch")
          b-col.pr-0.col-auto
            span.fe.fe-search.text-muted
          b-col
            b-row(align-v="center")
              b-col
                b-input.form-control.form-control-flush.search(
                  v-model="searchInput"
                  :placeholder="searchText ? searchText : $t('components.admin.ui.graphQlList.searchPlaceholder')"
                  v-on:keyup.enter="search")
              .col-auto
                b-button(
                  variant="outline-primary"
                  size="sm"
                  v-if="showSearchButton"
                  @click="search()") {{ $t('components.admin.ui.graphQlList.searchSubmit') }}
                b-button.ml-3(
                  variant="outline-danger"
                  size="sm"
                  v-if="showCancelSearchButton"
                  @click="cancelSearch()") {{ $t('components.admin.ui.graphQlList.cancelSearch') }}

  b-table(
    v-if="type === 'Table'"
    hover
    :items="tableItems"
    :busy="$apollo.queries.apolloQuery.loading"
    :fields="tableFields"
    class="card-table"
    :small="smallSizeTable"
    show-empty
    tdClass="hep"
    responsive
    @row-clicked="myRowClickHandler")

    template(#table-busy)
      .text-center.text-primary.my-2
        b-spinner.align-middle
        strong.ml-3 {{ $t('components.admin.ui.graphQlList.loading') }}

    template(#empty)
      h4.text-center.text-muted.m-0
        span.mr-2 &#128559;
        span {{ $t('components.admin.ui.graphQlList.noData') }}

    template(v-for='(_, name) in $scopedSlots' :slot='name' slot-scope='slotData')
      slot(:name='name' v-bind='slotData')

  div(v-if="type === 'Custome'")
    b-overlay(
      :show="$apollo.queries.apolloQuery.loading"
      variant="white"
      spinner-variant="primary")
      slot(name="customItems" :items="tableItems")

  div(
    v-bind:class="{ 'border-top': paginationBorderTop }")
    .d-flex.mx-4.mt-3.mb-2.justify-content-between.align-items-center.flex-wrap(:style="paginationDisablePadding ? `margin-left: 0 !important; margin-right: 0 !important` : undefined")
      div.mb-2
        p.text-muted.m-0 {{ $t('components.admin.ui.graphQlList.pagination.showing') }}
          span.text-body  {{ tableItems.length }}
          span  {{ $t('components.admin.ui.graphQlList.pagination.outOff') }}
          span.text-body  {{ totalCount || 0 }}
      div.mb-2
        b-pagination.m-0(
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalCount"
          :align="paginationAlign")

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { PaginationInput } from '../../../graphql'
//@ts-ignore
import deepmerge from 'deepmerge'

@Component({
  apollo: {
    apolloQuery: {
      query() {
        return this.query
      },
      variables() {
        return this.getQueryVariables
      },
      fetchPolicy: 'network-only',
      update(data) {
        return data[Object.keys(data)[0]]
      }
    }
  }
})
export default class GraphQlList extends Vue {
  @Prop({ required: false, type: String, default: 'Table' }) readonly type!: string

  @Prop({ required: false, type: Array, default: null }) readonly tableFields!: [] | null
  @Prop({ required: false, type: Boolean, default: true }) readonly smallSizeTable!: boolean
  @Prop({ required: false, type: Number, default: 20 }) readonly perPage!: number
  @Prop({ required: false, default: null }) readonly headerText!: string | null

  @Prop({ required: false, default: 'right' }) readonly paginationAlign!: string
  @Prop({ required: false, default: false }) readonly paginationDisablePadding!: boolean
  @Prop({ required: false, default: true, type: Boolean }) readonly paginationBorderTop!: string

  @Prop({ required: true }) readonly query!: string
  @Prop({ required: false, type: Object, default: () => ({}) }) readonly queryVariables!: {}

  @Prop({ required: false, default: false }) readonly useSearch!: boolean
  @Prop({ required: false }) readonly searchText: string | undefined

  @Prop({ required: false, default: () => [], type: Array }) readonly typesOptions!: { text: string, value: string }[]

  @Prop({ required: false, default: (record: any, index: number) => Function, type: Function }) readonly rowClickHandler!: Function

  apolloQuery: { hasMore: boolean, totalCount: number, data: [] } = {
    hasMore: false,
    totalCount: 0,
    data: []
  }

  myRowClickHandler(a: any, b: any) {
    this.rowClickHandler(a, b)
  }

  get getQueryVariables() {
    const a = {
      pagination: this.pagination,
      filtering: {
        ...this.filtering
      }
    }

    const b = this.queryVariables

    const result = deepmerge(a, b)
    return result
  }

  /************
  * Search
  ************/
  searchInput = null
  searchData: string | null = null

  get showSearchButton() {
    return this.searchInput !== this.searchData
  }

  get showCancelSearchButton() {
    return this.searchData !== null
  }

  search() {
    this.resetTable()
    this.searchData = this.searchInput
  }

  resetTable() {
    this.currentPage = 1
  }

  @Watch('searchData')
  searchDataChange() {
    this.currentPage = 1
    this.filtering.search = this.searchData!
  }

  cancelSearch() {
    this.searchInput = null
    this.searchData = null
    this.resetTable()
  }

  refetchQuery() {
    this.$apollo.queries.apolloQuery.refetch()
  }

  @Watch('queryVariables')
  queryVariablesChange(newData: any, oldData: any) {
    this.resetTable()
  }

  /************
  * Filtering
  ************/

  filtering: { search: undefined | string } = {
    search: undefined
  }

  /************
  * Pagination
  ************/
  currentPage = 1
  get totalCount() {
    return this.apolloQuery.totalCount
  }

  get tableItems() {
    return this.apolloQuery.data
  }
  get pagination(): PaginationInput {
    return {
      skip: this.currentPage === 1 ? 0 : (this.currentPage * this.perPage) - this.perPage,
      first: this.perPage
    }
  }
}
</script>

<style scoped lang="scss"></style>
