<template lang="pug">
	span
		b-badge(v-if="useIcon" :variant="res.variant") {{ res.label }}
		span(v-if="!useIcon") {{ res.label }}
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PaymentMethod } from '@/graphql'

@Component({
  components: {}
})
export default class PaymentStatus extends Vue {
	@Prop({ required: true, type: String }) method!: string
	@Prop({ required: false, type: Boolean, default: true }) useIcon!: boolean

	get res () {
	  switch (this.method as PaymentMethod) {
	    case 'MobilePayInvoice':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentMethod.mobilePay') as string,
	        variant: 'success'
	      }

	    case 'Points':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentMethod.points') as string,
	        variant: 'info'
	      }

	    case 'ConnectedPayments':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentMethod.connectedPayments') as string,
	        variant: 'info'
	      }

	    case 'ProductSubscription':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentMethod.productSubscription') as string,
	        variant: 'info'
	      }

	    case 'Stripe':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentMethod.stripe') as string,
	        variant: 'success'
	      }

	    case 'StripeInvoice':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentMethod.stripeInvoice') as string,
	        variant: 'success'
	      }

	    case 'Free':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentMethod.free') as string,
	        variant: 'success'
	      }

	    default:
	      return {
	        label: this.method,
	        type: 'dark'
	      }
	  }
	}
}
</script>

<style lang="sass">

</style>
