export default {
  in: 'In',
  time: 'Time',
  title: 'Padel booking',
  from: 'From',
  to: 'to',
  timeA: 'time',

  toStartDate: {
    days: 'days',
    hours: 'hours',
    hour: 'hour',
    min: 'min'
  }
}