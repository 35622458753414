export default {
  title: 'Create reserved time',
  note: {
    label: 'Title',
    placeholder: 'Padel...',
    desription: 'The title is shown in the calendar'
  },
  courts: {
    label: 'Courts',
    selectAll: 'Select all',
    errorSelectCourts: 'You need to select a court',
  },
  attendees: {
    label: 'Attendees'
  },
  internalNotes: {
    label: 'Internal notes',
    placeholder: 'My note...',
    info: "Required when 'Collect payment' is set to 'Outside of booking system.' You can use this field to record details of external payments for improved tracking.",
  },
  reservationType: {
    label: 'Reservation type'
  },
  paymentStatus: {
    label: 'Payment status'
  },
  paymentMethod: {
    label: 'Collect payment'
  },
  paymentMethods: {
    placeholder: 'Select method',
    stripeInvoice: 'Send invoice',
    external: 'Outside of booking system'
  },
  invoiceSettings: {
    title: 'Invoice settings',
    sendInvoiceTo: 'Send invoice to',
    defaultPricing: 'Use default court pricing',
    defaultPricingInfo: "The default price is automatically calculated based on the court's pricing and the selected time. Uncheck this option to enter a custom price.",
    customPrice: 'Custom price',
    paymentDeadline: {
      label: 'Payment deadline',
      info: 'If the invoice is not paid by the deadline, the reservation will automatically be canceled, and the invoice will be terminated.',
      invalidMessage: 'The payment deadline needs to be in the future.'
    },
    invalidCourtsAndDates: "Only one court and one date can be selected for 'Send invoice'. Please adjust your selection to continue."
  },
  type: {
    label: 'Select a type'
  },
  addAccessCodeToBooking: 'Add access code to booking',
  turnOnLightsAtStart: 'Turn the lights on',
  addCreatorAsAttendee: 'Add yourself as a attendee',
  date: {
    label: 'Date',
    description: 'You are able to select multiple days by choosing the days.'
  },
  startTime: {
    label: 'Start'
  },
  endTime: {
    label: 'End'
  },

  createReservedTimeButton: 'Create reservation',
  createReservedTimeLoadingButton: 'Creating reservation...',
  createdSuccessToast: 'Your reservation is created.',

  failedOnCourts: {
    text: 'There was an error with the fowlling courts: {0}. Probably because an exsting booking',
    title: 'An error happened'
  }
}