import { defineStore } from 'pinia'
import { apolloClient } from '@/plugins/apollo'
import { GetRanglistenSignUpLink } from '@/graphql'
import { useUserStore } from './user'
import { useLocationStore } from './location'

interface RanglistenState {
    signUpLink: string
}

export const useRanglistenStore = defineStore({
    id: 'Ranglisten',

    state: (): RanglistenState => ({
        signUpLink: ''
    }),

    actions: {
        async getSignUpLink(){
            const user = useUserStore().user
            const userLocation = useLocationStore().userLocation?.id

            if (user?.id && user?.firstName && user?.lastName && userLocation) {
                const response = await apolloClient.query({
                    query: GetRanglistenSignUpLink,
                    variables: {
                        locationId: userLocation,
                        userId: user.id,
                        userFirstName: user.firstName,
                        userLastName: user.lastName
                    }
                })
                this.signUpLink = response.data.ranglisten.signUpLink
            } else {
                // TODO
                // Before console.log, it threw an error -> caused issues when logging as an admin
                console.log('Ranglisten: Missing mandatory user params');
            }
        },

    }
})