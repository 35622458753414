export default {
  bookli: 'LeDap',
  okText: 'OK',
  closeText: 'Close',
  cancelText: 'Cancel',
  loadingText: 'Loading...',
  saveText: 'Save',
  delText: 'Delete',
  resetText: 'Reset',
  searchText: 'Search',
  queryText: 'Search',
  error: 'Error...',
  language: "Language",

  inputText: 'Please fill out',
  chooseText: 'Please select',

  redo: 'Update',
  back: 'Back',

  light: 'Light',
  dark: 'Dark',

  hoursShort: 'h',
  createNew: 'Create new',
  show: 'Show',
  none: 'None',
  delete: 'Delete',
  edit: 'Edit',
  cancel: 'Cancel',
  upload: 'Upload',
  current: 'Current',
  imageNotSet: 'No image has been uploaded',
  settings: 'Settings',

  all: 'All',

  yes: 'Yes',
  no: 'No',
  close: 'Close',

  past: 'Past',
  future: 'Future',

  send: 'Send',

  advancedSettings: {
    show: 'View',
    hide: 'Hide',
    text: 'advanced settings'
  },

  create: 'Create',
  update: 'Update',
  courts: 'Courts',
  remove: 'Remove',
  notAvailable: 'Not available',
  available: 'Available',

  minuttes: 'Minutes',

  goBack: 'Go back',

  signOut: 'Sign out',
  hours: 'hours',
  noLimit: 'No limit',
  users: 'users',

  notActive: 'Not active',
  point: 'points',
  time: 'time',
  to: 'to',

  default: 'Default',
  actionIsDone: 'The action is performed',
  change: 'Change',

  at: 'at',
  via: 'via',
  or: 'or'
}
