import { apolloClient } from "@/plugins/apollo"
import { gql } from "@apollo/client/core"
import { App } from "@capacitor/app"
import { Capacitor } from "@capacitor/core"


export async function getStripePaymentElementsCompatibility(): Promise<boolean> {
    /**
     * Check whether it's enabled on the current company
     */
    const { data: { companySettings: { usingPaymentElements } } } = await apolloClient.query({
        query: gql`
            query CompanySettings {
                companySettings {
                    usingPaymentElements 
                }
            }
        `,
        fetchPolicy: "no-cache",
    })
    
    if (!usingPaymentElements) {
        return false
    }

    // When implementing MobilePay, a native release
    // was needed to get it to work properly on iOS.
    // For app versions below 2.4 on iOS, the PaymentSheet
    // is not working properly, thus Stripe Payment Elements
    // is only enabled for iOS app version 2.4 and above.
    const platform = Capacitor.getPlatform()
    if (platform === 'ios') {
        const { version } = await App.getInfo()
        if (version < '2.4') {
            return false
        }
    }

    return true
}