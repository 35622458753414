export default {
  title: 'Payment activity',

  accounting: {
    isReady: 'Ready for accounting',
    viewInAccountingProgram: 'Show in accounting program',
    isAccounted: 'Posted'
  },

  activityType: {
    couponUsed: 'A coupon is added',
    paid: 'Payent is completed',
    platformFee: 'Platform fee',
    refund: 'Refund',
    created: 'Created',
    failed: 'Failed',
    other: 'Other'
  },

  activityCategory: {
    coupon: 'Coupon',
    payment: 'Payment',
    other: 'Other'
  }
}