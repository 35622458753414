<template>
	<b-card class="hover-scale bg-light point-product-card">
		<p class="h3 m-0 text-monospace">
			{{ title && title.length ? title : amount }}
		</p>
		<p class="text-muted m-0">
			{{ title ? `${amount} ${$t("pages.user.shop.points.pointProductCard.point")}` : $t("pages.user.shop.points.pointProductCard.point") }}
		</p>
		<p
			class="m-0"
			style="font-size: 12px"
		>
			{{ currencyFormatter(price, userCurrency) }}
		</p>
		<div class="discount">{{ discountPercent }}%</div>
	</b-card>
</template>

<script lang="ts">
import { useLocationStore } from "@/store/modules/location";
import { currencyFormatter } from "@/utils/currencyFormatter";
import { defineComponent } from "vue";

export default defineComponent({
	name: "PointProduct",
	props: {
		title: {
			type: String,
			required: false,
			default: null,
		},
		amount: {
			type: Number,
			required: true,
		},
		price: {
			type: Number,
			required: true,
		},
		discountPercent: {
			type: Number,
			required: true,
		},
	},
	methods: {
		currencyFormatter
	},
	setup() {
		const locationStore = useLocationStore()
		const userCurrency = locationStore.userLocation!.currency

		return {
			userCurrency
		}
	},
});
</script>

<style lang="scss" scoped>
@import "@/assets/css/main-app.scss";

.point-product-card {
	cursor: pointer;
	box-shadow: none;
	display: flex;
	flex-direction: column;
	text-align: center;

	.card {
		margin: 0px;
	}
}

.discount {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(25%, -25%);
	border-radius: 50%;
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $primary;
	color: white;
	border: 5px solid $body-bg;
	font-size: 10px;
}
</style>
