export default {
    subTitle: 'Message',
    pageTitle: 'Send newsletter',
    description: 'Send out newsletters to your users. You need to collect consent from your users in order to be able to send out newsletters. Read more: ',
    wikiTitle: 'Send-Newsletters',
    onBehalfOf: 'Send Emails from',

    error: {
        title: "Configuration issue: You have either none or multiple Newsletter agreements configured in your settings",
        addAgreementText: "You need consent from your users to send out newsletters. To see how to configure a new agreement see the video:",
        multipleAgreementsText: "There is currently no feature to send out newsletters depending on what agreement the users have accepted. For best use of the system, make sure you only have one agreement of type 'Newsletter' configured.",
        button: "Configure Company Agreements",
        wikiTitle: "Customer-Consent-and-Agreements"
    }
  }