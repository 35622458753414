import Vue from "vue";
import Router from "vue-router";
import pinaStore from "@/store/index";

import routes from "./routes";

import beforeGuard from "./before-guard";
import { useLayoutStore } from "@/store/modules/layout";
import { PlatformLayouts } from "@/layouts";

import { Preferences } from "@capacitor/preferences";
import { useUserStore } from "@/store/modules/user";

Vue.use(Router);

const router = new Router({
	mode: "history",
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

router.beforeEach(async (to, from, next) => {
	const Layout = useLayoutStore(pinaStore);
	const User = useUserStore(pinaStore);

	if (to.query.platformToken) {
		const platformToken = to.query.platformToken as string;
		await Preferences.set({ key: "PLATFORM_TOKEN", value: platformToken });
		router.replace({ path: to.path, query: { platformToken: null } });
	}

	let routeLayout: PlatformLayouts;
	to.matched.some((route) => {
		return route.meta.layout ? (routeLayout = route.meta.layout) : undefined;
	});
	if (Layout.layout !== routeLayout!) {
		Layout.setLayout(routeLayout!);
	}

	await beforeGuard(to, from, next);

	// console.log('hep', to.query.opnd, from.query.opnd, to.query.opnd as string || from.query.opnd as string)
	if (to.query.opnd || from.query.opnd) {
		User.setOpndId((to.query.opnd as string) || (from.query.opnd as string));
	}
});

router.afterEach((to, from) => {
	// @ts-ignore
	// App.$Progress.finish()
});

export default router;
