export default {
  subTitle: "Settings",
  pageTitle: "Company",
  imageSuccessToast: "Your image has been uploaded",
  successToast: "Updated",

  logo: {
    title: "Company logo",
    description: "Upload your company logo. The Company logo is displayed on info screens, the admin platform, in emails and for users when they select your location",
  },

  mailImage: {
    title: "Picture at the bottom of mail",
    description: "Upload an image that will be attached at the bottom of emails sent out to users from your location. Click on the link to view details on banner size and see how it looks for the user.",
  },

  homeBanner: {
    title: "User home banner",
    description: "Banner displayed for users on your locations' home page. Click on the link to view details on banner size and see how it looks for the user.",
    bannerInfo: "More info",
  },

  backgroundImage: {
    title: "Wallpaper on app",
    description: "Upload a custom background image that will be displayed for users in desktop view.",
  }
}