<template lang="pug">

b-modal(
  ref="app-welcome-flow-modal"
  title-tag="h4"
  centered
  v-model="appWelcomeFlowModalVisible"
  hide-footer
  hide-header
  :no-close-on-backdrop="true"
  size="md")

  div(v-if="showDownloadApp")
    DownloadApp(@done="done()")

  div(v-else)

    Welcome(
      style="animation-duration: 0.5s"
      v-if="step === 1"
      @nextStep="nextStep()")

    UserSignInOrCreate(
      style="animation-duration: 0.5s"
      v-if="step === 2"
      @done="done()")

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { } from '@/graphql'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'

@Component({
  components: {
    'UserSignInOrCreate': () => import('./userSingInOrCreate.vue'),
    'Welcome': () => import('./welcome.vue'),
    'DownloadApp': () => import('./downloadApp.vue')
  },
  apollo: {

  }
})
export default class CompanyUsersUserView extends Vue {

  showDownloadApp = false
  step = 1
  maxSteps = 2
  appWelcomeFlowModalVisible = false

  async created() {
    const platform = Capacitor.getPlatform()
    if (platform === 'android' || platform === 'ios') {
      const { value: appWelcomeStatus } = await Preferences.get({ key: 'appWelcomeDone' })

      if (!appWelcomeStatus) this.appWelcomeFlowModalVisible = true
    }
    else if (platform === 'web') {
      if (
        this.$route.path.includes('sign-in') ||
        this.$route.path.includes('/u/') ||
        this.$route.path.includes('/c/')) {
        const check = await Preferences.get({ key: 'haveShownDownloadApp' })
        if (!check.value) {
          this.showDownloadApp = true
          this.appWelcomeFlowModalVisible = true
        }
      }
    }
  }

  nextStep() {
    this.step++
  }

  done() {
    this.appWelcomeFlowModalVisible = false
    Preferences.set({ key: 'appWelcomeDone', value: 'true' })
  }

}
</script>

<style lang="sass" scoped>

</style>