<template lang="pug">
div(style="display: content") 
	b-link.text-primary(
		v-if="showChangeLink && type === 'link'"
		@click="show()") {{ $t('components.app.changeUserDefaultLocation.changeLocation') }}

	b-button(
		block
		variant="primary"
		v-if="showChangeLink && type === 'button'"
		@click="show()") {{ $t('components.app.changeUserDefaultLocation.changeLocation') }}

	b-modal(
		ref="change-user-default-location-modal"
		v-model="showChangeUserDefaultLocationModal"
		centered
		hide-footer
		hide-header
		no-close-on-esc
		no-close-on-backdrop
		modal-class="modal-max-body-height overflow-hidden"
		content-class="overflow-hidden"
		body-class="d-flex p-0"
		size="md")

		b-overlay.d-flex.flex-column(
			:show="loading || $apollo.queries.locations.loading"
			variant="white"
			spinner-variant="primary"
			style="width: 100%")

			div
				.d-flex.align-items-center.justify-content-between.py-3.px-4.border-bottom(style="width: 100%")
					div.flex-grow-1
						h3.m-0 {{ title }}
					div
						b-button-group
							b-button(
								@click="changeLocationType = 'list'"
								size="sm"
								:variant="changeLocationType === 'list' ? 'primary' : 'outline-secondary'") {{ $t('components.app.changeUserDefaultLocation.listType') }}
							b-button(
								style="width: 50%"
								size="sm"
								@click="changeLocationType = 'map'"
								:variant="changeLocationType === 'map' ? 'primary' : 'outline-secondary'") {{ $t('components.app.changeUserDefaultLocation.mapType') }}

				div.border-bottom.py-2.px-4(v-if="description")
					p.mt-3.mb-2.text-muted {{ description }}


				.border-bottom(v-if="changeLocationType === 'list'")

				.border-bottom(v-if="changeLocationType === 'map' && locationsInsideDefaultLocation.length")
					.py-2.px-4
						b-form-checkbox.py-2.px-4(
							size="md"
							v-model='showLocationsOutsideDefaultLocation') {{ $t('components.app.changeUserDefaultLocation.showLocationsOutsideDefaultLocation') }} {{ showLocationsOutsideDefaultLocationText }}

			div.p-4.locations-list(v-if="changeLocationType === 'list'")

				.cards-container

					div(v-if="listState === 'Country'")
						b-card.card-small-padding.cursor-pointer.hover-scale.shadow-none.bg-light.card-no-border(
							v-for="(country, index) in countriesList"
							:key="index"
							@click="selectCountry(country.code)")
							b-row(align-v="center")
								// b-col.border-right(style="align-self: stretch" cols="auto")
									.locations-list-logo-wrapper
										h4 {{ country.icon }}
								b-col
									h4.m-0 {{ country.name }}

					div(v-if="listState === 'All'")
						b-button.mb-4(
							block
							variant="outline-primary"
							@click="listState = 'Country'") {{ $t('components.app.changeUserDefaultLocation.backToAll') }}

						b-card.card-small-padding.cursor-pointer.hover-scale.shadow-none.bg-light.card-no-border(
							v-for="franchise in franchisesList"
							:key="franchise.id"
							@click="selectFranchise(franchise.id)"
							body-class="p-0")
							b-row(align-v="center")
								b-col.border-right(style="align-self: stretch" cols="auto")
									.locations-list-logo-wrapper
										img.locations-list-logo(:src="franchise.logoUrl")
								b-col.p-3
									h4.mb-1 {{ franchise.name }}
									small.text-muted {{ franchise.locationsCount }} {{ $t('components.app.changeUserDefaultLocation.locations') }}

						b-card.card-small-padding.cursor-pointer.hover-scale.shadow-none.bg-light.card-no-border(
							v-for="location in locationsNotInFranchise"
							:key="location.id"
							@click="changeDefaultLocation(location.id)"
							body-class="p-0")
							b-row(align-v="center")
								b-col.border-right(style="align-self: stretch" cols="auto")
									.locations-list-logo-wrapper
										img.locations-list-logo(:src="location.logoImageUrl || location.company.logoUrl")
								b-col.p-3
									h4.mb-1 {{ location.name }}
									small.text-muted {{ location.streetName }} - {{ location.zipCode }} {{ location.city }}

					div(v-if="listState === 'LocationsFranchise'")
						b-button.mb-4(
							block
							variant="outline-primary"
							@click="showAll()") {{ $t('components.app.changeUserDefaultLocation.backToAll') }}

						b-card.card-small-padding.cursor-pointer.hover-scale.shadow-none.bg-light.card-no-border(
							v-for="location in locationsInFranchise"
							:key="location.id"
							@click="changeDefaultLocation(location.id)"
							body-class="p-0")
							b-row(align-v="center")
								b-col.border-right(style="align-self: stretch" cols="auto")
									.locations-list-logo-wrapper
										img.locations-list-logo(:src="location.logoImageUrl || location.company.logoUrl")
								b-col.p-3
									h4.mb-1 {{ location.nameShort || location.name }}
									small.text-muted {{ location.streetName }} - {{ location.zipCode }} {{ location.city }}


			div(v-else-if="changeLocationType === 'map'" style="height: 100%")
				gmaps-map(:options="mapOptions")
					gmaps-info-window(
						@closed="infoWindowClosed"
						v-if="gmapsInfoWindowData"
						:options="gmapsInfoWindowData")
						img.map-info-window-logo(:src="gmapsInfoWindowData.logoImage || gmapsInfoWindowData.company.logoUrl")
						h4.mt-3 {{ gmapsInfoWindowData.name }}

						b-button.mt-3(
							block
							size="sm"
							@click="changeDefaultLocation(gmapsInfoWindowData.id)"
							variant="primary") {{ $t('components.app.changeUserDefaultLocation.chooseLocation') }}
					gmaps-marker(
						v-for="(m, i) in locationsForMap"
						:key="i"
						@key="i"
						:options="m"
						@click="locationMarkerClick(m)")

			.modal-footer-custome.overflow-visible(v-if="showGoBack")
				b-link.footer-button.text-body.border-top(@click="showChangeUserDefaultLocationModal = false") {{ $t('components.app.changeUserDefaultLocation.cancel') }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetLocationUserDefault, GetLocationUserDefaultQuery, LocationChangeUser, LocationChangeUserMutation, GetLocationsForSignUp, GetLocationsForSignUpQueryVariables, GetLocationsForSignUpQuery, SupportedCountries } from '@/graphql'
//@ts-ignore
import { gmapsMap, gmapsMarker, gmapsInfoWindow } from 'x5-gmaps'
import { Preferences } from '@capacitor/preferences'
import { useUserStore } from '@/store/modules/user'
import { useAuthStore } from '@/store/modules/auth'
import { i18n } from '@/locales/setupI18n'
import { useLocationStore } from '@/store/modules/location'
import router from '@/router'
import { usePayV2StripeStore } from '@/store/modules/payV2/stripeV2'

@Component({
	components: {
		gmapsMap,
		gmapsMarker,
		gmapsInfoWindow
	},
	apollo: {
		locationUserDefault: {
			query: GetLocationUserDefault,
			skip() { return this.User.user ? false : true }
		},
		locations: {
			query: GetLocationsForSignUp,
			variables(): GetLocationsForSignUpQueryVariables {
				return {

				}
			},
			update(data: GetLocationsForSignUpQuery) {
				return data.locationsForSignUp.data
			}
		}
	}
})
export default class CompanyUsersUserView extends Vue {
	@Prop({ required: false, type: Boolean, default: true }) private showChangeLink!: boolean
	@Prop({ required: false, type: Boolean, default: false }) private returnToHome!: boolean
	@Prop({ required: false, type: String, default: i18n.t('common.staticTemp.selectLocation') as string }) private title!: boolean
	@Prop({ required: false, type: String, default: i18n.t('common.staticTemp.link') as string }) private type!: boolean
	@Prop({ required: false, type: String }) private description!: string

	User = useUserStore()
	Auth = useAuthStore()
	LocationStore = useLocationStore()

	locations: GetLocationsForSignUpQuery['locationsForSignUp']['data'] = []

	locationUserDefault: GetLocationUserDefaultQuery['locationUserDefault'] | null = null

	showLocationsOutsideDefaultLocation = true

	changeLocationType: 'map' | 'list' = 'list'

	loading = false

	showChangeUserDefaultLocationModal = false

	showGoBack = false

	gmapsInfoWindowData = null

	selectedCountry: SupportedCountries | null = null

	get showLocationsOutsideDefaultLocationText() {
		if (!this.locationUserDefault) return ''

		if (this.locationUserDefault.company.franchise)
			return this.locationUserDefault.company.franchise.name

		return this.locationUserDefault.company.name
	}

	mapOptions = {
		mapId: '211f6a02a41706f5',
		center: { lat: 56.032638, lng: 10.695530 },
		zoom: 6,
		disableDefaultUI: true,
		zoomControl: true
	}

	searchInput: null | string = null

	async created() {
		const { value: userToken } = await Preferences.get({ key: 'PLATFORM_TOKEN' })
		if (userToken && this.locationUserDefault)
			this.showGoBack = true
	}

	@Watch('locationUserDefault')
	locationUserDefaultUpdate() {
		if (this.locationUserDefault) {
			this.showGoBack = true
		} else {
			this.showGoBack = false
		}
	}

	selectedFranchiseId: null | string = null
	listState: 'Country' | 'All' | 'LocationsFranchise' = 'Country'

	get locationsInFranchise() {
		if (!this.selectedFranchiseId) return []
		return this.locations.filter(x => x.company.franchise && x.company.franchise.id === this.selectedFranchiseId)
	}

	get countriesList() {
		const countries: { name: string, code: SupportedCountries, icon: string }[] = []
		const countryCodes: SupportedCountries[] = []

		this.locations.forEach(location => {
			const locationCountry = location.company.country
			if (!countryCodes.find(x => x === locationCountry)) countryCodes.push(locationCountry)
		})

		countryCodes.forEach(countryCode => {
			if (countryCode === SupportedCountries.Dk) countries.push({
				name: this.$t('common.country.dk').toString(),
				code: countryCode,
				icon: '🇩🇰'
			})
			else if (countryCode === SupportedCountries.Se) countries.push({
				name: this.$t('common.country.se').toString(),
				code: countryCode,
				icon: '🇸🇪'
			})
			else if (countryCode === SupportedCountries.No) countries.push({
				name: this.$t('common.country.no').toString(),
				code: countryCode,
				icon: '🇳🇴'
			})
			else if (countryCode === SupportedCountries.Fi) countries.push({
				name: this.$t('common.country.fi').toString(),
				code: countryCode,
				icon: '🇫🇮'
			})
			else if (countryCode === SupportedCountries.De) countries.push({
				name: this.$t('common.country.de').toString(),
				code: countryCode,
				icon: '🇩🇪'
			})
			else if (countryCode === SupportedCountries.Es) countries.push({
				name: this.$t('common.country.es').toString(),
				code: countryCode,
				icon: '🇪🇸'
			})
			else if (countryCode === SupportedCountries.Gb) countries.push({
				name: this.$t('common.country.gb').toString(),
				code: countryCode,
				icon: '🇬🇧'
			})
			else if (countryCode === SupportedCountries.At) countries.push({
				name: this.$t('common.country.at').toString(),
				code: countryCode,
				icon: '🇦🇹'
			})
		})

		return countries
	}

	get franchisesList() {
		const franchises: { id: string, name: string, logoUrl?: string, locationsCount: number }[] = []

		this.locations.forEach(location => {
			if (location.company.country !== this.selectedCountry) return

			if (location.company.franchise) {
				if (!franchises.find(x => x.id === location.company.franchise!.id)) {
					//@ts-ignore
					franchises.push({
						...location.company.franchise,
						locationsCount: 0
					})
				}
				const index = franchises.findIndex(x => location.company.franchise!.id === x.id)
				franchises[index].locationsCount += 1
			}
		})

		return franchises
	}

	get locationsNotInFranchise() {
		return this.locations.filter(x => x.company.country === this.selectedCountry).filter(x => !x.company.franchise)
	}

	selectFranchise(franchiseId: string) {
		this.selectedFranchiseId = franchiseId
		this.listState = 'LocationsFranchise'
	}

	showAll() {
		this.selectedFranchiseId = null
		this.listState = 'All'
	}


	mounted() {
		// this.$refs['change-user-default-location-modal'].show()
	}

	selectCountry(code: SupportedCountries) {
		this.selectedCountry = code
		this.listState = 'All'
	}

	get locationsInsideDefaultLocation() {
		let locations = this.locations

		if (!this.locationUserDefault) return []

		if (this.locationUserDefault.company.franchise) {
			locations = locations.filter(x => x.company.franchise && this.locationUserDefault!.company.franchise!.id === x.company.franchise.id)
		}
		else {
			locations = locations.filter(x => x.company.id === this.locationUserDefault!.company.id)
		}

		if (this.searchInput && this.searchInput.length) {
			locations = locations.filter(x => x.streetName.includes(this.searchInput!) || x.zipCode.includes(this.searchInput!) || x.name.includes(this.searchInput!))
		}

		return locations
	}

	get locationsOutsideDefaultLocation() {
		let locations = this.locations
		locations = locations.filter(x => !this.locationsInsideDefaultLocation.includes(x))

		if (this.searchInput && this.searchInput.length) {
			locations = locations.filter(x => x.streetName.includes(this.searchInput!) || x.zipCode.includes(this.searchInput!) || x.name.includes(this.searchInput!))
		}

		locations = locations.sort((a, b) => {
			if (a.company.franchise && b.company.franchise) {
				return a.company.franchise.name.localeCompare(b.company.franchise!.name, 'da', { sensitivity: 'base' })
			}
			else {
				return a.company.name.localeCompare(b.company.name, 'da', { sensitivity: 'base' })
			}
		})

		return locations
	}

	infoWindowClosed() {
		this.gmapsInfoWindowData = null
	}

	locationMarkerClick(location: any) {
		this.gmapsInfoWindowData = location
	}


	get locationsForMap() {
		const abc = this.showLocationsOutsideDefaultLocation ? this.locationsOutsideDefaultLocation : []

		let locations = [
			...this.locationsInsideDefaultLocation,
			...abc
		]

		locations = locations.filter(x => x.lat && x.lng).map(location => {
			return {
				...location,
				position: {
					lat: parseFloat(location.lat!),
					lng: parseFloat(location.lng!)
				},
				icon: location.company.mapIconUrl || null
			}
		})

		return locations
	}

	async changeDefaultLocation(locationId: string) {
		this.loading = true
		this.gmapsInfoWindowData = null

		this.$emit('locationChangeInit')

		const changeLocationMutation = await this.$apollo.mutate({
			mutation: LocationChangeUser,
			variables: {
				newLocation: locationId,
			},
			errorPolicy: 'all'
		})

		if (changeLocationMutation.errors) {
			this.$bvToast.toast(changeLocationMutation.errors[0].message, {
				title: this.$t('common.error') as string,
				variant: 'danger'
			})
			this.loading = false
			return
		}

		const changeLocation = (changeLocationMutation.data as LocationChangeUserMutation).locationChangeUser

		if (changeLocation.changePlatformToken) {
			await this.Auth.signInPlatform({
				userPlatformConnectionId: changeLocation.userPlatformConnection!,
				routeToHome: this.returnToHome
			})
		} else {
			await this.LocationStore.initLocation()
		}

		const stripeV2Store = usePayV2StripeStore()
		await stripeV2Store.initStripe()

		this.loading = false
		this.showChangeUserDefaultLocationModal = false

		this.listState = 'Country'
		this.selectedFranchiseId = null

		if (this.Auth.preAuthRoute) {
			if (router.currentRoute.fullPath !== this.Auth.preAuthRoute) {
				router.push({ path: this.Auth.preAuthRoute });
				this.Auth.preAuthRoute = ""
			}
		}

		this.$emit('locationUpdated')
	}

	show() {
		this.showChangeUserDefaultLocationModal = true
	}
}
</script>

<style lang="sass">

.map-info-window-logo
	width: 90px
	height: auto


.locations-list-logo
	padding: 10px
	width: inherit
	height: auto

.locations-list
	height: 100%
	flex-grow: 1
	overflow-y: auto

.overflow-visible
	overflow: visible !important

.locations-list-logo-wrapper
	align-self: stretch
	display: flex
	align-items: center
	justify-content: center
	width: 90px
	height: 100%
	padding-left: 12px


</style>
