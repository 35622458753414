<template lang="pug">
b-overlay(
  :show="$apollo.queries.friends.loading || deleteFriend.isLoading"
  variant="white"
  spinner-variant="primary")

  b-button(
    @click="$refs['add-friend-modal'].show()"
    size="md"
    variant="primary"
    block) {{ $t('components.app.userFriends.addFriendButton') }}

  add-friend(
    ref="add-friend-modal"
    v-on:friend-added="$apollo.queries.friends.refetch()")

  b-card.bg-light.card-small-padding.mb-0.mt-4.card-no-border.shadow-none(v-if="!friends.length")
    p.mb-2 {{ $t('components.app.userFriends.noFriends.title') }}
    small.text-muted {{ $t('components.app.userFriends.noFriends.text') }}

  .cards-container.mt-4(v-else)
    b-card.bg-light.card-no-border.shadow-none.card-small-padding(
      v-for="user in friends"
      :key="user.id")
      b-row(align-v="center")
        b-col
          p.m-0 {{ user.name }}
        b-col.col-auto
          b-button(variant="outline-danger" @click="deleteFriend.mutate({ friendUserId: user.id })" size="sm") {{ $t('components.app.userFriends.deleteFriend.submitButton') }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetFriends, GetFriendsQuery, FriendshipDelete, FriendshipDeleteMutationVariables } from '@/graphql'
import AddFriend from '@/components/app/addFriend.vue'
import { SmartMutation } from '@/utils/smartMutation';

@Component({
  components: {
    AddFriend
  },
  apollo: {
    friends: {
      query: GetFriends,
      update(data: GetFriendsQuery) {
        return data.friends.data
      }
    }
  }
})
export default class CompanyUsersUserView extends Vue {

  friends = []

  created() {
    this.deleteFriend.componentThis = this
  }

  deleteFriend = new SmartMutation({
    doneToast: {
      i18nString: 'components.app.userFriends.deleteFriend.successToast'
    },
    mutation: FriendshipDelete,
    componentThis: null,
    refetchQueryName: 'friends',
    variables(): FriendshipDeleteMutationVariables {
      return {
        friendUserId: ''
      }
    }
  })

}
</script>

<style lang="sass" scoped>

</style>