export default {
  infoCard: {
    title: 'This feature is still in beta',
    text: 'But we have chosen to make it available to you! Read more below about how to use the feature and we are otherwise just looking forward to seeing your best duels on social media!',
    readMoreButton: 'Read how to do',
    hideMessageButton: 'Hide this message'
  },

  canNotGenerate: {
    title: 'Can not be done here ...',
    text: 'This track is not filmed by a camera, so unfortunately you can not expose video out. Next time you play, try to choose a different course.'
  },

  isBeforeBookingEnd: {
    title: 'The match has to be played first...',
    text: 'This course is being filmed, but since the match has not been played yet, there is nothing to see. Good game! 🎾'
  },

  createVideo: {
    title: 'Create your video',
    text: 'When you create a video below, you have the option to watch the video. In order to create your video, you must be sure that all players on the court accept that the recordings will be visible to you as participants.',
    createVideoAccepted: 'I am responsible for all players on the field accepting the above.',
    createVideoButton: 'Create video'
  }
}