export default {
	title: "Point product",
	description: "Points can be used to pay for events or court bookings.",

	isControlledByFranchise: {
		title: "Is controlled by franchise",
		description: "Point products at this company is controlled by the franchise.",
	},

	createNewPointProductButton: "Create new point product",

	noPointProducts: {
		title: "No point products",
		description: "This company has not created any point products yet.",
	},

	point: "point",

	modal: {
		titles: {
			edit: "Edit point product",
			create: "Create point product",
		},
		descriptions: {
			create: "You are about to create a point product. Fill the inputs.",
			edit: "You are about to edit a point product. Fill the inputs.",
		},
		inputLabels: {
			productTitle: "Product title",
			pointsAmount: "Points amount",
			price: "Price",
			order: "Order (popularity)",
		},
		buttonTexts: {
			create: "Create point product",
			edit: "Update point product",
			delete: "Delete point product",
		},
		toasts: {
			created: "Your point product has been created",
			edited: "Your point product has been updated",
			deleted: "Your point product has been deleted",
		},
		loadingTexts: {
			create: "Creating point product...",
			edit: "Updating point product...",
			delete: "Deleting point product...",
		},
	},
};
