export function getErrorMessage(error: unknown): string {
	let err = "";

	if (Array.isArray(error) && error.length > 0 && error[0].hasOwnProperty("message")) {
		err = error[0].message;
	} else if (error instanceof Error) {
		err = error.message;
	} else if (typeof error === "string") {
		err = error;
	} else {
		console.error(error);
		return "An unknown error occurred";
	}

	return err !== "undefined" ? err : "An unknown error occurred";
}
