export enum Browser {
    Firefox,
    SamsungBrowser,
    Opera,
    EdgeLegacy,
    Edge,
    Chrome,
    Safari,
    Unknown
}

export function getCurrentBrowser(userAgent: string): Browser {
    if (userAgent.includes("Firefox")) {
        return Browser.Firefox;
    } else if (userAgent.includes("SamsungBrowser")) {
        return Browser.SamsungBrowser;
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        return Browser.Opera;
    } else if (userAgent.includes("Edge")) {
        return Browser.EdgeLegacy;
    } else if (userAgent.includes("Edg")) {
        return Browser.Edge;
    } else if (userAgent.includes("Chrome")) {
        return Browser.Chrome;
    } else if (userAgent.includes("Safari")) {
        return Browser.Safari;
    } else {
        return Browser.Unknown;
    }
}