import { defineStore } from 'pinia'
import { loadScript, OnApproveData, PayPalButtonsComponent, PayPalNamespace } from "@paypal/paypal-js"
import { usePayStore } from './pay'
import { usePayPaymentTokenStore } from './paymentToken'
import { usePayLoadingStore } from './loading'
import { apolloClient } from '@/plugins/apollo'
import { GetPayPalGetClientData, GetPayPalGetClientDataQuery, GetPayPalGetClientDataQueryVariables, PaymentTokenFaildReason, PayPalConfirmPayment, PayPalConfirmPaymentMutation, PayPalConfirmPaymentMutationVariables } from '@/graphql'
import { Preferences } from '@capacitor/preferences'
import VueApp from '@/main'
import { i18n } from '@/locales/setupI18n'

export const payPalButtonId = 'paypal-button'

interface PayPayPalState {
  payPalClientData: GetPayPalGetClientDataQuery['payPalGetClientData'] | null
  payPal: PayPalNamespace | null
  payPalButton: PayPalButtonsComponent | null
  payPalButtonIsInitialized: boolean
}

export const usePayPayPalStore = defineStore({
  id: 'PayPayPal',

  state: (): PayPayPalState => ({
    payPal: null,
    payPalButton: null,
    payPalClientData: null,
    payPalButtonIsInitialized: false
  }),

  getters: {

  },

  actions: {

    async initPayPal() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initPayPal', true)

      await this.loadPayPal()

      payLoadingStore.set('initPayPal', false)
    },

    async showAcceptPayPal() {
      const check = await Preferences.get({ key: 'hasAcceptedPayPalV1' })
      if (!check.value) {
        const sure = await VueApp.$bvModal.msgBoxConfirm(i18n.t('components.app.pay.payPal.acceptPayPalTerms.text') as string, {
          title: i18n.t('components.app.pay.payPal.acceptPayPalTerms.title') as string,
          centered: true,
          titleTag: 'h4',
          bodyClass: 'text-muted',
          footerClass: 'py-2',
          okVariant: 'primary',
          cancelVariant: 'outline-secondary',
          okTitle: i18n.t('components.app.pay.payPal.acceptPayPalTerms.accept') as string,
          cancelTitle: i18n.t('components.app.pay.payPal.acceptPayPalTerms.cancel') as string
        })
  
        if (!sure) throw new Error('PayPal not accepted')
  
        await Preferences.set({ key: 'hasAcceptedPayPalV1', value: 'true' })
      }
    },

    async loadPayPal() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('loadPayPal', true)

      if (this.payPal) await this.resetPayPal()

      await this.setPayPalClientData()

      if (!this.payPalClientData) console.log('loadPayPal - missing payPalClientData')
      else {
        try {
          this.payPal = await loadScript({
            "client-id": this.payPalClientData.clientId,
            "intent": 'authorize',
            'currency': this.payPalClientData.currency.toUpperCase()
          })
        } catch (error) {
          console.log('loadPayPal - failed to load', error)
        }
      }

      payLoadingStore.set('loadPayPal', false)
    },

    async setPayPalClientData() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('setPayPalClientData', true)

      const { data } = await apolloClient.query<GetPayPalGetClientDataQuery, GetPayPalGetClientDataQueryVariables>({
        query: GetPayPalGetClientData,
        variables: {},
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      })

      this.payPalClientData = data.payPalGetClientData || null
      payLoadingStore.set('setPayPalClientData', false)
    },

    async initPayPalPayment() {
      try { await this.showAcceptPayPal() } catch (error) {
        throw new Error('initPayPalPayment - did not accept')
      }

      if (!this.payPal) await this.loadPayPal()
      if (!this.payPal) throw new Error(i18n.t('common.staticTemp.payPalIntegration.errorMessageNoPaypal') as string)
      if (!this.payPal.Buttons) throw new Error(i18n.t('common.staticTemp.payPalIntegration.errorMessageNoPaypalBtns') as string) 

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initPayPalPayment', true)

      if (this.payPalButton) {
        await this.payPalButton.close()
        this.payPalButton = null
      }

      this.payPalButtonIsInitialized = false

      try {
        this.payPalButton = this.payPal.Buttons({
          fundingSource: 'paypal',
          createOrder: async (data, actions) => {
            const payPaymentTokenStore = usePayPaymentTokenStore()
            const payStore = usePayStore()

            await payStore.startPayment()
  
            if (!payPaymentTokenStore.paymentToken) throw new Error(i18n.t('common.staticTemp.payPalIntegration.errorMessagePaymentToken') as string)
            if (!payPaymentTokenStore.paymentToken.payPalInvoiceId) throw new Error(i18n.t('common.staticTemp.payPalIntegration.errorMessageInvoiceId') as string) 
  
            return actions.order.create({
              intent: 'AUTHORIZE',
              purchase_units: [{
                invoice_id: payPaymentTokenStore.paymentToken.payPalInvoiceId,
                amount: {
                  value: payPaymentTokenStore.paymentToken.amountToPay.toString(),
                  currency_code: payPaymentTokenStore.paymentToken.currency.toUpperCase()
                }
              }]
            })
          },
          onInit: (data, actions) => {
            this.payPalButtonIsInitialized = true
          },
          onCancel: async (data) => {
            const payStore = usePayStore()
            await payStore.payError(PaymentTokenFaildReason['Rejected']) 
          },
          onApprove: async (data, actions) => {
            await this.payPalOnApprove(data)
          },
          onError: async (err) => {
            const payStore = usePayStore()
            await payStore.payError(PaymentTokenFaildReason['Canceled']) 
          }
        })

        this.payPalButton.render(`#${payPalButtonId}`)
      } catch (error) {
        console.log('initPayment - create payPalButton error', error)
      }

      payLoadingStore.set('initPayPalPayment', false)
    },

    async payPalOnApprove(data: OnApproveData) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('payPalButton.onApprove', true, {
        orderId: data.orderID
      })

      const payPaymentTokenStore = usePayPaymentTokenStore()

      await apolloClient.mutate<PayPalConfirmPaymentMutation, PayPalConfirmPaymentMutationVariables>({
        mutation: PayPalConfirmPayment,
        variables: {
          payPalInvoiceId: payPaymentTokenStore.paymentToken!.payPalInvoiceId!,
          payPalOrderId: data.orderID
        }
      })

      payLoadingStore.set('payPalButton.onApprove', false)
    },

    async resetPayPal() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('resetPayPal', true)

      if (this.payPalButton) {
        await this.payPalButton.close()
        this.payPalButton = null
      }
      if (this.payPal) {
        // Remove paypal script
        this.payPal = null
      }

      this.payPalButtonIsInitialized = false

      this.payPalClientData = null

      payLoadingStore.set('resetPayPal', false)
    }

  }
})
