import { apolloClient } from '@/plugins/apollo'
import { SaEvent, SaEventMutationVariables } from '@/graphql'

export async function saEvent (event: string, value: number = 1, dimension?: string) {
  await apolloClient.mutate({
    mutation: SaEvent,
    variables: {
      event,
      value,
      dimension
    }
  })
}
