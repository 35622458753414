export default {
  subTitle: 'Coupon code',
  notActiveToast: 'This code is not active',
  activeToast: 'This code is active',
  setAsActice: 'Change to active again',
  mode: 'Mode',
  discountFor: {
    label: 'Discount for',
    discountIsOnlyForBuyer: 'Buyer share',
    normal: 'The entire amount'
  },
  endDate: {
    label: 'End date',
    none: 'No end date'
  },
  discountType: 'Discount type',
  percentDiscount: 'Percent discount',
  amountDiscount: 'Amount discount',
  bookingHoursDiscount: 'Hours discount',
  maxUsage: 'Max usage',
  maxUsagePrCustomer: 'Max usage per user',
  maxUsagePrCustomerEvents: 'Max event bookings with discount',
  maxUsagePrCustomerPrMonthEvents: 'Max event bookings with discount is per month',
  resourceBookingEndDateAfter: 'Booking start date AFTER date',
  resourceBookingEndDateBefore: 'Booking start date BEFORE date',
  resourceBookingTimeRanges: {
    label: 'Booking time ranges',
    rangesLength: '{0} time ranges'
  },
  maximumResourceBookingHoursPrMonthUsage: 'Max booking hours per month',
  onlyForUserGroups: 'Only for user groups',
  subscriptionProductCoupon: 'Connected to membership',

  analytic: {
    title: 'Statistics',
    usagesCount: 'Times used',
    totalUsedDiscountAmount: 'Discount'
  },

  couponUsages: {
    id: 'User',
    discountAmount: 'Amount',
    payment: 'Payment'
  },

  updateCouponModal: {
    title: 'Edit coupon code',
    updateCouponButton: 'Update coupon',
    updateCouponLoadingButton: 'Updating coupon...',
    updatedSuccessToast: 'The discount code is updated',
    createdSuccessToast: 'The discount code is created'

  },

  disableCouponSuccessToast: 'The discount code has been set as inactive'
}