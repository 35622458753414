export default {
  subTitle: 'Membership',

  infoTab: {
    title: 'Informations',
    infoCards: {
      paymentsSum: 'Revenue sum',
      usedOnPaymentsCount: 'Times used',
    },
    activity: {
      title: 'activity'
    },
    info: {
      addedByUserGroup: 'Added by user group',
      cancelDate: 'Cancel date',
      connectedTo: 'Connected to',
      subscriptionProduct: 'Membership',
      platform: 'Platform'
    },
    actions: {
      delete: 'Delete membership',
      reactivate: 'Reactivate membership'
    }
  },

  paymentsTab: {
    title: 'Payments'
  },

  usedOnPaymentsTab: {
    title: 'Used on payments'
  },

  canceleSubscriptionModal: {
    title: 'Cancel membership',
    cancelImmediately: 'Cancel immidiately',
    cancelImmediatelyText: 'If there is a check mark above, the membership will be canceled immediately and removed from the user. If not, it corresponds to if the user unsubscribed.',
    refundLatestPayment: 'Refund latest payment',
    cancelButton: 'Cancel membership',

    successToast: 'The membership is deleted'
  },

  reactivateSubscription: {
    successToast: 'The membership is reactivated'
  },

  noSubscriptionPayment: {
    cancelledBooking: 'You have no membership discount because you have previously cancelled this booking',
    missingDetails: 'You have no membership discount because the booking is missing details',
    maxBookingsMonth: 'You have no membership discount because you have already reached max number of active bookings this month',
    maxActiveBookings: 'You have no membership discount because you have already reached max number of active bookings',
    cancelDate: 'You have no membership discount because the cancel date is before booking start',
    bTime: 'You have no membership discount since this booking is in a A-timeslot, discount is only wihin B-timeslots',
    multipleBookings: 'You have no membership discount because you have another booking at the same time',
    specificResourceType: 'You have no membership discount because the discount is only for specific court types',
    wrongStartDate: 'You have no membership discount because this date is not part of the subscription',
    wrongTimeframe: 'You have no membership discount because this booking is not within the time frame where the discount is valid',
    cantCombineWithPoints: 'You have no membership discount because it can not be combined with points'
  },
}