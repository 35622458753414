import {
	AppleSignInInput,
	AuthSignInPlatform,
	AuthSignInPlatformMutation,
	AuthSignInUser,
	AuthSignInUserMutation,
	AuthSignInUserMutationVariables,
	GetAuthPlacesForSignIn,
	GetAuthPlacesForSignInQuery,
} from "@/graphql";
import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { i18n } from "@/locales/setupI18n";
import { apolloClient } from "@/plugins/apollo";
import App from "@/main";
import router from "@/router";
import { useUserStore } from "./user";
import { useLocationStore } from "./location";
import { sortByKey } from "@/utils/sortByKey";

interface AppState {
	userPlatformConnections: GetAuthPlacesForSignInQuery["authPlacesForSignIn"];
	userPlatformLoading: boolean;
	preAuthRoute: string;
}

interface SignInPlatformData {
	userPlatformConnectionId: string;
	routeToHome?: boolean;
	refreshPage?: boolean;
}

interface SignInUserData {
	emailAndPassword?: { email: string; password: string };
	facebookAuthToken?: string;
	appleSignIn?: AppleSignInInput;
	bookliUserAuthToken?: string;
}

export const useAuthStore = defineStore({
	id: "Auth",

	state: (): AppState => ({
		userPlatformConnections: [],
		userPlatformLoading: false,
		preAuthRoute: "",
	}),

	getters: {
		currentPlatformConnection: (state) => {
			const User = useUserStore();
			if (!User.user) return undefined;
			return state.userPlatformConnections.find(
				(platform) => platform.id === User.user!.userPlatformConnectionId
			);
		},
		sortedUserPlatformConnections(state) {
			const currentConnection = this.currentPlatformConnection?.company?.name;
			const modifiedList = state.userPlatformConnections
				.filter((x) => x.role.toLocaleLowerCase() !== "user") // We filter it out from a UI/UX perspective to prevent a very long list, examples of excludes: User - WeArePadle
				.map((item) => {
					let displayName = "";
					switch (item.role) {
						case "Admin":
							displayName = i18n.t("common.userRole.admin") as string;
							break;
						case "User":
							displayName = `${i18n.t("common.userRole.user") as string} ${
								currentConnection ? "- " + currentConnection : ""
							}`;
							break;
						case "Technician":
							displayName = i18n.t("common.userRole.technician") as string;
							break;
						case "Priceff":
							displayName = i18n.t("common.userRole.priceff") as string;
							break;
						default:
							displayName = item.company
								? item.company.name
								: item.franchise
								? item.franchise.name
								: item.role;
							break;
					}
					return {
						...item,
						displayName,
					};
				});

			return sortByKey({
				list: modifiedList,
				key: "displayName",
				locale: i18n.locale,
			});
		},
	},

	actions: {
		async signInPlatform(data: SignInPlatformData) {
			const { value: userAuthToken } = await Preferences.get({
				key: "USER_TOKEN",
			});
			const User = useUserStore();

			if (!userAuthToken)
				throw new Error(
					i18n.t("common.staticTemp.auth.errorMessageAuthToken") as string
				);
			if (!data.userPlatformConnectionId)
				throw new Error(
					i18n.t("common.staticTemp.auth.errorMessageConnectionId") as string
				);

			this.userPlatformLoading = true;

			if (data.routeToHome === undefined) data.routeToHome = true;

			const signInPlatformMutation = await apolloClient.mutate({
				mutation: AuthSignInPlatform,
				errorPolicy: "all",
				variables: {
					userPlatformConnection: data.userPlatformConnectionId,
				},
			});
			const signInPlatformData = (
				signInPlatformMutation.data as AuthSignInPlatformMutation
			).authSignInPlatform;

			if (signInPlatformMutation.errors) {
				App.$bvToast.toast(signInPlatformMutation.errors[0].message, {
					title: i18n.t("common.staticTemp.auth.errorMessageFailure") as string,
					variant: "warning",
				});
				this.userPlatformLoading = false;
				return;
			}

			await Preferences.set({
				key: "PLATFORM_TOKEN",
				value: signInPlatformData,
			});

			if (data.refreshPage) {
				location.reload();
				return;
			}

			await User.initUser().then(async () => {
				try {
					await apolloClient.resetStore();
				} catch (error) {
					console.error(error);
				}
			});

			this.userPlatformLoading = false;
			if (this.preAuthRoute) {
				if (router.currentRoute.fullPath !== this.preAuthRoute) {
					router.push({ path: this.preAuthRoute });
					this.preAuthRoute = "";
				}
			} else if (data.routeToHome) {
				router.push({ name: User.defaultRoutes!.home });
			}
		},

		async getUserPlatformConnections() {
			const platformsQuery = await apolloClient.query({
				query: GetAuthPlacesForSignIn,
				fetchPolicy: "no-cache",
			});

			const userPlatformConnections = (
				platformsQuery.data as GetAuthPlacesForSignInQuery
			).authPlacesForSignIn;

			this.userPlatformConnections = userPlatformConnections;
		},

		async signInUser(data?: SignInUserData) {
			let userAuthToken: null | string = null;
			const User = useUserStore();

			if (!data) {
				const { value: userToken } = await Preferences.get({
					key: "USER_TOKEN",
				});
				if (userToken) {
					await User.initUser();
					return;
				} else
					throw new Error(
						i18n.t("common.staticTemp.auth.errorMessageNotSignedIn") as string
					);
			} else if (data.bookliUserAuthToken) {
				userAuthToken = data.bookliUserAuthToken;
			} else {
				const variables: AuthSignInUserMutationVariables = {
					emailAndPassword: data.emailAndPassword,
					facebookAuthToken: data.facebookAuthToken,
					appleSignIn: data.appleSignIn,
				};
				const signInMutation = await apolloClient.mutate({
					mutation: AuthSignInUser,
					errorPolicy: "all",
					variables,
				});

				if (signInMutation.errors) {
					const error = signInMutation.errors[0].message as string;
					let errorText: null | string = null;

					if (error.includes("ErrorEmailOrPassword"))
						errorText = i18n.t(
							"common.signInErrorCodes.ErrorEmailOrPassword"
						) as string;
					else if (error.includes("ErrorSignInWithFacebook"))
						errorText = i18n.t(
							"common.signInErrorCodes.ErrorSignInWithFacebook"
						) as string;
					else if (error.includes("ErrorSignInWithApple"))
						errorText = i18n.t(
							"common.signInErrorCodes.ErrorSignInWithApple"
						) as string;
					else if (error.includes("ErrorCouldNotValidate"))
						errorText = i18n.t(
							"common.signInErrorCodes.ErrorCouldNotValidate"
						) as string;

					App.$bvToast.toast(errorText!, {
						title: i18n.t("common.error") as string,
						variant: "warning",
					});

					throw new Error("Error");
				}

				userAuthToken = (signInMutation.data as AuthSignInUserMutation)
					.authSignInUser;
			}

			await Preferences.set({ key: "USER_TOKEN", value: userAuthToken });

			await this.getUserPlatformConnections();
		},

		clearUserPlatformConnections() {
			this.userPlatformConnections = [];
		},

		async signUserOut() {
			const User = useUserStore();
			const Location = useLocationStore();

			await Preferences.remove({ key: "PLATFORM_TOKEN" });
			await Preferences.remove({ key: "USER_TOKEN" });
			this.clearUserPlatformConnections();
			User.destroyUser();
			Location.destroyLocation();

			try {
				const facebookAuth = await FacebookLogin.getCurrentAccessToken();
				if (facebookAuth.accessToken) {
					await FacebookLogin.logout();
				}
			} catch (error) {}

			location.reload();
		},
	},
});
