import { defineStore } from 'pinia'
import { GetLocationUserDefault, GetLocationUserDefaultQuery, GetLocationAdmin, GetLocationAdminQuery, GetLocations, GetLocationsQuery, NoxVersion, UserRole } from '@/graphql'
import { apolloClient } from '@/plugins/apollo'
import { useUserStore } from './user'

export interface LocationState {
  userLocation: {
    id: string,
    name: string,
    currency: string
  } | null,
  allLocations: GetLocationsQuery['locations']['data'] | [],
  selectedLocation: string,
  locationDetails: GetLocationAdminQuery['location'] | null
  loading: {
    locations: boolean,
    locationDetails: boolean
  }
}

export type LocationDetails = {
  id: string,
  name: string,
  city: string,
  controllerUnit: {
    id: string,
    url: string,
    noxVersion: NoxVersion
  } 
}


export const useLocationStore = defineStore({
  id: 'Location',
  state: (): LocationState => ({
    userLocation: null,
    locationDetails: null,
    allLocations: [],
    selectedLocation: '',
    loading: {
      locations: false,
      locationDetails: false
    }
  }),

  getters: {
    currentUserLocation: state => state.userLocation,
    selectedLocationDetails: state => state.locationDetails,
    locationControllerUnit: state => state.locationDetails?.controllerUnit,
    activeLocations: state => state.allLocations.filter((x) => x.allowSignUps),
    getLocationName: state => state.allLocations.find((x) => { return x.id === state.selectedLocation })?.nameShort ?? state.allLocations.find((x) => { return x.id === state.selectedLocation})?.name
  },

  actions: {
    async getUserLocation(){
      const getLocation = await apolloClient.query({
        query: GetLocationUserDefault,
        fetchPolicy: 'network-only'
      })

      const data = (getLocation.data as GetLocationUserDefaultQuery)

      if(data.locationUserDefault) {
        const locationData: LocationState['userLocation'] = {
          id: data.locationUserDefault.id,
          name: data.locationUserDefault.name,
          currency: data.locationUserDefault.company.currency
        }

        this.userLocation = locationData
      } else {
        this.userLocation = null
      }
      
    },

    async getLocations(){
      this.loading.locations = true
      const getLocations = await apolloClient.query({
        query: GetLocations,
        fetchPolicy: 'network-only',
      })

      const data = (getLocations.data as GetLocationsQuery)
      this.allLocations = data.locations.data
      this.loading.locations = false
    },

    async getLocationDetails(){
      this.loading.locationDetails = true
      const getAdminLocation = await apolloClient.query({
        query: GetLocationAdmin,
        fetchPolicy: 'network-only',
        variables: {
          id: this.selectedLocation
        }
      })

      const adminData = (getAdminLocation.data as GetLocationAdminQuery)

      if(adminData.location){
        this.locationDetails = adminData.location
      }

      this.loading.locationDetails = false
    },

    async initLocation() {
      const User = useUserStore()
      try {
        if (User.currentUser?.role === UserRole.User) {
          await this.getUserLocation()
        }
        else if (User.currentUser?.role === UserRole.Company) {
          if (User.currentUser?.companyId) {
            await this.getLocations()
            // Find the first location that is available for signup
            const defaultActiveLocationForCompany = this.activeLocations.filter((x) => { return x.company.id === User.currentUser?.companyId})[0]?.id
            // Find the first location of all locations
            const defaultLocationForCompany = this.allLocations.filter((x) => { return x.company.id === User.currentUser?.companyId })[0]?.id
            // If there is an active location for the company, select it as default. 
            // Otherwise, just select the first location.
            this.selectedLocation = defaultActiveLocationForCompany ?? defaultLocationForCompany
            this.getLocationDetails()
          }
        }
      } catch(error) {
        console.log(error)
      }
    },

    async destroyLocation() {
      this.locationDetails = null
      this.userLocation = null
    },
  },
})
