import { CustomRouteConfig } from './'
import { UserRole } from '@/graphql'
import { things } from '@/things/config'
import { Route } from 'vue-router'

const routes: CustomRouteConfig[] = [

  {
    name: 'Company',
    path: '/c',
    component: () => import('@/views/company/index.vue'),
    meta: {
      requireAuth: true,
      requireRole: UserRole['Company'],
      layout: 'Admin',
      title: 'Firma'
    },
    children: [

      /************
      * Root
      ************/
      {
        name: 'Company.Dashboard',
        path: 'dashboard',
        component: () => import('@/views/company/dashboard.vue'),
        meta: {
          title: 'Startsiden'
        }
      },

      {
        name: 'Company.Statistics',
        path: 'statistics',
        component: () => import('@/views/company/statistics/index.vue'),
        meta: {
          title: 'Statistikker'
        },
        children: [
          {
            name: 'Company.Statistics.Home',
            path: 'statistics/home',
            component: () => import('@/views/company/statistics/home.vue'),
            meta: {
              title: 'Statistikker'
            }
          },
          {
            name: 'Company.Statistics.General',
            path: 'statistics/general',
            component: () => import('@/views/company/statistics/general.vue'),
            meta: {
              title: 'Statistikker'
            }
          },
          {
            name: 'Company.Statistics.BookingSlots',
            path: 'statistics/booking-slots',
            component: () => import('@/views/company/statistics/bookingSlots.vue'),
            meta: {
              title: 'Statistikker'
            }
          },
        ]
      },

      /************
      * User
      ************/
      {
        name: 'Company.Users',
        path: 'users',
        component: () => import('@/views/company/users.vue'),
        meta: {
          title: 'Brugere'
        }
      },

      /************
      * UserProductSubscriptions
      ************/
      {
        name: 'Company.UserProductSubscriptions',
        path: 'subscriptions',
        component: () => import('@/views/company/userProductSubscriptions.vue'),
        meta: {
          title: 'Medlemskaber'
        }
      },

      /************
    * UserProductSubscriptions
    ************/
      {
        name: 'Company.UserGroups',
        path: 'user-groups',
        component: () => import('@/views/company/userGroups.vue'),
        meta: {
          title: 'Brugergrupper'
        }
      },

      /************
      * Payments
      ************/
      {
        name: 'Company.Payments',
        path: 'payments',
        component: () => import('@/views/company/payments.vue'),
        meta: {
          title: 'Betalinger'
        }
      },

      /************
      * Help
      ************/
       {
        name: 'Company.Help',
        path: 'help',
        component: () => import('@/views/company/help.vue'),
        meta: {
          title: 'Hjælp'
        }
      },

      /************
      * Workshops
      ************/
      {
        name: 'Company.Workshop.Create',
        path: 'workshops/create',
        component: () => import('@/views/company/workshops/createWorkshop.vue'),
        meta: {
          title: 'Opret Arrangement'
        }
      },
      {
        name: 'Company.Workshops',
        path: 'workshops',
        component: () => import('@/views/company/workshops/workshops.vue'),
        meta: {
          title: 'Arrangementer'
        }
      },

      /************
      * ResourceBookings
      ************/
      /*
      {
        name: 'Company.ResourceBooking',
        path: 'bookings/:id',
        component: () => import('@/views/company/resourceBookings/resourceBooking.vue'),

      },

     {
      name: 'Company.ResourceBooking',
        path: 'bookings/:id',
        component: () => import('@/components/admin/thingInfo/showThing.vue'),
        props: (route) => ({
          id: route.params.id,
          type: 'ResourceBooking',
          as: 'FullPage'
        })
      },
      */
      {
        name: 'Company.ResourceBookings',
        path: 'bookings',
        component: () => import('@/views/company/resourceBookings.vue'),
        meta: {
          title: 'Bookinger'
        }
      },

      /************
      * Create message/newsletter
      ************/
     /** OUTCOMMENT TO DISABLE BO15-203 */
      {
        name: 'Company.Newsletter',
        path: 'newsletter',
        component: () => import('@/views/company/sendNewsletter.vue'),
        meta: {
          title: 'Newsletter'
        }
      },

      /************
      * Settings
      ************/
      {
        name: 'Company.Settings',
        path: 'settings',
        component: () => import('@/views/company/settings/index.vue'),
        meta: {
          title: 'Indstillinger'
        },
        children: [
          {
            name: 'Company.Settings.Home',
            path: 'home',
            component: () => import('@/views/company/settings/home.vue'),
            meta: {
              title: 'Indstillinger'
            }
          },
          {
            name: 'Company.Settings.User',
            path: 'user',
            component: () => import('@/views/company/settings/user.vue'),
            meta: {
              title: 'User'
            }
          },
          {
            name: 'Company.Settings.Priceff',
            path: 'priceff',
            component: () => import('@/views/company/settings/priceff.vue'),
            meta: {
              title: 'Dynamiske priser'
            }
          },
          {
            name: 'Company.Settings.GiftCards',
            path: 'gift-cards',
            component: () => import('@/views/company/settings/giftCards.vue'),
            meta: {
              title: 'Gavekort'
            }
          },
          {
            name: 'Company.Settings.PlatformInvoices',
            path: 'platform-invoices',
            component: () => import('@/views/company/settings/platformInvoices.vue'),
            meta: {
              title: 'Platform faktura'
            }
          },
          {
            name: 'Company.Settings.InfoScreens',
            path: 'info-screens',
            component: () => import('@/views/company/settings/infoScreens.vue'),
            meta: {
              title: 'Infoskærme'
            }
          },
          {
            name: 'Company.Settings.Adminstrators',
            path: 'admins',
            component: () => import('@/views/company/settings/administrators.vue'),
            meta: {
              title: 'Admins'
            }
          },
          {
            name: 'Company.Settings.Reports',
            path: 'reports',
            component: () => import('@/views/company/settings/reports.vue'),
            meta: {
              title: 'Reports'
            }
          },
          {
            name: 'Company.Settings.Company',
            path: 'company',
            component: () => import('@/views/company/settings/company.vue'),
            meta: {
              title: 'Firma'
            }
          },
          {
            name: 'Company.Settings.Integrations',
            path: 'integrations',
            component: () => import('@/views/company/settings/intergrations.vue'),
            meta: {
              title: 'Integrationer'
            }
          },
          {
            name: 'Company.Settings.Locations',
            path: 'locations',
            component: () => import('@/views/company/settings/locations.vue'),
            meta: {
              title: 'Lokationer'
            }
          },
          {
            name: 'Company.Settings.AccessCodes',
            path: 'access-codes',
            component: () => import('@/views/company/settings/accessCodes.vue'),
            meta: {
              title: 'Adgangskontrol'
            }
          },
          {
            name: 'Company.Settings.Messages',
            path: 'messages',
            component: () => import('@/views/company/settings/messages.vue'),
            meta: {
              title: 'Beskeder'
            }
          },
          {
            name: 'Company.Settings.Payment',
            path: 'payment',
            component: () => import('@/views/company/settings/payment.vue'),
            meta: {
              title: 'Betalinger'
            }
          },
          {
            name: 'Company.Settings.Resource',
            path: 'resource',
            component: () => import('@/views/company/settings/resource.vue'),
            meta: {
              title: 'Baner'
            }
          },
          {
            name: 'Company.Settings.Agreements',
            path: 'agreements',
            component: () => import('@/views/company/settings/agreements.vue'),
            meta: {
              title: 'Dokumenter'
            },
          },
          {
            name: 'Company.Settings.SubscriptionProducts',
            path: 'subscription-products',
            component: () => import('@/views/company/settings/subscriptionProduct.vue'),
            meta: {
              title: 'Memberships'
            }
          },
          {
            name: 'Company.Settings.Migration',
            path: 'migration',
            component: () => import('@/views/company/settings/migration.vue'),
            meta: {
              title: 'Migrations'
            }
          },
          {
            name: 'Company.Settings.AdditionalServices',
            path: 'additional-services',
            component: () => import('@/views/company/settings/additionalServices.vue'),
            meta: {
              title: 'Additional services'
            }
          },
          {
            path: '*',
            redirect: { name: 'Company.Settings.Home' }
          }
        ]
      },

      ...things.filter(thing => thing.moreInfoRoutePath).map(thing => ({
        name: `Company.${thing.type}`,
        path: thing.moreInfoRoutePath!,
        meta: {
          title: thing.routeTitle!,
          thingType: thing.type
        },
        component: () => import('@/things/thingRoute.vue'),
      }))
    ]

  }
]

export default routes
