export default {
  underProcess: {
    title: 'Video is being processed',
    text: {
      one: 'Your video is ready around',
      two: '- This video is currently being processed. You will receive an email when it is finished and then you will be able to find it here.'
    }
  },
  seconds: 'sec.',
  minutes: 'min.',

  startTimeButton: 'Start point',
  endTimeButton: 'End point',
  createClipButton: 'Create clip',
  clipIsCreatedSuccessToast: 'The clip has been created'
}