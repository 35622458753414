import { Currency } from "@/graphql";
import { NumberFormat } from "vue-i18n";



export const numberFormats: { [key in Currency]: NumberFormat } = {
  'USD': {
    currency: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol'
    }
  },
  'DKK': {
    currency: {
      style: 'currency',
      currency: 'DKK',
      currencyDisplay: 'symbol'
    }
  },
  'EUR': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    }
  },
  'GBP': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    }
  }
}