export default {
  subTitle: 'Overview',
  pageTitle: 'Memberships',

  table: {
    product: 'Product',
    status: 'Status',
    showProductHover: 'View product',
    showCutomerHover: 'View user bruge'
  }
}