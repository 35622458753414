<template lang="pug">
b-modal(
	hide-header
	v-model="isNotConnectedToNetwork"
	centered
	hide-footer
	:no-close-on-esc="true"
	:no-close-on-backdrop="true"
	:hide-header-close="true"
	size="sm")
	div.mb-4
		<svg class="d-block mx-auto" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="128px" height="128px"><path fill="#fff" d="M99,54c11,0,20,9,20,20s-9,20-20,20H29C18,94,9,85,9,74s9-20,20-20h5v-5c0-16.6,13.4-30,30-30s30,13.4,30,30v5H99z"/><path fill="#fff" d="M106 105c-1.7 0-3-1.3-3-3V92c0-1.7 1.3-3 3-3s3 1.3 3 3v10C109 103.7 107.7 105 106 105zM106 115c-.2 0-.4 0-.6-.1-.2 0-.4-.1-.6-.2-.2-.1-.3-.2-.5-.3-.2-.1-.3-.2-.4-.4-.1-.1-.3-.3-.4-.4-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.3.3-.5.1-.2.2-.3.4-.4.7-.7 1.7-1 2.7-.8.2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.6.6.9 1.3.9 2.1s-.3 1.6-.9 2.1c-.1.1-.3.3-.4.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2C106.4 115 106.2 115 106 115z"/><path fill="#fcca3d" d="M119.5,111.4L98.3,76.1c-0.9-1.5-2.5-2.4-4.3-2.4s-3.4,0.9-4.3,2.4l-21.2,35.3c-0.9,1.6-1,3.4-0.1,5c0.9,1.6,2.5,2.5,4.4,2.5h42.3c1.8,0,3.5-0.9,4.4-2.5C120.4,114.9,120.4,113,119.5,111.4z"/><path fill="#fff" d="M94 102c-1.7 0-3-1.3-3-3V89c0-1.7 1.3-3 3-3s3 1.3 3 3v10C97 100.7 95.7 102 94 102zM94 112c-.8 0-1.6-.3-2.1-.9-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.8.3-1.6.9-2.1 1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.3.9 2.1 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5C95.6 111.7 94.8 112 94 112z"/><g><path fill="#444b54" d="M61,97H29C16.3,97,6,86.7,6,74s10.3-23,23-23h2v-2c0-18.2,14.8-33,33-33s33,14.8,33,33v2h2c12.7,0,23,10.3,23,23c0,2.1-0.3,4.1-0.8,6.1c-0.4,1.6-2.1,2.5-3.7,2.1c-1.6-0.4-2.5-2.1-2.1-3.7c0.4-1.5,0.6-3,0.6-4.5c0-9.4-7.6-17-17-17h-5c-1.7,0-3-1.3-3-3v-5c0-14.9-12.1-27-27-27S37,34.1,37,49v5c0,1.7-1.3,3-3,3h-5c-9.4,0-17,7.6-17,17s7.6,17,17,17h32c1.7,0,3,1.3,3,3S62.7,97,61,97z"/></g></svg>
		
	h3.text-center {{ $t('components.shared.networkConnection.title') }}
	p.text-muted.text-center.mb-0 {{ $t('components.shared.networkConnection.text') }}

	b-button.mt-4(
		block
		@click="reloadPage()"
		variant="primary") {{ $t('components.shared.networkConnection.reloadPageButton') }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {  } from '@/graphql'
import { Network, ConnectionStatus } from '@capacitor/network'
import { PluginListenerHandle } from '@capacitor/core'

@Component({
	components: {

	},
	apollo: {

	}
})
export default class CompanyUsersUserView extends Vue {

networkListener: null | PluginListenerHandle = null

	isConnectedToNetwork = true

	get isNotConnectedToNetwork() {
		return !this.isConnectedToNetwork
	}

	created () {
		this.networkListener = Network.addListener('networkStatusChange', status => {
			this.networkStatusChange(status)
		})
	}

	destroyed() {
		if (this.networkListener)
			this.networkListener.remove()
	}

	networkStatusChange(newStatus: ConnectionStatus) {
		if (newStatus.connected)
			this.isConnectedToNetwork = true
		else
			this.isConnectedToNetwork = false
	}

	reloadPage() {
		location.reload()
	}

}
</script>

<style lang="sass" scoped>

</style>