export default {
  subTitle: 'Settings',
  pageTitle: 'Your user',

  userProfile: {
    title: 'Your profile',
    description: "Here, you can update your user's name, country, and phone number. Changes affect only your profile, and not company data. Click 'Update user info' to update instantly. "
  },

  connections: {
    title: 'Connections',
    description: "Here, you can create a new connection for your user. This can also be done by logging in to your user and changing the default location.",
    createNewConnectionButton: 'Create new connection',

    createConnectionModal: {
      title: 'Create new connection',
      description: 'Connect to another company or location.'
    }
  }
}