export default {

  missingInput: 'Missing input',
  isAlreadyUsed: 'Is already in use',
  contactInformation: 'Contact information',

  dataIsNotValidMessage: 'Date is not valid',

  email: {
    label: 'Email address',
    placeholder: 'Your email',
    errorNotVaild: 'This is not a valid email'
  },

  phoneNumber: {
    label: 'Phone number',
    placeholder: 'Your phone number',
    errorNotValid: 'Phone number is not valid'
  },

  birthday: {
    label: 'Date of birth',
    placeholder: 'Your date of birth',
    errorNotVaild: 'Date of birth is not valid',
    errorMissing: 'Your are missing date of birth',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December'
    }
  },

  selectUser: {
    label: 'User'
  },

  selectUsers: {
    label: 'Users'
  },

  pinCode: {
    label: 'Pin code',
    placeholder: '00000'
  },

  cardCode: {
    label: 'Card code',
    placeholder: '00000'
  },

  popularity: {
    label: 'Order (popularity)'
  },

  days: {
    label: 'Days'
  },

  address: {
    label: 'Address',
    placeholder: 'Fill in your address',
    errorMissing: 'You need to fill in the address'
  },

  door: {
    label: 'Doors'
  },

  location: {
    label: 'Location',
    placeholder: 'Choose a location',
    firstOption: 'Choose a location...'
  },

  namePerson: {
    label: 'Name',
    placeholder: 'Write your name',
  },

  nameItem: {
    label: 'Name',
    placeholder: 'Write a name',
  },

  nameShortItem: {
    label: 'Short name',
    placeholder: 'Write a short name',
  },

  message: {
    label: 'Message'
  },

  firstName: {
    label: 'First name',
    placeholder: 'Write your first name',
  },

  maxAttendees: {
    label: 'Maximum number of participants'
  },

  categorySelect: {
    label: 'Category',
    placeholder: 'Select a category',
    firstOption: 'Select a category...'
  },

  lastName: {
    label: 'Last name',
    placeholder: 'Write your last name'
  },

  password: {
    label: 'Password',
    placeholder: 'Write a password',
    errorNotVaild: 'This is not a valid password'
  },

  newPassword: {
    label: 'New password',
    placeholder: 'Write a new password'
  },

  date: {
    label: 'Date',
    placeholder: 'Select a date'
  },

  streetName: {
    label: 'Street name',
    placeholder: 'My road 3..'
  },

  zipCode: {
    label: 'Zip code',
    placeholder: '8300'
  },

  city: {
    label: 'City',
    placeholder: 'Odder'
  },

  streetNameAndNumber: {
    label: 'Street name and number',
  },

  zipAndCity: {
    label: 'Zip code and city',
  },

  vatNumber: {
    label: 'VAT number',
  },

  namePersonOrCompany: {
    label: 'Name of person or company',
  },

  timeStart: {
    label: 'Start time'
  },

  timeEnd: {
    label: 'End time'
  },

  country: {
    label: 'Country'
  },

  title: {
    label: 'Title',
    placeholder: 'Write a title'
  },

  description: {
    label: 'Description',
    placeholder: 'Write a description'
  },

  typeSelect: {
    label: 'Type',
    placeholder: 'Select a type',
    firstOption: 'Select a type...'
  },

  gender: {
    label: 'Gender',
    typeLabel: {
      select: 'Select gender',
      male: 'Male',
      female: 'Female',
      other: 'Other'
    }
  },

  friendSearch: {
    placeholder: 'Search for friend (name or mobile number)'
  },

  logo: {
    label: 'Logo',
    placeholder: 'Select a logo'
  },

  upload: {
    attachmentsLabel: 'Attachments',
    placeholder: 'Select a file or drop it here...',
    dropPlaceholder: 'Drag your file to here...'
  },

  passwordRepeat: {
    label: 'Repeat password',
    placeholder: 'Repeat your password',
    errorNotSame: 'The two passwords are not the same.'
  },

  passwordRequirements: {
    headline: 'Password requirements',
    requirement: 'Min 8 characters, uppercase, lowercase, number'
  },

  privacy: {
    errorMissing: 'You need to accept both our privacy policy and terms of trade'
  },

  businessAccount: {
    toggle: 'Business account',
    businessName: 'Company name',
    businessNamePlaceholder: 'Company Ltd.',
    vatNumber: 'VAT number',
  },

  reservationType: {
    label: 'Reservation type',
    placeholder: 'Select a type'
  }
}