export default {
	subTitle: "Overview",
	pageTitle: "Settings",

	items: {
		user: {
			title: "Your user",
			description: "Information and settings for your company",
		},
		company: {
			title: "Company",
			description: "Information and settings for your company",
		},
		adminstrators: {
			title: "Administrators",
			description: "Information and settings for your company",
		},
		platformInvoices: {
			title: "Platform invoices",
			description: "Information and settings for your company",
		},
		giftCards: {
			title: "Gift card",
			description: "Information and settings for your company",
		},
		payment: {
			title: "Discount & points",
			description: "Information and settings for your company",
		},
		infoScreens: {
			title: "Info screens",
			description: "Information and settings for your company",
		},
		priceff: {
			title: "Dynamic prices",
			description: "Information and settings for your company",
		},
		subscriptionProducts: {
			title: "Memberships",
			description: "Information and settings for your company",
		},
		agreements: {
			title: "Agreement documents",
			description: "Information and settings for your company",
		},
		resource: {
			title: "Courts",
			description: "Information and settings for your company",
		},
		locations: {
			title: "Locations",
			description: "Information and settings for your company",
		},
		messages: {
			title: "Messages",
			description: "Information and settings for your company",
		},
		accessCodes: {
			title: "Access",
			description: "Information and settings for your company",
		},
		integrations: {
			title: "Integrations",
			description: "Information and settings for your company",
		},
		additionalservices: {
			title: "Additional services",
		}
	},
};
