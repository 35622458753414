<template lang="pug">
b-overlay.p-0(
  :show="$apollo.queries.workshopBooking.loading"
  variant="transperant" 
  spinner-variant="primary")
  b-link(
    :to="{ name: 'User.Workshop', params: { id: workshopBooking.workshop.id } }"
    v-if="workshopBooking")
    b-row
      b-col.d-flex.align-items-center.justify-content-center.flex-column.border-right.pl-4(
        cols="auto"
        v-if="timeType === 'timeTo' || timeType === 'startTime'")
        span.text-center(v-if="timeType === 'timeTo'")
          small.text-muted.m-0 {{ $t('components.app.resourceBookingCard.in') }}
          p.m-0.text-body.text-center {{ to }}
        span.text-center(v-if="timeType === 'startTime'")
          small.text-muted.m-0 {{ $t('components.app.workshopBookingCard.time') }}
          p.m-0.text-body {{ date.startDate | moment('HH:mm') }}

      b-col.py-2.px-3
        span.text-body {{ workshopBooking.workshop.title }}
        b-row.pt-1(style="flex-wrap: nowrap" align-v="center")
          b-col.col-auto.pr-0.text-muted
            i.fe.fe-calendar.icon-sm.mr-2
          b-col.pl-1
            small.mt-1.text-body <span class=""> {{ date.startDate | moment('ddd. D. MMM') }} {{ $t('components.app.workshopBookingCard.timeA') }} {{ date.startDate | moment('HH:mm') }} </span> {{ $t('components.app.workshopBookingCard.to') }} <span class="">{{ $t('components.app.workshopBookingCard.timeA') }} {{ date.endDate | moment('HH:mm') }}</span>

        b-row(style="flex-wrap: nowrap" align-v="center")
          b-col.col-auto.pr-0
            i.fe.fe-map-pin.text-muted.icon-sm.mr-2
          b-col.pl-1
            small.mt-1.text-body {{ workshopBooking.workshop.location.name }}, {{ workshopBooking.workshop.location.streetName }} - {{ workshopBooking.workshop.location.zipCode }} {{ workshopBooking.workshop.location.city }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetWorkshopBookingCardQuery, GetWorkshopBookingCard, GetWorkshopBookingCardQueryVariables } from '../../graphql'

@Component({
  components: {

  },
  apollo: {
    workshopBooking: {
      query: GetWorkshopBookingCard,
      variables(): GetWorkshopBookingCardQueryVariables {
        return {
          id: this.workshopBookingId
        }
      }
    }
  }
})
export default class CompanyUsersUserView extends Vue {
  @Prop({ required: true }) readonly workshopBookingId!: string
  @Prop({ required: false, default: 'timeTo' }) readonly timeType!: 'timeTo' | 'startTime'
  @Prop({ required: false }) readonly selectedDayId!: string

  workshopBooking: GetWorkshopBookingCardQuery['workshopBooking'] | null = null

  get date(): { startDate: Date, endDate: Date } {
    if (!this.workshopBooking) return { startDate: new Date(), endDate: new Date() }
    if (this.selectedDayId) {
      const day = this.workshopBooking.workshop.days.find(x => x.id === this.selectedDayId)
      return {
        startDate: day?.startDate,
        endDate: day?.endDate
      }
    }
    else {
      return {
        startDate: this.workshopBooking.workshop.nextDay?.startDate,
        endDate: this.workshopBooking.workshop.nextDay?.endDate
      }
    }
  }

  get bookingNextToMinutes() {
    if (!this.workshopBooking) return 0
    return this.$moment(this.date.startDate as Date).diff(this.$moment(), 'minutes')
  }

  get toDays() {
    return this.bookingNextToMinutes! / 1440
  }

  get toMinutes() {
    return this.bookingNextToMinutes!
  }

  get toHours() {
    return this.bookingNextToMinutes! / 60
  }

  get to() {
    if (this.toMinutes > 1 * 60 * 24) {
      return `${Math.floor(this.toDays)} ${this.$t('components.app.workshopBookingCard.toStartDate.days') as string}`
    } else if (this.toMinutes > 60) {
      const r = Math.floor(this.toHours)
      const ending = r > 1 ? this.$t('components.app.workshopBookingCard.toStartDate.hours') as string : this.$t('components.app.workshopBookingCard.toStartDate.hour') as string
      return `~${r} ${ending}`
    } else {
      return `${Math.floor(this.toMinutes)} ${this.$t('components.app.workshopBookingCard.toStartDate.min') as string}`
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
