export default {
    subTitle: "Settings",
    pageTitle: "Additional services",
    description: 'On this page, you are able to create and edit additional services. An additional service is displayed to an user as they create a booking. <br> The service could be something in the form of, for example, ball machines, or other services provided during the booking. <br> <br> At the moment, it is only possible to set up a maximum of 1 additional service per club. ',
    createButton: {
        title: 'Create additional service',
        loading: 'Loading...'
    },
    createFormInput: {
        name: 'Name of service',
        price: 'Price of service',
        description: 'Description of service',
        capacity: 'Capacity of service (set to 0 if unlimited)',
        resourceCategory: 'The category in which service should be activated'
    },
    createdAdditionalServices: {
        success: 'Successfully created additional service!',
        noAdditionalServices: 'No additional services created yet.'
    },
    updateAdditionalService: {
        title: 'Update additional service',
    },
    serviceCard: {
        capacity: 'Capacity',
        price: 'Price',
        description: 'Description',
        unlimited: 'Unlimited'
    },
    show: 'Show',
    hidden: 'Additional service is hidden for users?'

}
