<template lang="pug">
	span
		b-badge(v-if="useIcon" :variant="res.variant") {{ res.label }}
		span(v-if="!useIcon") {{ res.label }}
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class PaymentStatus extends Vue {
	@Prop({ required: true, type: String }) status!: string
	@Prop({ required: false, type: Boolean, default: true }) useIcon!: boolean

	get res () {
	  switch (this.status) {
	    case 'Paid':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentStatus.paid') as string,
	        variant: 'success'
	      }

	    case 'Refunded':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentStatus.refunded') as string,
	        variant: 'info'
	      }

		case 'PartialRefunded':
		return {
			label: this.$t('components.admin.enumTransformers.paymentStatus.partialrefunded') as string,
			variant: 'info'
		}
	    case 'Failed':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentStatus.failed') as string,
	        variant: 'danger'
	      }

	    case 'Canceled':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentStatus.canceled') as string,
	        variant: 'secondary'
	      }

	    case 'Pending':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentStatus.pending') as string,
	        variant: 'warning'
	      }

	    case 'WaitingStatus':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentStatus.waitingStatus') as string,
	        variant: 'warning'
	      }

	    case 'Created':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentStatus.created') as string,
	        variant: 'warning'
	      }

	    case 'Sent':
	      return {
	        label: this.$t('components.admin.enumTransformers.paymentStatus.sent') as string,
	        variant: 'success'
	      }

	    default:
	      return {
	        label: this.status,
	        type: 'dark'
	      }
	  }
	}
}
</script>

<style lang="sass">

</style>
