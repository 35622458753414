export default {
  title: 'Add campaign code',

  firstPaymentDate: 'First payment date',

  addModal: {
    title: 'Add campaign code',
    inputPlaceholder: 'Enter campaign code',
    submit: 'Add',
    submitLoading: 'Validating...',
  },

  validate: {
    title: 'Not valid',
    notValid: 'Campaign code is not valid',
  }
}