export default {
  subTitle: 'Settings',
  pageTitle: 'Gift card',
  description: 'Create new gift cards here. Click "Create new gift card" to set the points amount, expiration date and number of gift cards to be created. Once created, gift cards appear in the table with details like gift card code, value, expiration date, and usage status.',

  createNewGiftCardButton: 'Create new gift card',

  table: {
    code: 'Code',
    createdAt: 'Created at',
    expiryDate: 'Expiration date',
    giftCardExpired: '(Gift card has expired)',
    pointsAmount: 'Points amount',
    used: 'Used'
  },

  createModal: {
    title: 'Create gift card',

    pointsAmount: {
      label: 'Points amount'
    },
    expiryDate: {
      label: 'Expiration date (empty for none)'
    },
    createGiftCardsCount: {
      label: 'Number of gift cards to be created of this type'
    },

    platformFeeDescription: 'There is a platform transaction fee (3% + DKK 3) on all points, but it is only when / if it is redeemed by a user.',

    createGiftCardButton: 'Create gift card',
    createGiftCardLoadingButton: 'Creating gift card...'
  },

  giftCardCreated: {
    title: 'Gift cards have been created',
    description: 'You have now created some gift cards. Below are the codes the users need to enter to redeem the gift card. Take a picture or copy below and then paste it into your template.'
  },

  copyCodes: {
		buttonText: "Copy all codes",
		codesCopied: "Gift card codes copied to clipboard",
	},
}