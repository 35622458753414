<template>
    <div>
        <div class="button-wrapper">
            <b-button block size="lg" variant="outline-danger" @click="backToOverview">
                {{ $t('components.app.pay.stripePaymentElement.cancelPayment') }}
            </b-button>
        </div>
        <div class="payment-element-wrapper">
            <div id="payment-element"></div>
        </div>
        <div id="payment-message" class="hidden payment-message"></div>
        <div v-if="isSafari" class="allow-redirect-message">
            <i class="fe fe-alert-circle allow-redirect-message-icon"></i>
            <small class="allow-redirect-message-text">
                {{ $t('components.app.pay.stripePaymentElement.requirePopupsWarning') }}
            </small>
        </div>
        <div class="button-wrapper">
            <b-button block size="lg" variant="primary" id="pay-button" @click="pay">
                <div class="spinner hidden" id="spinner">
                    <b-spinner small></b-spinner>
                </div>
                <span id="button-text">
                    {{ payButtonText }}
                </span>
            </b-button>
        </div>
    </div>
</template>
<script lang="ts">
import { ConfirmPaymentIntent, ConfirmPaymentIntentMutation, ConfirmPaymentIntentMutationVariables, PaymentTokenState } from '@/graphql'
import { apolloClient } from '@/plugins/apollo'
import { usePayPaymentTokenStore } from '@/store/modules/pay/paymentToken'
import { Appearance, Stripe, StripeElements, StripePaymentElement, StripePaymentElementOptions, loadStripe, PaymentIntent } from '@stripe/stripe-js'
import Vue, { onMounted, ref, watch } from 'vue'
import { i18n } from '@/locales/setupI18n'
import { Browser, getCurrentBrowser } from '@/utils/getCurrentBrowser'

export default Vue.extend({
    setup(_, { emit }) {
        let stripe: Stripe | null
        let elements: StripeElements | null
        let paymentIntent: PaymentIntent
        let browser: Browser = getCurrentBrowser(window.navigator.userAgent)
        const paymentTokenStore = usePayPaymentTokenStore()
        const payButtonText = ref<string>('')
        const isSafari = ref<boolean>(browser === Browser.Safari)

        const backToOverview = () => {
            emit('back-to-overview')
        }
    
        onMounted(async () => {
            setLoading(true)

            stripe = await loadStripe(process.env.STRIPE_KEY!)

            if (!stripe) {
                console.error('Error loading Stripe.')
                return
            }

            const appearance: Appearance = {
                theme: 'stripe',
                variables: {
                    colorPrimary: '#2c2b2c'
                }
            }

            elements = stripe.elements({
                appearance: appearance,
                clientSecret: paymentTokenStore.paymentToken?.stripeClientSecret!
            })

            const paymentElementOptions: StripePaymentElementOptions = {
                layout: 'tabs',
            }

            const paymentElement: StripePaymentElement = elements.create('payment', paymentElementOptions)
            paymentElement.mount('#payment-element')

            await fetchAmountToPay()

            setLoading(false)
        })

        const pay = async () => {
            setLoading(true)

            if (!stripe || !elements) {
                console.error('Stripe is not initialized.')
                return
            }

            const { error: submitError } = await elements.submit()

            if (submitError) {
                console.error('Error submitting elements', submitError)
                setLoading(false)
                return
            }

            const { error: createConfirmationTokenError, confirmationToken } = await stripe.createConfirmationToken({ elements })

            if (createConfirmationTokenError) {
                console.error('Error creating confirmation token', createConfirmationTokenError)
                setLoading(false)
                return
            }


            const { errors, data } = await apolloClient.mutate<ConfirmPaymentIntentMutation, ConfirmPaymentIntentMutationVariables>({
                mutation: ConfirmPaymentIntent,
                variables: {
                    paymentIntentId: paymentIntent.id,
                    confirmationTokenId: confirmationToken.id
                }
            })

            if (errors?.length) {
                for (const error of errors) {
                    console.error(error)
                }
                return
            }

            if (data?.nextActionUrl) {
                window.open(data.nextActionUrl, '_blank')
            }
        }

        const showMessage = (messageText: string) => {
            const messageContainer = document.querySelector('#payment-message')

            if (!messageContainer) {
                console.error('Could not find message container.')
                return
            }

            messageContainer.classList.remove('hidden')
            messageContainer.textContent = messageText
    
            setTimeout(() => {
                messageContainer.classList.add('hidden')
                messageContainer.textContent = ''
            }, 4000)
        }

        const setLoading = (isLoading: boolean) => {    
            if (isLoading) {
                // Disable the button and show a spinner
                (document.querySelector("#pay-button") as HTMLButtonElement).disabled = true
                document.querySelector("#spinner")!.classList.remove("hidden")
                document.querySelector("#button-text")!.classList.add("hidden")
            } else {
                (document.querySelector("#pay-button") as HTMLButtonElement).disabled = false
                document.querySelector("#spinner")!.classList.add("hidden")
                document.querySelector("#button-text")!.classList.remove("hidden")
            }
        }

        const fetchAmountToPay = async () => {
            if (!stripe) {
                console.error('Stripe is not initialized.')
                return
            }

            const result = await stripe.retrievePaymentIntent(paymentTokenStore.paymentToken?.stripeClientSecret!)
            
            if (result.error) {
                console.error('Error retrieving Payment Intent -', result.error)
                return
            }

            paymentIntent = result.paymentIntent
            
            payButtonText.value = `${i18n.t('components.app.pay.stripePaymentElement.pay')} ${paymentTokenStore.paymentToken?.currency} ${paymentIntent.amount / 100}`
        }

        watch(
            () => paymentTokenStore.paymentToken?.attemptCount,
            () => {
                const updatedPaymentToken = paymentTokenStore.paymentToken
                if (updatedPaymentToken?.state === PaymentTokenState.PaymentAttemptFailed) {
                    showMessage(updatedPaymentToken.lastFailureReason!)
                    setLoading(false)
                }
            }
        )

        return {
            pay,
            backToOverview,
            payButtonText,
            isSafari
        }
    }
})

</script>
<style lang="scss" scoped>
.button-wrapper {
    padding: 1rem 1rem;
}

.payment-element-wrapper {
    padding: 0rem 0rem;
}

.payment-message {
    padding: 0.25rem 1.5rem;
    color: #ca6661;
}

.allow-redirect-message {
    padding: 0.25rem 1.5rem;
}

.allow-redirect-message-icon {
    color: #FF9D97;
}

.allow-redirect-message-text {
    opacity: 0.7;
}

#payment-element {
    border: none;
}
</style>