export default {
  
  giftCard: {

    boxTitle: 'Campaign codes',
    createButton: 'Create campaign code',

    table: {
      code: 'Code',
      note: 'Note'
    },

    createModal: {
      title: 'Create campaign code',
      code: {
        label: 'Code name (optional)',
        placeholder: 'Enter name of code'
      },
      createMultipleCodes: 'Create multiple codes',
      createSingleCode: 'Create single code',
      errorCreateCode: 'Could not create campaign code - the code needs to be unique',
      successCreateCode: 'Succesfully created campaign codes!',
      note: {
        label: 'Note (optional)',
        placeholder: 'Enter note'
      },
      createCount: 'Create count',
      validUntil: 'Valid until (optional)',
      maxUsageTimes: 'Max usage times',
      startAfterPeriods: 'Start after periods (in months)',
      companies: 'Companies (if none, then all will work)',

      submit: 'Create campaign code',
      submitLoading: 'Creating campaign code...'
    },
    
    finishModal: {
      title: 'Campaign codes created',
      text: 'ABC',
    }
  }

}