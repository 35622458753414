export default {
  home: 'Home',
  calendar: 'Your calendar',

  shop: {
    pointAndSubscription: 'Point / Subscription',
    point: 'Points',
    subscription: 'Subscription',
    shop: 'Shop'
  },
  account: 'Account',

  bookResource: 'Book court'
}