export default {
  showUser: 'Show user',
  showUserGroup: 'Show user group',
  showSubscription: 'Show subscription',
  showSubscriptionProduct: 'Show subscription product',
  showPayment: 'Show payment',
  showCoupon: 'Show discount code',
  showStripeInvoiceId: 'Show Stripe invoice',
  showConnectedPayment: 'Show connected payment',
  showPointAccount: 'Show point account',
  showAccessCode: 'Show access code',
  showWorkshop: 'Show event',
  showRecurringBooking: 'Show recurring booking',
  showAdditionalServices: 'Show additional services'
}