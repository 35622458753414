export default {
  welcome: 'Welcome',
  startPage: 'Start page',
  status: 'Status',
  online: 'Online',
  offline: 'Offline',
  monthly: 'Monthly',
  bookingFulfillment: 'Booking fulfillment',
  paymentMethod: 'Payment method',
  thisWeek: 'This week',
  franchiseOverview: 'Franchise overview',
  prCompany: "Per company",
  revenue: "Revenue",
  prPaymentMethod: "Per payment method",
  platformFee: "Platform fee",
  feeProfit: "Fee profit",
  signInMethod: "Sign-in method",
  pageVisits: "Page visits",
  newUsers: "New users",
  statistics: "Statistics",
  today: "Today",
  yesterday: "Yesterday",
  week: "Last 7 days",
  month: "Month to date",
  dailyResource: "Per resource per day",
  sum: "Sum",
  avg: "Avg.",
  number: 'Number',
  usersOnPlatform: "Users on the platform right now",
}