export default {
  haveCouponButton: 'Add discount code?',
  autoCouponButton: 'Automatic codes ({0})',

  addedCoupon: {
    text: 'Coupon code:',
    remove: 'Remove'
  },

  autoCouponsModal: {
    title: 'Automatic discount codes',
    text: 'You can make use of these discount codes because you are part of a specific user group or meet different requirements.'
  },

  addCouponModal: {
    title: 'Add discount code',
    codePlaceholder: 'Discount code',
    addButton: 'Add discount code',
    addLoadingButton: 'Validating coupon code...',
    cancel: 'Cancel',

    notValid: {
      title: 'Not valid',
      text: 'The discount code entered is not valid. Try another.'
    }
  }
}