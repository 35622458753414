export default {
  subTitle: 'Settings',
  pageTitle: 'Dynamic prices',

  resourceTypeUpdated: 'The type is updated',

  resourceTypesTable: {
    name: 'Name',
    standardPriceA: 'A price',
    standardPriceB: 'B price',

    updatePrice: 'Update price',
    usePriceffDynamicPricing: 'Use dynamic pricing'
  },

  prices: {
    title: 'Prices'
  }
}