<template>
  <div>
    <b-modal
      content-class="modal-overflow-fix"
      :title="$t('components.app.paymentInvoiceModal.title')"
      v-model="showModal"
      @hide="closeModal"
      title-tag="h4"
      centered
      hide-footer
      size="sm"
    >
      <div v-if="!invoiceUrl">
        <b-card>
          <b-row>
            <b-col>
              <p class="mt-1">{{ $t('components.app.paymentInvoiceModal.details') }}</p>
            </b-col>
            <b-col cols="auto">
              <i id="invoice-details-info-icon" class="fe fe-help-circle text-muted icon-sm mr-2"></i>
              <b-popover 
                target="invoice-details-info-icon"
                triggers="hover"
                placement="top">
                  <p class="mb-0">{{ $t('components.app.paymentInvoiceModal.detailsInfo') }}</p>
              </b-popover>
            </b-col>
          </b-row>
          <div v-for="detail in Object.values(userDetails)" :key="detail">
            <p class="m-0 text-muted"> {{ detail }}</p>
          </div>
        </b-card>
         
        <b-button
          class="mt-3"
          variant="primary"
          block
          @click="createPaymentInvoice()"
          :disabled="this.isLoading"
        >
          <span v-if="this.isLoading">
            <b-spinner small type="grow"></b-spinner>
            <span>{{ $t('components.app.paymentInvoiceModal.createButton.loading') }}</span>
          </span>
          <span v-else>{{ $t('components.app.paymentInvoiceModal.createButton.init') }}</span>
        </b-button>
      </div>

      <div v-else>
        <b-button
          block
          variant="primary"
          target="_blank"
          :href="invoiceUrl"
        >
          {{ $t('components.app.paymentInvoiceModal.invoiceLink') }}
        </b-button>
      </div>

      <div v-if="errorMessage" class="mt-3">
            <b-alert variant="warning" show>
                {{ errorMessage }}
            </b-alert>
          </div>
    </b-modal>
  </div>
</template>

<script>
import { GetPaymentInvoice } from '@/graphql';
import { useUserStore } from '@/store/modules/user'
import Vue from "vue";

export default Vue.extend({
  name: 'PaymentInvoiceModal',
  components: {},
  props: {
    paymentId: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  data() {
    return {
      userStore: useUserStore(),
      invoiceUrl: null,
      isLoading: false,
      userDetails: {},
      showModal: this.show,
      errorMessage: null
    }
  },
  async created() {
    const data = await this.userStore.getUserInformation()
    this.userDetails = data?.businessUser 
      ? {
          name: data?.businessUser.businessName,
          streetName: data?.streetName,
          zipAndCity: `${data?.zipCode} ${data?.city}`,
          vatNumber: data?.businessUser.vatNumber
        } 
    : {
          companyName: `${data?.firstName} ${data?.lastName}`,
          streetName: data?.streetName,
          zipAndCity: `${data?.zipCode} ${data?.city}`
    }
  },
  methods: {
    closeModal() {
      this.invoiceUrl = null
      this.errorMessage = null 
      this.$emit('closePaymentInvoiceModal')
    },
    async createPaymentInvoice() {
      this.isLoading = true
      await this.$apollo.mutate({
        mutation: GetPaymentInvoice,
        variables: { payment: this.paymentId }
      }).then((result) => {
        this.isLoading = false
        this.invoiceUrl = result.data?.paymentInvoice
      }).catch((error) => {
        this.isLoading = false
        this.errorMessage = error.message
      })
    },
  },
  watch: {
      show(newVal) {
        this.showModal = newVal;
      },
	},
});
</script>

<style lang="scss" scoped>
</style>