export default {
  title: 'Reservation type',
  UserBooking: 'User booking',
  BeforeOpening: 'Before opening',
  ResourceMaintenance: 'Maintenance',
  Workshop: 'Event',
  EventOutsideOfBookingSystem: 'External event',
  ManuelUserBooking: 'Manual user booking',
  UserBookingWithAdditionalService: 'User booking with additional service',
  Closed: 'Closed',
  Other: 'Other',
  Traning: 'Training',
}