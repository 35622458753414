import { defineStore } from 'pinia'
import { ApplePayEventsEnum, GooglePayEventsEnum, PaymentFlowEventsEnum, PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe'
import { apolloClient } from '@/plugins/apollo'
import { GetStripeGetSetupIntent, GetStripeGetSetupIntentQuery, GetStripeGetSetupIntentQueryVariables, GetUserStripe, GetUserStripeQuery, GetUserStripeQueryVariables, PaymentTokenFaildReason, StripePaymentMetodAdded, StripePaymentMetodAddedMutation, StripePaymentMetodAddedMutationVariables, StripeUserRemovePaymentMethod, StripeUserRemovePaymentMethodMutation, StripeUserRemovePaymentMethodMutationVariables } from '@/graphql'
import { usePayPaymentTokenStore } from './paymentToken'
import { usePayLoadingStore } from './loading'
//@ts-ignore
import { defineCustomElements } from '@stripe-elements/stripe-elements/dist/esm/loader'
import VueApp from '@/main'
import { i18n } from '@/locales/setupI18n'
import { Capacitor } from '@capacitor/core'
import { usePayStore } from './pay'

const ledapDisplayName = 'LeDap'

interface PayStripeState {
  stripeIsInitialized: boolean

  canUseApplePay: boolean
  canUseGooglePay: boolean
  canUseCardPayment: boolean
  canUseSavedCard: undefined | boolean

  useStripeWeb: boolean

  applePayIsInitialized: boolean
  googlePayIsInitialized: boolean
  cardIsInitialized: boolean

  savedStripeSources: GetUserStripeQuery['user']['stripeSources']
}

async function boolifyPromise(promise: Promise<any>) {
  return promise
    .then(() => true)
    .catch(() => false)
}

// https://github.com/capacitor-community/stripe
// https://stripe.capacitorjs.jp/ 

export const usePayStripeStore = defineStore({
  id: 'PayStripe',

  state: (): PayStripeState => ({
    stripeIsInitialized: false,
     
    canUseApplePay: false,
    canUseCardPayment: false,
    canUseGooglePay: false,
    canUseSavedCard: undefined,
    
    useStripeWeb: false,

    applePayIsInitialized: false,
    googlePayIsInitialized: false,
    cardIsInitialized: false,

    savedStripeSources: []
  }),

  getters: {

  },

  actions: {
    async initStripe(config: { loadCanUseSavedCard?: boolean } = {}) {
      const stripeKey = process.env.STRIPE_KEY ?? ''
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initStripe', true, {
        stripeIsInitialized: this.stripeIsInitialized
      })

      this.stripeIsInitialized = false

      const platform = Capacitor.getPlatform()

      if (platform === 'web') {
        this.useStripeWeb = true
        await defineCustomElements()
        // await loadStripe(stripeKey)
      }
      
      await Stripe.initialize({
        publishableKey: stripeKey
      })

      this.canUseApplePay = await boolifyPromise(Stripe.isApplePayAvailable())
      this.canUseGooglePay = await boolifyPromise(Stripe.isGooglePayAvailable())
      this.canUseCardPayment = await this.checkCanUseCardPay()
      this.canUseSavedCard = config.loadCanUseSavedCard
        ? await this.checkUserHasSavedCard()
        : await Promise.resolve(typeof this.canUseSavedCard === 'boolean' ? this.canUseSavedCard : undefined)

      this.stripeIsInitialized = true

      payLoadingStore.set('initStripe', false, {
        canUseApplePay: this.canUseApplePay,
        canUseGooglePay: this.canUseGooglePay,
        canUseCardPayment: this.canUseCardPayment,
        canUseSavedCard: this.canUseSavedCard
      })
    },

    //* Apple Pay
    async initApplePay() {
      const payPaymentTokenStore = usePayPaymentTokenStore()
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initApplePay', true)
      this.applePayIsInitialized = false

      if (!payPaymentTokenStore.paymentToken) throw new Error(i18n.t('common.staticTemp.stripeTs.errorApplePayPaymentToken') as string) 
      if (!payPaymentTokenStore.paymentToken.stripePaymentIntentId) throw new Error(i18n.t('common.staticTemp.stripeTs.errorApplePayIntentId') as string) 
      if (!payPaymentTokenStore.paymentToken.stripeClientSecret) throw new Error(i18n.t('common.staticTemp.stripeTs.errorApplePayClientSecret') as string)

      const sellerDisplay = `${payPaymentTokenStore.paymentToken.company.name} (${i18n.t('common.via')} ${i18n.t('common.label.ledap')})`
      const displayName = `${payPaymentTokenStore.paymentToken.label} ${i18n.t('common.at')} ${sellerDisplay}`

      await Stripe.createApplePay({
        paymentIntentClientSecret: payPaymentTokenStore.paymentToken.stripeClientSecret,
        paymentSummaryItems: [{
          label: displayName,
          amount: payPaymentTokenStore.paymentToken.amountToPay
        }],
        merchantIdentifier: 'merchant.ledap.app',
        //@ts-ignore
        merchantDisplayName: displayName,
        countryCode: payPaymentTokenStore.paymentToken.country,
        currency: payPaymentTokenStore.paymentToken.currency,
        requiredShippingContactFields: []
      })

      this.applePayIsInitialized = true
      payLoadingStore.set('initApplePay', false)
    },

    async createApplePay() {
      try {
        const result = await Stripe.presentApplePay();
        if (result.paymentResult === ApplePayEventsEnum.Completed) {
          // Happy path
        }
        else throw new Error(`${i18n.t('common.staticTemp.stripeTs.errorMessagePaymentSheet')} ${result.paymentResult}`) 
      } catch (error) {
        const payStore = usePayStore()
        await payStore.payError(PaymentTokenFaildReason.Rejected) 
      }
    },

    //* Google Pay
    // https://pay.google.com/business/console/payment/BCR2DN4T4TR4VDQ5
    async initGooglePay() {
      const payPaymentTokenStore = usePayPaymentTokenStore()
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initGooglePay', true)

      this.googlePayIsInitialized = false

      if (!payPaymentTokenStore.paymentToken) {
        throw new Error(i18n.t('common.staticTemp.stripeTs.errorGooglePayPaymentToken') as string)
      }
      if (!payPaymentTokenStore.paymentToken.stripePaymentIntentId) {
        throw new Error(i18n.t('common.staticTemp.stripeTs.errorGooglePayIntentId') as string)
      }
      if (!payPaymentTokenStore.paymentToken.stripeClientSecret) {
        throw new Error(i18n.t('common.staticTemp.stripeTs.errorGooglePayClientSecret') as string)
      }

      const platform = Capacitor.getPlatform()

      if (platform === 'web') {
        await Stripe.createGooglePay({
          paymentIntentClientSecret: payPaymentTokenStore.paymentToken.stripeClientSecret,
          paymentSummaryItems: [{
            label: payPaymentTokenStore.paymentToken.label,
            amount: payPaymentTokenStore.paymentToken.amountToPay * 100 // BO15-639 This is needed to display the correct amount in the GooglePay pop up payment modal sheet
          }],
          merchantIdentifier: 'dk.bookli.app',
          countryCode: payPaymentTokenStore.paymentToken.country,
          currency: payPaymentTokenStore.paymentToken.currency
        })
      } else {
        await Stripe.createGooglePay({
          paymentIntentClientSecret: payPaymentTokenStore.paymentToken.stripeClientSecret,
          paymentSummaryItems: [{
            label: payPaymentTokenStore.paymentToken.label,
            amount: payPaymentTokenStore.paymentToken.amountToPay
          }],
          merchantIdentifier: 'dk.bookli.app',
          countryCode: payPaymentTokenStore.paymentToken.country,
          currency: payPaymentTokenStore.paymentToken.currency
        })
      }

      this.googlePayIsInitialized = true
      payLoadingStore.set('initGooglePay', false)
    },

    async createGooglePay() {
      try {
        const result = await Stripe.presentGooglePay();
        if (result.paymentResult === GooglePayEventsEnum.Completed) {
          // Happy path
        }
        else throw new Error(`${i18n.t('common.staticTemp.stripeTs.errorMessagePaymentSheet')}  ${result.paymentResult}`) 
      } catch (error) {
        const payStore = usePayStore()
        await payStore.payError(PaymentTokenFaildReason.Rejected) 
      }
    },

    //* Card Pay
    async checkCanUseCardPay(): Promise<boolean> {
      return true
    },

    async initCardPayment() {
      const payPaymentTokenStore = usePayPaymentTokenStore()
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initCardPayment', true)
      this.cardIsInitialized = false

      if (!payPaymentTokenStore.paymentToken) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessagePaymentToken') as string) 
      if (!payPaymentTokenStore.paymentToken.stripeClientSecret) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageClientSecret') as string) 
      if (!payPaymentTokenStore.paymentToken.user.stripeCustomerId) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageCustomerId') as string) 
      if (!payPaymentTokenStore.paymentToken.user.stripeEphemeralKey) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageEphemeralKey') as string) 
      
      if (this.useStripeWeb) {

      }
      else {
        await Stripe.createPaymentSheet({
          paymentIntentClientSecret: payPaymentTokenStore.paymentToken.stripeClientSecret,
          customerId: payPaymentTokenStore.paymentToken.user.stripeCustomerId,
          customerEphemeralKeySecret: payPaymentTokenStore.paymentToken.user.stripeEphemeralKey,
          merchantDisplayName: ledapDisplayName,
          withZipCode: false,
          returnURL: 'https://ledap.app/stripe-callback',
          enableApplePay: false,
          enableGooglePay: false,
          countryCode: payPaymentTokenStore.paymentToken.country,
        })
      }
      
      this.cardIsInitialized = true
      payLoadingStore.set('initCardPayment', false)
    },
    
    async createCardPayment() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('createCardPayment', true)
      try {
        if (this.useStripeWeb) { }
        else {
          const result = await Stripe.presentPaymentSheet();
          if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
            // Happy path
          }
          else throw new Error( `${i18n.t('common.staticTemp.stripeTs.errorMessagePaymentSheet')} ${result.paymentResult}`) 
        }
      } catch (error) {
        const payStore = usePayStore()
        await payStore.payError(PaymentTokenFaildReason.Rejected)
      }

      payLoadingStore.set('createCardPayment', false)
    },

    async createCardPaymentWeb(stripe: any, card: any) {
      const payPaymentTokenStore = usePayPaymentTokenStore()
      const payStore = usePayStore()

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('createCardPaymentWeb', true)

      await payStore.startPayment()

      if (!payPaymentTokenStore.paymentToken) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessagePaymentToken') as string) 
      if (!payPaymentTokenStore.paymentToken.stripeClientSecret) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageClientSecret') as string) 

      const result = await stripe.confirmCardPayment(payPaymentTokenStore.paymentToken.stripeClientSecret, {
        payment_method: {
          card,
        }
      })
  
      if (result.paymentIntent == undefined) {
        const payStore = usePayStore()
        await payStore.payError(PaymentTokenFaildReason.Rejected)
      }
  
      if (result.paymentIntent != undefined && result.paymentIntent.status == "succeeded" ) {
      }

      payLoadingStore.set('createCardPaymentWeb', false)
    },

    async checkUserHasSavedCard() {
      return this.loadStripeUser()
    },

    async loadStripeUser() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('loadStripeUser', true)

      const { data } = await apolloClient.query<GetUserStripeQuery, GetUserStripeQueryVariables>({
        query: GetUserStripe,
        variables: { },
        fetchPolicy: 'network-only'
      })

      this.savedStripeSources = data.user.stripeSources
      if (this.savedStripeSources.length) this.canUseSavedCard = true
      payLoadingStore.set('loadStripeUser', false)
      return data.user.haveSavedStripeSource
    },

    resetStripe() {
      this.applePayIsInitialized = false
      this.googlePayIsInitialized = false
      this.cardIsInitialized = false
      this.canUseSavedCard = false
    },

    async getSetupIntent() {
      const { data: { stripeGetSetupIntent } } = await apolloClient.query<GetStripeGetSetupIntentQuery, GetStripeGetSetupIntentQueryVariables>({
        query: GetStripeGetSetupIntent,
        variables: {},
        fetchPolicy: 'network-only'
      })

      return stripeGetSetupIntent
    },

    async createSavedSource(stripe?: any, card?: any) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('createSavedSourceLoading', true)

      const stripeSetupIntent = await this.getSetupIntent()

      try {
        if (this.useStripeWeb) {
          await stripe.confirmCardSetup(stripeSetupIntent.stripeIntentClientSecret, {
            payment_method: {
              card,
            },
          })
        }
        else {
          await Stripe.createPaymentFlow({
            setupIntentClientSecret: stripeSetupIntent.stripeIntentClientSecret,
            customerEphemeralKeySecret: stripeSetupIntent.stripeEphemeralKey,
            customerId: stripeSetupIntent.stripeCustomerId,
            withZipCode: false,
            merchantDisplayName: ledapDisplayName
          })
          
          await Stripe.presentPaymentFlow()
          const confirmResult = await Stripe.confirmPaymentFlow();
  
          if (confirmResult.paymentResult === PaymentFlowEventsEnum.Completed) {
            // Happy path
          } 
        }

        await apolloClient.mutate<StripePaymentMetodAddedMutation, StripePaymentMetodAddedMutationVariables>({
          mutation: StripePaymentMetodAdded,
          variables: {}
        })

        await this.loadStripeUser()
      } catch (error) {
        console.log('createSavedSource - error', error)
      }

      payLoadingStore.set('createSavedSourceLoading', false)
    },

    async removeStripeSource(paymentMethodId: string) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('removeStripeSource', true)

      const deletePaymentMethod = await apolloClient.mutate<StripeUserRemovePaymentMethodMutation, StripeUserRemovePaymentMethodMutationVariables>({
        mutation: StripeUserRemovePaymentMethod,
        variables: {
          paymentMethodId
        },
        errorPolicy: 'all'
      })

      if (deletePaymentMethod.errors) {
        VueApp.$bvToast.toast(deletePaymentMethod.errors[0].message, {
          title: i18n.t('common.error') as string,
          variant: 'danger'
        })
      }

      await this.loadStripeUser()

      payLoadingStore.set('removeStripeSource', false)
    }
  }
})
