<template lang="pug">
b-overlay(
	:show="createUserPlatformConnection.isLoading || authPlacesForSignUp.loading || Auth.userPlatformLoading"
	variant="white"
	spinner-variant="primary")
	b-form-group(:label="$t('pages.technician.location.company')" v-if="!companySelectedOnCreate")
		validation-provider(name='company' rules='required' v-slot='{ errors, untouched }')
			b-form-select(
				:state='untouched === true ? null : errors.length == 0 ? true : false'
				v-model='selectedPlace'
				:options="placesForSignUp"
				name='company')

				template(v-slot:first)
					option(:value="null" disabled) {{ $t('components.admin.location.createCompany.selectCompany') }} 

			b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}


	b-form-group.mb-0(:label="$t('components.app.changeUserDefaultLocation.defaultTitle')" v-if="defaultLocationOptions.length > 1")
		.cards-container
			b-card.bg-light.card-no-border.shadow-none.card-small-padding.cursor-pointer.hover-scale.mb-3(v-for="(location, index) in defaultLocationOptions" @click="selectedCompanyLocation = location.value" :key="index")
				h4.mb-0 {{ location.text }}
				small {{ location.subTitle }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetAuthPlacesForSignUpQuery, UserPlatformConnectionCreateMutationVariables, UserPlatformConnectionCreate, GetAuthPlacesForSignUp } from '@/graphql';
import { SmartMutation } from '@/utils/smartMutation'
import { Preferences } from '@capacitor/preferences'
import { useAuthStore } from '@/store/modules/auth';

@Component({
	components: {

	},
	apollo: {
		authPlacesForSignUp: {
			query: GetAuthPlacesForSignUp,
			fetchPolicy: 'no-cache',
			update(data: GetAuthPlacesForSignUpQuery) {
				if (data.authPlacesForSignUp.length === 1) {
					const item = data.authPlacesForSignUp[0]
					this.selectedPlace = `${item.__typename}:::${item.id}`
					this.companySelectedOnCreate = true
				}
				return data.authPlacesForSignUp
			}
		},
	}
})
export default class CompanyUsersUserView extends Vue {

	created() {
		// this.checkLoginStateFacebook()
		this.createUserPlatformConnection.componentThis = this
	}

	Auth = useAuthStore()

	companySelectedOnCreate = false
	authPlacesForSignUp: GetAuthPlacesForSignUpQuery['authPlacesForSignUp'] = []
	get placesForSignUp() {
		return this
			.authPlacesForSignUp
			.map(item => { return { value: `${item.__typename}:::${item.id}`, text: item.name } })
	}

	selectedPlace: string | null = null
	selectedCompanyLocation: string | null = null

	defaultLocationOptions: { value: string, text: string, subTitle: string }[] = []
	@Watch('selectedPlace')
	selectedPlaceChanged() {
		if (!this.selectedPlace) return
		this.defaultLocationOptions = []
		const id = this.selectedPlace.split(':::')[1]
		const item = this.authPlacesForSignUp.find(item => item.id === id)!

		const locations = item.locations

		if (item.__typename === 'CompanyForSignUp') {
			this.defaultLocationOptions = item.locations.map(location => { return { value: `${item.id}:::${location.id}`, text: location.name, subTitle: `${location.streetName} - ${location.zipCode} ${location.city}` } })
		} else if (item.__typename === 'FranchiseForSignUp') {
			this.defaultLocationOptions = item.locations.map(location => { return { value: `${location.company.id}:::${location.id}`, text: location.name, subTitle: `${location.streetName} - ${location.zipCode} ${location.city}` } })
		}

		if (this.defaultLocationOptions.length === 1) this.selectedCompanyLocation = this.defaultLocationOptions[0].value
	}

	companyId: string | null = null
	defaultLocationId: string | null = null

	@Watch('selectedCompanyLocation')
	async selectedCompanyLocationChanged() {
		if (!this.selectedCompanyLocation) return
		const split = this.selectedCompanyLocation.split(':::')
		this.companyId = split[0]
		this.defaultLocationId = split[1]

		const { value: userAuthToken } = await Preferences.get({ key: 'USER_TOKEN' })
		if (!userAuthToken) {
			console.log('missing userAuthToken')
			return
		}

		const createUserPlatformConnection = await this.createUserPlatformConnection.mutate({
			location: this.defaultLocationId
		})

		await this.Auth.signInPlatform({
			userPlatformConnectionId: createUserPlatformConnection.userPlatformConnectionCreate.id
		})
	}

	createUserPlatformConnection = new SmartMutation({
		componentThis: null,
		mutation: UserPlatformConnectionCreate,
		variables: (): UserPlatformConnectionCreateMutationVariables => {
			return {

			}
		}
	})

}
</script>

<style lang="sass" scoped>

</style>