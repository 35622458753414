export default {
    state: {
        Overview: 'Overview',
        Delivery: 'Delivery',
        SelectPaymentType: 'Select Payment Type',
        PayPalForm: 'Finalise Purchase',
        Payment: 'Payment'
    },
    productDetails: {
        size: 'Size:',
        stock: {
            only: 'Only',
            leftInStock: 'left in stock'
        }
    },
    shippingDetails: {
        free: 'FREE',
        postCodeInput: 'Enter your postcode',
        deliveryOptionsButton: 'Update delivery options'
    },
    invalidPostCode: {
        title: 'Invalid Postcode',
        description: 'The postcode you have entered is invalid. Please enter another postcode.'
    },
    noDeliveryOptions: {
        title: "No Delivery Options Available",
        description: "There are no delivery options available for the postcode you have entered."
    },
    invalidPayPalForm: {
        title: 'Invalid Form',
        description: 'The form is invalid. Please fill in the necessary information.'
    },
    noItemsInBasket: {
        title: 'No items in shopping bag',
        description: 'Go back and buy items',
        button: 'Go to shop'
    },
    footer: {
        delivery: 'Delivery',
        total: 'Total',
        goNext: 'Go next'
    }
}