import { CustomRouteConfig } from '.'

const routes: CustomRouteConfig[] = [
  {
    name: 'SignIn',
    path: '/sign-in',
    component: () => import('@/views/auth/signIn.vue'),
    meta: {
      layout: 'FullPage',
      title: 'Log ind'
    }
  },
  {
    name: 'SignUpUser',
    path: '/sign-up/user',
    component: () => import('@/views/auth/signUpUser.vue'),
    meta: {
      layout: 'FullPage',
      title: 'Opret bruger'
    }
  },
  {
    name: 'PasswordReset',
    path: '/password-reset',
    component: () => import('@/views/auth/passwordReset.vue'),
    meta: {
      layout: 'FullPage',
      title: 'Ny adgangskode'
    }
  },
  {
    name: 'PasswordChange',
    path: '/change-password',
    component: () => import('@/views/auth/passwordChange.vue'),
    meta: {
      layout: 'FullPage',
      title: 'Ændre adgangskode'
    }
  },
  {
    name: 'VerifyEmail',
    path: '/verify-email',
    component: () => import('@/views/auth/verifyEmail.vue'),
    meta: {
      layout: 'FullPage',
      title: 'Verificere email'
    }
  },
  {
    name: 'CompanyCreate',
    path: '/company-create',
    component: () => import('@/views/auth/createCompany.vue'),
    meta: {
      layout: 'FullPage',
      title: 'Opret firma'
    }
  }

]

export default routes
