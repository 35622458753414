<template lang="pug">
b-modal(
	modal-class="modal-max-body-height"
	ref="add-friend-modal"
	centered
	hide-footer
	hide-header
	:no-close-on-backdrop="true"
	size="sm")

	b-overlay(
		:show="createFriendship.isLoading"
		variant="transparent"
		spinner-variant="primary")

		b-row.justify-content-center.mb-3
			b-col(auto)
				h2.m-0 {{ $t('components.app.addFriend.title') }}
			b-col.text-right
				b-link.text-danger(@click="$refs['add-friend-modal'].hide()") {{ $t('components.app.addFriend.closeWindow') }}

		p.text-muted {{ $t('components.app.addFriend.description') }}

		b-form-input.mb-4(
			v-model="phoneNumberSearch"
			:placeholder="$t('components.app.addFriend.seachPlaceholder')"
			@input="inputChange($event)")

		b-overlay.cards-container.add-friends-list(
			:show="$apollo.queries.usersToAdd.loading"
			spinner-variant="primary"
			variant="transparent"
			style="min-height: 100px")

			b-card.bg-light.card-small-padding.mb-0(v-if="!phoneNumberIsValidForResult")
				p.mb-2 {{ $t('components.app.addFriend.seachNotValid.title') }}
				small.text-muted {{ $t('components.app.addFriend.seachNotValid.text') }}

			b-card.bg-light.card-small-padding.mb-0(v-else-if="!usersToAdd.length && !$apollo.queries.usersToAdd.loading")
				p.mb-2 {{ $t('components.app.addFriend.notFound.title') }}
				small.text-muted {{ $t('components.app.addFriend.notFound.text') }}

			b-card.bg-light.card-no-border.shadow-none.card-small-padding(v-for="user in usersToAdd" :key="user.id")
				b-row(align-v="center")
					b-col
						p.m-0 {{ user.name }}
					b-col.col-auto
						b-button(
							size="sm"
							variant="outline-primary"
							:disabled="user.isFriends"
							@click="createFriendship.mutate({ friendUserId: user.id })") {{ user.isFriends ? $t('components.app.addFriend.youAreFriends') : $t('components.app.addFriend.addFriendButton') }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetFriendsPossibleToAdd, GetFriendsPossibleToAddQueryVariables, FriendshipCreate, FriendshipCreateMutationVariables, GetFriendsPossibleToAddQuery, GetFriends, GetFriendsQueryVariables, GetFriendsQuery } from '@/graphql'
import { SmartMutation } from '../../utils/smartMutation'

function replaceAll(string: string, search: string, replace: string) {
	return string.split(search).join(replace);
}

@Component({
	components: {

	},
	apollo: {
		usersToAdd: {
			query: GetFriendsPossibleToAdd,
			// fetchPolicy: 'no-cache',
			variables(): GetFriendsPossibleToAddQueryVariables {
				return {
					search: this.seachValue
				}
			},
			update(data: GetFriendsPossibleToAddQuery) {
				return data.friendsPossibleToAdd.data
			},
			skip() { return !this.seachValue }
		},
		friends: {
			query: GetFriends,
			variables(): GetFriendsQueryVariables {
				return {

				}
			},
			update(data: GetFriendsQuery) {
				return data.friends.data
			}
		}
	}
})
export default class CompanyUsersUserView extends Vue {

	phoneNumberSearch = ''
	seachValue: null | string = null
	phoneNumberMinLenght = 4

	@Watch('phoneNumberSearch')
	phoneNumberSearchChange() {
		this.usersToAdd = []
	}

	usersToAdd = []
	friends = []

	get phoneNumberIsValidForResult() {
		return this.phoneNumberSearch.length >= this.phoneNumberMinLenght
	}

	friendAdded() {
		this.phoneNumberSearch = ''
		this.$emit('friend-added')
	}

	createFriendship = new SmartMutation({
		doneToast: {
			i18nString: 'components.app.addFriend.createFriendshipSuccessToast'
		},
		mutation: FriendshipCreate,
		componentThis: null,
		hideModalName: 'add-friend-modal',
		refetchQueryName: 'friends',
		doneFunction: () => {
			this.friendAdded()
		},
		variables(): FriendshipCreateMutationVariables {
			return {
				friendUserId: ''
			}
		}
	})

	show() {
		// @ts-ignore
		this.$refs['add-friend-modal'].show()
	}

	created() {
		this.createFriendship.componentThis = this
	}

	hep(value: string) {
		if (value.length <= 4) return
		this.seachValue = value
	}

	inputChange = this.debounce((value: string) => {
		this.hep(value)
	})

	debounce<T extends Function>(cb: T, wait = 800) {
		let h = 0;
		let callable = (...args: any) => {
			clearTimeout(h)
			//@ts-ignore
			h = setTimeout(() => cb(...args), wait);
		}
		return <T>(<any>callable)
	}
}
</script>

<style lang="sass" scoped>

.add-friends-list
	max-height: 450px
	overflow-y: auto

</style>
