export default {
  subTitle: 'Overview',
  pageTitle: 'Users',
  userTypeFilter: {
    allUsers: 'All users',
    users: 'Users',
    businessUsers: 'Business Users'
  },
  type: {
    user: 'User',
    businessUser: 'Business User'
  },
  table: {
    seachText: 'Search by first name, e-mail and telephone number',
    user: 'User',
    createdAt: 'Created',
    type: 'Type'
  }
}