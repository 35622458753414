import { defineStore } from 'pinia'
import { GetCouponGetUserAutoCoupons, GetCouponGetUserAutoCouponsQuery, GetCouponGetUserAutoCouponsQueryVariables, GetCouponIsValid, GetCouponIsValidQuery, GetCouponIsValidQueryVariables } from '@/graphql'
import { i18n } from '@/locales/setupI18n'
import app from '@/main'
import { apolloClient } from '@/plugins/apollo'
import { usePayStore } from './pay'
import { usePayLoadingStore } from './loading'
import { usePayPriceStore } from './price'
import { usePayV2StripeStore } from '../payV2/stripeV2'
import { usePayV2Store } from '../payV2/payV2'


interface PayCouponState {
  userAutoCoupons: GetCouponGetUserAutoCouponsQuery['couponGetUserAutoCoupons']
  selectedCouponSlug: string | null

  couponSlugInput: string | null
  showCouponModal: boolean

  setAutoCouponOnLoad: boolean
}

export const usePayCouponStore = defineStore({
  id: 'PayCoupon',

  state: (): PayCouponState => ({
    userAutoCoupons: [],
    selectedCouponSlug: null,
    couponSlugInput: null,
    showCouponModal: false,
    setAutoCouponOnLoad: true
  }),

  getters: {
    canUseCoupon(state) {
      const payPriceStore = usePayPriceStore()
      return payPriceStore.priceData?.validToUseCoupon
    }
  },

  actions: {
    async setCouponSlug(couponSlug: string | null, validate = true) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('setCouponSlug', true, {
        couponSlug,
        validate
      })

      this.selectedCouponSlug = null
      this.couponSlugInput = null

      if (couponSlug && validate) {
        const isValid = await this.couponSlugIsValid(couponSlug)

        if (!isValid) {
          payLoadingStore.set('setCouponSlug', false, {
            isNotVaild: true
          })
          return
        }
      }

      if (couponSlug) this.setAutoCouponOnLoad = true
      
      this.selectedCouponSlug = couponSlug

      this.showCouponModal = false

      const payPriceStore = usePayPriceStore()
      payPriceStore.initCalcuatePrice('setCouponSlug', true)
      payLoadingStore.set('setCouponSlug', false)
    },

    removeCoupon() {
      this.setAutoCouponOnLoad = false
      this.setCouponSlug(null, false)
    },

    resetCoupon() {
      this.selectedCouponSlug = null
      this.userAutoCoupons = []
    },

    async couponSlugIsValid(couponSlug: string): Promise<boolean> {
      const stripeV2Store = usePayV2StripeStore()
      const payStore = stripeV2Store.isStripePaymentElementsCompatible ? usePayV2Store(): usePayStore()
      const payPriceStore = usePayPriceStore()

      if (!payStore.purchaseData) throw new Error(i18n.t('common.staticTemp.coupon.errorMessagePurchaseData') as string)
      if (!payPriceStore.priceData) throw new Error(i18n.t('common.staticTemp.coupon.errorMessagePriceData') as string)

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('couponSlugIsValid', true)

      const { data } = await apolloClient.query<GetCouponIsValidQuery, GetCouponIsValidQueryVariables>({
        query: GetCouponIsValid,
        variables: {
          couponSlug: couponSlug,
          couponPurchaseDetails: {
            productId: payPriceStore.priceData.productId,
            resourceId: payStore.purchaseData.resourceBooking ? payStore.purchaseData.resourceBooking.resourceId : undefined,
						resoruceBookingStartDate: payStore.purchaseData.resourceBooking ? payStore.purchaseData.resourceBooking.startDate : undefined,
						resourceBookingEndDate: payStore.purchaseData.resourceBooking ? payStore.purchaseData.resourceBooking.endDate : undefined,
          }
        }
      })

      const isValid = data.couponIsValid ? true : false

      if (!isValid) {
        app.$bvToast.toast(i18n.t('components.app.pay.coupon.addCouponModal.notValid.text') as string, {
          title: i18n.t('components.app.pay.coupon.addCouponModal.notValid.title') as string,
          variant: 'danger'
        })
      }

      payLoadingStore.set('couponSlugIsValid', false)
      return isValid
    },

    async initCoupons() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initCoupons', true)
      if (!this.canUseCoupon) {
        this.selectedCouponSlug = null
        payLoadingStore.set('initCoupons', false, {
          returnedOnCanUseCoupon: true
        })
        return
      }

      const payStore = usePayStore()
      if (payStore.isInitialized) {
        payLoadingStore.set('initCoupons', false, {
          returnedOnIsInitialized: true
        })
        return
      }

      if (this.selectedCouponSlug) {
        const isValid = await this.couponSlugIsValid(this.selectedCouponSlug)
        if (isValid) {
          payLoadingStore.set('initCoupons', false, {
            returnedOnIsValid: true
          })
          return
        }
      }
      
      this.resetCoupon()
      await this.getUserAutoCoupons()

      payLoadingStore.set('initCoupons', false, {
        returnedOnIsInitialized: true
      })

      if (this.selectedCouponSlug) return true
    },

    async getUserAutoCoupons(setCouponSlugOnInit = true) {
      if (!this.canUseCoupon) {
        return
      }
      this.userAutoCoupons = []
      const stripeV2Store = usePayV2StripeStore()
      const payPriceStore = usePayPriceStore()
      const payLoadingStore = usePayLoadingStore()
      let payStore

      if (!payPriceStore.priceData) {
        throw new Error(i18n.t('common.staticTemp.coupon.errorMessagePriceData') as string)
      } 

      if (stripeV2Store.isStripePaymentElementsCompatible) {
        payStore = usePayV2Store()
      } else {
        payStore = usePayStore()
      }

      if (payStore.isInitialized) setCouponSlugOnInit = false
      if (!payStore.purchaseData) throw new Error(i18n.t('common.staticTemp.coupon.errorMessagePurchaseData') as string)
      
      payLoadingStore.set('getUserAutoCoupons', true, {
        setCouponSlugOnInit
      })

      const { data } = await apolloClient.query<GetCouponGetUserAutoCouponsQuery, GetCouponGetUserAutoCouponsQueryVariables>({
        query: GetCouponGetUserAutoCoupons,
        variables: {
          amountToPay: payPriceStore.priceData.amountToPay,
          couponPurchaseDetails: {
            productId: payPriceStore.priceData.productId,
            resourceId: payStore.purchaseData.resourceBooking ? payStore.purchaseData.resourceBooking.resourceId : undefined,
            resoruceBookingStartDate: payStore.purchaseData.resourceBooking ? payStore.purchaseData.resourceBooking.startDate : undefined,
            resourceBookingEndDate: payStore.purchaseData.resourceBooking ? payStore.purchaseData.resourceBooking.endDate : undefined,
          }
        }
      })

      // * Sort coupons so we get the best one first
      this.userAutoCoupons = [...data.couponGetUserAutoCoupons].sort((a, b) => a.amountWithCoupon - b.amountWithCoupon)

      if (setCouponSlugOnInit && this.userAutoCoupons.length && this.setAutoCouponOnLoad) {
        await this.setCouponSlug(this.userAutoCoupons[0].coupon.slug, false)
      }

      payLoadingStore.set('getUserAutoCoupons', false)
    }
  }
})
