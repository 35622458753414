import { PaymentCancelReservedProduct, PaymentCancelReservedProductMutation, PaymentCancelReservedProductMutationVariables, PaymentReserveProduct, PaymentReserveProductMutation, PaymentReserveProductMutationVariables } from '@/graphql'
import { apolloClient } from '@/plugins/apollo'
import { defineStore } from 'pinia'
import { usePayLoadingStore } from './loading'
import { usePayStore } from './pay'
import { usePayPaymentTokenStore } from './paymentToken'
import { usePayPriceStore } from './price'
import { i18n } from '@/locales/setupI18n'
import { GraphQLError } from 'graphql'
import { ApolloError } from '@apollo/client/errors'
import { getErrorMessage } from '@/utils/getErrorMessage'
import { usePayV2StripeStore } from '../payV2/stripeV2'
import { usePayV2Store } from '../payV2/payV2'

interface PayProductReservationState {
  reservedProductData: PaymentReserveProductMutation['paymentReserveProduct'] | null
}

export const usePayProductReservationStore = defineStore({
  id: 'PayProductReservation',

  state: (): PayProductReservationState => ({
    reservedProductData: null,
  }),

  getters: {

  },

  actions: {
    async reserveProduct() {
      const payPriceStore = usePayPriceStore()

      if (!payPriceStore.priceData) throw new Error(i18n.t('common.staticTemp.productReservation.errorMessagePriceData') as string) 

      if (this.reservedProductData) {
        // * If the product is already reserved, cancel the reservation first
        // await this.cancelReservedProduct()
      }

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('reserveProduct', true)

      let reservationData
      try {
        const { data } = await apolloClient.mutate<PaymentReserveProductMutation, PaymentReserveProductMutationVariables>({
          mutation: PaymentReserveProduct,
          variables: {
            paymentPriceToken: payPriceStore.priceData.paymentPriceToken
          }

        })
        reservationData = data
      } catch (error: ApolloError | any) {
        const errorMessage = getErrorMessage(error)
        console.error(`Unable to mutate product reservation. ${errorMessage}`)
      
        throw new Error(errorMessage);
      }

      this.reservedProductData = reservationData!.paymentReserveProduct

      payLoadingStore.set('reserveProduct', false)
    },

    async cancelReservedProduct() {
      const stripeV2Store = usePayV2StripeStore()
      const payStore = stripeV2Store.isStripePaymentElementsCompatible ? usePayV2Store() : usePayStore()
      const payPaymentTokenStore = usePayPaymentTokenStore()

      if (!this.reservedProductData) return
      if (payPaymentTokenStore.paymentToken?.state === 'Paid') return
      
      if (!payStore.purchaseData) throw new Error(i18n.t('common.staticTemp.productReservation.errorMessagePurchaseData') as string) 
      if (!this.reservedProductData) throw new Error(i18n.t('common.staticTemp.productReservation.errorMessageResProdData') as string) 

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('cancelReservedProduct', true, {
        productType: payStore.purchaseData.productType,
        resourceBooking: this.reservedProductData.resourceBooking?.id,
        workshopBooking: this.reservedProductData.workshopBooking?.id
      })

      await apolloClient.mutate<PaymentCancelReservedProductMutation, PaymentCancelReservedProductMutationVariables>({
        mutation: PaymentCancelReservedProduct,
        variables: { data: {
          productType: payStore.purchaseData.productType,
          resourceBooking: this.reservedProductData.resourceBooking?.id,
          workshopBooking: this.reservedProductData.workshopBooking?.id
        }}
      })

      this.reservedProductData = null
      payLoadingStore.set('cancelReservedProduct', false)
    },

    reservationIsUsed() {
      this.reservedProductData = null
    }
  }
})
