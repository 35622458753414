<template lang="pug">
b-card.flex-item(v-if="hasAd" body-class="p-0" style=" cursor: pointer" @click="openUrl(adData.userHomeAdSpace1Link)")
  img.ad-image(
    class="w-full"
    :src="adData.userHomeAdSpace1ImgUrl")

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetCompanyAppImages, GetCompanyAppImagesQuery } from '@/graphql';
import router from '@/router';

@Component({
  components: {

  },
  apollo: {
    adData: {
      query: GetCompanyAppImages,
      update(data: GetCompanyAppImagesQuery) {
        return data.companySettings
      }
    }
  }
})
export default class CompanyUsersUserView extends Vue {

  adData: GetCompanyAppImagesQuery['companySettings'] | null = null

  get hasAd() {
    if (!this.adData) return false
    if (!this.adData.userHomeAdSpace1ImgUrl) return false
    return true
  }

  openUrl(url: string) {
    let path: string = ''
    if (url.includes('ledap.app')) {
      path = url.split('ledap.app').pop() || ''
    }

    if (path) {
      router.push({ path })
    } else {
      const win = window.open(url, '_blank')
      win!.focus()
    }
  }
}
</script>

<style lang="sass" scoped>

.ad-image
  width: 100%

</style>