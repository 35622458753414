export default {
    warning: {
        title: "IMPORTANT - Read",
        text: "You need to obtain consent from your users to be allowed to send messages. Sending SMS messages incurs costs."
    },

    recipients: {
        selectGroupTitle: "Who should it be sent to?",
        sendToTypeEnum: {
            Users: 'Users',
            Workshops: 'Workshop',
            UserGroups: 'User Group',
            SubscriptionProducts: 'Membership',
            AllCompanyUsers: 'All Company Users',
            Newsletter: 'Users signed up for newsletter'
        },
        userGroups: {
            text: "To send to a user group, go to the list of user groups, find the group, and then click 'Send message'.",
            button: "Go to user groups"
        },
        workshops: {
            text: "To send to an event, go to events, find the event, and then click 'Send message'.",
            button: "Go to events"
        },
        subscriptionProducts: {
            text: "To send to a membership, go to memberships, find the membership, and then click 'Send message'.",
            button: "Go to memberships"
        },
        newsletter: {
            text: "To send out a newsletter to all users that have given consent to receive newsletters, go to 'Send Newsletter'.",
            button: "Go to Send Newsletter"
        },
        allCompanyUsers: {
            warningTitle: "Do you know what you're doing?",
            warningText: "You are about to send a message to all your users. Be certain in your actions!"
        },
        definedOnCreate: {
            aboutTo: "You are about to send a message to all users signed up for",
            with: "with a total of",
            users: "users"
        },
        selectUsers: {
            label: "Select users",
            noResult: "No results for",
            text: "Search by name, email, and number."
        }
    },

    messageType: {
        title: "Type",
        email: "Email",
        sms: "SMS",
        messagePlaceHolder: "Kolding Center...",
        subject: "Subject",
        smsTitle: "Message",
        category: {
            title: "Category",
            placeholder: "-- Select category --",
            info: "Users can specify what types of emails they wish to receive. Using categories correctly will help us keep low spam levels and ensure that Users receive necessary emails."
        }
    },

    test: {
        title: "Send test message",
        Email: "Enter the email address.",
        Sms: "For SMS, enter the phone number. REMEMBER: Prefix the number with your country code (e.g. Denmark: '45').",
        multipleRecipients: "To send to multiple recipients, separate with commas.",
        button: "Send test",
        doneToast: "Test message is sent"
    },

    submit: {
        button: "Send message",
        sending: "Sending message...",
        doneToast: "Message sent"
    }
}
