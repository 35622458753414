export default {
  title: 'Credit card',

  noEnabled: {
    title: 'Credit card not possible',
    text: 'It is not yet possible to add your credit card to our app. Add it via a regular browser.'
  },

  actionText: {
    start: 'You are about to',
    action: {
      add: 'add',
      update: 'update'
    },
    endning: 'your credit card.'
  },

  stripeIsSafe: 'Safe payment with Stripe.',

  submitButton: {
    actionLoading: {
      create: 'Adding',
      update: 'Updating'
    },
    action: {
      create: 'Add',
      update: 'Update'
    },
    creditCard: 'credit card'
  },

  successToast: 'Your credit card is added'
}