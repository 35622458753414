import { useLocaleStore } from "@/store/modules/locale";

function isValidLocale(locale: string): boolean {
	try {
		new Intl.NumberFormat(locale);
		return true;
	} catch (e) {
		return false;
	}
}

function isValidCurrency(currency: string): boolean {
	try {
		new Intl.NumberFormat("en-US", { style: "currency", currency }).format(1);
		return true;
	} catch (e) {
		return false;
	}
}

export function currencyFormatter(
	value: number,
	currency: string,
	locale?: string
): string {
	const localeStore = useLocaleStore();
	locale = locale ?? localeStore.selectedLocale;

	if (!isValidLocale(locale)) {
		console.warn("Invalid locale provided, defaulting to en-US");
		locale = "en-US";
	}

	if (!isValidCurrency(currency)) {
		console.warn("Invalid currency code provided, defaulting to USD");
		currency = "USD";
	}

	return new Intl.NumberFormat(locale, {
		style: "currency",
		currency: currency,
	}).format(value);
}
