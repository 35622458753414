export default {
  title: 'How to export a video',
  one: 'To watch your video, start by clicking "Create Video". When your recording is ready, you can watch it here and you will also receive an email.',
  two: 'Your video will be stored for 48 hours and then it will be deleted.',
  three: 'We have made so you can make small clips from your match and download them to your device. You can make all the clips you want and once you have created them, you can download them to your device.',
  four: 'How to create a clip for download',
  list: {
    one: 'Start by going to the place in the video timeline where you would like to start a clip from.',
    two: 'Then click on "Start point"',
    three: 'Now fast forward to where you want your clip to stop.',
    four: 'Now press "End point"',
    five: 'You can now create your clip by pressing "Create clip"',
    six: 'Your clip can now be seen in the list below the video'
  }
}