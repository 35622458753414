export default {
  bookliScreen: {
    UpdateScreenInfo: 'This screen is not updated with the latest information... This is typically because there is no internet connection. Attempts will be made to establish a connection.',
    upcomingEvents: 'Our upcoming events',
    availableEvents: 'Only events with available slots are displayed',
    welcome: 'Welcome to',
    currentlyHave: 'You currently have',
    goodGame: 'Good game!',
    playAt: 'You are playing at',
    in: 'in',
    min: 'minutes',
    errorMessageId: 'Missing location id',
    max: 'Max.',
    nobody: 'Nobody',
    next: 'NEXT:',
    now: 'NOW:',
    to: 'to',
    noBookings: 'There are no bookings right now...',
    help: 'Need help?',
    callUs: 'If there are any problems, you can call us at',
    week: 'Week',
  },
}