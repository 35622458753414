<template lang="pug">
b-modal.bottom-modal(
  v-model="addStripeSourceVisible"
  :backdrop="true"
  hide-header
  hide-footer
  modal-class="bottom-modal"
  body-class="p-0")
  .buttom-sidebar-close-wrapper.cursor-pointer(@click="addStripeSourceVisible = false")
    .fe.fe-arrow-left.text-muted.mr-2
    small.text-muted {{ $t('common.cancel') }}

  .bottom-modal-content
    b-overlay(
      :show="payLoadingStore.loading"
      variant="white"
      spinner-variant="primary")
      .px-4.py-4
        h3 {{ $t('components.app.addStripeSource.title') }}
        p {{ $t('components.app.addStripeSource.description') }}

        div(v-if="payStripeStore.useStripeWeb")
          div(id="card-element" ref="cardElement")

          b-button.mt-3(
            block
            size="lg"
            :disabled="!complete"
            @click="addSourceWeb()"
            variant="primary") {{ $t('components.app.addStripeSource.addCard') }}

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import {  } from '@/graphql'
import { usePayStore } from '@/store/modules/pay/pay'
import { usePayStripeStore } from '@/store/modules/pay/stripe'
import { usePayLoadingStore } from '@/store/modules/pay/loading'
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js'

@Component({
  apollo: {

  }
})
export default class CompanyUsersUserView extends Vue {

  payStore = usePayStore()
  payLoadingStore = usePayLoadingStore()
  payStripeStore = usePayStripeStore()

  addStripeSourceVisible = false

  stripe: Stripe | null = null
  elements: StripeElements | null = null
  cardElement: any = null

  complete = false
  stripeKey = process.env.STRIPE_KEY
  instanceOptions = {
    // https://stripe.com/docs/js/initializing#init_stripe_js-options
  }

  elementsOptions = {
    // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
  }

  cardOptions = {
    // https://stripe.com/docs/stripe.js#element-options
  }

  async mounted() {
    await this.payStripeStore.initStripe()
  }

  async show() {
    this.addStripeSourceVisible = true

    if (this.payStripeStore.useStripeWeb) {
      this.stripe = await loadStripe(this.stripeKey!)

      if (this.stripe) {
        this.elements = this.stripe.elements()

        if (this.elements) {
          this.cardElement = this.elements.create('card')
          this.cardElement.mount(this.$refs.cardElement)

          this.cardElement.on('change', (event: any) => {
            this.complete = event.complete
          })
        }
      }
    } else {
      await this.payStripeStore.createSavedSource()
      this.addStripeSourceVisible = false
    }
  }

  async addSourceWeb() {
    if (!this.stripe || !this.cardElement) {
      console.error('Stripe.js has not been properly initialized.')
      return
    }

    await this.payStripeStore.createSavedSource(this.stripe, this.cardElement)
    this.addStripeSourceVisible = false
  }
}
</script>

<style lang="sass" scoped>

</style>