// TODO: Eventually this file will be replaced by memberships.ts

export default {
	title: "Subscriptions",
	franchiseDescription:
		"Below you can create memberships that will be available on all Companies. Users that buy the membership, will be able to use it on all Companies within the Franchise.",
	companyDescription:
		"Below you can customise memberships that can be used by your users.",
	advancedSettings: "Advanced settings",
	createNewSubscriptionProductButton: "Create new subscription",
	createNewSubscriptionCategoryButton: "Create new category",

	subscriptionProductsCategoryTable: {
		title: "Categories",
		id: "Name",
	},

	subscriptionProductsTable: {
		id: "Name",
		companyName: "Company",
	},

	createSubscriptionProductCategoryModal: {
		title: "Create subscription category",

		createSubscriptionProductCategoryButton: "Create category",
		createSubscriptionProductCategoryLoadingButton: "Creating category...",
		successToast: "The category is created",
	},

	createSubscriptionProductModal: {
		title: "Create new subscription product",
		useOnAllCompanies: "Can be used across franchise",
		company: "Company",
		category: "Category",
		allowPublicSignUp: "All users can sign up to this subscription",
		paymentMaxCover: "Max payment covered by subscription",
		specifics: "Specifics",
		discount: "Discount",
		payment: "Payment",

		paymentMaxHoursCovered: {
			label: "Max hours",
			placeholder: "Number of max hours",
		},
		paymentMaxValueCovered: {
			label: "Max value",
			placeholder: "Max value",
		},

		maxNumberOfActiveSubscriptions: {
			label: "Max number of users with active subscriptions",
		},

		discountOnValue: {
			title: "Discount type on part of payment",
			paymentDiscountPercentage: {
				label: "Percentage of part",
				placeholder: "Percentage of part",
			},
			paymentDiscountAmount: {
				label: "Fixed value",
				placeholder: "Fixed value",
			},
			paymentDiscountAmountPrBookingInterval: {
				label: "Fixed value per booking interval (default: per 30 min.)",
				placeholder: "Fixed value",
			},
		},

		monthlyPrice: "Monthly price",
		paymentCyclesAfterCancele: {
			label: "Number of payments after termination",
			placeholder: "Number",
		},
		paymentTimesPrYear: {
			label: "Payment frequency",
			annually: "Annually (Once per year)",
			monthly: "Monthly (12 times per year)",
		},
		discountBTimes: "Only discount in B time-slots",
		discountCourtType: "Only discount for specific court types",

		paymentOnTheFirstOfMonth: "Payment is on the first of the month",

		subscriptionUsage: {
			title: "Subscription usage",
			maxActiveBookings: "Max active bookings paid with subscription",
			paymentMaxActiveBookingsPerMonth: "Max active bookings per month",
			maxDaysCreateResourceBookingAhead: "Days to book ahead",
			events: {
				allowUsageOnWorkshopBookings: "Allow usage on events",
				discountEvents: "Discount on events",
			},
			unlimited: "0 for unlimited",
			default: "0 for default",
			rules: {
				title: "Rules for usage",
				addRule: "Add rule",
				noRules: "No rules for usage",
				popover: {
					title: "Add rule",
					type: "Type",
					between: {
						text: "Booking has to have start between",
					},
					createRule: "Create rule",
				},
			},

			discountOnOtherPayments: {
				title: "Discount on other purchases",
				info: "This discount applies to bookings beyond the maximum number of bookings. In other words, if the user has exceeded the maximum number of bookings that can be paid with the subscription, this discount will be used. The discount also applies in HIGH-time (A-time) if the membership is tied to LOW-time (B-time).",
				percentDiscountOnOtherPayments: {
					label: "Discount in percentage",
				},
				timeIntervals: {
					title: "Time intervals for discount code",
					addTimeInterval: "Add time interval",
					noTimeIntervals: "No time intervals",
					popover: {
						title: "Add time interval",
						createTimeInterval: "Create time interval",
					},
				},
			},

			subscriptionSignUp: {
				title: "Sign up to subscription",
				signUpFee: {
					label: "Sign up fee",
				},
				startDateForFirstPaymentData: {
					checkbox: "Start payment from fixed date",
					daysBeforeFirstPayment: {
						label: "Days before first payment",
					},
					dateBeforeFirstPayment: {
						label: "Start payment from fixed date",
						placeholder: "Date",
					},
				},
				type: "Type",
				activeBeforeFirstPayment: "Active subscription before first payment",
				rulesForSignUp: {
					title: "Rules for sign up",
					addRule: "Add rule",
					noRules: "No rules for sign up",
					popover: {
						title: "Add rule",
						type: "Type",
						ageRule: "User age rule",
						age: "Age",
						userGroups: "Choose user groups",
						users: "users",
						createRule: "Create rule",
					},
				},
			},
		},

		createSubscriptionProductButton: "Create subscription",
		createSubscriptionProductLoadingButton: "Creating subscription...",
		successToast: "The subscription is created.",
	},

	subscriptionProductFirstPaymentOptions: {
		date: "Fixed date",
		days: "Number of days",
	},

	subscriptionProductUsageRuleTypes: {
		resourceBookingStartDate: "Court booking start date",
	},

	subscriptionProductSignUpRuleTypes: {
		age: "Age",
		userGroup: "User group",
	},

	subscriptionProductPaymentMaxCoverTypes: {
		all: "The entire amount",
		none: "None",
		value: "Amount",
		hours: "Max hours",
	},

	subscriptionProducPaymentDiscountTypes: {
		percentage: "Percentage",
		amount: "Fixed value",
		amountPrBookingInterval: "Fixed value per booking interval",
	},

	subscriptionProductSignUpRuleAgeTypes: {
		greaterThan: "Older than",
		lessThan: "Younger than",
	},

	subscriptionAlertMessage: {
		updateSubscription: "The information has been updated",
		addedDate: "The date has been added",
	},

	subscriptionInfo: {
		usedForEvents: "Can be used at events",
		discountOnEvents: "Discount on events",
		courtTypes: "Works for the following court types",
		maxActiveBookingsPerMonth: "Max active bookings per month ",
		maxActiveBookings: "Max active bookings",
		coveragePerBookingInterval: "Covered amount per booking interval",
		coverageAmount: "Covered amount",
		coverage: "Covers",
		discountType: "Discount type",
		maxHoursCovered: "Max hours covered",
		maxAmountCovered: "Max amount covered",
		maxPaymentCoverage: "Max payment covered type",
		appliesToBTimes: "Applies to B times only",
		daysFutureBookings: "Days you can book in the future",
		setUpFee: "Set-up fee",
		discountOtherPurchases: "Percentage discount on other purchases",
		paymentsPerYear: "Number of payments per year",
		monthlyPrice: "Monthly price",
		yearlyPrice: "Yearly price",
		franchise: "Franchise",
		updateInfo: "Update information",
		membershipProduct: "Membership product",
		activeSubscriptions: "Active subscriptions",
		monthlyIncome: "Monthly income",
		sendMessage: "Send a message",
		freeStartup: "Add a free company startup",
		addStartup: "Add startup",
		addsStartup: "Adds startup...",
		updateSubscription: "Update subscription product",
		updating: "Updating subscription product",
		validDate: "Valid start and end date",
		companies: "Companies",
		date: "Date",
		freePeriod: "Free periods",
	},
};
