export default {
  seachText: 'Seach user name, email or phone number',

  viewUserHover: 'Show user',

  table: {
    id: 'id',
    value: 'Amount & (value)',
    product: 'Product',
    status: 'Status',
    method: 'Method'
  }
}