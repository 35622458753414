export default {
  subTitle: 'Support',
  title: 'Create a support ticket',
  description: 'The page you are viewing in the background is saved to the support ticket. As an example, if you want help to an event, please be on the page for the specfic event.',

  preSelectedUser: 'You have preselected a user for this ticket.',
  preSelectedResourceBooking: 'You have preselected a booking for this ticket.',

  selectUser: {
    checkbox: 'This is regarding a specfic user',
    label: 'Select the user',
  },

  signedInCompany: {
    one: 'You are signed in at',
    two: '. If the support case is not connected ti this location, you have to switch to the right location.'
  },

  placeholderSupportTicketMessage: 'Describe your issue here...',
  isCreatedToast: 'Your ticket is sent',

  ctas: {
    needHelp: 'Need help',
    createTicket: 'Create a support ticket'
  }
}