export default {
  subTitle: 'Overview',
  pageTitle: 'User groups',

  createUserGroupButton: 'Create group',

  userGroupsTable: {
    id: 'Group',
    usersCount: 'Users',
    points: 'Points (used {0} days)',
    productSubscription: 'Membership',
    coupons: 'Discount codes',

    showPointAccountHover: 'View point account',
    addUserHover: 'Add user'
  },

  createUserGroupModal: {
    title: 'Create a user group',
    name: {
      label: 'Name',
      placeholder: 'My group...'
    },
    emailEnding: {
      label: 'Automatically add users with email extension (eg. padelclub.dk)',
      placeholder: 'padelclub.dk'
    },
    maxBookingsDayAhead: 'Max days you can book in the future',
    maxUsersCount: 'Max number of users',

    createUserGroupButton: 'Create group',
    createUserGroupLoadingButton: 'Creating group...',

    createdToastText: 'The group is created'
  }
}