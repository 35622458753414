export default {
  
  accessControl: {
    title: 'Access codes',
    description: "Manage custom access codes for your location. This includes creating, updating, and deleting staff codes, as well as determining which doors each code can access.",

    createAccessCodeButton: 'Create access code',
    yourAccessCodeButton: 'Your code',
    accessCodeTable: {
      id: 'Name',
      pinCode: 'Pin code',
      status: 'Status'
    }
  },

  doorGroups: {
    title: 'Door groups',
    description: "Create door groups to facilitate which doors different access codes can open. Users who have booked a court will receive an access code that allows entry through the doors in the 'Users default group'.",
    createDoorGroupButton: 'Create group',
    doorGroupsTable: {
      id: 'Name'
    }
  },

  doorGroupSettingsModal: {
    title: 'Door group settings',
    fullAccessTo: 'Full access to',
    updateDoorGroupButton: 'Update group',
    updateDoorGroupLoadingButton: 'Updating...',
    successToast: 'The group is updated'
  },

  createDoorGroupModal: {
    title: 'Create door group',
    createDoorGroupButton: 'Create group',
    createDoorGroupLoadingButton: 'Creating...',
    successToast: 'The group is created'
  },

  createAccessCodeModal: {
    title: 'Add a new code',
    description: 'You are creating a new access code. Fill the inputs below.',
    pinCode: {
      label: 'Pin code',
      placeholder: '00000'
    },
    cardCode: {
      label: 'Card code',
      placeholder: '00000'
    },
    fullAccessTo: 'Full access to',
    groupsLabel: 'Groups',
    createAccessCodeButton: 'Create code',
    createAccessCodeLoadingButton: 'Creating code...',
    successToast: 'The access code is created.',
  }

}