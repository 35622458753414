<template lang="pug">
.container-fluid.container-full.pt-4.safe-area-inset-top
	.fullsize-message(v-if="showUsedGiftCard")
		.content(@click="doConfetti()")
			h3.m-0 {{ $t('pages.user.account.showUsedGiftCard.text', [ giftCardUsedPointsAmount ]) }}

	header
		h1.display-4.m-0.ml-3 {{ $t('pages.user.account.title') }}

	section.mt-4
		b-card.card-small-padding-extra-small.card-no-border.shadow-none.m-0
			.d-flex.align-items-center.cursor-pointer(v-b-toggle.friends)
				b-row 
					b-col
						i.fe.fe-users.icon-md
					b-col
						h4.m-2 {{ $t('pages.user.account.friends.title') }}

			b-collapse#friends(accordion="user-settings")
				hr.mb-4
				UserFriends

		b-card.card-small-padding-extra-small.card-no-border.shadow-none.m-0(v-if="hasLedapShopOrderHistory")
			.d-flex.justify-content-between.align-items-center.cursor-pointer(v-b-toggle.ecomHistory)
				b-row
					b-col
						i.fe.fe-shopping-bag.icon-md
					b-col(cols="auto")
						h4.m-2 {{ $t('pages.user.account.ecomHistory.title') }}

			b-collapse#ecomHistory(accordion="user-settings")
				hr.mb-4
				OrderHistory(:orderHistory="trinityEcomOrderHistory")

		b-card.card-small-padding-extra-small.card-no-border.shadow-none.m-0
			.d-flex.justify-content-between.align-items-center.cursor-pointer(v-b-toggle.giftCard)
				b-row
					b-col
						i.fe.fe-gift.icon-md
					b-col(cols="auto")
						h4.m-2 {{ $t('pages.user.account.giftCard.title') }}

			b-collapse#giftCard(accordion="user-settings")
				hr.mb-4

				b-card.bg-light.card-small-padding.m-0.mt-4.card-no-border.shadow-none
					p.mb-2 {{ $t('pages.user.account.giftCard.redeemGiftCard.title') }}
					small.text-muted {{ $t('pages.user.account.giftCard.redeemGiftCard.description') }}

						b-row.mt-3
							b-col
								b-form-input.giftcard-input(
									v-model='useGiftCard.variables.giftCardCode'
									type='text' 
									:placeholder="$t('pages.user.account.giftCard.redeemGiftCard.inputPlaceholder')")
							b-col
								b-button(
									variant="primary"
									block
									@click="useGiftCard.mutate()"
									:disabled="useGiftCard.isLoading") 
									span(v-if="useGiftCard.isLoading")
										b-spinner.button-loading(small type="grow")
										span {{ $t('pages.user.account.giftCard.redeemGiftCard.redeemGiftCardLoadingButton') }}
									span(v-else) {{ $t('pages.user.account.giftCard.redeemGiftCard.redeemGiftCardButton') }}

		b-card.card-small-padding-extra-small.card-no-border.shadow-none(v-if="!userOnlyHaveUserRoles").m-0
			.d-flex.justify-content-between.align-items-center.cursor-pointer(v-b-toggle.user-company)
				b-row 
					b-col 
						i.fe.fe-briefcase.icon-md
					b-col(cols="auto")
						h4.m-2 {{ $t('pages.user.account.connections.title') }}

			b-collapse#user-company(accordion="user-settings")
				hr.mb-3
				userPlatformConnections(:reloadPageOnChange="true")
				b-button.mt-4(
					variant="primary"
					block
					size="md"
					@click="$refs['create-user-platform-connection-modal'].show()") {{ $t('pages.user.account.connections.createNewButton') }}

			b-modal(
				:title="$t('pages.user.account.connections.createNewModal.title')"
				ref="create-user-platform-connection-modal"
				title-tag="h4"
				centered
				hide-footer
				size="md")
				p.text-muted {{ $t('pages.user.account.connections.createNewModal.description') }}
				createUserPlatformConnection

		b-card.card-small-padding-extra-small.card-no-border.shadow-none.m-0
			.d-flex.justify-content-between.align-items-center.cursor-pointer(v-b-toggle.user-settings)
				b-row
					b-col 
						i.fe.fe-user.icon-md
					b-col(cols="auto")
						h4.m-2 {{ $t('pages.user.account.account.title') }}

			b-collapse#user-settings(accordion="user-settings")
				hr.mb-4
				UpdateUser
				b-button.mt-4(
					block
					@click="deleteUser()"
					size="sm"
					variant="outline-danger") {{ $t('pages.user.account.account.deleteUser.buttonText') }}

		b-card.card-small-padding-extra-small.card-no-border.shadow-none.m-0(v-if="userGroups.length")
			.d-flex.justify-content-between.align-items-center.cursor-pointer(v-b-toggle.user-groups)
				b-row 
					b-col 
						i.fe.fe-users.icon-md
					b-col(cols="auto") 
						h4.m-2 {{ $t('pages.user.account.userGroups.title') }}

			b-collapse#user-groups(accordion="user-settings")
				hr.mb-4

				b-card.bg-light.shadow-none.card-no-border.card-small-padding.m-0.mt-3(v-for="userGroup in userGroups" :key="userGroup.id")
					.d-flex.justify-content-between.align-items-center
						h4.m-0 {{ userGroup.name }}
						b-button(
							v-if="userGroup.userIsGroupAdmin"
							size="sm"
							variant="outline-primary"
							@click="$router.push({ name: 'User.UserGroupAdmin', params: { id: userGroup.id } })") {{ $t('pages.user.account.userGroups.show') }}

		b-card.card-small-padding-extra-small.card-no-border.shadow-none.m-0
			.d-flex.justify-content-between.align-items-center.cursor-pointer(v-b-toggle.user-payment-sources)
				b-row
					b-col 
						i.fe.fe-credit-card.icon-md
					b-col(cols="auto")
						h4.m-2 {{ $t('pages.user.account.paymentSources.title') }}
			b-collapse#user-payment-sources(accordion="user-settings")
				b-overlay(
					:show="payLoadingStore.loading"
					variant="white"
					spinner-variant="primary")
					hr.mb-3

					.cards-container
						b-card.card-small-padding.shadow-none.bg-light.card-no-border(v-if="!payStripeStore.savedStripeSources.length")
							p.mb-2 {{ $t('pages.user.account.paymentSources.noPaymentSources.title') }}
							small.text-muted {{ $t('pages.user.account.paymentSources.noPaymentSources.description') }}

						b-card.card-small-padding.card-no-border.shadow-none(v-for="source in payStripeStore.savedStripeSources" :key="source.id")
							b-row(align-v="center")
								b-col
									h4 {{ $t('common.staticTemp.stripeCode' )}} {{ source.last4 }} 
									p.text-muted.m-0 {{ source.expMonth }}/{{ source.expYear }} - {{ source.brand }}

								b-col(v-if="source.isDefaultCard || payStripeStore.savedStripeSources.length === 1")
									b-badge(pill) {{ source.isDefaultCard ? 'Default' : '' }}

								.col-auto
									b-button(
										@click="payStripeStore.removeStripeSource(source.id)"
										variant="outline-warning") {{ $t('pages.user.account.paymentSources.paymentSource.remove') }}

					b-button.mt-4(
						variant="primary"
						block
						@click="$refs['add-stripe-source'].show()")
						span(v-if="payStripeStore.savedStripeSources.length") {{ $t('pages.user.account.paymentSources.updatePaymentSourceButton') }}
						span(v-else) {{ $t('pages.user.account.paymentSources.addCreditCardButton') }}

					AddStripeSource(ref="add-stripe-source")

		b-card.card-small-padding-extra-small.card-no-border.shadow-none.m-0
			.d-flex.justify-content-between.align-items-center.cursor-pointer(v-b-toggle.user-payments)
				b-row 
					b-col
						i.fe.fe-dollar-sign.icon-md
					b-col
						h4.m-2 {{ $t('pages.user.account.payments.title') }}
			b-collapse#user-payments(accordion="user-settings")
				hr.mb-4
					
				.cards-container.mt-4(v-if="this.payments.data && this.payments.data?.length")
					PaymentCard(
						v-for="payment in this.payments.data"
						:money-value="payment.moneyValue ?? 0"
						:currency="payment.currency"
						:product="payment.product"
						:productResourceBooking="payment.productResourceBooking"
						:productPointTransaction="payment.productPointTransaction"
						:connectedPayment="payment.connectedPayment"
						:company="payment.company"
						:createdAt="payment.createdAt"
						:paymentMethod="payment.paymentMethod"
						:status="payment.status"
						:invoiceUrl="payment.invoiceUrl"
						:showInvoice="payment.paymentMethod === 'MobilePayInvoice' || payment.paymentMethod === 'Stripe' || payment.paymentMethod === 'PayPal' || payment.paymentMethod === 'StripeInvoice'"
						:showInvoiceHandler="() => openPaymentInvoiceModal(payment.id)")

				b-card(v-else class="m-0 card-small-padding bg-light card-no-border shadow-none" style="width: 100%")
					p.mb-2 {{ $t('pages.user.account.payments.noPayments.title') }}
					small.text-muted {{ $t('pages.user.account.payments.noPayments.description') }}
				
				b-pagination(
					v-if="payments && payments.totalCount > 5"
					v-model="page"
					:total-rows="payments.totalCount"
					:per-page="perPage"
					align="center"
					class="my-3")
				
				PaymentInvoiceModal(
					:paymentId="this.selectedPaymentId" 
					:show="this.showPaymentInvoiceModal"
					@closePaymentInvoiceModal="closePaymentInvoiceModal")

		b-card.card-small-padding-extra-small.card-no-border.shadow-none.m-0
			.d-flex.justify-content-between.align-items-center.cursor-pointer(v-b-toggle.user-agreements)
				b-row 
					b-col 
						i.fe.fe-paperclip.icon-md
					b-col
						h4.m-2 {{ $t('pages.user.account.agreements.title') }}
			b-collapse#user-agreements(accordion="user-settings")
				hr.mb-4

				GraphQlList(
					:query="require('@/graphql/queries/userAgreement/userAgreements.gql')"
					type="Custome"
					:perPage="5"
					paginationAlign="center"
					:paginationBorderTop="false"
					:queryVariables="{}")

					template(#customItems="{ items }")

						b-card.card-small-padding.bg-light.card-no-border.shadow-none(v-b-toggle.legal-entity v-if="leDapShopavailable")
							p.mb-1 LeDap Shop - {{ $t("components.app.ecom.legalEntity.generalTerms.title") }}
							b-collapse#legal-entity(accordion="legal-entity")
								hr
								LegalEntity

						b-card.card-small-padding.bg-light.mb-2.card-no-border.shadow-none(v-if="!items.length && !leDapShopavailable" style="width: 100%")
							p.mb-0 {{ $t('pages.user.account.agreements.noAgreements.title') }}

						b-card.card-small-padding.bg-light.card-no-border.shadow-none(
						v-else
						v-for="userAgreement in items"
						:key="userAgreement.id")
							b-row
								b-col
									p.mb-1 {{ userAgreement.agreement.name }}
									b-link.agreement-link(target="_blank" :href="userAgreement.agreement.documentUrl") {{ $t('pages.user.account.agreements.showAgreements') }}
									p.m-0.mt-2.text-muted(v-if="userAgreement.description") {{ userAgreement.agreement.description }}
								b-col(cols="auto")
									b-badge(variant="success" v-if="userAgreement.accepted") 
										span {{ $t('pages.user.account.agreements.accepted') }} 
											i.fe.fe-check-circle
									b-badge(variant="secondary" v-else) 
										span {{ $t('pages.user.account.agreements.notAccepted') }} 
											i.fe.fe-slash

		b-card.card-small-padding-extra-small.card-no-border.shadow-none.m-0
			.d-flex.justify-content-between.align-items-center.cursor-pointer(v-b-toggle.user-faq)
				b-row
					b-col 
						i.fe.fe-help-circle.icon-md
					b-col(cols="auto")
						h4.m-2 {{ $t('pages.user.account.faq.title') }}

			b-collapse#user-faq(accordion="user-settings")
				hr.mb-3
				div.mb-3
					a(href="https://faq.ledap.app" class="underline") <b>{{ $t('pages.user.account.faq.link') }}</b>  
					span  {{ $t('pages.user.account.faq.text') }}

		LangSwitcher.m-0

		b-button.mt-3(variant="danger" block @click="Auth.signUserOut()") {{ $t('pages.user.account.signOut') }}

		b-link.my-3.d-block.text-center(@click="refetchApp()") {{ $t('pages.user.account.reloadPage') }}

		small.text-muted.text-center.d-block.mt-3(v-if="appVersionWeb") {{ "b." + appVersionWeb }}
		small.text-muted.text-center.d-block.mt-3(v-if="appVersion") {{ appVersion }}

		.mb-4

		b-modal(
			:title="$t('common.staticTemp.mobilePay')"
			ref="mobilepay-invoice-modal"
			title-tag="h4"
			centered
			hide-footer
			size="sm")
			p.text-muted {{ $t('pages.user.account.mobilePayInvoiceModal.textOne') }}
			p.text-muted {{ $t('pages.user.account.mobilePayInvoiceModal.textTwo') }}

			b-button(
				block
				variant="primary"
				@click="$refs['mobilepay-invoice-modal'].hide()") {{ $t('pages.user.account.mobilePayInvoiceModal.close') }}

	ConfirmModal(
		ref="confirm-modal"
		:title="$t('pages.user.account.account.deleteUser.msgBoxConfirm.title')"
		:message="$t('pages.user.account.account.deleteUser.msgBoxConfirm.text')")
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { GetUserAccountPageQuery, GiftCardUseMutationVariables, GiftCardUse, GiftCardUseMutation, GetPointAmount, GetPointAmountQuery, GetUserGroupsUser, GetUserGroupsUserQuery, UserDeleteMutationVariables, UserDelete, GetTrinityEComGetOrderHistory, SupportedCountries, GetPayments, GetPaymentsQuery, GetUserInformationQuery } from '@/graphql'
import GraphQlList from '@/components/admin/ui/graphQlList.vue'
import PaymentMethod from '@/components/admin/enumTranformers/paymentMethod.vue'
import UserPlatformConnections from '@/components/auth/userPlatformConnections.vue'
import CreateUserPlatformConnection from '@/components/auth/createUserPlatformConnection.vue'
import { App } from '@capacitor/app'
import UpdateUser from '@/components/shared/updateUser.vue'
import UserFriends from '@/components/app/userFriends.vue'
import OrderHistory from '@/components/app/ecom/orderHistory.vue'
import LegalEntity from '@/components/app/ecom/legalEntity.vue'
import LangSwitcher from '@/components/shared/langSwitcher.vue'
import AddStripeSource from '@/components/app/addStripeSource.vue'
import { SmartMutation } from '@/utils/smartMutation'
import JSConfetti from 'js-confetti'
import { Capacitor } from '@capacitor/core'
import { useAuthStore } from '@/store/modules/auth'
import { useUserStore } from '@/store/modules/user'
import { usePayStripeStore } from '@/store/modules/pay/stripe'
import PaymentInvoiceModal from '@/components/app/paymentInvoiceModal.vue'
import { usePayLoadingStore } from '@/store/modules/pay/loading'
import gql from 'graphql-tag';
import { currencyFormatter } from '@/utils/currencyFormatter'
import PaymentCard from '@/components/app/paymentCard.vue'

const jsConfetti = new JSConfetti()

@Component({
	components: {
		OrderHistory,
		LegalEntity,
		UserFriends,
		GraphQlList,
		PaymentMethod,
		UserPlatformConnections,
		CreateUserPlatformConnection,
		UpdateUser,
		LangSwitcher,
		PaymentInvoiceModal,
		AddStripeSource,
		PaymentCard
	},
	methods: {
		currencyFormatter
	},
	apollo: {
		pointAmount: {
			query: GetPointAmount,
			update(data: GetPointAmountQuery) {
				return data.me.pointAccount.pointsAmount
			}
		},
		userGroups: {
			query: GetUserGroupsUser,
			update(data: GetUserGroupsUserQuery) {
				return data.userGroups.data
			}
		},
		companyUsing: {
			query: gql`
				query GetUsingPointProducts {
					me {
						company {
							id
							usingPointProducts
							usingSubscriptionProducts
							usingEcom
						}
					}
				}
			`,
			update(data: { me: { company: { usingPointProducts: boolean, usingSubscriptionProducts: boolean, usingEcom: boolean } } }) {
				return data.me.company
			}
		},
		trinityEcomOrderHistory: {
			query: GetTrinityEComGetOrderHistory,
			variables() {
				return {
					country: this.country
				}
			},
			skip() {
				return (![SupportedCountries.Dk, SupportedCountries.De, SupportedCountries.At].includes(this.country))
			},
			error(error) {
				console.error(error)
			}
		},
		payments: {
            query: GetPayments,
            update(data: GetPaymentsQuery) {
                return data.payments
            },
            variables() {
                return {
                    filtering:  { 
                        showConnectedPayments: true, 
                        hidePaymentMethods: [ 'ConnectedPayments' ] 
                    },
					pagination: {
						skip: this.page === 1 ? 0 : (this.page * this.perPage) - this.perPage,
						first: this.perPage
					}
                }
            },
		}
	}
})
export default class CompanyUsersUserView extends Vue {
	me: GetUserAccountPageQuery['me'] = {
		user: {
			id: '',
		}
	}
	pointAmount = 0
	payStripeStore = usePayStripeStore()
	payLoadingStore = usePayLoadingStore()

	companyUsing = {
		usingPointProducts: false,
		usingSubscriptionProducts: false,
		usingEcom: false
	}

	trinityEcomOrderHistory: Array<any> = [];

	userGroups: GetUserGroupsUserQuery['userGroups']['data'] = []

	payments = []
	selectedPaymentId: string = ''
	showPaymentInvoiceModal: boolean = false
	perPage = 5
	page = 1

	Auth = useAuthStore()
	User = useUserStore()

	signedInCompany = this.User.user!.companyId

	appVersion: null | string = null
	appVersionWeb: null | undefined | string = null

	get userOnlyHaveUserRoles() {
		const check = this.Auth.userPlatformConnections.find(x => x.role !== 'User')
		return check ? false : true
	}

	get hasLedapShopOrderHistory() {
		return this.trinityEcomOrderHistory.length > 0
	}

	get leDapShopavailable() {
		if (this.companyUsing.usingEcom)
			return true
		else {
			return false
		}
	}

	get country() {
		return this.User.user?.country
	}

	async created() {
		this.useGiftCard.componentThis = this
		this.userDelete.componentThis = this

		this.appVersionWeb = process.env.BUILD_ID

		if (Capacitor.getPlatform() !== 'web') {
			const getAppVersion = await App.getInfo()
			if (getAppVersion) {
				this.appVersion = `v${getAppVersion.version} - b.: ${getAppVersion.build}`
			}
		}

		await this.payStripeStore.loadStripeUser()
	}

	userDelete = new SmartMutation({
		componentThis: null,
		doneToast: {
			text: this.$t('common.staticTemp.deletedUser') as string
		},
		mutation: UserDelete,
		variables: (): UserDeleteMutationVariables => {
			return {
				//@ts-ignore
				id: null
			}
		}
	})

	async deleteUser() {

		//@ts-ignore
		const userAccept = await this.$refs['confirm-modal'].open()

		if (!userAccept) return

		// If user can't be deleted, mutation returns undefined
		const deleteResponse = await this.userDelete.mutate()
		if (deleteResponse) {
			await this.Auth.signUserOut()
		}
	}

	useGiftCard = new SmartMutation({
		componentThis: null,
		refetchQueryName: 'pointAmount',
		mutation: GiftCardUse,
		variables: (): GiftCardUseMutationVariables => {
			return {
				giftCardCode: ''
			}
		},
		doneFunction: (data: GiftCardUseMutation) => {
			this.giftCardUsed(data)
		}
	})

	showUsedGiftCard = false
	giftCardUsedPointsAmount = 0
	giftCardUsed(data: GiftCardUseMutation) {
		//@ts-ignore
		this.giftCardUsedPointsAmount = data.giftCardUse
		this.showUsedGiftCard = true
		this.doConfetti()
		setTimeout(() => {
			this.doConfetti()
		}, 1000)
		setTimeout(() => {
			this.showUsedGiftCard = false
		}, 2500)
	}

	doConfetti() {
		jsConfetti.addConfetti({
			emojis: ['🎾', '❤️', '💥', '🎾', '🎾'],
		})
	}

	refetchApp() {
		location.reload()
	}

	openPaymentInvoiceModal(paymentId: string) {
		this.selectedPaymentId = paymentId;
		this.showPaymentInvoiceModal = true
	}

	closePaymentInvoiceModal() {
		this.selectedPaymentId = ''
		this.showPaymentInvoiceModal = false
	}
}
</script>

<style scoped lang="scss">
.fullsize-message {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	
	.content {
		background-color: white;
		padding: 30px;
		border-radius: 10px;
		box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 21, 0.29);
	}
}
	
.giftcard-input::placeholder {
	font-size: 12px;
}
</style>
