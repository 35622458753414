export default {
  text: "You must accept '{0}' agreements below to make use of their services.",
  showAgreementButton: 'Show conditions',
  isNeeded: {
    one: 'Fields made with',
    two: 'must be accepted.'
  },
  submitButton: 'Complete',
  signOut: 'Sign out',
  missingSomeNeededAgreementsToast: 'You are missing some of the fields...'
}