export default {
	page: {
		title: "Platform fees",
		description: "Read more about platform fees here:",
	},
	table: {
		titles: {
			companies: "Companies",
			franchises: "Franchises",
		},
		headers: {
			name: "Name",
			pointTransaction: "Point transaction",
			paymentPlatform: "Platform fee",
			subscriptionPlatformPrimary: "Subscription fee primary",
			subscriptionPlatformSecondary: "Subscription fee secondary",
			invoicePaymentPlatform: "Invoice fee",
			actions: "Actions",
		},
	},
	editModal: {
		title: "Edit platform fee",
		information: {
			title: "Entity",
			type: "Type",
			id: "ID",
			name: "Name",
		},
		settings: {
			entity: {
				titles: {
					company: "Company settings",
					franchise: "Franchise settings",
				},
				pointTransactionPlatformFeePercentage:
					"Point Transaction Platform Fee Percentage",
				pointTransactionPlatformStatic: "Point Transaction Platform Static",
			},
			stripe: {
				title: "Stripe connection settings",
				paymentPlatformFeeStatic: "Payment Platform Fee Static",
				paymentPlatformFeePercentage: "Payment Platform Fee Percentage",
				invoicePaymentPlatformFeeStatic: "Invoice Platform Fee Static",
				invoicePaymentPlatformFeePercentage: "Invoice Platform Fee Percentage",
				subscriptionPlatformFeePrimaryStatic:
					"Subscription Platform Fee Primary Static",
				subscriptionPlatformFeePrimaryPercentage:
					"Subscription Platform Fee Primary Percentage",
				subscriptionPlatformFeeSecondaryStatic:
					"Subscription Platform Fee Secondary Static",
				subscriptionPlatformFeeSecondaryPercentage:
					"Subscription Platform Fee Secondary Percentage",
			},
		},
		buttons: {
			cancel: "Cancel",
			save: "Save",
		},
	},
};
