export default {
    step: 'Step',
    of: 'of',
    createCompany: 'Create a company',
    inputCreateCompany: 'Fill out the fields below and create a company.',
    userInfo: 'User information',
    userDescription: "In order to create a new company, location or franchise, you need to have an account. Either sign in with your existing account or create a new",
    companyDescription: "Fill in information below in order to create a new company and location.",
    franchiseDescription: "Fill in information below in order to create a franchise.",
    loginUser: 'Log in with an existing user',
    slug: 'Slug',
    franchise: 'Franchise name',
    franchisePlaceholder: 'Padel club',
    company: 'Company name',
    companyPlaceholder: 'Padelclub Kolding',
    next: 'Next',
    companyInfo: 'Company information',
    back: 'Back',
    creatingCompany: 'Creating company...',
    create: 'Create company',
    createFranchise: 'Franchise information',
    companyCreated: 'Great! Your company has been created',
    logIn: 'You can now log in and use the platform services!',
    goToLogin: 'Go to login',
    errorMessageNoToken: 'You have not added any token...',
    selectCompany: "Select a company..."
}