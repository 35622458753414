export default {
  admin: {
    dashboard: 'Dashboard',
    users: 'Users',
    companies: 'Companies',
    locations: 'Locations',
    settings: 'Settings'
  },

  technician: {
    dashboard: 'Dashboard',
    locations: 'Locations'
  },

  priceff: {},

  user: {},

  company: {
    dashboard: 'Dashboard',
    resourceBookings: 'Bookings',
    workshops: 'Events',
    payments: 'Payments',
    users: 'Users',
    userGroups: 'User groups',
    userProductSubscriptions: 'Memberships',
    createMessage: 'Send newsletter',
    settings: {
      start: 'Settings',
      user: 'Your user',
      company: 'Company',
      accessCodes: 'Access control',
      infoScreens: 'Signage screens',
      subscriptionProducts: 'Memberships',
      platformInvoices: 'Platform invoices',
      agreements: 'Agreement documents',
      additionalservices: 'Additional services',
      admins: 'Administrators',
      giftCards: 'Gift card',
      locations: 'Locations',
      resource: 'Courts',
      priceff: 'Dynamic pricing',
      payment: 'Discount codes & points',
      integrations: 'Integrations',
      messages: 'Messages',
      migration: 'Migration'
    },
    help: 'Info & help'
  },

  franchise: {
    dashboard: 'Dashboard',
    companies: 'Companies',
    statistics: {
      start: 'Statistics',
      general: 'General',
      points: 'Points',
      fromCompanyCreated: 'From company start',
      scenarios: 'Scenarios'
    },
    settings: 'Settings'
  }
}