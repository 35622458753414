import { defineStore } from 'pinia'

interface PlatformState {
  connectedToGraphqlApi: boolean
}

export const usePlatformStore = defineStore({
  id: 'Platform',

  state: (): PlatformState => ({
    connectedToGraphqlApi: false
  }),

  getters: {

  },

  actions: {
    setConnectedToGraphqlApi(newIsConnectedState: boolean) {
      this.connectedToGraphqlApi = newIsConnectedState
    }
  }
})
