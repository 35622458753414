<template lang="pug">
b-card(v-if="showDownloadApp")
  h2 {{ header || $t('components.shared.appWelcomeFlow.downloadApp.title') }}
  p.text-muted {{ text || $t('components.shared.appWelcomeFlow.downloadApp.text') }}

  p.text-muted {{ $t('components.shared.appWelcomeFlow.downloadApp.appName') }}&nbsp;
    span.text-body {{ $t('common.bookli') }}

  b-row
    b-col
      a(@click="openAppInStore(appStoreUrl)")
        img.app-download-img(src="/img/app-download-ios.png")

    b-col
      a(@click="openAppInStore(googlePlayStoreUrl)")
        img.app-download-img(src="/img/app-download-android.png")

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { } from '@/graphql'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { ledapAppStoreUrl, ledapGooglePlayStoreUrl } from '@/constants/externalUrls'


@Component({
  components: {

  },
  apollo: {

  }
})
export default class CompanyUsersUserView extends Vue {
  @Prop({ required: false }) private header!: string
  @Prop({ required: false }) private text!: string

  showDownloadApp = false
  appStoreUrl = ledapAppStoreUrl
  googlePlayStoreUrl = ledapGooglePlayStoreUrl

  async created() {
    const platform = Capacitor.getPlatform()
    if (platform === 'android' || platform === 'ios') {

    }
    else if (platform === 'web') {
      this.showDownloadApp = true
    }
  }

  openAppInStore(openUrl: string) {
    const win = window.open(openUrl, '_blank')
    win!.focus()
  }

}
</script>

<style lang="sass" scoped>
.app-download-img
  width: 100%
  height: auto
</style>
