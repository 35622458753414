import { GetAdditionalServiceReservation, GetAdditionalServiceReservationMutation, GetAdditionalServiceReservationMutationVariables, GetPointAmount, GetPointAmountQuery, GetPointAmountQueryVariables, PaymentReserveProductMutation, PaymentTokenFaildReason, PaymentTokenPaymentType, ProductType } from '@/graphql'
import { i18n } from '@/locales/setupI18n'
import { apolloClient, wsLink } from '@/plugins/apollo'
import VueApp from '@/main'
import { defineStore } from 'pinia'
import { getErrorMessage } from '@/utils/getErrorMessage'
import { usePayV2PaymentMethodStore } from './paymentMethodV2'
import { usePayV2StripeStore } from './stripeV2'
import { usePayLoadingStore } from '../pay/loading'
import { usePayPaymentTokenStore } from '../pay/paymentToken'
import { usePayV2PointsStore } from './pointsV2'
import { usePayUserProductSubscriptionPaymentStore } from '../pay/userProductSubscriptionPayment'
import { usePayProductReservationStore } from '../pay/productReservation'
import { usePayPriceStore } from '../pay/price'
import { usePayCouponStore } from '../pay/coupon'
import { usePaySubscriptionProductGiftCardStore } from '../pay/subscriptionProductGiftCard'

export type DoneFunction = (data: PaymentReserveProductMutation['paymentReserveProduct']) => Promise<any>

export interface InitPaymentData {
  source: string
  purchaseData: PurchaseData
  doneFunction: DoneFunction
}

interface PurchaseData {
  productId?: string
  quantity?: number
  productType: ProductType
  pointProductId?: string
  subscriptionProductId?: string
  resourceBooking?: {
    resourceId: string
    startDate: Date
    endDate: Date
    attendees: string[]
    additionalServicesIds?: string[]
    internalNotes?: string
  }
  workshopBooking?: {
    workshopId: string
    userBookingNote?: string
  }
  resourceBookingAttendeeBuyOutInput?: {
    attendeeUserId: string
    resourceBookingId: string
  }
}

interface PayState {
  initWsLinkOnConnectedHandler: () => void

  payIsInitialized: boolean

  isInitialized: boolean
  loadingText: string | null

  startPaymentIsInitialized: boolean
  paymentIsStarted: boolean

  paymentIsSuccessful: boolean
  paymentFailed: boolean

  //* FUNCTIONS
  doneFunction: DoneFunction | null

  //* PURCHASE
  purchaseData: PurchaseData | null

  //* POINTS
  pointsAmount: number
}


export const usePayV2Store = defineStore({
  id: 'PayV2',

  state: (): PayState => ({
    initWsLinkOnConnectedHandler: wsLink.client.on('connected', async () => {
      //* Listen for ws link connected
      const payPaymentTokenStore = usePayPaymentTokenStore()
      await payPaymentTokenStore.refetchPaymentToken()
    }),

    payIsInitialized: false,

    isInitialized: false,
    loadingText: null,

    startPaymentIsInitialized: false,
    paymentIsStarted: false,

    paymentIsSuccessful: false,
    paymentFailed: false,

    //* FUNCTIONS
    doneFunction: null,

    //* PURCHASE
    purchaseData: null,

    //* POINTS
    pointsAmount: 0,
  }),

  getters: {
    readyForPayment: state => {
      if (!state.purchaseData) return false
      
      if (state.purchaseData.productType === ProductType.ResourceBooking) {
        if (!state.purchaseData.resourceBooking) return false
        if (!state.purchaseData.resourceBooking.resourceId) return false
        if (!state.purchaseData.resourceBooking.startDate) return false
        if (!state.purchaseData.resourceBooking.endDate) return false
        if (!state.purchaseData.resourceBooking.attendees) return false
      }

      if (state.purchaseData.productType === ProductType.Workshop) {
        if (!state.purchaseData.workshopBooking) return false
        if (!state.purchaseData.workshopBooking.workshopId) return false
      }

      if (state.purchaseData.productType === ProductType.PointProduct) {
        if (!state.purchaseData.pointProductId) return false
      }

      if (state.purchaseData.productType === ProductType.SubscriptionProduct) {
        if (!state.purchaseData.subscriptionProductId) return false
      }

      return true
    },
  },

  actions: {
    async loadPayment() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('loadPayment v2', true)

      this.payIsInitialized = false

      const payStripeStore = usePayV2StripeStore()
      await payStripeStore.initStripe()

      this.payIsInitialized = true
      
      payLoadingStore.set('loadPayment v2', false)
    },

    async initPayment(data: InitPaymentData) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initPayment v2', true, {
        source: data.source,
        purchaseData: data.purchaseData,
        doneFunction: data.doneFunction
      })

      if (!this.payIsInitialized) await this.loadPayment()

      if (this.isInitialized) await this.payReset()

      const payPriceStore = usePayPriceStore()

      this.purchaseData = data.purchaseData
      this.doneFunction = data.doneFunction

      // If we include additional services in booking - update price store to include additional services
      if(this.purchaseData.resourceBooking?.additionalServicesIds) {
        await payPriceStore.updatePriceAdditionalServices(this.purchaseData.resourceBooking.additionalServicesIds)
      }

      if (this.purchaseData.subscriptionProductId) {
        const paySubscriptionProductGiftCard = usePaySubscriptionProductGiftCardStore()
        paySubscriptionProductGiftCard.selectedSubscriptionProductId = this.purchaseData.subscriptionProductId
      }
      
      try {
        await payPriceStore.initCalcuatePrice('initPayment', false)
        
        this.isInitialized = true
      } catch (error) {
        console.log('initPayment - error', error)
      }

      payLoadingStore.set('initPayment v2', false)
    },

    async initStartPayment(source: string) {
      if (!this.readyForPayment) {
        throw new Error(i18n.t('common.staticTemp.pay.errorMessageNotReadyInit') as string)
      }
      
      const payProductReservationStore = usePayProductReservationStore()
      const payPaymentMethodStore = usePayV2PaymentMethodStore()

      const selectedPaymentType = payPaymentMethodStore.selectedPaymentType
      console.log('Pay store v2', selectedPaymentType)

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initStartPayment v2', true, {
        source
      })

      this.startPaymentIsInitialized = false

      try {
        await payProductReservationStore.reserveProduct()
        this.startPaymentIsInitialized = true
        payLoadingStore.set('initStartPayment v2', false)
      } catch (error) {
        const errorMessage = getErrorMessage(error)

        console.log('initStartPayment - error', errorMessage)

        const toastMessage = errorMessage.includes(
            'Invalid booking. Unable to create resource booking reservation.'
          ) ? i18n.t('common.staticTemp.pay.errorMessageUnableToReserveCourt').toString()
            : i18n.t('common.error').toString();

        VueApp.$bvToast.toast(toastMessage, {
          title: i18n.t('common.error') as string,
          variant: 'warning',
        })

        await this.payReset()
        payLoadingStore.set('initStartPayment v2', false)
        throw new Error(errorMessage)
      }
    },

    async startPaymentV2() {
      if (!this.readyForPayment) throw new Error(i18n.t('common.staticTemp.pay.errorMessageNotReady') as string)
      if (this.paymentIsStarted) throw new Error(i18n.t('common.staticTemp.pay.errorMessagePayStarted') as string)
      
      // Use v2 stores
      const payStripeStore = usePayV2StripeStore()
      const payPaymentMethodStore = usePayV2PaymentMethodStore()

      // Use existing stores
      const payLoadingStore = usePayLoadingStore()
      const payPaymentTokenStore = usePayPaymentTokenStore()

      if (!this.isInitialized) throw new Error(i18n.t('common.staticTemp.pay.errorMessageFalseInitialized') as string) 
      
      try {
        payLoadingStore.set('startPayment v2', true)
        this.paymentIsStarted = true

        await payPaymentTokenStore.createPaymentToken({ useNewPaymentApi: true })

        const selectedPaymentType = payPaymentMethodStore.selectedPaymentType

        if (selectedPaymentType === PaymentTokenPaymentType.Stripe) {
          await payStripeStore.initStripePayment()
        }
        else if (selectedPaymentType === PaymentTokenPaymentType.Free) {}
        else if (selectedPaymentType === PaymentTokenPaymentType.Points) {}
        else if (selectedPaymentType === PaymentTokenPaymentType.UserProductSubscription) {}
        else throw new Error(i18n.t('common.staticTemp.pay.errorMessageDontKnow') as string) 
      } catch (error) {
        console.log('startPayment - error', error)
        payLoadingStore.set('startPayment v2', false) 
        this.payError()
      }

      payLoadingStore.set('startPayment v2', false) 
    },

    async payCancel() {
      return this.payReset()
    },

    browserUnload() {
      this.payReset()
      return 'You where about to pay. The payment has now been canceled.'
    },


    async payReset() {
      if (!this.isInitialized) return
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('payReset v2', true)

      this.isInitialized = false

      const payPaymentToken = usePayPaymentTokenStore()
      const payPointsStore = usePayV2PointsStore()
      const payUserProductSubscriptionStore = usePayUserProductSubscriptionPaymentStore()
      const payStripeStore = usePayV2StripeStore()
      const payProductReservationStore = usePayProductReservationStore()
      const payPriceStore = usePayPriceStore()
      const payPaymentMethodStore = usePayV2PaymentMethodStore()
      const payCouponStore = usePayCouponStore()
      const paySubscriptionProductGiftCard = usePaySubscriptionProductGiftCardStore()

      payPointsStore.reset()
      payUserProductSubscriptionStore.resetUserProductSubscriptionPayment()
      payPriceStore.resetPrice()
      payPaymentMethodStore.reset()
      payCouponStore.resetCoupon()
      paySubscriptionProductGiftCard.resetGiftCard()
      payStripeStore.showStripeWebPaymentElement = false
      
      await payPaymentToken.resetPaymentToken()
      await payProductReservationStore.cancelReservedProduct()

      this.purchaseData = null
      this.startPaymentIsInitialized = false
      this.paymentIsStarted = false
      this.doneFunction = null

      this.paymentIsSuccessful = false
      this.paymentFailed = false

      payLoadingStore.set('payReset v2', false)
    },

    async checkAdditionalServiceCapacity(additionalServiceId: string): Promise<Boolean> {
      let capacityReached = false

      if (this.purchaseData && this.purchaseData.resourceBooking) {
        const result = await apolloClient.mutate<GetAdditionalServiceReservationMutation, GetAdditionalServiceReservationMutationVariables>({
          mutation: GetAdditionalServiceReservation,
          variables: { 
              additionalServiceId: additionalServiceId,
              resourceBookingStartDate: this.purchaseData.resourceBooking?.startDate.toLocaleString(),
              resourceBookingEndDate: this.purchaseData.resourceBooking?.endDate.toLocaleString(),
          }
        })
        capacityReached = !!result.data?.additionalService
      } 
      return capacityReached
    },

    async paySuccess() {
      if (!this.doneFunction) throw new Error(i18n.t('common.staticTemp.pay.errorMessageDoneFunction') as string)
      this.paymentIsSuccessful = true

      const payProductReservationStore = usePayProductReservationStore()

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('paySuccess v2', true)

      const reservedProductData = payProductReservationStore.reservedProductData!

      payProductReservationStore.reservationIsUsed()

      await this.doneFunction(reservedProductData)
      await this.payReset()

      payLoadingStore.set('paySuccess v2', false)
    },

    async payError(failedReason?: PaymentTokenFaildReason) {
      this.paymentFailed = true
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('payError v2', true, {
        failedReason
      })

      await this.initPayment({
        source: 'payError v2',
        doneFunction: this.doneFunction!,
        purchaseData: this.purchaseData!
      })

      payLoadingStore.set('payError v2', false)
    },

    startLoadingText() {
      this.loadingText = i18n.t('components.app.pay.index.loadingText.one') as string
      setTimeout(() => {
        this.loadingText = i18n.t('components.app.pay.index.loadingText.two') as string
      }, 1000)
      setTimeout(() => {
        this.loadingText = i18n.t('components.app.pay.index.loadingText.three') as string
      }, 2000)
    }
  }
})
