export default {
  changeLocation: 'Change location',
  defaultTitle: 'Choose your location',

  listType: 'List',
  mapType: 'Map',

  showLocationsOutsideDefaultLocation: 'Show locations outside of',
  locations: 'locations',

  backToAll: 'Back to all',

  chooseLocation: 'Choose location',
  cancel: 'Keep current'
}