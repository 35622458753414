import { GetPointAccountCanUseRestPointsForPayment, GetPointAccountCanUseRestPointsForPaymentQuery, GetPointAccountCanUseRestPointsForPaymentQueryVariables } from '@/graphql'
import { apolloClient } from '@/plugins/apollo'
import { defineStore } from 'pinia'
import { usePayLoadingStore } from './loading'
import { usePayPaymentMethodStore } from './paymentMethod'
import { usePayPriceStore } from './price'
import { i18n } from '@/locales/setupI18n'

interface PayRestPointsState {
  useRestPointsIsDisabled: boolean
  canDoRestPointPayment: boolean | null
  useRestPoints: boolean | null
  useRestPointsData: {
    pointAccountId: string
    pointsAmount: number
  } | null
}

export const usePayRestPointsStore = defineStore({
  id: 'PayRestPoints',

  state: (): PayRestPointsState => ({
    useRestPointsIsDisabled: false,
    canDoRestPointPayment: null,
    useRestPoints: null,
    useRestPointsData: null
  }),

  getters: {
    useRestPointsPointAccountId: state => {
      if (state.useRestPoints && state.canDoRestPointPayment) return state.useRestPointsData?.pointAccountId
      return undefined
    }
  },

  actions: {
    async setUseRestPoints(newState: boolean, source?: string) {
      const initState = this.useRestPoints
      const hasChanged = initState !== newState
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('setUseRestPoints', true, {
        source,
        newState,
        initState,
        hasChanged
      })

      if (!this.canDoRestPointPayment) {
        this.useRestPoints = false
      } else {
        this.useRestPoints = newState
      } 

      if (hasChanged) {
        const payPriceStore = usePayPriceStore()
        await payPriceStore.initCalcuatePrice('setUseRestPoints')
      }

      payLoadingStore.set('setUseRestPoints', false, {
        hasChanged
      })

      return hasChanged
    },

    async getCanDoRestPointsPayment(source: string) {
      const initCanDoRestPointPayment = this.canDoRestPointPayment

      const payPaymentMethodStore = usePayPaymentMethodStore()
      const payPriceStore = usePayPriceStore()

      if (!payPriceStore.priceData) {
        throw new Error(i18n.t('common.staticTemp.restPayment.errorMessagePriceData') as string)
      }

      if (!payPaymentMethodStore.selectedPaymentMethod) {
        throw new Error(i18n.t('common.staticTemp.restPayment.errorMessageSelPayMethod') as string)
      }

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('getCanDoRestPointsPayment', true, {
        source,
        initCanDoRestPointPayment,
      })

      if (this.useRestPointsIsDisabled) {
        payLoadingStore.set('getCanDoRestPointsPayment', false, {
          useRestPointsIsDisabled: this.useRestPointsIsDisabled
        })
        return
      }

      const { data } = await apolloClient.query<GetPointAccountCanUseRestPointsForPaymentQuery, GetPointAccountCanUseRestPointsForPaymentQueryVariables>({
        query: GetPointAccountCanUseRestPointsForPayment,
        variables: {
          product: payPriceStore.priceData.productId,
          paymentAmount: payPriceStore.priceData.amountWithoutRestPoints,
          paymentMethod: payPaymentMethodStore.selectedPaymentMethod
        }
      })

      this.canDoRestPointPayment = data.pointAccountCanUseRestPointsForPayment.canDo
      this.useRestPointsData = this.canDoRestPointPayment ? {
        pointAccountId: data.pointAccountCanUseRestPointsForPayment.pointAccountId!,
        pointsAmount: data.pointAccountCanUseRestPointsForPayment.pointsAmount!
      } : null


      let hasChanged = true
      if (this.canDoRestPointPayment && initCanDoRestPointPayment && this.useRestPoints === false) {
        hasChanged = false
      }
      
      if (this.canDoRestPointPayment === false && initCanDoRestPointPayment === null) {
        hasChanged = false
      }
      
      if (hasChanged) {
        await this.setUseRestPoints(this.canDoRestPointPayment, 'getCanDoRestPointsPayment')
      }

      payLoadingStore.set('getCanDoRestPointsPayment', false, {
        initCanDoRestPointPayment,
        canDoRestPointPayment: this.canDoRestPointPayment,
        useRestPointsData: this.useRestPointsData,
        hasChanged
      })

      return hasChanged
    },

    resetRestPoints(disableRestPoints = false) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('resetRestPoints', true)
      this.useRestPoints = null
      this.useRestPointsData = null
      this.canDoRestPointPayment = null

      if (disableRestPoints) this.useRestPointsIsDisabled = true

      payLoadingStore.set('resetRestPoints', false)
    }
  }
})
