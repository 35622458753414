export default {
  title: 'Order confirmation',
  description: 'Your order has been placed and will be delivered soon',
  button: 'Go back to shop',
  orderDetails: {
    shipping: 'Shipping',
    orderDate: 'Order date',
    orderId: 'Order number',
    discount: 'Discount'
  }
}