export default {
  title: 'Reset password',
  description: 'Enter a new password below and then repeat it.',

  missingToken: 'A token is missing...',

  changePasswordButton: 'Change password',
  changePasswordLoadingButton: 'Changing password...',

  done: {
    text: 'Your password has been changed and you can now sign in with it.',
    title: 'Success'
  }
}