export default {
  cancel: 'Cancel purchase',

  productInfo: {
    buy: 'You are about to buy',
    points: 'points',
    price: 'at a price of'
  },

  willBeOnAccount: 'Your points will be added to your points account at',

  done: {
    title: 'Your purchase has been approved',
    text: 'You can now use your points to book courts for.',
    backToStart: 'Back to start'
  },

  error: {
    title: 'A error happened...',
    cancel: 'Cancel'
  }
}