export default {
	title: "Statistics",
	viewFor: {
		company: "Company",
		location: "Location",
		locationBetaText: "This is in betal. All numbers will not update acutely.",
	},
	viewStatistics: "Show statistics",
	hideStatistics: "Hide statistics",

	users: {
		title: "Users",
		newUsers: "New users",
		usersCountDefaultLocation: "Users count with default location",
	},

	subscriptions: {
		title: "Subscriptions",
		subscriptionsStatusCount: "Total active subscriptions",
		subscriptionsIncomeSumMonthly: "Monthly revenue",
		subscriptionsIncomeSumYearly: "Yearly revenue",
		subscriptionsActivityCount: "New/canceled subscriptions",
		inStatus: {
			title: "Memberships in status",
			showList: "Show list",
			status: {
				activeUser: "Active - User",
				activeAdmin: "Active - Admin",
				soonActive: "Soon active",
				soonCanceled: "Soon canceled",
			},
		},
	},

	revenue: {
		title: "Revenue",
		paymentsSum: "Revenue",
		refundsSum: "Refunds",
		pointUsageSum: "Point usage",
	},

	bookings: {
		title: "Bookings",
		newResourceBookingsCount: "New bookings",
		possiblePercentageResourceBooked: "Utilization",
		resourceBookingsHours: "Booking hours",
		resourceBookingsHoursFuture: "Future hours",
		resourceBookingsAvgDurationMinutes: "Avg. booking duration",
		newWorkshopBookingsCount: "Event bookings",
		resourceBookingsAvgBetweenCreatedAndStart: "Avg. days from created to start",
		activeMembersBookings: "Active members bookings",
		activeMembersHours: "Active members hours",
	},

	charts: {
		title: "Charts",
		possiblePercentageResourceBookedChart: "Utilization",
		resourceBookingsCreatedChart: "Booking creation",
		paymentMethod: "Payment method",
		avgAge: {
			title: "User age",
			usersAgeAvg: "Avg. age",
			years: "years",
		},
		gender: "Gender",
		userMap: "User map",
		genders: {
			male: "Men",
			female: "Women",
			other: "Other",
		},
	},

	dateRangeOptions: {
		today: "Today",
		yesterday: "Yesterday",
		last3Days: "Last 3 days",
		last7Days: "Last 7 days",
		monthToDate: "Month to date",
	},

	hourAbbreviation: "h",
};
