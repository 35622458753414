import Vue from "vue";
import "./assets/css/tailwind.css";
import pinaStore from "@/store/index";

import App from "@/App.vue";
import router from "@/router";
import { PiniaVuePlugin } from "pinia";
// @ts-ignore
import moment from "moment-timezone";
import VueApollo from "vue-apollo";
import { apolloClient, apolloProvider } from "@/plugins/apollo";
import "iframe-resizer/js/iframeResizer.contentWindow.min.js";
import { FacebookLogin } from "@capacitor-community/facebook-login";
//@ts-ignore
import x5GMaps from "x5-gmaps";
import BootstrapVue from "bootstrap-vue";
import VueGtag from "vue-gtag";
import { App as CapacitorApp } from "@capacitor/app";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import * as validationRules from "vee-validate/dist/rules";
import VueMoment from "vue-moment";
import "@/assets/fonts/feather/feather.css";
import "animate.css/animate.min.css";
import "moment/locale/da";
import { i18n, initLocale } from "./locales/setupI18n";
import { SplashScreen } from "@capacitor/splash-screen";
import { Stripe } from "@capacitor-community/stripe";
import { Preferences } from "@capacitor/preferences";
import { useUserStore } from "@/store/modules/user";
import * as Sentry from "@sentry/vue";
import ConfirmModal from "@/components/shared/ConfirmModal.vue";

Vue.use(x5GMaps, {
	key: process.env.GMAPS_API_KEY,
	map_ids: "4ea0cb9c12878803",
	libraries: ["visualization"],
});

CapacitorApp.addListener("appUrlOpen", async (event): Promise<void> => {
	let slug = "";

	// Check if the URL is a non http link and assume its a deep link
	if (!event.url.startsWith("http")) {
		slug = event.url.split("://").reverse()[0];
	} else {
		// For normal (non-deeplinks) URLs, attempt to extract the path after '.app'
		slug = event.url.split(".app").reverse()[0];
	}

	if (slug) {
		// Fix for BO15-721. Without this the router before guard will fail the navigation to universal links due to attempting to navigating to multiple routes at once.
		// TODO: Given more time this should be fixed in the router / before guard and the timeout should be removed.
		setTimeout(async () => {
			await router.push({ path: slug }).catch((err) => {
				console.error("Failed to navigate to slug:", err);
			});
		}, 1000);
		return;
	}

	if (/stripe-callback((\?|#).*)?$/gm.test(slug)) {
		await Stripe.handleURLCallback!({ url: event.url });
		return;
	}
});

CapacitorApp.addListener("appStateChange", async (state) => {
	if (state.isActive) {
		await apolloClient.resetStore();
	}
});

const isProduction = import.meta.env.PROD;

let sentryEventCount = 0;
const MAX_SENTRY_EVENTS_PER_MINUTE = 10; // Adjust as needed

Sentry.init({
	Vue,
	dsn: "https://838b7d46a75e364753a70e3e20d3605c@o4505765754109952.ingest.us.sentry.io/4505765773312000",
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [
				"localhost",
				"/^https://api.bookli.app/",
				"/^https://api.ledap.com/",
				"/^https://api.test-ledap.com/",
			],
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],

	// Throttling mechanism for Sentry events
	beforeSend(event) {
		const now = new Date();
		if (sentryEventCount >= MAX_SENTRY_EVENTS_PER_MINUTE) {
			return null; // Discard the event
		}
		sentryEventCount++;
		setTimeout(() => {
			sentryEventCount = Math.max(0, sentryEventCount - 1); // Decrement count after a minute
		}, 60000);
		return event;
	},
	// Performance Monitoring
	tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0.1,
	// Session Replay
	replaysSessionSampleRate:
		import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate:
		import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	debug: !(import.meta.env.VITE_APP_ENV === "production"),
	environment: import.meta.env.VITE_APP_ENV,
});

Vue.use(VueApollo);
Vue.use(BootstrapVue);

if (isProduction) {
	Vue.use(VueGtag, {
		config: { id: import.meta.env.VITE_GTAG_ID },
	});
}
for (let rule in validationRules) {
	// @ts-ignore
	extend(rule, validationRules[rule]);
}
extend("password", {
	validate: (value, { other }: any) => value === other,
	message: "The password confirmation does not match.",
	params: [{ name: "other", isTarget: true }],
});

moment.tz.setDefault("Europe/Copenhagen");
Vue.use(VueMoment, {
	moment,
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('ConfirmModal', ConfirmModal);

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);

const app = new Vue({
	pinia: pinaStore,
	router,
	apolloProvider,
	i18n,
	async mounted() {
		await Preferences.migrate();
		await initLocale();
		await FacebookLogin.initialize({ appId: "151705393041405" });
		SplashScreen.hide();
	},
	render: (h) => h(App),
}).$mount("#app");

export default app;
