import { genMessage } from '../helper';
// import momentLocale from 'moment/dist/locale/eu';

const modules = import.meta.globEager('./en-GB/**/*.ts');
export default {
  message: {
    ...genMessage(modules, 'en-GB'),
  },
  // momentLocale,
  // momentLocaleName: 'eu',
};
