import { CustomRouteConfig } from './'
import { UserRole } from '@/graphql'

import index from '@/views/user/index.vue'
import home from '@/views/user/home.vue'
import calendar from '@/views/user/calendar.vue'
import createResourceBooking from '@/views/user/createResourceBooking/create.vue'
import shop from '@/views/user/shop.vue'
import account from '@/views/user/account.vue'

const routes: CustomRouteConfig[] = [
  {
    name: 'User',
    path: '/u',
    component: index, // () => import('@/views/user/index.vue'),
    meta: {
      requireAuth: true,
      requireRole: UserRole['User'],
      layout: 'App',
      title: ''
    },
    children: [

      {
        name: 'User.Home',
        path: 'home',
        component: home, // () => import('@/views/user/home.vue'),
        meta: {
          title: 'Startside'
        }
      },

      {
        name: 'User.Calendar',
        path: 'calendar',
        component: calendar, // () => import('@/views/user/calendar.vue'),
        meta: {
          title: 'Kalender'
        }
      },

      {
        name: 'User.ResourceBooking.Create',
        path: 'booking/create',
        component: createResourceBooking, // () => import('@/views/user/create-resource-booking.vue'),
        meta: {
          title: 'Opret booking'
        }
      },

      {
        name: 'User.ResourceBooking.Created',
        path: 'booking/created/:resourceBookingId',
        component: () => import('@/views/user/createResourceBooking/created.vue'),
        meta: {
          title: 'Booking oprettet'
        }
      },

      {
        name: 'User.ResourceBooking',
        path: 'resource-booking/:id',
        component: () => import('@/views/user/resourceBooking.vue'),
        meta: {
          title: 'Booking'
        }
      },

      {
        name: 'User.UserGroupAdmin',
        path: 'user-group/:id',
        component: () => import('@/views/user/userGroupAdmin.vue'),
        meta: {
          title: 'Brugergruppe'
        }
      },

      {
        name: 'User.Shop',
        path: 'shop',
        component: shop, // () => import('@/views/user/shop.vue'),
        meta: {
          title: 'Butik'
        }
      },

      {
        name: 'User.Workshops',
        path: 'workshops',
        component: () => import('@/views/user/workshops/workshops.vue'),
        meta: {
          title: 'Arrangementer'
        }
      },

      {
        name: 'User.Workshop',
        path: 'workshops/:id',
        component: () => import('@/views/user/workshops/workshop.vue'),
        meta: {
          title: 'Arrangement'
        }
      },

      {
        name: 'User.Account',
        path: 'account',
        component: account, // () => import('@/views/user/account.vue'),
        meta: {
          title: 'Konto'
        }
      },

      // * SHOP
      {
        name: 'User.Ecom.Home',
        path: 'ecom',
        component: () => import('@/views/user/ecom/home.vue'),
        meta: {
          title: 'Shop',
          requireEcomEnabled: true
        }
      },
      {
        name: 'User.Ecom.Checkout',
        path: 'ecom/checkout',
        component: () => import('@/views/user/ecom/checkout.vue'),
        meta: {
          title: 'Shop',
          requireEcomEnabled: true
        }
      },
      {
        name: 'User.Ecom.orderConfirmation',
        path: 'ecom/confirmation/:checkoutId',
        component: () => import('@/views/user/ecom/orderConfirmation.vue'),
        meta: {
          title: 'Shop',
          requireEcomEnabled: true
        }
      },
      {
        name: 'User.Ecom.Product',
        path: 'ecom/product/:id',
        component: () => import('@/views/user/ecom/product.vue'),
        meta: {
          title: 'Shop',
          requireEcomEnabled: true
          // hideNavigation: true
        }
      },
    ]
  }
]

export default routes
