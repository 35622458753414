import { defineStore } from 'pinia'
import { PaymentIntentResult, loadStripe } from '@stripe/stripe-js'
import { usePayPaymentTokenStore } from './paymentToken'
import { Capacitor } from '@capacitor/core'
import { PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe'
import { i18n } from '@/locales/setupI18n'

export const usePayMobilePayStore = defineStore('PayMobilePay', () => {
  async function startPayment() {
    const publishableKey = process.env.STRIPE_KEY
    const paymentTokenStore = usePayPaymentTokenStore()
    let paymentIntentResult: PaymentIntentResult | null = null

    if (!paymentTokenStore.paymentToken) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessagePaymentToken') as string) 
    if (!paymentTokenStore.paymentToken.stripeClientSecret) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageClientSecret') as string) 

    const stripe = await loadStripe(publishableKey!)
      
    if (!stripe) throw new Error('MobilePay: Error loading stripe')

    try {
      paymentIntentResult = await stripe.retrievePaymentIntent(paymentTokenStore.paymentToken.stripeClientSecret)
    } catch (error) {
      console.error('MobilePay: Unable to fetch Payment Intent', error)
    }

    const platform = Capacitor.getPlatform()

    if (platform === 'web') {
      const mobilePayAuthUrl = paymentIntentResult?.paymentIntent?.next_action?.redirect_to_url?.url
      
      if (!mobilePayAuthUrl) throw new Error('No MobilePay Auth URL') // TODO: Replace with translation
      
      window.open(mobilePayAuthUrl, '_blank')
    }
    else if (platform === 'android' || platform === 'ios') {

      const returnUrl = paymentIntentResult?.paymentIntent?.next_action?.redirect_to_url?.return_url

      if (!paymentTokenStore.paymentToken.user.stripeCustomerId) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageCustomerId') as string) 
      if (!paymentTokenStore.paymentToken.user.stripeEphemeralKey) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageEphemeralKey') as string) 
      if (!returnUrl) throw new Error('No return url') // TODO: Replace with translation

      await Stripe.createPaymentSheet({
        paymentIntentClientSecret: paymentTokenStore.paymentToken.stripeClientSecret,
        customerId: paymentTokenStore.paymentToken.user.stripeCustomerId,
        customerEphemeralKeySecret: paymentTokenStore.paymentToken.user.stripeEphemeralKey,
        merchantDisplayName: 'LeDap',
        returnURL: returnUrl
      })

      const result = await Stripe.presentPaymentSheet()
    
      if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
        // In case the payment token subscriber fails - refetch the payment token
        paymentTokenStore.refetchPaymentToken()
      } else {
        throw new Error(`${i18n.t('common.staticTemp.stripeTs.errorMessagePaymentSheet')} ${result.paymentResult}`)
      }
    }
  }

  return {
    startPayment
  }
})