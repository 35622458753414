export default {
  subTitle: "Settings",
  pageTitle: "Locations",
  description: "Manage your company's location. Here you can upload a location overview, logo and a banner that's attached to booking confirmations sent from the location. For best use of the system, create one location per company. ",

  createNewLocation: "Create new location",

  updateLocationButton: "Update location",

  updateLocationModal: {
    title: "Update location",

    imageMailLogo: "Picture at the bottom of mail",
    logoTitle: "Logo",
    overviewImage: "Overview image",
    updateLocationButton: "Update location",
    updatedToast: "Location is updated",
    activateLocation: "Activate location for users",
    active: "Location is active",
    notActive: "Location is not active",
    notActiveWarning: "Caution: Setting this location to inactive will remove it from the list of available locations, preventing users from choosing it. Please ensure this action is necessary and won't impact essential services.",
  },

  createLocationModal: {
    title: "Create location",
    description: "You are now creating a new location. Fill in the fields below.",

    createLocationButton: "Create location",
    createLocationLoadingButton: "Creating location...",
    createdToast: "The location is created"
  }
}