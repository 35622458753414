export default {
  resourceBooking: {
    name: 'Court booking',
    hover: 'Show bookning'
  },
  pointTransaction: {
    name: 'Point purchase',
    hover: 'Show point account'
  },
  workshopBooking: {
    name: 'Event booking',
    hover: 'Show event'
  },
  userProductSubscription: {
    name: 'Subscripton',
    hover: 'Show subscription'
  },
  userProductSubscriptionSignUpFee: {
    name: 'Subscription creation fee',
    hover: 'Show subscription'
  },
  conectedPayment: {
    name: 'Connected payment',
    hover: 'Show master payment'
  }
}