export default {
  createModal: {
    title: 'Create proforma invoice',
    getInvoice: 'Get invoice'
  },
  
  invoiceLinkModal: {
    title: 'Invoice link',
    showInvoice: 'Show invoice'
  }
}