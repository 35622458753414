export default {
    back: 'Back to overview',
    missingId: 'Missing product id',

    badges: {
        inStock: 'In Stock ',
        freeShipping: 'Free Shipping ',
        outOfStock: 'Out of Stock '
    },

    sections: {
        sizes: 'Sizes',
        description: 'Description',
        specification: 'Specification',
        stock: 'Stock'
    },

    button: {
        selectSize: 'Select size',
        addToBasket: 'Add to basket',
        outOfStock: 'Out of Stock'
    },

    addedToShoppingBag: {
        success: 'Added to your basket',
        size: 'Size:',
        error: {
            title: 'Oops - selected item could not be added to basket',
            only: 'Only',
            leftInStock: 'left in stock'
        },
        goToBasket: 'Go to basket',
        backToShop: 'Keep shopping'
    }
}