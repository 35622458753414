export default {
    firstNameLabel: "First name",
    firstNamePlaceHolder: "Enter your first name",
    lastNameLabel: "Last name",
    lastNamePlaceHolder: "Enter you last name",
    countryLabel: "Country",
    phoneLabel: "Phone number",
    phonePlaceHolder: "Enter your phone number",
    emailLabel: "Email",
    emailPlaceHolder: "Enter email",
    streetNamePlaceHolder: "Enter street name",

    updateButton: "Update user info",
    updateButtonLoading: "Updating...",

    success: "Your profile has been updated"
}