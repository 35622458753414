import { CustomRouteConfig } from './'
import { UserRole } from '@/graphql'

const routes: CustomRouteConfig[] = [
  {
    name: 'Admin',
    path: '/a',
    component: () => import('@/views/admin/index.vue'),
    meta: {
      requireAuth: true,
      requireRole: UserRole['Admin'],
      layout: 'Admin',
      title: ''
    },
    children: [

      /************
      * Root
      ************/
      {
        name: 'Admin.Dashboard',
        path: 'dashboard',
        component: () => import('@/views/admin/dashboard.vue'),
        meta: {
          title: 'Startside'
        }
      },

      {
        name: 'Admin.Settings',
        path: 'settings',
        component: () => import('@/views/admin/settings/settings.vue'),
        meta: {
          title: 'Indstillinger'
        }
      },

      /************
      * Users
      ************/
      {
        name: 'Admin.Users',
        path: 'users',
        component: () => import('@/views/admin/users/users.vue'),
        meta: {
          title: 'Brugere'
        }
      },
      {
        name: 'Admin.User',
        path: 'users/:id',
        component: () => import('@/views/admin/users/user.vue'),
        meta: {
          title: 'Bruger'
        }
      },

      /************
      * Locations
      ************/
      {
        name: 'Admin.Locations',
        path: 'locations',
        component: () => import('@/views/admin/locations/locations.vue'),
        meta: {
          title: 'Lokationer'
        }
      },
      {
        name: 'Admin.Location',
        path: 'locations/:id',
        component: () => import('@/views/admin/locations/location.vue'),
        meta: {
          title: 'Lokation'
        }
      },

      /************
      * Companies
      ************/
      {
        name: 'Admin.Companies',
        path: 'companies',
        component: () => import('@/views/admin/companies/companies.vue')

      },
      {
        name: 'Admin.Company',
        path: 'companies/:id',
        component: () => import('@/views/admin/companies/company.vue'),
        meta: {
          title: 'Firma'
        }
      },
    ]
  }
]

export default routes
