<template lang="pug">
b-overlay(
  :show="$apollo.queries.resourceBooking.loading"
  variant="transperant"
  spinner-variant="primary")
  b-link(
    v-if="resourceBooking"
    @click="$router.push({ name: 'User.ResourceBooking', params: { id: resourceBooking.id } })")
    b-row
      b-col.d-flex.align-items-center.justify-content-center.flex-column.border-right.pl-4(
        v-if="timeType === 'timeTo' || timeType === 'startTime'"
        cols="auto")
        span.text-center(v-if="timeType === 'timeTo'")
          small.text-muted.m-0 {{ $t('components.app.resourceBookingCard.in') }}
          p.m-0.text-body.text-center {{ to }}
        span.text-center(v-if="timeType === 'startTime'")
          small.text-muted.m-0 {{ $t('components.app.resourceBookingCard.time') }}
          p.m-0.text-body.text-center {{ resourceBooking.startDate | moment('HH:mm') }}

      b-col.py-2.px-3
        span.text-body(v-if="resourceBooking.additionalService") {{ $t('components.app.resourceBookingCard.title') }}
          span.additional-service-text  - {{ resourceBooking.additionalService.name }}
        span.text-body(v-else) {{ $t('components.app.resourceBookingCard.title') }}
        b-row(align-v="center")
          b-col(cols="auto").pr-0.mt-1
            i.fe.fe-flag.icon-sm.mr-2
          b-col.pl-1
            small {{ resourceBooking.resource.shortName }}

        b-row(align-v="center")
          b-col(cols="auto").pr-0.mt-1
            i.fe.fe-clock.icon-sm.mr-2
          b-col.pl-1
            small {{ resourceBooking.startDate | moment('HH:mm') }} - {{ resourceBooking.endDate | moment('HH:mm') }}

        b-row(align-v="center")
          b-col(cols="auto").pr-0.mt-1
            i.fe.fe-map-pin.icon-sm.mr-2
          b-col.pl-1
            small.mt-1.text-body {{ resourceBooking.resource.location.name }}, {{ resourceBooking.resource.location.streetName }} - {{ resourceBooking.resource.location.zipCode }} {{ resourceBooking.resource.location.city }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetResourceBookingCard, GetResourceBookingCardQuery, GetResourceBookingCardQueryVariables } from '@/graphql'

@Component({
  components: {

  },
  apollo: {
    resourceBooking: {
      query: GetResourceBookingCard,
      variables(): GetResourceBookingCardQueryVariables {
        return {
          id: this.resourceBookingId
        }
      }
    },
  }
})
export default class CompanyUsersUserView extends Vue {
  @Prop({ required: true }) readonly resourceBookingId!: string
  @Prop({ required: false, default: 'timeTo' }) readonly timeType!: 'timeTo' | 'startTime'

  resourceBooking: GetResourceBookingCardQuery['resourceBooking'] | null = null

  get bookingNextToMinutes() {
    if (!this.resourceBooking) return 0
    return this.$moment(this.resourceBooking.startDate as Date).diff(this.$moment(), 'minutes')
  }

  get toDays() {
    return this.bookingNextToMinutes! / 1440
  }

  get toMinutes() {
    return this.bookingNextToMinutes!
  }

  get toHours() {
    return this.bookingNextToMinutes! / 60
  }

  get to() {
    if (this.toMinutes > 1 * 60 * 24) {
      return `${Math.floor(this.toDays)} ${this.$t('components.app.resourceBookingCard.toStartDate.days') as string}`
    } else if (this.toMinutes > 60) {
      const r = Math.floor(this.toHours)
      const ending = r > 1 ? this.$t('components.app.resourceBookingCard.toStartDate.hours') as string : this.$t('components.app.resourceBookingCard.toStartDate.hour') as string
      return `~${r} ${ending}`
    } else {
      return `${Math.floor(this.toMinutes)} ${this.$t('components.app.resourceBookingCard.toStartDate.min') as string}`
    }
  }
}
</script>

<style lang="sass" scoped>

.additional-service-text
  color: #FF9D97 !important

</style>
