export default {
  goBack: 'Go back',
  point: 'Points',

  pointUsage: {
    title: 'Points',
    fixedPeriod: 'Show consumption for specific period',
    points: 'points'
  }

}