
export default {
  title: 'Agreement Documents',
  description: 'Add and update agreements for Newsletter subscriptions, Privacy Policy, Terms of Trade, and Membership Terms. Make sure that customers have access to the most updated terms and conditions to maintain compliance and enhance transparency in customer interactions.',
  createAgreement: 'Add new document',

  agreementsTable: {
    name: 'Name',
    createdAt: 'Created on',
    count: 'Accepted',
    documentUrl: 'Document',

    userAccptedAgreementsCountHover: 'Users who have accepted',
    userAgreementsCountHover: 'Show document',
    usersTotalHover: 'Users total',

    update: 'Update'
  },

  createAgreementModal: {
    title: 'Create new document',
    description: 'You are now creating a new document. Fill in the fields below.',

    document: {
      label: 'Document'
    },
    requiredAccept: 'Acceptance required',
    type: {
      label: 'Type'
    },
    showWhen: {
      label: 'Show When'
    },

    createAgreementButton: 'Create document',
    createAgreementLoadingButton: 'Creates document...',

    successToast: 'Document created'
  },

  updateAgreementModal: {
    title: 'Update Document',
    description: 'You are updating agreement terms that have been accepted by your users. In case of changes that are outside the scope of the first agreement, users must be asked to re-consent to the new terms.',
    document: {
      label: 'Document'
    },
    createNewLastUpdated: 'Require re-consent',
    updateAgreementButton: 'Update document',
    updateAgreementLoadingButton: 'Update document...',
    successToast: 'The agreement is updated'
  },

  agreementTypeOptions: {
    newsletter: 'Newsletter',
    termsOfTrade: 'Terms of trade',
    privacyPolicy: 'Privacy Policy',
    userSubscription: 'Membership Terms'
  },

  agreementShowWhenOptions: {
    userSignUpCompany: 'When user signs up',
    createUserProductSubscription: 'When user creates membership'
  }
}