export default {
  order: 'Order',
  noOrderHistory: 'You have no order history',

  deliveryInfo: {
    open: 'Open',
    completed: 'Delivered',
    inProgress: 'In progress',
    cancelled: 'Cancelled',
    error: 'error',
  },

  address: {
    deliveryAddress: 'Delivery Address',
    billingAddress: 'Billing Address'
  },

  price: {
    total: 'Total',
    price: 'Price'
  }
}