import { defineStore } from 'pinia'
import { usePayPaymentTokenStore } from '../pay/paymentToken'
import { usePayLoadingStore } from '../pay/loading'
//@ts-ignore
import { Capacitor } from '@capacitor/core'
import { usePayV2Store } from './payV2'
import { PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe'
import { PaymentTokenFaildReason, ProductType } from '@/graphql'
import { getStripePaymentElementsCompatibility } from '@/utils/getStripePaymentElementsCompatibility'
import { i18n } from '@/locales/setupI18n'

const ledapDisplayName = 'LeDap'

interface PayStripeState {
  stripeIsInitialized: boolean
  canUseApplePay: boolean
  canUseGooglePay: boolean
  showStripeWebPaymentElement: boolean
  isStripePaymentElementsCompatible: boolean
  resetSubscriptionModalTrigger: number
}

async function boolifyPromise(promise: Promise<any>) {
  return promise
    .then(() => true)
    .catch(() => false)
}

export const usePayV2StripeStore = defineStore({
  id: 'PayStripeV2',

  state: (): PayStripeState => ({
    stripeIsInitialized: false,
    canUseApplePay: false,
    canUseGooglePay: false,
    isStripePaymentElementsCompatible: false,
    showStripeWebPaymentElement: false,
    resetSubscriptionModalTrigger: 0
  }),

  getters: {

  },

  actions: {
    async initStripe() {
      const stripeKey = process.env.STRIPE_KEY ?? ''
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initStripe v2', true, {
        stripeIsInitialized: this.stripeIsInitialized
      })

      this.stripeIsInitialized = false
      
      await Stripe.initialize({
        publishableKey: stripeKey
      })
      this.canUseApplePay = await boolifyPromise(Stripe.isApplePayAvailable())
      this.canUseGooglePay = await boolifyPromise(Stripe.isGooglePayAvailable())

      this.stripeIsInitialized = true
      this.isStripePaymentElementsCompatible = await getStripePaymentElementsCompatibility()

      payLoadingStore.set('initStripe v2', false, {
        canUseApplePay: this.canUseApplePay,
        canUseGooglePay: this.canUseGooglePay,
        isStripePaymentElementsCompatible: this.isStripePaymentElementsCompatible
      })
    },

    async initStripePayment() {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initStripePayment v2', true)
      const platform = Capacitor.getPlatform()
      if (platform === 'web') {
        this.showStripeWebPaymentElement = true
      } else {
        this.initAppPayment()
      }
      payLoadingStore.set('initStripePayment v2', false)
    },

    /**
     * Payment sheet - native
     */
    async initAppPayment() {
      const paymentTokenStore = usePayPaymentTokenStore()

      // NULL-CHECKING FOR REQUIRED PARAMETERS
      if (!paymentTokenStore.paymentToken) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessagePaymentToken') as string)
      if (!paymentTokenStore.paymentToken.stripeClientSecret) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageClientSecret') as string) 
      if (!paymentTokenStore.paymentToken.user.stripeCustomerId) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageCustomerId') as string) 
      if (!paymentTokenStore.paymentToken.user.stripeEphemeralKey) throw new Error(i18n.t('common.staticTemp.stripeTs.errorMessageEphemeralKey') as string) 

      await Stripe.createPaymentSheet({
        paymentIntentClientSecret: paymentTokenStore.paymentToken.stripeClientSecret,
        customerId: paymentTokenStore.paymentToken.user.stripeCustomerId,
        customerEphemeralKeySecret: paymentTokenStore.paymentToken.user.stripeEphemeralKey,
        merchantDisplayName: ledapDisplayName,
        returnURL: `${import.meta.env.VITE_PLATFORM_URL}/ext-payment-redirect`,
        enableApplePay: this.canUseApplePay,
        applePayMerchantId: this.canUseApplePay ? 'merchant.ledap.app' : undefined,
        countryCode: paymentTokenStore.paymentToken.country,
        enableGooglePay: this.canUseGooglePay
      })

      const { paymentResult } = await Stripe.presentPaymentSheet()

      if (paymentResult === PaymentSheetEventsEnum.Completed) {
        // In case the payment token subscriber fails - refetch the payment token
        paymentTokenStore.refetchPaymentToken()
      } else if (paymentResult === PaymentSheetEventsEnum.Canceled) {
        const payStore = usePayV2Store()
        if (payStore.purchaseData?.productType === ProductType.SubscriptionProduct) {
          this.resetSubscriptionModalTrigger++
        } else {
          await paymentTokenStore.resetPaymentToken(PaymentTokenFaildReason.Canceled, false)
          payStore.startPaymentIsInitialized = false
          payStore.paymentIsStarted = false
        }
      }
    },
  }
})
