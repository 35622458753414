<template>
  <div v-if="true">
    <b-modal
      @hidden="handleModalHidden"
      v-model="isOpen"
      title-tag="h4"
      centered
      hide-header
      hide-footer
      hide-header-close
      size="md"
    >
      <div>
        <span>{{
          $t(
            "pages.user.createResourceBooking.create.paymentModal.additionalServices.descriptionInfoRequired"
          )
        }}</span>
      </div>
      <b-form @submit.prevent="handleSubmit">
        <b-form-group
          v-for="(info, index) in additionalService.additionalInfo"
          class="field-label"
          :key="index"
          :label="
            $t(
              `pages.user.createResourceBooking.create.paymentModal.additionalServices.${info}`
            )
          "
        >
          <b-form-input
            v-model="inputValues[index]"
            placeholder="Fill in info here..."
          >
          </b-form-input>
        </b-form-group>

        <b-button
          :class="{ 'btn-enabled': isFormValid, 'btn-disabled': !isFormValid }"
          variant="primary"
          size="lg"
          type="submit"
          :disabled="!isFormValid"
          @click="handleSubmit"
          >Submit</b-button
        >
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { AdditionalServiceInfoType } from "@/graphql";
import Vue from "vue";

export default Vue.extend({
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    additionalService: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      localOpen: this.open,
      inputValues: Array(this.additionalService.additionalInfo.length).fill(""),
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      },
    },
    isFormValid() {
      return this.inputValues.every((value) => value);
    },
  },
  methods: {
    handleModalHidden() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$emit("additionalInfoAdditionalServices", this.inputValues);
      this.$emit("close");
      this.localOpen = false;
    },
  },
});
</script>

<style lang="scss">
.field-label {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
}

.btn-enabled {
  background-color: #000000;
  color: white;
}

.btn-disabled {
  background-color: #d3d3d3e0;
  color: white;
}
</style>
