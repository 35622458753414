export default {
  subTitle: 'Company',

  infoTab: {
    title: 'Informations',

    locationsTable: {
      title: 'Locations'
    }
  },

  bookingsTab: {
    title: 'Bookings'
  }
}