import { defineStore } from "pinia";
import { usePayLoadingStore } from "../pay/loading";
import { PaymentUserAllowedPaymentTypesV2Query } from "@/graphql";
import { usePayPriceStore } from "../pay/price";

interface PayV2PointsState {
    selectedPointAccount: PointAccount | null
    availablePointAccounts: PaymentUserAllowedPaymentTypesV2Query['paymentUserAllowedPaymentTypesV2']['pointAccounts'] 
    usePoints: boolean | null
    restPointAccountId: string | null
    usePointsInsteadOfDiscount: boolean
}

interface PointAccount {
    id: string;
    pointsAmount: number;
    removeDiscountToDoPayment?: boolean | null | undefined;
    enoughPointsForPayment?: boolean | null | undefined;
    userGroup?: {
        id: string;
        name: string
    } | null
    company?: {
        id: string;
        name: string
    } | null
    franchise?: {
        id: string;
        name: string
    } | null
}

export const usePayV2PointsStore = defineStore({
    id: 'PayV2Points',

    state: (): PayV2PointsState => ({
        selectedPointAccount: null,
        availablePointAccounts: [],
        usePoints: null,
        restPointAccountId: null,
        usePointsInsteadOfDiscount: false
    }),
    getters: {
        getDefaultPointAccount: (state): PointAccount | null => {
            const prevSelectedPointAccount = state.availablePointAccounts.find(x => x.id === state.selectedPointAccount?.id)
            if (prevSelectedPointAccount) {
                return prevSelectedPointAccount
            } else {
                const privatePointAccount = state.availablePointAccounts.find(x => x.userGroup === null)
                if (privatePointAccount && privatePointAccount.pointsAmount > 0) {
                    return privatePointAccount
                }
                
                const firstPointAccountWithPoints = state.availablePointAccounts.find(x => x.pointsAmount > 0)
                if (firstPointAccountWithPoints) {
                    return firstPointAccountWithPoints
                }

                return null
            }
        },
        availablePointAccountsIncludesOtherThanPrivate: (state): boolean => {
            return state.availablePointAccounts.some(x => !!x.userGroup)
        }
    },
    actions: {
        async initPoints(pointAccounts: PointAccount[]): Promise<void> {
            this.availablePointAccounts = pointAccounts
            const newDefaultPointAccount = this.getDefaultPointAccount
            if (newDefaultPointAccount) {
                // If points are allowed and has not been de-selected > use points
                if (!newDefaultPointAccount.removeDiscountToDoPayment && this.usePoints === null) {
                    this.usePoints = true
                // If points can not be combined with discount > do not use points
                } 
                this.setPointAccount(newDefaultPointAccount)
            }
        },
        async setPointAccount(pointAccount: PointAccount): Promise<void> {
            const payLoadingStore = usePayLoadingStore()
            payLoadingStore.set('setPointAccountId V2', true, {
                pointAccountId: pointAccount.id
            })
            this.selectedPointAccount = pointAccount
            if (this.selectedPointAccount.removeDiscountToDoPayment) {
                this.usePoints = false
            }
            await this.toggleUseRestPoint()
            payLoadingStore.set('setPointAccountId V2', false, {
                pointAccount: this.selectedPointAccount
            })
        },
        async toggleUseRestPoint(): Promise<void> {
            // Only toggle if change dvs
                // - restPointAccountId has gone from null to not null
                // - or restPointAccountId has changed id            
            const prevRestPointAccountId = this.restPointAccountId
            if (this.selectedPointAccount && this.usePoints && !this.selectedPointAccount.enoughPointsForPayment) {
                this.restPointAccountId = this.selectedPointAccount?.id
            } else {
                this.restPointAccountId = null
            }
            if (prevRestPointAccountId !== this.restPointAccountId) {
                const priceStore = usePayPriceStore()
                await priceStore.initCalcuatePrice('toggleUseRestPoint', false, false)
            }
        },
        setUsePointsInsteadOfDiscount(newState: boolean): void {
            this.usePointsInsteadOfDiscount = newState
        },
        reset(): void {
            this.selectedPointAccount = null
            this.availablePointAccounts = []
            this.usePoints = null
            this.restPointAccountId = null
            this.usePointsInsteadOfDiscount = false
        }
    }
})