export default {
  subTitle: 'Overview',
  pageTitle: 'Bookings',

  topAnalytic: {
    hoursDate: 'Hours',
    nextDays: 'Hours the comming {0} days'
  },

  createResevationButton: 'Create reservation',

  recurringBookings: {
    title: 'Recurring bookings',
    createButton: 'Create',
    table: {
      startTime: 'Start time',
      endTime: 'End time',
      active: 'Active',
      isActiveTrue: 'Yes',
      isActiveFalse: 'No'
    }
  }
}