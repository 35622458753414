export default {
  
  addFriendButton: 'Add a new friend',

  noFriends: {
    title: 'You have not added any friends',
    text: 'You have not added any friends to your user. Click "Add a new friend" above.'
  },

  deleteFriend: {
    submitButton: 'Remove',
    successToast: 'Your friend has been removed.'
  }
}