export default {
  paid: 'Paid',
  refunded: 'Refunded',
  partialrefunded: 'Partially refunded',
  failed: 'Failed',
  canceled: 'Canceled',
  pending: 'Pending...',
  waitingStatus: 'Pending status...',
  created: 'Created',
  sent: 'Sent'
}