<template>
  <div class="px-3 pt-3">
    <div
      v-if="additionalServices && additionalServices.length > 0"
      class="px-3 pt-3">
      <div class="row align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <h4 class="m-0 mb-3">
            {{
              $t(
                "pages.user.createResourceBooking.create.paymentModal.additionalServices.title"
              )
            }}
          </h4>
        </div>
      </div>
    </div>
    <div class="additional-service-wrapper">
      <div
        class="additional-service"
        v-for="(service, index) in additionalServices"
        :key="index"
        :class="{ disabled: additionalServiceStore.isServiceDisabled(service) }"
        @click="handleServiceClick(service)"
      >
        <AdditionalServicesInfoReqModal
          :open="open"
          @close="open = false"
          :additionalService="service"
          @additionalInfoAdditionalServices="handleModalClose"
        />
        <div v-if="additionalServiceStore.isServiceSelected(service)" class="service-remove">
          <i class="fe fe-minus icon-lg"></i>
        </div>
        <div v-else class="service-add">
          <i class="fe fe-plus icon-lg"></i>
        </div>
        <i
          class="fe fe-info icon-lg custom-icon"
          @click.stop="showInfoBox(service)"
        ></i>
        <div class="service-add-values">
          <div class="m-1">{{ service.name }}</div>
          <span class="m-1">{{ currencyFormatter(service.product.price, currency) }}</span>
        </div>
        <div
          v-if="showInfo && additionalServiceStore.currentService?.id === service.id"
          class="service-info"
          @mouseleave="hideInfoBox"
        >
          <p v-html="service.product.description" class="list-style"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { onMounted } from "vue";
import { AdditionalService } from "@/graphql";
import { useLocationStore } from "@/store/modules/location";
import { useAdditionalServiceStore } from "@/store/modules/additionalService";
import AdditionalServicesInfoReqModal from "./additionalServicesInfoReqModal.vue";
import { currencyFormatter } from "@/utils/currencyFormatter";

export default Vue.extend({
  components: {
    AdditionalServicesInfoReqModal,
  },
  props: {
    additionalServices: {
      type: Array as () => AdditionalService[],
      default: () => [],
    },
  },
  setup(props) {
    const additionalServiceStore = useAdditionalServiceStore();

    onMounted(() => {
      additionalServiceStore.checkServiceCapacity(props.additionalServices);
    });

    return {
      additionalServiceStore
    }
  },
  data() {
    return {
      showInfo: false,
      open: false,
      infoAdditionalService: [] as string[],
      serviceToToggle: null as AdditionalService | null,
    };
  },
  computed: {
    currency() {
      const locationStore = useLocationStore()
      return locationStore.userLocation?.currency!
    }
  },
  methods: {
    async handleServiceClick(service: AdditionalService) {
      if (service.additionalInfoRequired && !this.additionalServiceStore.isServiceSelected(service)) {
        //{
        this.open = true;
        this.serviceToToggle = service;
      } else {
        this.toggleService(service);
      }
    },
    handleModalClose(inputValues: string[]) {
      this.open = false;
      this.infoAdditionalService = inputValues;

      if (this.serviceToToggle) {
        this.toggleService(this.serviceToToggle);
        this.serviceToToggle = null;
      }
    },
    async toggleService(service: AdditionalService | null) {
      if (!service) {
        return;
      }

      if (this.additionalServiceStore.isServiceDisabled(service)) {
        // If the service is disabled, do nothing.
        return;
      }

      const index = this.additionalServiceStore.selectedServices.findIndex((s) => s === service.id);

      if (index > -1) {
        // The service is already selected, so remove it.
        this.additionalServiceStore.selectedServices.splice(index, 1);
        this.additionalServiceStore.selectedServiceId = "";
        this.infoAdditionalService = [];

      } else {
        // The service is not selected, so add it.
        this.additionalServiceStore.selectedServices.push(service.id);
        this.additionalServiceStore.selectedServiceId = service.id;
      }

      this.$emit(
        "serviceToggled",
        this.additionalServiceStore.selectedServices,
        this.infoAdditionalService
      );

      this.hideInfoBox();
    },
    showInfoBox(service: AdditionalService) {
      this.showInfo = true;
      this.additionalServiceStore.currentService = service;
    },
    hideInfoBox() {
      this.showInfo = false;
      this.additionalServiceStore.currentService = {} as AdditionalService;
    },
    currencyFormatter
  },
});
</script>

<style>
.additional-service-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 90px;
}

.additional-service {
  border-radius: 5px;
  border: 1px solid #e3ebf6;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1px;
}

.service-add,
.service-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0b39e0;
  color: white;
  height: 100%;
  border-radius: 5px 5px 0 0;
  position: relative;
}

.additional-service.disabled {
  background-color: #f0f0f0;
}

.additional-service.disabled .service-add,
.additional-service.disabled .service-remove {
  background-color: #ccc;
}

.additional-service.disabled .service-add-values {
  color: #888;
}

.custom-icon {
  color: white;
  position: absolute;
  right: 15px;
  top: 1px;
}

.custom-icon:active {
  opacity: 0.6;
}

.service-remove {
  background-color: #0b39e089;
}

.service-add-values {
  padding: 5px;
}

.service-info {
  position: absolute;
  top: 0;
  background-color: #f9f9f9;
  border: 1px solid #e3ebf6;
  border-radius: 5px;
  padding: 10px;
  display: none;
  z-index: 1;
  width: 300px;
}

.additional-service:hover .service-info,
.additional-service:focus .service-info {
  display: block;
}

.list-style {
	ol {
		list-style-type: decimal;
		list-style-position: inside;
  }
		
	ul {
		list-style-type: disc;
		list-style-position: inside; 
  }
}
</style>
