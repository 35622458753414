export default {
  title: 'Discount codes',
  activeTitle: 'Active codes',
  inactiveTitle: 'Inactive codes',
  description: "Create and manage discount codes here! Codes can offer discounts as a fixed amount, percentage, or booking hours. <br><br> You can also reactivate previously used discount codes, but it's recommended to create new ones if you don't want to reuse the exact name of an inactive code. If you do reactivate a code, ensure all settings are correct.",
  createButton: 'Create discount code',

  noActiveCoupons: 'No active discount codes',
  noInactiveCoupons: 'No inactive discount codes',
  couponTable: {
    hours: 'hours',
    endDate: 'End date',
    maxUsagesPrUser: 'Max usage per customer',
    maxUsages: 'Max usage in total'
  },

  createCouponModal: {
    title: 'Create discount code',
    description: 'You are about to create a discount code. Fill the inputs below.',

    createCouponButton: 'Create discount code',
    createCouponLoadingButton: 'Creating discount code...',
    userGroups: "User groups",

  }
}