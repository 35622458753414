<template>
  <div>
    <div id="card-element" ref="cardElement"></div>
    <b-button @click="pay()" size="lg" variant="primary" class="pay-button">
      {{ $t('components.app.pay.index.payWith') }} {{ $t('components.app.pay.index.paymentOptions.creditCard') }}
    </b-button>
  </div>
</template>
<script lang="ts">
import Vue, { ref, onMounted } from 'vue'
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js'
import { usePayStripeStore } from '@/store/modules/pay/stripe'

export default Vue.extend({
  setup() {
    const stripe = ref<Stripe | null>(null)
    const elements = ref<StripeElements | null>(null)
    const cardElement = ref<any>(null)

    const payStripeStore = usePayStripeStore()

    onMounted(async () => {
      const stripeInstance = await loadStripe(process.env.STRIPE_KEY!)
      if (stripeInstance) {
        stripe.value = stripeInstance
        elements.value = stripeInstance.elements()

        if (elements.value) {
          cardElement.value = elements.value.create('card')
          cardElement.value.mount('#card-element')
        }
      }
    })

    const pay = async () => {
      if (!stripe.value || !cardElement.value) {
        console.error('Stripe.js has not been properly initialized.')
        return
      }

      try {
        await payStripeStore.createCardPaymentWeb(stripe.value, cardElement.value)                
      } catch (error) {
        console.error('Payment failed:', error)
      }
    }

    return {
      cardElement,
      pay
    }
  }
})
</script>
<style lang="scss" scoped>
.pay-button {
  margin-top: 1rem;
  width: 100%;
}
</style>