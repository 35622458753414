import { GetAllShortStringMappingsQuery } from "@/graphql";

export function messageLineParser({
	messageLines,
	mappings
}:
{
	messageLines: string[],
	mappings: GetAllShortStringMappingsQuery['getAllShortStringMappings']
}): string[] {
	const replacementRules = [
		{
			condition: (line: string) => line.startsWith('NDID') && line.includes('\ninD'),
			replace: (line: string) => line
				.replace(/^NDID/, 'Returned by !useDiscounts or initDiscount:')
				.replace('inD', 'initDiscount:')
		},
		{
			condition: (line: string) => line.startsWith('SC') && line.includes('\nCD'),
			replace: (line: string) => line
				.replace(/^SC/, 'Checked user product subscription')
				.replace('CD', 'Can do:')
		},
		{
			condition: (line: string) => line.startsWith('OBD'),
			replace: (line: string) => line
				.replace(/^OBD/, 'Returned by isOwner and discountCodeIdOnlyForBuyer. Discount is:')
		},
		{
			condition: (line: string) => line.startsWith('MaxSD'),
			replace: (line: string) => line
				.replace(/^MaxSD/, 'The largest discount from subscriptions is:')
		},
		{
			condition: (line: string) => line.startsWith('CAP'),
			replace: (line: string) => line
				.replace(/^CAP/, 'Coupon applied:')
		},
		{
			condition: (line: string) => line.startsWith('NO_DISC'),
			replace: (line: string) => line
				.replace(/^NO_DISC/, 'No discount methods applied')
		},
		{
			condition: (line: string) => line.startsWith('NU') && line.length === 2,
			replace: (line: string) => line
				.replace(/^NU/, 'Is not a user')
		},
	];

	return messageLines.map(originalMessageLine => {
		let messageLine = originalMessageLine;

		// Apply predefined replacements if conditions are met
		replacementRules.forEach(rule => {
			if (rule.condition(messageLine)) {
				messageLine = rule.replace(messageLine);
			}
		});

		// Apply mappings replacements
		mappings.forEach(mapping => {
			if (messageLine.includes(mapping.shortString)) {
				messageLine = messageLine.replace(new RegExp(mapping.shortString, 'g'), mapping.fullString);
			}
		});

		return messageLine;
	});
}