import { PlatformLayouts } from '@/layouts'
import { defineStore } from 'pinia'

interface LayoutState {
  layout: PlatformLayouts | null
}

export const useLayoutStore = defineStore({
  id: 'Layout',

  state: (): LayoutState => ({
    layout: null
  }),

  getters: {

  },

  actions: {
    setLayout(layout: PlatformLayouts) {
      this.layout = layout
    }
  }
})
