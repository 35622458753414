export default {
  title: 'Reset password',
  description: 'Write your email below and receive a reset link.',

  resetButton: 'Resets password...',
  resetLoadingButton: 'Reset password',

  goToSignIn: {
    text: 'Do you remember your password?',
    button: 'Sign in'
  },

  emailSent: {
    message: 'An email has been sent to you where you can change your password.',
    title: 'Email sent'
  }
}