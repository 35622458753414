export default {
  showCourtsHeader: 'Want to see available times before logging in or creating an account?',
  showCourtsButton: 'Show available times',

  appleSignInButton: 'Sign in with Apple',
  facebookSignInButton: 'Sign in with Facebook',

  newPassword: 'Forgot your password?',
  newPasswordGet: 'Get a new one',

  signInButton: 'Sign in',
  signInLoadingButton: 'Signing in...',

  dontHaveAAccount: "Don't have an account?",
  dontHaveAAccountButton: 'Create an account',

  changeUserDefaultLocation: {
    title: 'Select your default location',
    description: 'Currently, there is no default location selected for your account. Choose below which location you want to make use of. You will always be able to switch between all our locations once you have logged in.'
  },

  selectCompany: {
    title: 'Choose where you want to sign in',
    signOut: 'Change account / sign out'
  },

  facebookAndroidError: {
    description: 'There is an error with Facebook login on Android app. To log in, you must do so via a browser. Click the button below to open LeDap in your browser.',
    openBookliApp: 'Open LeDap.app',
    closeWindow: 'Close this window'
  },
  wrongSignInMethod: {
    label: 'Wrong sign in credentials',
    facebook: 'You have previously signed in to LeDap with Facebook',
    apple: 'You have previously signed in to LeDap with Apple'
  }
}