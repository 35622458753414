<template>
  <div v-if="open">
    <b-modal v-model="open" ref="booking-table-modal" centered hide-header hide-footer body-class="p-0 overflow-hidden" size="xl">
      <div class="mx-4 my-3">
        <b-button
          variant="primary"
          block
          @click="$emit('close')">
            {{ $t('common.closeText') }}
        </b-button>
      </div>
      <ResourceBookingsTimeline ref="resourceBookingTimeLine" :isPublic="true" :showViewFullScreen="false"></ResourceBookingsTimeline>
    </b-modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import ResourceBookingsTimeline from '@/components/admin/resourceBookings/timeline.vue';

export default Vue.extend({
  components: {
    ResourceBookingsTimeline,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>

</style>
  