export default {
  chooseDate: 'Choose date',
  pricePerHourLabel: 'Price per hour',
  resourceCategoryLabel: 'Choose category: ',

  createReservedTime: {
    text1: 'Create reserved date on',
    text2: 'You have choosen start time',
    text3: 'and end time',
    createReservedTime: 'Create reservation'
  }
}