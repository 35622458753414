export default {
  loading: 'Loading...',
  createBooking: 'Sign up',
  hasBooking: 'Signed up',
  onWaitlist: 'On waiting list',
  joinWaitlist: 'Sign up wait list',
  cancelWaitlist: 'Cancel waitlist',
  cancelBookingButton: 'Cancel booking',
  cancelBookingLoadingButton: 'Canceling booking...',

  waitlistInfoModal: {
    text: 'You are registered for the waiting list. As soon as there is a seat, you will receive a sms and then you can sign up for the event. It is "first come, first served".',
    close: 'Close window'
  },

  createWorkshopBookingModal: {
    title: 'Sign up for "{0}"',
    cancel: 'Cancel',
    paymentText: {
      one: 'You are about to sign up for',
      two: 'to a price of'
    },
    requireUserBookingNote: {
      text: '(The field below must be completed before you can proceed to payment.)',
      inputPlaceholder: 'Write an answer to the question (field must be filled in)'
    },
    privateWorkshopKey: {
      private: 'Private',
      label: 'Private event code',
      description: 'This is a private event. Fill in the event code in order to be able to book the event.',
      placeholder: 'Write the event code',
      valid: 'You have entered the wrong code and cannot book this event.',
      waitlist: {
        signup: 'You are about to sign up for the waitlist for',
        description: 'This is a private event. Fill in the event code in order to sign up to the waitlist for this event.',
      },
    },
    created: {
      title: 'You have signed up for "{0}"',
      text: 'On the day of the event, you will receive a message with more information.',
      back: 'Back to start'
    },

    error: {
      title: 'A mistake happened...',
      back: 'Cancel'
    }
  },

  cancelBooking: {
    sure: {
      text: 'Are you sure you want to cancel this booking?'
    },
    successToast: 'You have canceled your booking for this event'
  },

  workshopJoinWaitlistSuccessToast: 'You are registered for the waiting list. You will receive a SMS as soon as there is a seat.'
}