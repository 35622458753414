export default {
  title: 'Attendees',
  tableAttendees: {
    name: 'Name',
    value: 'Part',
    discount: 'Discount',
    restAmount: 'Pay'
  },
  tablePayments: {
    name: 'Name',
    status: 'Payment',
    moneyValue: 'PAid & (value)'
  },
  additionalServicePayment: {
    title: 'Additional service',
    value: 'Value'
  }
}