export default {
    title: 'Manage all Admin roles',
    description: 'Create and manage platform connections accross the app. Use the button below give a User access to multiple Company, Franchise and System Admin platforms. You can only grant access to Users that have an existing account in Ledap.',
    readMore: 'Read more about the different roles and permissions:',
    wikiTitle: 'Roles-and-permissions',
    addButton: 'Grant Admin Access',

    admins: {
        title: 'Admin platform',
        description: 'The Admin platform refers to the current platform. Very few people should have access to this platform, as it allows you to see sensitive data as well as give access to the entire system. In the table you can see all Users with access to the Admin platform.',

        table: {
            title: 'Admin Platform access',
            name: 'Name',
            email: 'Email',
    
            options: {
                delete: 'Delete',
                edit: 'Edit'
            }
        }
    },

    createModal: {
        title: 'Grant Admin Access',
        
        verifyUser: {
            description: 'The admin account needs to be connected to an existing user.',
            button: 'Validate user',
        },

        accessControl: {
            userInfo: 'Add platform access to',

            adminConnections: {
                title: 'Access to Admin Platforms',
                description: 'Give access to System Admin platforms. Be careful in selecting these accesses, as they provide extensive access to the system.',
                warning: 'Make sure you know what you are doing - Admin access should almost never be selected'
            },

            companyConnections: {
                title: 'Access to Company Platforms',
                description: 'Give Admin access to Company platforms. Select one or multiple locations below to make the User an admin at the location.',
                show: 'Show locations',
                hide: 'Hide locations',
                selectAllFor: 'Select all for'
            },

            franchiseConnections: {
                title: 'Access to Franchise Platforms',
                description: 'Give Admin access to the Franshise platforms. The Franchise platforms are seperated from the specific Company platforms.'
            },

            selectAll: 'Select All',
            submit: 'Add admin access'
        }
    }
}