export default {
	titles: {
		pageTitle: "Memberships",
	},
	tables: {
		titles: {
			memberships: "Memberships",
			categories: "Categories",
		},
		columns: {
			categoryName: "Name",
			membershipName: "Name",
			companyName: "Company",
		},
	},

	descriptions: {
		franchise:
			"Below you can create memberships that will be available on all Companies. Users that buy the membership, will be able to use it on all Companies within the Franchise.",
		company:
			"Below you can customize memberships that can be used by your users.",
	},

	create: {
		membership: {
			newMembershipButton: "Create new membership",
			modal: {
				title: "Create new membership",
				useOnAllCompanies: "Can be used across franchise",
				inputNameTitle: "Name",
				inputNamePlaceholder: "Membership name",
				inputDescriptionTitle: "Description",
				inputDescriptionPlaceholder: "Membership description",
				monthlyPrice: "Monthly price",
				categorySelectionTitle: "Category",
				maxCoverage: {
					title: "Max payment covered by membership",
					options: {
						all: {
							label: "The entire amount",
						},
						none: {
							label: "None",
						},
						value: {
							label: "Amount",
							placeholder: "Max value",
						},
						hours: {
							label: "Max hours",
							placeholder: "Number of max hours",
						},
					},
					inputValuePlaceholder: "Max value",
				},
				discount: {
					title: "Discount type on part of payment",
					options: {
						percentage: {
							label: "Percentage",
							placeholder: "Percentage of part",
						},
						amount: {
							label: "Fixed value",
							placeholder: "Fixed value",
						},
						bookingInterval: {
							label: "Fixed value per booking interval",
							placeholder: "Fixed value",
						},
					},
				},
				inputMonthlyPriceTitle: "Monthly price",
				inputMonthlyPricePlaceholder: "Monthly price",
				payment: {
					title: "Payment",
					paymentsPerYear: {
						title: "Payments per year",
						options: {
							annually: {
								label: "Annually (Once per year)",
							},
							monthly: {
								label: "Monthly (12 times per year)",
							},
						},
					},
					paymentOnTheFirstOfMonth: "Payment is on the first of the month",
					startPaymentFrom: {
						label: "Start payment from custom date",
						options: {
							specificDate: {
								label: "Specific date",
								placeholder: "Date",
							},
							numberOfDays: {
								label: "Number of days",
								description:
									"This can be used to offer users a free trial period before their first payment",
							},
						},
						activateBeforeFirstPayment:
							"Activate membership before first payment",
					},
					signUp: {
						label: "Sign up fee",
						placeholder: "",
					},
					termination: {
						label: "Number of payments after termination",
						placeholder: "",
					},
				},
				additionalDiscount: {
					title: "Discount",
					options: {
						bTimes: {
							label: "Only discount in B time-slots",
						},
						courts: {
							label: "Only discount for specific court types",
						},
						events: {
							label: "Discount on events",
							inputDiscountLabel: "Discount on events",
							inputDiscountPlaceholder: "Discount on events",
							inputMaxBookingsWithDiscountLabel:
								"Max event bookings with discount",
							inputMaxBookingsWithDiscountPlaceholder:
								"Max event bookings with discount",
						},
					},
				},
				advancedSettings: {
					title: "Advanced settings",
					buttonShowHideLabel: "Advanced settings",
					unlimited: "0 for unlimited",
					inputMaxActiveBookingsPerMonth: {
						label: "Max active bookings per month",
						placeholder: "Max active bookings per month",
					},
					inputMaxActiveBookingsWithMembership: {
						label: "Max active bookings paid with membership",
						placeholder: "Max active bookings paid with membership",
					},
					inputMaxNumberOfActiveUsers: {
						label: "Max number of users with active memberships",
						placeholder: "Max number of users with active memberships",
					},
					allowPublicSignUp: "All users can sign up to this membership",
					inputMaxDaysCreateBookingAhead: {
						label: "Days to book ahead",
						placeholder: "Number of days to book ahead",
						description: "0 for default",
					},
					inputDiscountOnOtherPurchases: {
						label: "Discount on other purchases",
						placeholder: "Discount on other purchases",
						description:
							"This discount applies to bookings beyond the maximum number of bookings. In other words, if the user has exceeded the maximum number of bookings that can be paid with the membership, this discount will be used. The discount also applies in HIGH-time (A-time) if the membership is tied to LOW-time (B-time).",
					},
					timeIntervals: {
						title: "Time intervals for discount code",
						create: {
							title: "Add time interval",
							startTime: {
								label: "Start time",
							},
							endTime: {
								label: "End time",
							},
							createButton: {
								label: "Create time interval",
								loading: "Creating time interval",
							},
						},
					},
					signUpRules: {
						title: "Rules for sign up",
						create: {
							title: "Add sign up rule",
							options: {
								title: "Type",
								age: {
									userAge: "User age rule",
									age: "Age",
									greaterThan: "Older than",
									lessThan: "Younger than",
								},
								userGroup: {
									userGroups: "Choose user groups",
									user: "user",
									users: "users",
									userGroup: "User group",
								},
							},
							createButton: {
								label: "Create rule",
								loading: "Creating rule",
							},
						},
					},
					usageRules: {
						title: "Rules for usage",
						create: {
							title: "Add usage rule",
							options: {
								title: "Type",
								bookingStartDate: {
									label: "Court booking start date",
									description: "Booking has to have start between",
								},
							},
							createButton: {
								label: "Create rule",
								loading: "Creating rule",
							},
						},
					},
				},
			},
			createButton: {
				label: "Create membership",
				loading: "Creating membership",
			},
			successfullyCreatedMessage: "The membership has been created.",
		},
		category: {
			newCategoryButton: "Create new category",
			modal: {
				title: "Create new category",
				inputNameTitle: "Name",
				inputNamePlaceholder: "Category name",
				createButton: "Create category",
				creating: "Creating category",
			},
			successfullyCreatedMessage: "The category has been created.",
		},
	},

	update: {
		membership: {
			successfullyUpdatedMessage: "The membership has been updated",
			modal: {
				title: "Update membership",
				button: {
					label: "Update membership",
				},
				name: {
					label: "Name",
					placeholder: "Membership name",
				},
				description: {
					label: "Description",
					placeholder: "Membership description",
				},
				maxCoverage: {
					title: "Max payment covered by membership",
					options: {
						all: {
							label: "The entire amount",
						},
						none: {
							label: "None",
						},
						value: {
							label: "Amount",
							placeholder: "Max value",
						},
						hours: {
							label: "Max hours",
							placeholder: "Number of max hours",
						},
					},
				},
				discount: {
					title: "Discount type on part of payment",
					options: {
						percentage: {
							label: "Percentage",
							placeholder: "Percentage of part",
						},
						amount: {
							label: "Fixed value",
							placeholder: "Fixed value",
						},
						bookingInterval: {
							label: "Fixed value per booking interval",
							placeholder: "Fixed value",
						},
					},
				},

				advancedSettings: {
					unlimited: "0 for unlimited",
					maxActiveBookingsPerMonth: {
						label: "Max active bookings per month",
						placeholder: "Max active bookings per month",
					},
					maxActiveBookingsWithMembership: {
						label: "Max active bookings paid with membership",
						placeholder: "Max active bookings paid with membership",
					},
					maxNumberOfActiveUsers: {
						label: "Max number of users with active memberships",
						placeholder: "Max number of users with active memberships",
					},
					allowPublicSignUp: "All users can sign up to this membership",
					additionalDiscount: {
						options: {
							bTimes: {
								label: "Only discount in B time-slots",
							},
							events: {
								label: "Discount on events",
								discount: {
									label: "Discount on events",
									placeholder: "Discount on events",
								},
								maxBookings: {
									label: "Max event bookings with discount",
									placeholder: "Max event bookings with discount",
									isPerMonth: {
										label: "Max event bookings with discount is per month",
									},
								},
							},
						},
					},
				},

				price: {
					title: "Prices",
					toggle: "Update prices",
					warning: `<b>IMPORTANT:</b>
							<br />
							Updating a price will update it immediately in the app. All users that sign up to the membership after the price is updated, will pay the new price. Users who have already signed up to the membership will continue to pay the old price.
							<br />
							<b>LEGAL REMINDER:</b>
							<br />
							Please verify that any subscription price changes comply with legal standards and regulations before proceeding.
							<br />
							To inform the members; please use the "Send a message" functionality.`,
					recurringPrice: "Recurring price",
					signUpFee: "Sign up fee",
					additionalOptions: {
						label: "Additional options",
						updateExistingSubscriptions: {
							title: "Update existing subscriptions",
							afterNextInvoiceOption: "After the next invoice",
							afterNextInvoiceInfoTitle: "Update existing subscriptions after the next invoice",
							afterNextInvoiceInfo: "All users that are signed up for the membership will get the new price after their next invoice. Memberships that are in termination period will get the new price if they are re-activated.",
							beforeNextInvoiceOption: "Before the next invoice",
							beforeNextInvoiceInfoTitle: "Update existing subscriptions before the next invoice",
							beforeNextInvoiceInfo: "All users that are signed up for the membership will get the new price on their next invoice. Open or unpaid invoices will not be updated. Memberships that are in termination period will get the new price if they are re-activated."
						}					
					},
				},
			},
		},
	},

	membershipInfo: {
		usedForEvents: "Can be used at events",
		discountOnEvents: "Discount on events",
		courtTypes: "Works for the following court types",
		maxActiveBookingsPerMonth: "Max active bookings per month ",
		maxActiveBookings: "Max active bookings",
		coveragePerBookingInterval: "Covered amount per booking interval",
		coverageAmount: "Covered amount",
		coverage: "Covers",
		discountType: "Discount type",
		maxHoursCovered: "Max hours covered",
		maxAmountCovered: "Max amount covered",
		maxPaymentCoverage: "Max payment covered type",
		appliesToBTimes: "Applies to B times only",
		daysFutureBookings: "Days you can book in the future",
		setUpFee: "Set-up fee",
		discountOtherPurchases: "Percentage discount on other purchases",
		paymentsPerYear: "Number of payments per year",
		monthlyPrice: "Monthly price",
		yearlyPrice: "Yearly price",
		franchise: "Franchise",
		updateInfo: "Update information",
		membershipProduct: "Membership product",
		activeMemberships: "Active memberships",
		monthlyIncome: "Monthly income",
		sendMessage: "Send a message",
		freeStartup: "Add a free company startup",
		addStartup: "Add startup",
		addsStartup: "Adds startup...",
		updateMembership: "Update membership product",
		updating: "Updating membership product",
		validDate: "Valid start and end date",
		companies: "Companies",
		date: "Date",
		freePeriod: "Free periods",
		daysBeforeFirstPayment: "Days before first payment",
		activateBeforeFirstPayment: "Activate membership before first payment",
		paymentsAfterTermination: "Number of payments after termination",
		discount: "Discount",
		timeIntervals: "Time intervals for discount code",
		maxActiveBookingsPaidWithMembership:
			"Max active bookings paid with membership",
		maxNumberOfUsersWithActiveMemberships:
			"Max number of users with active memberships",
		allowPublicSignUp: "All users can sign up to this membership",
		signUpRules: "Rules for sign up",
		usageRules: "Rules for usage",
		category: "Category",
		signUpFee: "Sign up fee",
		addedDate: "The date has been added",
	},

	freeStartupDateAdded: "The date has been added",
};
