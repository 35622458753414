<template lang="pug">
div(style="height: 100%").bg-light
	b-overlay.container-fluid.py-4.safe-area-inset-top(
		:show="$apollo.queries.resourceCategories.loading"
		variant="background"
		spinner-variant="primary"
		v-if="!selectedResourceCategory")
		header
			h1.display-4 {{ $t('pages.user.createResourceBooking.create.noSelectedResourceCategory.title') }}
			p.text-muted.lead {{ $t('pages.user.createResourceBooking.create.noSelectedResourceCategory.description') }}

		.cards-container.mt-4
			b-card(v-if="!resourceCategories.length")
				p.mb-2 {{ $t('pages.user.createResourceBooking.create.noSelectedResourceCategory.noResourceCategories.title') }}
				small.text-muted {{ $t('pages.user.createResourceBooking.create.noSelectedResourceCategory.noResourceCategories.description') }}

			b-card(
				style="cursor: pointer"
				v-for="resourceCategory in resourceCategories"
				:key="resourceCategory.id"
				@click="selectResourceCategory(resourceCategory.id)")
				h3.mb-2 {{ resourceCategory.name }}
				small.text-muted {{ resourceCategory.description }}

	transition(
		enter-active-class="animated fadeIn"
		leave-active-class="animated fadeOut"
		mode='out-in')
		.container-fluid.layout-max-height-full(v-if="selectedResourceCategory" style="animation-duration: 0.2s")

			.margin-top-4-safe-area

			b-link.text-muted.d-flex.align-items-center.mb-3(
				v-if="resourceCategories.length > 1"
				@click="selectedResourceCategory = null")
				i.fe.fe-arrow-left.icon-md
				span.ml-2 {{ $t('pages.user.resourceBooking.goBack') }}


			.d-flex.align-items-center
				small.mb-0.item-min-width.mr-2 {{ $t('pages.user.createResourceBooking.create.type') }}
				.small-boxes.grab-able 
					.item.item-with-bg.d-flex.align-items-center.cursor-pointer(
						v-for="resourceType in resourceTypes"
						:key="resourceType.id"
						v-bind:class="{ selected: selectedResourceType && selectedResourceType.id === resourceType.id }" 
						@click="hapticsImpact(); selectedResourceType = resourceType")
						span {{ resourceType.name }}

			.d-flex.align-items-center.mt-3
				small.mb-0.item-min-width.mr-2 {{ $t('pages.user.createResourceBooking.create.hours') }}
				.small-boxes
					.item.item-with-bg.d-flex.align-items-center.cursor-pointer(
						v-for="(time, index) in possibleDurations"
						:key="index"
						:id="'select-duration-' + time.minuts"
						v-bind:class="{ selected: selectedDurationMinuts === time.minuts }"
						@click="selectDurationMinutes(time.minuts, true)")
						span {{ time.hours }}
						small.ml-2  {{ time.text }}

			.d-flex.align-items-center.mt-3
				small.mb-0.item-min-width.mr-2 {{ $t('pages.user.createResourceBooking.create.date') }}
				div(style="overflow-x: auto")
					.small-boxes.mb-2
						.item.cursor-pointer(
							v-for="(month, index) in possibleMonths"
							:key="index"
							v-bind:class="{ selected: selectedMonth === month.isoString }"
							@click="hapticsImpact(); selectMonth(month.isoString)")
							small {{ month.label }} {{ month.time }}

					.small-boxes#date-box(ref="dateRef" v-on:scroll="looper()" class="items-center")
						.item.item-with-bg.cursor-pointer(
							v-for="(date, index) in possibleDates"
							:key="index"
							v-bind:id="'date-' + date.date"
							v-bind:class="{ selected: new Date(selectedDate).getTime() === new Date(date.date).getTime() }"
							@click="selectDate(date.date)")
							small.d-block.text-center {{ date.dayLabel }}
							span.d-block.mx-auto.text-center {{ date.day }}

						.item.min-w-fit
							small.d-block.text-muted.text-center {{ $t('pages.user.createResourceBooking.create.maxBookableDays') }}
							span.d-block.mx-auto.text-center {{ maxDaysCreateResourceBookingAheadUser }}

			small.mt-3.d-block.text-center <b class="text-body">{{ selectedDate | moment('dddd') }} {{ selectedDate | moment('D MMMM') }} </b> {{ $t('pages.user.createResourceBooking.create.selectionText.with') }} <b class="text-body"> {{ selectedDurationMinuts / 60 }} {{ $t('pages.user.createResourceBooking.create.selectionText.hours') }} </b>{{ $t('pages.user.createResourceBooking.create.selectionText.play') }}&nbsp;
				b.text-body {{ selectedLocation.name }}&nbsp;
				span(v-if="!showActionRow")
					span -&nbsp;
					b-link(@click="$refs['changeUserDefaultLocation'].show()") {{ $t('pages.user.createResourceBooking.create.changeLocation') }}

			ChangeUserDefaultLocation(
				:title="$t('components.app.changeUserDefaultLocation.defaultTitle')"
				ref="changeUserDefaultLocation"
				:showChangeLink="false"
				@locationUpdated="locationUpdated"
			)

			b-row.mt-2(v-if="showActionRow")
				b-col
					b-button(
						block
						size="sm"
						@click="$refs['changeUserDefaultLocation'].show()"
						variant="outline-primary") {{ $t('pages.user.createResourceBooking.create.changeLocation') }}

				b-col
					b-button(
						block
						size="sm"
						@click="userCreateResourceBookingsTimelineModal = true"
						variant="outline-primary") {{ $t('pages.user.createResourceBooking.create.viewBookingTable') }}

			b-overlay.pt-0.pb-0.scroll-container.card-white-bg.card-white-full.mt-3.layout-max-height-full(
				style="padding: 0"
				:show="$apollo.queries.timeTabel.loading"
				variant="white"
				spinner-variant="primary")

				.blur-top-white
				.cards-container.py-4.scroll-container#time-table(style="padding: 0 20px; overflow-y: auto")
					.cards-container-overlay(v-if="allowShowingDynamicPriceInfo && usingDynamicPrices")
						h3 {{ $t('pages.user.createResourceBooking.create.dynamicPricing.title') }}
						p.text-muted {{ $t('pages.user.createResourceBooking.create.dynamicPricing.descriptionOne') }}
						p.text-muted {{ $t('pages.user.createResourceBooking.create.dynamicPricing.descriptionTwo') }}
						p.mb-0
							strong {{ $t('pages.user.createResourceBooking.create.dynamicPricing.descriptionStrong') }}

						b-button.d-block.mt-3(
							block
							variant="primary"
							@click="hideDynamicPricesInfoBox()") {{ $t('pages.user.createResourceBooking.create.dynamicPricing.viewButton') }}

					b-card.bg-light.shadow-none.card-no-border.card-small-padding.m-0(v-else-if="timeTabelMissingInformation")
						p.mb-2 {{ $t('pages.user.createResourceBooking.create.noSelectedResourceCategory.title') }}
						small.text-muted {{ $t('pages.user.createResourceBooking.create.missingInfo.description') }}

					b-card.card-small-padding.card-no-border.cursor-pointer.hover-scale.bg-light(
						v-else
						body-class="p-0"
						v-for="(time, index) in timeTabelForBooking"
						:key="index"
						@click="selectTime(time)"
						:id="'time-' + time.utcTime")
						b-row
							b-col.d-flex.align-items-center.justify-content-center.flex-column.border-right.pl-4.time-slot-min-width(cols="2")
								p.m-0 {{ time.startDate | moment('HH:mm') }}

							b-col.p-2(cols="auto")
								small.text-secondary.m-0.pl-2 {{ time.availableResources.length }} {{ availableResourceEnding(time.availableResources.length) }}&nbsp;
									span.hide-text-small {{ $t('pages.user.createResourceBooking.create.timeAvailable.courtsAble') }}
								p.m-0.pl-2
									span(v-if="time.priceWithActiveCoupon || time.priceWithActiveCoupon === 0")
										span(style="text-decoration: line-through") {{ time.price }}&nbsp;
										span {{ formatTimePrice(time.priceWithActiveCoupon) }} 
									span(v-else) {{ formatTimePrice(time.price.price, LocationStore.userLocation.currency) }}
										span(v-if="selectedResourceCategory.company.usingPointProducts") &nbsp;/ {{ $t('pages.user.createResourceBooking.create.timeAvailable.pricePoints') }}

					b-card.bg-light.shadow-none.card-no-border.card-small-padding.m-0(v-if="!timeTabelForBooking.length && !$apollo.queries.timeTabel.loading")
						p.mb-2 {{ $t('pages.user.createResourceBooking.create.timeAvailable.noOptions.title') }}
						small.text-muted {{ $t('pages.user.createResourceBooking.create.timeAvailable.noOptions.description') }}


	userCreateResourceBookingsTimelineModal(
		:open="userCreateResourceBookingsTimelineModal"
		@close="userCreateResourceBookingsTimelineModal = false"
	)

	b-modal.bottom-modal(
		v-if="isValidDate"
		:backdrop="true"
		hide-header
		hide-footer
		modal-class="bottom-modal"
		v-model="paymentWindowVisible"
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		body-class="p-0")
		.buttom-sidebar-close-wrapper.cursor-pointer.p-2(
			@click="resetPaymentWindow()")
			.fe.fe-arrow-left.text-muted.mr-1
			small.text-muted {{ $t('pages.user.createResourceBooking.create.paymentModal.changeBooking') }}

		b-overlay.bottom-modal-content.pt-2(
			v-if="selectedLocation && selectedResourceType && selectedResource && !stripeV2Store.showStripeWebPaymentElement"
			variant="background"
			spinner-variant="primary")

			div.payment-modal-scrollable-container
				.info-wrapper.d-flex.px-3.pt-3.pb-3
					.div.ml-2
						b-row
							b-col.col-auto.pr-0
								i.fe.fe-clock.icon-sm.mr-2
							b-col.pl-1
								small.mt-1 {{ selectedTime.startDate | moment('HH:mm') }} - {{ selectedTime.endDate | moment('HH:mm') }}
								small ({{ duractionMinutsMinutes / 60 }}{{$t('pages.user.createResourceBooking.create.paymentModal.sumText.hours')}})

						b-row
							b-col.col-auto.pr-0
								i.fe.fe-calendar.icon-sm.mr-2
							b-col.pl-1
								small.mt-1 {{ selectedTime.startDate | moment('dddd') }} <span class="text-body">{{ $t('pages.user.createResourceBooking.create.paymentModal.sumText.date') }} {{ selectedTime.startDate | moment('D MMMM') }} </span>

						b-row(style="flex-wrap: nowrap")
							b-col.col-auto.pr-0
								i.fe.fe-map-pin.icon-sm.mr-2
							b-col.pl-1
								small.mt-1 {{ selectedLocation.name }}

						b-row(style="flex-wrap: nowrap")
							b-col.col-auto.pr-0
								i.fe.fe-flag.icon-sm.mr-2
							b-col.pl-1
								small.mt-1 {{ selectedResourceType.name }} - {{ selectedResource.name }} -&nbsp;
									b-link(@click="$refs['select-court-modal'].show()") {{ $t('pages.user.createResourceBooking.create.paymentModal.changeResource') }}?

				b-overlay(
					variant="background"
					spinner-variant="primary")
					div.px-3.pt-3
						b-row(align-v="center" align-h="between")
							b-col
								.d-flex.align-items-center
									h4.m-0 {{ $t('pages.user.createResourceBooking.create.paymentModal.attendees.title') }} 

							b-col.d-flex.justify-content-end
								b-link.small(@click="showAttendeesInfoBox()") {{ $t('pages.user.createResourceBooking.create.paymentModal.attendees.whatIsThis') }}


						.mt-3.booking-attendee-wrapper
							b-skeleton(v-if="!bookingAttendees.length" v-for="index in selectedResource.maxAttendees" height="80px" :key="index.id")

							.booking-attendee(v-for="attendee in bookingAttendees.filter(x => x.isUser)")
								.attendee-action(
									style="background-color: #CA6660"
									v-if="!attendee.isOwner"
									@click="deleteBookingAttendee(attendee.user.id, true)")
									small.fe.fe-minus

								div
									small.font-weight-bold.m-0(style="word-break: break-all")
										span(v-if="attendee.isOwner") {{ $t('pages.user.createResourceBooking.create.paymentModal.attendees.you') }}
										span(v-else) {{ attendee.user.firstName }}

								div.mt-auto
									small.d-block.mb-1(v-if="attendee.userProductSubscription")
										b-badge(variant="success") {{ $t('pages.user.createResourceBooking.create.paymentModal.attendees.subscription') }}
									small.d-block.mb-1(v-if="attendee.coupon")
										b-badge(variant="success") {{ $t('pages.user.createResourceBooking.create.paymentModal.attendees.discount') }}

									small.text-muted.extra-small(v-if="attendee.discountValue") -{{ formatTimePrice(attendee.discountValue) }}
									small.text-body.d-block.m-0.mt-n1 {{ formatTimePrice(attendee.restMoneyValue) }}

							.booking-attendee.p-0(
								@click="showAttendeesResourceBookingModal = true"
								v-for="(attendee, index) in bookingAttendees.filter(x => !x.isUser)" :key="index")

								div.attendee-add
									.fe.fe-plus.icon-lg

								div.mt-auto.attendee-add-values
									small.text-muted.extra-small(v-if="attendee.discountValue") -{{ formatTimePrice(attendee.discountValue) }}
									small.text-body.d-block.m-0.mt-n1 {{ formatTimePrice(attendee.restMoneyValue) }} 

						b-row(v-if="displayNoDiscountMessage").pt-2
							b-col.col-auto.d-flex.align-items-center
								i.fe.fe-alert-circle.text-info.icon-sm
							b-col.p-0.pt-0.mt-1
								small.text-muted {{ noSubPaymentReason.reason.length > 0 ? noSubPaymentReason.reason : $t('things.userProductSubscription.noSubscriptionPayment.cantCombineWithPoints')}} 
				div
					additionalServicesModal(
						:additionalServices='additionalServices'
						@serviceToggled='updateAdditionalServices'
					)

					div.pay-wrapper.mt-3
						Pay

				.safe-area-inset-bottom-0
		div.bottom-modal-content.pt-2(v-if="stripeV2Store.showStripeWebPaymentElement")
			StripePaymentElement(@back-to-overview="backToOverview()")

	b-modal(
		ref="select-court-modal"
		footer-class="modal-footer-custome"
		centered
		size="sm")

		template(#modal-header)
			.d-flex.justify-content-between.align-items-center(style="width: 100%;")
				h3.m-0 {{ $t('pages.user.createResourceBooking.create.selectResourceModal.title') }}
				span.ml-3.text-muted {{ selectedTime.startDate | moment('D MMM') }} kl. {{ selectedTime.startDate | moment('HH:mm') }}

		template(#modal-footer)
			b-link.footer-button.text-body.p-3.shadow-none.bg-white(@click="$refs['select-court-modal'].hide()") {{ $t('common.closeText') }}

		b-card.card-small-padding.cursor-pointer.hover-scale.shadow-none.bg-light.card-no-border(
			@click="hapticsImpact(); selectResource(selectedTime.availableResources[0])")
			p.m-0 {{ $t('pages.user.createResourceBooking.create.selectResourceModal.chooseRandom') }}

		.cards-container(v-if="selectedTime")
			b-card.card-small-padding.cursor-pointer.hover-scale.shadow-none.bg-light.card-no-border(
				v-for="(resource, index) in selectedTime.availableResources"
				:key="index"
				@click="hapticsImpact(); selectResource(resource)")
				b-row(align-v="center")
					b-col(v-if="resource.logoUrl" cols="auto")
						img(:src="resource.logoUrl" style="width: 55px")
					b-col(cols="auto")
						p.m-0 {{ resource.name }}

	b-modal(
		modal-class="modal-max-body-height"
		body-class="d-flex"
		ref="attendees-resource-booking-modal"
		v-model="showAttendeesResourceBookingModal"
		centered
		hide-footer
		hide-header
		:no-close-on-backdrop="true"
		size="md")

		.friends-wrapper(v-if="selectedResource")
			b-row.justify-content-center.mb-3
				b-col(auto)
					h2.m-0 {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.title') }}
				b-col(cols="auto")
					b-link.text-primary(@click="showAttendeesResourceBookingModal = false") {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.close') }}

			p.text-muted.mb-2 {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.youCanAdd') }} <b class="text-dark">{{ selectedResource.maxAttendees - 1 }}</b> {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.youHaveChoosen') }}&nbsp;
				b.text-dark {{ resourceBookingSelectedAttendeesIds.length }} {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.outOff') }} {{ selectedResource.maxAttendees }}&nbsp;
				span {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.possible') }}

			b-button.mt-3(
				size="sm"
				block
				variant="outline-primary"
				@click="$refs['add-friend-modal'].show()") {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.addFriend') }}

			add-friend(
				ref="add-friend-modal"
				@friend-added="$apollo.queries.friends.refetch()")

			b-form-input.mb-4.mt-3(
				v-model="friendSearch"
				:placeholder="$t('input.friendSearch.placeholder')")

			b-card.bg-light.card-small-padding.mb-0(v-if="!friends.length && !friendSearch.length")
				p.mb-2 {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.noFriends.title') }}
				small.text-muted {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.noFriends.description') }}
				b-button.mt-3(
					size="sm"
					block
					variant="outline-primary"
					@click="$refs['add-friend-modal'].show()") {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.addFriend') }}

			b-card.bg-light.card-small-padding.mb-0(v-else-if="!friends.length")
				p.mb-2 {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.noFriendsSearch.title') }}
				small.text-muted {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.noFriendsSearch.description') }}

			.add-friends-list.flex-column
				b-card.bg-light.shadow-none.card-no-border.card-small-padding(
					v-for="friend in friends" :key="friend.id")
					b-row(align-v="center")
						b-col
							p.m-0 {{ friend.name }}
						b-col.col-auto
							b-button(
								v-if="resourceBookingSelectedAttendeesIds.find(x => x === friend.id)"
								size="sm"
								@click="deleteBookingAttendee(friend.id)"
								variant="outline-danger") {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.friendList.remove') }}
							b-button(
								v-else-if="resourceBookingSelectedAttendeesIds.length < selectedResource.maxAttendees"
								size="sm"
								@click="addBookingAttendee(friend.id)"
								variant="outline-primary") {{ $t('pages.user.createResourceBooking.create.attendeesResourceBookingModal.friendList.add') }}
	
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import {
	GetResourcesAvailableTimeSchema,
	GetResourcesAvailableTimeSchemaQueryVariables,
	GetResourcesAvailableTimeSchemaQuery,
	GetResourceTypes,
	GetResourceTypesQueryVariables,
	GetResourceTypesQuery,
	GetLocationUserDefault,
	GetLocationUserDefaultQuery,
	GetResourceCategoriesQuery,
	GetResourceCategories,
	GetFriends,
	GetFriendsQueryVariables,
	GetFriendsQuery,
	ProductType,
	ReasonCode,
	GetLocation,
	GetUserBasicsQuery,
	SupportedCountries,
	UserSingInMethod,
	GetUserBasics,
	GetUserBasicsQueryVariables,
	GetLocationQuery,
	GetAllShortStringMappings,
	GetAllShortStringMappingsQuery,
	GetAdditionalServicesQueryVariables,
	GetAdditionalServices,
	AdditionalService,
	PaymentTokenFaildReason,
} from "@/graphql";
import AddFriend from "@/components/app/addFriend.vue";
import { Capacitor } from "@capacitor/core";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import ChangeUserDefaultLocation from "@/components/app/changeUserDefaultLocation.vue";
import gql from "graphql-tag";
import smoothscroll from "smoothscroll-polyfill";
import Pay from "@/components/app/pay/index.vue";
import { saEvent } from "@/utils/saEvent";
import ResourceBookingsTimeline from "@/components/admin/resourceBookings/timeline.vue";
import { Preferences } from "@capacitor/preferences";
import { DoneFunction, usePayStore } from "@/store/modules/pay/pay";
import { usePayPriceStore } from "@/store/modules/pay/price";
import { useLocationStore } from "@/store/modules/location";
import { sendSegmentData, SegmentTypeOfEvent } from "@/segment/segmentData";
import userCreateResourceBookingsTimelineModal from "@/views/other/userCreateResourceBookingsTimelineModal.vue";
import { messageLineParser } from "@/utils/messageLineParser";
import additionalServicesModal from "./additionalServicesModal.vue";
import { useUserStore } from "@/store/modules/user";
import { currencyFormatter } from "@/utils/currencyFormatter";
import StripePaymentElement from "@/components/app/pay/stripePaymentElement.vue";
import { usePayV2StripeStore } from "@/store/modules/payV2/stripeV2";
import { usePayV2Store } from "@/store/modules/payV2/payV2";
import { usePayPaymentTokenStore } from "@/store/modules/pay/paymentToken";
import { usePayV2PointsStore } from "@/store/modules/payV2/pointsV2";
import { useAdditionalServiceStore } from "@/store/modules/additionalService";
import { logAnalyticsEvent } from "@/utils/fcmAnalytics";

smoothscroll.polyfill();

export interface InitBookingSettings {
	resourceId: string;
	startDate: Date;
	endDate: Date;
}

@Component({
	components: {
		ChangeUserDefaultLocation,
		Pay,
		AddFriend,
		ResourceBookingsTimeline,
		userCreateResourceBookingsTimelineModal,
		additionalServicesModal,
		StripePaymentElement,
	},
	apollo: {
		user: {
			query: GetUserBasics,
			variables(): GetUserBasicsQueryVariables {
				return {
					id: this.id,
				};
			},
		},
		locationUserDefault: {
			query: GetLocation,
			variables() {
				return {
					id: this.LocationStore.currentUserLocation?.id,
				};
			},
			skip() {
				return !this.LocationStore.currentUserLocation?.id;
			},
			update(data) {
				this.LocationStore.getUserLocation();

				return data.location;
			},
		},
		selectedLocation: {
			query: GetLocationUserDefault,
			update(data: GetLocationUserDefaultQuery) {
				return data.locationUserDefault;
			},
			errorPolicy: "all",
			error(error) {},
		},

		friends: {
			query: GetFriends,
			variables(): GetFriendsQueryVariables {
				return {
					filtering: {
						search: this.friendSearch.length ? this.friendSearch : undefined,
					},
				};
			},
			fetchPolicy: "network-only",
			update(data: GetFriendsQuery) {
				return data.friends.data;
			},
		},

		timeTabel: {
			query: GetResourcesAvailableTimeSchema,
			variables(): GetResourcesAvailableTimeSchemaQueryVariables {
				return {
					resourceType: this.selectedResourceType.id,
					location: this.selectedLocation.id,
					date: this.selectedDate,
					durationMinuts: this.selectedDurationMinuts,
				};
			},
			fetchPolicy: "network-only",
			update(data: GetResourcesAvailableTimeSchemaQuery) {
				this.timeTabel = [];
				return data.resourcesAvailableTimeSchema.times;
			},
			skip() {
				if (
					this.selectedResourceType &&
					this.selectedDurationMinuts &&
					this.selectedDate &&
					this.selectedLocation
				) {
					this.timeTabelMissingInformation = false;
					return false;
				}
				this.timeTabel = [];
				this.timeTabelMissingInformation = false;
				return true;
			},
		},

		resourceCategories: {
			query: GetResourceCategories,
			update(data: GetResourceCategoriesQuery) {
				if (data.resourceCategories.data.length === 1) {
					this.resourceCategories = data.resourceCategories.data;
					this.selectResourceCategory(data.resourceCategories.data[0].id);
				}
				return data.resourceCategories.data;
			},
		},

		resourceTypes: {
			query: GetResourceTypes,
			variables(): GetResourceTypesQueryVariables {
				return {
					filtering: {
						resourceCategory: this.selectedResourceCategory.id,
						location: this.selectedLocation.id,
					},
				};
			},
			update(data: GetResourceTypesQuery) {
				this.selectedResourceType = data.resourceTypes.data[0];
				return data.resourceTypes.data;
			},
			skip() {
				if (this.selectedResourceCategory && this.selectedLocation)
					return false;

				return true;
			},
		},

		shortStringMappings: {
			query: GetAllShortStringMappings,
			result({ data }) {
				if (data && data.getAllShortStringMappings) {
					this.shortStringMappings = data.getAllShortStringMappings;
				} else {
					console.error("shortStringMappings data is missing");
				}
			},
			error(error) {
				console.error("shortStringMappings query error:", error);
			},
		},
		additionalServices: {
			query: GetAdditionalServices,
			variables(): GetAdditionalServicesQueryVariables {
				return {
					companyId: this.selectedCompanyId,
					resourceCategoryId: this.selectedResourceCategory?.id,
				};
			},
			update: (data: any) => data?.additionalServices,
			watch: ["selectedCompanyId"],
		},
	},
})
export default class CreateResourceBooking extends Vue {
	LocationStore = useLocationStore();
	paymentWindowVisible = false;
	reasonForNoSubPayment = "";

	User = useUserStore();

	formatTimePrice(value: number) {
		if (this.LocationStore.userLocation?.currency) {
			return currencyFormatter(
				value,
				this.LocationStore.userLocation?.currency
			);
		} else {
			return value;
		}
	}

	selectedCompanyId = this.User.user ? this.User.user.companyId || null : null;

	@Watch("User.user.companyId", { immediate: true })
	onCompanyIdChange(newVal: string | null) {
		this.selectedCompanyId = newVal;
	}

	async locationUpdated(): Promise<void> {
		//@ts-ignore
		await this.$apollo.queries.resourceCategories.refetch()

		if (this.resourceCategories.length > 1) {
			this.selectedResourceCategory = null
		}
	}

	userCreateResourceBookingsTimelineModal = false;

	async handleInitBookingSettings() {
		const data = JSON.parse(
			this.$route.query.initSettings as string
		) as InitBookingSettings;

		this.$router.replace({ query: { initSettings: undefined } });

		const getResource = await this.$apollo.query({
			query: gql`
				query GetResource($id: ID!) {
					resource(id: $id) {
						id
						maxAttendees
						name
					}
				}
			`,
			variables: {
				id: data.resourceId,
			},
		});

		const resource = getResource.data as {
			resource: { id: string; maxAttendees: number };
		};

		this.selectTime(
			{
				startDate: data.startDate,
				endDate: data.endDate,
			},
			false
		);

		this.selectResource(resource.resource);
	}

	hapticsImpact(style = ImpactStyle.Medium) {
		if (Capacitor.getPlatform() === "web") return;
		Haptics.impact({
			style: style,
		});
	}

	get possibleDurations() {
		return !this.selectedResourceCategory
			? []
			: this.selectedResourceCategory.durationMinutesIntervals.map((x) => ({
					hours: x / 60,
					minuts: x,
					text:
						x <= 60
							? (this.$t(
									"pages.user.createResourceBooking.create.hourtText.hour"
							  ) as string)
							: (this.$t(
									"pages.user.createResourceBooking.create.hourtText.hours"
							  ) as string),
			  }));
	}

	get usingDynamicPrices() {
		return this.timeTabel.find((x) => x.price.isDynamicPrice);
	}

	get duractionMinutsMinutes() {
		if (!this.selectedTime) return 0;
		//@ts-ignore
		const diff = this.$moment(this.selectedTime.endDate).diff(
			this.selectedTime.startDate,
			"minutes"
		);
		return diff;
	}

	async hideDynamicPricesInfoBox() {
		this.allowShowingDynamicPriceInfo = false;
		await Preferences.set({ key: "HIDE_DYNAMIC_PRICE_INFO", value: "true" });
	}

	friendSearch = "";

	payStore = usePayStore();
	payPriceStore = usePayPriceStore();
	stripeV2Store = usePayV2StripeStore();
	payV2Store = usePayV2Store();
	paymentTokenStore = usePayPaymentTokenStore();
	pointsStore = usePayV2PointsStore()
	additionalServiceStore = useAdditionalServiceStore()

	locationUserDefault: null | GetLocationQuery["location"] = null;

	showAttendeesResourceBookingModal = false;

	friends: GetFriendsQuery["friends"]["data"] = [];
	selectedDate = this.$moment().endOf("day").toISOString();
	selectedResourceCategory:
		| GetResourceCategoriesQuery["resourceCategories"]["data"][0]
		| null = null;
	selectedDurationMinuts = 0;
	selectedResourceType:
		| GetResourceTypesQuery["resourceTypes"]["data"][0]
		| null = null;
	selectedLocation: GetLocationUserDefaultQuery["locationUserDefault"] | null =
		null;

	selectedTime:
		| GetResourcesAvailableTimeSchemaQuery["resourcesAvailableTimeSchema"]["times"][0]
		| null = null;
	selectedResource:
		| GetResourcesAvailableTimeSchemaQuery["resourcesAvailableTimeSchema"]["times"][0]["availableResources"][0]
		| null = null;

	timeTabel: GetResourcesAvailableTimeSchemaQuery["resourcesAvailableTimeSchema"]["times"] =
		[];
	timeTabelMissingInformation = false;

	resourceCategories: GetResourceCategoriesQuery["resourceCategories"]["data"] =
		[];
	resourceTypes: GetResourceTypesQuery["resourceTypes"]["data"] = [];

	user: GetUserBasicsQuery["user"] = {
		id: "",
		name: null,
		email: null,
		firstName: null,
		lastName: null,
		city: null,
		zipCode: null,
		recurringBookingsAttendee: [],
		phoneNumber: "",
		country: SupportedCountries.Dk,
		userSignInMethod: UserSingInMethod.None,
		createdAt: "",
		isDeleted: false,
		groups: [],
		userProductSubscriptions: [],
		accessCode: null,
		lat: null,
		lng: null,
		haveSavedStripeSource: false,
		resourceBookingsPrWeek: {
			prWeekRatio: [],
			pastWeekRatio: 0,
			averageRatio: 0,
			diffBetweenPastWeekAndAverageRatio: 0,
		},
	};

	async created() {
		this.additionalServiceStore.selectedServices = []
		this.additionalServiceStore.infoAdditionalService = ''

		this.$nextTick(() => {
			if (!this.LocationStore.currentUserLocation) {
				//@ts-ignore
				this.$refs["changeUserDefaultLocation"].show();
			}
		});
		this.$watch(
			() => this.LocationStore.currentUserLocation,
			(newLocation, oldLocation) => {
				if (!newLocation && oldLocation) {
					//@ts-ignore
					this.$refs["changeUserDefaultLocation"].show();
				}
			}
		);

		await saEvent("resourceBooking.init");

		const { value: shouldHideDynamicPriceInfo } = await Preferences.get({
			key: "HIDE_DYNAMIC_PRICE_INFO",
		});
		this.allowShowingDynamicPriceInfo = !shouldHideDynamicPriceInfo;

		if (this.$route.query.date) {
			//@ts-ignore
			this.selectDate(this.$route.query.date);
		}

		if (this.$route.query.initSettings) {
			this.handleInitBookingSettings();
		}
	}

	@Watch("timeTabel")
	timeTabelChange() {
		if (!this.timeTabel.length) return;

		const get = this.timeTabel.find((x) => x.utcTime > "04:00");
		if (!get) return;

		let el = document.getElementById(`time-${get.utcTime}`);
		if (!el) {
			setTimeout(() => {
				this.timeTabelChange();
			}, 200);
			return;
		}

		const timeTableElement = document.getElementById("time-table");

		const { top: timeTableToTop } = timeTableElement!.getBoundingClientRect();
		const { top: timeToToTop } = el.getBoundingClientRect();

		const diff = timeToToTop - timeTableToTop;
		if (diff > 0) el.scrollIntoView({ behavior: "smooth" });
	}

	get showActionRow() {
		const height = document.documentElement.clientHeight;
		return height > 655 ? true : false;
	}

	allowShowingDynamicPriceInfo = false;

	get timeTabelForBooking() {
		return this.timeTabel.filter((time) => time.availableForBooking);
	}

	get maxDaysCreateResourceBookingAheadUser() {
		return !this.selectedResourceCategory
			? 0
			: this.selectedResourceCategory.maxDaysCreateResourceBookingAheadUser;
	}

	get endDateForBookingAhead() {
		return this.$moment()
			.add(this.maxDaysCreateResourceBookingAheadUser, "days")
			.toDate();
	}

	get possibleMonths() {
		const a: { label: string; date: Date; isoString: string }[] = [];
		const startDate = this.$moment().startOf("month").endOf("day");
		const endDate = this.$moment(this.endDateForBookingAhead).endOf("month");

		while (startDate.isBefore(endDate)) {
			a.push({
				label: startDate.format("MMMM"),
				date: startDate.toDate(),
				isoString: startDate.toISOString(),
			});
			startDate.add(1, "month");
		}

		return a;
	}

	selectedMonth = this.$moment().startOf("month").endOf("day").toISOString();

	get possibleDates() {
		const startDate = this.$moment().endOf("day");
		const endDate = this.$moment(this.endDateForBookingAhead);

		const result: { day: string; dayLabel: string; date: string }[] = [];

		const countDays = this.$moment(endDate).diff(
			this.$moment(startDate),
			"days"
		);

		// * +2 Because we don't want to include current day
		for (let i = 0; i < countDays + 2; i++) {
			const d = this.$moment(startDate).add({ days: i });
			result.push({
				day: d.format("D"),
				dayLabel: d.format("dd"),
				date: d.toISOString(),
			});
		}

		return result;
	}

	get isValidDate() {
		const currentDate = new Date();
		const selectedDate = new Date(this.selectedTime?.startDate);

		if (selectedDate.getTime() >= currentDate.getTime()) {
			return true;
		}
		return false;
	}

	async showAttendeesInfoBox() {
		await this.$bvModal.msgBoxOk(
			((this.$t(
				"pages.user.createResourceBooking.create.paymentModal.attendees.notNeeded.one"
			) as string) +
				" " +
				this.$t(
					"pages.user.createResourceBooking.create.paymentModal.attendees.notNeeded.two"
				)) as string,
			{
				title: this.$t(
					"pages.user.createResourceBooking.create.paymentModal.attendees.title"
				) as string,
				centered: true,
				titleTag: "h4",
				bodyClass: "text-muted",
				footerClass: "py-2",
				okVariant: "primary",
			}
		);
	}

	looper() {
		const elements = document.getElementById("date-box");

		const a = elements!.getElementsByTagName("div");
		const viewPortWith = window.innerWidth;

		let center = viewPortWith / 2;

		if (viewPortWith > 575) center = (viewPortWith - 236) / 2;

		for (var i = 0; i < a.length; i++) {
			const b = a[i].getBoundingClientRect();
			const toLeft = b.left;
			if (toLeft > center - 50 && toLeft < center + 50) {
				const date = a[i].id.replace("date-", "");
				const startMonth = this.$moment(date)
					.startOf("month")
					.endOf("day")
					.toISOString();
				this.selectedMonth = this.possibleMonths.find(
					(x) => x.isoString === startMonth
				)!.isoString;
				break;
			}
		}
	}

	goToStart() {
		location.reload();
	}

	availableResourceEnding(number: number) {
		return number === 1
			? (this.$t(
					"pages.user.createResourceBooking.create.resourcesText.resource"
			  ) as string)
			: (this.$t(
					"pages.user.createResourceBooking.create.resourcesText.resources"
			  ) as string);
	}

	scrollIntroCenter(el: any) {
		el.scrollIntoView({
			behavior: "smooth",
			block: "center",
			inline: "center",
		});
	}

	scrollIntroCenterFromEvent(event: any) {
		event.currentTarget.scrollIntoView({
			behavior: "smooth",
			block: "center",
			inline: "center",
		});
	}

	selectResourceCategory(resourceCategoryId: string) {
		this.selectedResourceCategory = this.resourceCategories.find(
			(x) => x.id === resourceCategoryId
		)!;
		this.selectDurationMinutes(
			this.selectedResourceCategory.defaultDurationMinutes,
			false
		);
	}

	selectMonth(isoString: string) {
		if (this.selectedMonth === isoString) return;
		this.selectedMonth = isoString;
		let newSelectedDate: Date | null = null;
		if (!this.possibleDates.find((date) => date.date === isoString)) {
			newSelectedDate = new Date(this.possibleDates[0].date);
		} else {
			newSelectedDate = new Date(isoString);
		}

		this.selectDate(newSelectedDate);
	}

	selectDate(date: Date) {
		//@ts-ignore
		const queryDate = this.$moment(date).endOf("day").toISOString();

		this.hapticsImpact();

		this.selectedDate = queryDate;
		const ref = `date-${queryDate}`;
		setTimeout(() => {
			const el = document.getElementById(ref);

			if (!el) {
				this.selectDate(new Date());
				return;
			}

			this.scrollIntroCenter(el);
		}, 500);
	}

	selectTime(time: any, selectResource = true) {
		this.selectedTime = time;

		if (selectResource) {
			this.selectResource(this.selectedTime!.availableResources[0]);
		}
	}

	async selectResource(resource: any) {
		this.additionalServiceStore.selectedServices = []

		this.selectedResource = resource;
		// @ts-ignore
		this.$refs["select-court-modal"].hide();

		this.initBookingPayment();

		await saEvent("resourceBooking.resourceSelected");
	}

	selectDurationMinutes(minutes: number, doHaptic = true) {
		this.selectedDurationMinuts = minutes;
		if (doHaptic) this.hapticsImpact();

		this.$nextTick(() => {
			const el = document.getElementById("select-duration-" + minutes);
			this.scrollIntroCenter(el);
		});
	}

	//* ATTENDEES
	resourceBookingSelectedAttendeesIds: string[] = [];

	@Watch("showAttendeesResourceBookingModal")
	showAttendeesResourceBookingModalChange() {
		if (!this.showAttendeesResourceBookingModal) {
			this.initBookingPayment();
		}
	}

	get bookingAttendees() {
		if (!this.payPriceStore.priceData?.resourceBookingInfo) return [];
		const attendees =
			this.payPriceStore.priceData.resourceBookingInfo.attendees!;
		this.resourceBookingSelectedAttendeesIds = attendees
			.filter((x) => x.isUser)
			.map((x) => x.user!.id!);
		return attendees;
	}

	checkAttendees() {
		if (
			this.resourceBookingSelectedAttendeesIds.length >
			this.selectedResource!.maxAttendees
		)
			this.resourceBookingSelectedAttendeesIds =
				this.resourceBookingSelectedAttendeesIds.slice(
					0,
					this.selectedResource!.maxAttendees
				);
	}

	deleteBookingAttendee(userId: string, shouldReloadPrice?: boolean) {
		this.resourceBookingSelectedAttendeesIds =
			this.resourceBookingSelectedAttendeesIds.filter((x) => x !== userId);
		if (shouldReloadPrice) this.initBookingPayment();
	}

	addBookingAttendee(userId: string, shouldReloadPrice?: boolean) {
		this.resourceBookingSelectedAttendeesIds.push(userId);
		if (
			this.resourceBookingSelectedAttendeesIds.length >=
			this.selectedResource!.maxAttendees
		) {
			//@ts-ignore
			this.$refs["attendees-resource-booking-modal"].hide();
		}

		if (shouldReloadPrice) this.initBookingPayment();
	}

	additionalServices: AdditionalService[] = [];

	async updateAdditionalServices(
		selectedServicesIds: string[],
		infoAdditionalService: string[]
	) {
		this.additionalServiceStore.selectedServices = selectedServicesIds;
		this.additionalServiceStore.infoAdditionalService = infoAdditionalService.join(", ");
		this.initBookingPayment();
	}

	//* PAYMENT
	async initBookingPayment() {
		this.paymentWindowVisible = true;

		// Firebase Analytics -> push notification to track users who have started but not completed booking
		logAnalyticsEvent('booking_started', { company_id: this.selectedCompanyId, user_id: this.user.id })

		if (!this.selectedTime)
			throw new Error(
				this.$t("common.staticTemp.errorMessageSelectedTime") as string
			);
		if (!this.selectedResource)
			throw new Error(
				this.$t("common.staticTemp.errorMessageSelectedResource") as string
			);

		if(this.additionalServiceStore.selectedServices.length === 0) {
			this.additionalServiceStore.infoAdditionalService = ''
		}

		this.checkAttendees();
		if (this.stripeV2Store.isStripePaymentElementsCompatible) {
			await this.payV2Store.initPayment({
				source: "ResourceBookingInit",
				doneFunction: this.doneFunction,
				purchaseData: {
					productType: ProductType.ResourceBooking,
					resourceBooking: {
						startDate: this.selectedTime.startDate,
						endDate: this.selectedTime.endDate,
						resourceId: this.selectedResource.id,
						attendees: this.resourceBookingSelectedAttendeesIds,
						additionalServicesIds: this.additionalServiceStore.selectedServices,
						internalNotes:
						this.additionalServiceStore.infoAdditionalService !== ''
							? this.additionalServiceStore.infoAdditionalService
									: undefined,
					},
				},
			});
		} else {
			await this.payStore.initPayment({
				source: "ResourceBookingInit",
				doneFunction: this.doneFunction,
				purchaseData: {
					productType: ProductType.ResourceBooking,
					resourceBooking: {
						startDate: this.selectedTime.startDate,
						endDate: this.selectedTime.endDate,
						resourceId: this.selectedResource.id,
						attendees: this.resourceBookingSelectedAttendeesIds,
						additionalServicesIds: this.additionalServiceStore.selectedServices,
						internalNotes:
							this.additionalServiceStore.infoAdditionalService !== ''
								? this.additionalServiceStore.infoAdditionalService
								: undefined,
					},
				},
			});
		}
	}

	get displayNoDiscountMessage() {
		const attendeeMessageShouldBeDisplayed = this.payPriceStore.useDiscounts && this.hasAttendeMessageLine && this.noSubPaymentReason.reason
		const combineDiscountMessageShouldBeDisplayed = this.pointsStore.usePointsInsteadOfDiscount
		return attendeeMessageShouldBeDisplayed || combineDiscountMessageShouldBeDisplayed
	}

	get hasAttendeMessageLine() {
		const attendee = this.bookingAttendees.find((attendee) => attendee.isOwner);
		if (attendee) {
			this.canDoSubscriptionPayment(attendee.messageLines);
			return true;
		}
		return false;
	}

	shortStringMappings:
		| GetAllShortStringMappingsQuery["getAllShortStringMappings"]
		| null = null;

	canDoSubscriptionPayment(attendeeMessageLines: string[]) {
		const messageLines = messageLineParser({
			messageLines: attendeeMessageLines,
			mappings: this.shortStringMappings ?? [],
		});

		const canDoLine = messageLines.find((line) => line.includes("Can do:"));

		if (!canDoLine) {
			return;
		}

		if (canDoLine.includes("Can do: false")) {
			const reasonCodeStr = messageLines.find((str) => str.includes("Code"));
			const reasonCode =
				reasonCodeStr?.split("Code")[1].split(".")[0].trim() ?? "";

			this.reasonForNoSubPayment = reasonCode;
		} else if (canDoLine.includes("Can do: true")) {
			this.reasonForNoSubPayment = "";
		}
	}

	get noSubPaymentReason() {
		switch (this.reasonForNoSubPayment) {
			case ReasonCode.Canceled:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.cancelledBooking"
					) as string,
				};
			case ReasonCode.Details:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.missingDetails"
					) as string,
				};
			case ReasonCode.MaxMonth:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.maxBookingsMonth"
					) as string,
				};
			case ReasonCode.MaxTotal:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.maxActiveBookings"
					) as string,
				};
			case ReasonCode.CancelStartdate:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.cancelDate"
					) as string,
				};
			case ReasonCode.BTime:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.bTime"
					) as string,
				};
			case ReasonCode.DoubleBooking:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.multipleBookings"
					) as string,
				};
			case ReasonCode.ResourceType:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.specificResourceType"
					) as string,
				};
			case ReasonCode.InvalidStartdate:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.wrongStartDate"
					) as string,
				};
			case ReasonCode.Timeframe:
				return {
					reason: this.$t(
						"things.userProductSubscription.noSubscriptionPayment.wrongTimeframe"
					) as string,
				};
			default:
				return {
					reason: "",
				};
		}
	}

	async done(resourceBookingId: string) {
		this.$router.push({
			name: "User.ResourceBooking.Created",
			params: { resourceBookingId },
		});

		const dataForSegment = {
			booking_type: "User Booking",
			duration_minutes: this.selectedDurationMinuts,
			booking_date: new Date(this.selectedTime?.startDate),
			club_country: this.locationUserDefault?.company.country,
			club_id: this.locationUserDefault?.id,
			club_name: this.locationUserDefault?.name,
			email: this.user.email,
			booking_id: resourceBookingId,
			booking_price: this.payPriceStore.priceData?.amountToPay.toString(),
			booking_system: "LeDap",
		};

		sendSegmentData(SegmentTypeOfEvent.BookingCompleted, dataForSegment);

        // Firebase Analytics -> push notification to track users who have started but not completed booking
        logAnalyticsEvent('booking_completed', { company_id: this.selectedCompanyId, user_id: this.user.id })

		await saEvent("resourceBooking.done");
	}

	doneFunction: DoneFunction = async (data) => {
		return this.done(data.resourceBooking?.id!);
	};

	async resetPaymentWindow(): Promise<void> {
		this.paymentWindowVisible = false;
		await this.payV2Store.payReset();
		await this.payStore.payReset();
	}

	async backToOverview(): Promise<void> {
		this.stripeV2Store.showStripeWebPaymentElement = false;
		await this.paymentTokenStore.resetPaymentToken(
			PaymentTokenFaildReason.Canceled,
			false
		);
		this.payV2Store.startPaymentIsInitialized = false;
		this.payV2Store.paymentIsStarted = false;
	}
}
</script>

<style lang="sass" scoped>
.item-min-width
	min-width: 45px

.time-slot-min-width
	min-width: 70px
.extra-small
	font-size: 0.7125rem

.booking-attendee-wrapper
	display: grid
	grid-template-columns: 1fr 1fr 1fr 1fr
	column-gap: 6px

.booking-attendee
	border-radius: 5px
	border: 1px solid #e3ebf6
	background-color: white
	display: flex
	flex-direction: column
	position: relative
	padding: 5px

.attendee-add
	display: flex
	justify-content: center
	align-items: center
	background-color: #0B39E0
	color: white
	height: 100%
	border-radius: 5px 5px 0 0
	cursor: pointer

.attendee-add-values
	padding: 5px


.attendee-action
	position: absolute
	top: -5px
	right: -5px
	height: 23px
	width: 23px
	border-radius: 100%
	border: 3px #F9F9FB solid
	display: flex
	justify-content: center
	align-items: center
	color: white

.friends-wrapper
	height: auto
	display: flex
	flex-direction: column

.add-friends-list
	display: flex
	overflow-y: auto
	overflow-x: hidden

@media(max-width: 330px)
	.hide-text-small
		display: none


.cards-container-overlay
	display: flex
	justify-content: center
	align-items: center
	flex-direction: column

	.info-wrapper
		background-color: white
		border-bottom: solid #E3EBF6 1px

	.pay-wrapper
		background-color: white
		border-top: solid #E3EBF6 1px

.payment-modal-scrollable-container
	overflow-y: auto
	max-height: 90vh

@media screen and (max-width: 750px)
	.payment-modal-scrollable-container
		max-height: 80vh

</style>
