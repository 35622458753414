import { RouteConfig } from 'vue-router'
import { PlatformLayouts } from '@/layouts'

import authRoutes from './auth'
import adminRoutes from './admin'
import companyRoutes from './company'
import franchiseRoutes from './franchise'
import userRoutes from './user'
import technicianRoutes from './technician'
import priceffRoutes from './priceff'
import otherRoutes from './other'
import { UserRole } from '@/graphql'
import { RouteConfigSingleView } from 'vue-router/types/router'

export interface CustomRouteConfig extends RouteConfigSingleView {
	meta?: {
		requireAuth?: boolean
		requireRole?: UserRole
		layout?: PlatformLayouts
		hideNavigation?: boolean
		title: string
		requireEcomEnabled?: boolean
	},
	children?: CustomRouteConfig[]
}

const routes: CustomRouteConfig[] = [
  ...authRoutes,
  ...adminRoutes,
  ...companyRoutes,
  ...userRoutes,
  ...franchiseRoutes,
	...technicianRoutes,
  ...otherRoutes,
	...priceffRoutes,

  /*
	{
		path: '/abc',
		component: () => import('@/views/test.vue'),
	},
	*/

  {
    path: '*',
    redirect: { name: 'SignIn' }
  }
]

export default routes
