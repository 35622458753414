export default {
  title: 'Add / remove points',
  description: 'Here you can add or remove points to',
  add: 'Add',
  remove: 'Remove',

  amount: {
    label: 'Points amount'
  },
  note: {
    label: 'Note',
    placeholder: 'Write a note...'
  },
  isOneToOne: 'Point is one to one the money value',
  valueInMoney: 'Value in money',
  skipPlatformFees: 'Do not take platform fee',

  changePointButton: 'point',
  changePointLoadingButton: 'Change points...',

  successPointsAdded: 'Points have been successfully added!',
  successPointsRemoved: 'Points have been successfully removed!',
  errorNegativeEnteredAmount: 'You have entered a negative point amount to remove or add - please enter a positive value',
  errorNegativeBalance: 'Can not remove points - the amount to remove is larger than the amount on the point account'
}