<template>
    <b-card class="shadow-none border-secondary card-small-padding">
      <b-row>
        <b-col class="d-flex align-items-center justify-content-center flex-column border-right" cols="3">
          <p class="m-0 text-center">{{ currencyFormatter(moneyValue, currency) }}</p>
        </b-col>
  
        <b-col>
          <h4>
            <span v-if="productResourceBooking">{{ $t('pages.user.account.payments.payment.productType.resourceBooking') }}</span>
            <span v-else-if="product.type === 'SubscriptionProduct'">{{ $t('pages.user.account.payments.payment.productType.subscriptionProduct') }}</span>
            <span v-else-if="product.type === 'SubscriptionProductSignUpFee'">{{ $t('pages.user.account.payments.payment.productType.subscriptionProductSignUpFee') }}</span>
            <span v-else-if="productPointTransaction">{{ $t('pages.user.account.payments.payment.productType.pointTransaction') }}</span>
            <span v-else-if="product.type === 'WorkshopBooking'">{{ $t('pages.user.account.payments.payment.productType.workshop') }}</span>
            <span v-else>{{ product.type }}</span>
          </h4>
  
          <!-- Connected Payment Resource Booking -->
          <b-row v-if="connectedPayment && connectedPayment.productResourceBooking" class="flex-wrap: nowrap" align-v="center">
            <b-col class="col-auto pr-0">
              <i class="fe fe-box text-muted icon-sm mr-2"></i>
            </b-col>
            <b-col class="pl-1">
              <small class="mt-1">
                <b-link :to="{ name: 'User.ResourceBooking', params: { id: connectedPayment.productResourceBooking.id } }">{{ $t('pages.user.account.payments.payment.showBooking') }}</b-link>
              </small>
            </b-col>
          </b-row>
  
          <!-- Product Resource Booking -->
          <b-row v-if="productResourceBooking" class="flex-wrap: nowrap" align-v="center">
            <b-col class="col-auto pr-0">
              <i class="fe fe-box text-muted icon-sm mr-2"></i>
            </b-col>
            <b-col class="pl-1">
              <small class="mt-1">
                <b-link :to="{ name: 'User.ResourceBooking', params: { id: productResourceBooking.id } }">{{ $t('pages.user.account.payments.payment.showBooking') }}</b-link>
              </small>
            </b-col>
          </b-row>
  
          <!-- Company Info -->
          <b-row v-if="company" class="flex-wrap: nowrap" align-v="center">
            <b-col class="col-auto pr-0">
              <i class="fe fe-home text-muted icon-sm mr-2"></i>
            </b-col>
            <b-col class="pl-1">
              <small class="mt-1">{{ company.name }}</small>
            </b-col>
          </b-row>
  
          <!-- Payment Date -->
          <b-row class="flex-wrap: nowrap" align-v="center">
            <b-col class="col-auto pr-0">
              <i class="fe fe-calendar text-muted icon-sm mr-2"></i>
            </b-col>
            <b-col class="pl-1">
              <small class="mt-1">{{ createdAt | moment('DD/MM/YYYY HH:mm') }}</small>
            </b-col>
          </b-row>
  
          <!-- Payment Method and Status -->
          <b-row class="flex-wrap: nowrap" align-v="center">
            <b-col class="col-auto pr-0">
              <i class="fe fe-star text-muted icon-sm mr-2"></i>
            </b-col>
            <b-col class="pl-1">
              <small class="mt-1">
                <PaymentMethod :method="paymentMethod" :useIcon="false" />
                <span> -&nbsp;</span>
                <PaymentStatus :status="status" :useIcon="false" />
              </small>
            </b-col>
          </b-row>
  
          <!-- Payment Receipt Link -->
          <b-row v-if="showInvoice" class="flex-wrap: nowrap" align-v="center">
            <b-col class="col-auto pr-0">
              <i class="fe fe-file text-muted icon-sm mr-2"></i>
            </b-col>
            <b-col class="pl-1">
              <small class="mt-1">
                <b-link @click="showInvoiceHandler">{{ $t('pages.user.account.payments.payment.showReceipt') }}</b-link>
              </small>
            </b-col>
          </b-row>
  
          <!-- Invoice URL -->
          <b-row v-else-if="invoiceUrl" class="flex-wrap: nowrap" align-v="center">
            <b-col class="col-auto pr-0">
              <i class="fe fe-file text-muted icon-sm mr-2"></i>
            </b-col>
            <b-col class="pl-1">
              <small class="mt-1">
                <b-link :href="invoiceUrl" target="_blank">{{ $t('pages.user.account.payments.payment.showReceipt') }}</b-link>
              </small>
            </b-col>
          </b-row>
  
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import PaymentMethod from '@/components/admin/enumTranformers/paymentMethod.vue';
  import PaymentStatus from '@/components/admin/enumTranformers/paymentStatus.vue';
  import { currencyFormatter } from '@/utils/currencyFormatter';
  import Vue from "vue";
  
  export default Vue.extend({
    name: 'PaymentCard',
    components: {
      PaymentMethod,
      PaymentStatus,
    },
    props: {
      moneyValue: { type: Number, required: true },
      currency: { type: String, required: true },
      product: { type: Object, required: true },
      productResourceBooking: { type: Object, default: null },
      productPointTransaction: { type: Boolean, default: false },
      connectedPayment: { type: Object, default: null },
      company: { type: Object, default: null },
      createdAt: { type: String, required: true },
      paymentMethod: { type: String, required: true },
      status: { type: String, required: true },
      invoiceUrl: { type: String, default: null },
      showInvoice: { type: Boolean, default: false },
      showInvoiceHandler: { type: Function, default: null },
    },
    methods: {
      currencyFormatter
    },
  });
  </script>
  
<style scoped>
/* Add any scoped CSS styling here if needed */
</style>
  