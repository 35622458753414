export default {
    userSearch: {
        notLoggedIn: 'You are not logged in to LeDap. Go to',
        ledapApp: 'ledap.app',
        toLogIn: 'to log in.',
        emailNr: 'Email or mobile number',
        search: 'Search via email or number',
        noUser: 'Could not find user in LeDap',
        showUser: 'Show user in LeDap',
        logIn: 'Log in'
    },

    frontAttachments: {
        notLoggedIn: 'You need to be signed in as Company, Franchise or Admin on the ledap.app platform. Go to',
    }
}