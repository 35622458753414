export default {
  subTitle: 'User group',
  addUser: 'Add user',

  info: {
    maxDaysCreateResourceBookingAhead: 'Can create bookings in the future',
    automaticAddUsersWithEmailEnding: 'Automatically add to group via email',
    maxUserLimit: 'Max number of users',
    coupons: 'Discount codes',
    automaticSubscriptionProduct: 'Automatic membership',
    adminsCanHandleUsers: 'Admin can add and remove users'
  },

  actions: {
    showPointAccount: 'View point account',
    userGroupCreatePointAccount: 'Create point account',
    automaticSubscriptionProductCreate: 'Create automatic membership',
    automaticSubscriptionProductRemove: 'Remove automatic membership',
    update: 'Update group',
    delete: 'Delete group'
  },

  usersTable: {
    name: 'Name',
    admin: 'Admin',
    showUser: 'View user'
  },

  updateUserGroupModal: {
    title: 'Update user group',
    automaticAddUsersWithEmailEnding: {
      label: 'Automatically add users with email extension (eg. padelclub.dk)',
      placeholder: 'padelclub.dk'
    },
    maxDaysCreateResourceBookingAhead: 'Max days you can book in the future',
    maxUserLimit: 'Max number of users',
    adminsCanHandleUsers: 'Admin can add and remove users from user group',
    updateButton: 'Updating group...',
    updateLoadingButton: 'Update group',
    successToast: 'The group is updated',
  },

  addAutomaticSubscriptionModal: {
    title: 'Add automatic membership',
    text: 'When you add a membership here, it will be created on all group members WITHOUT payment. That is, you yourself must make sure to get (if need be) the monthly payment from the group.',

    selectSubscriptionProduct: {
      label: 'Choose a membership',
      placeholder: 'Choose a membership...'
    },
    addButton: 'Add automatic membership',
    addLoadingButton: 'Adding automatic membership...'
  },

  removeUserFromGroupSuccessToast: 'The user has been removed',
  userGroupCreatePointAccountSuccessToast: 'Point account is created',
  userGroupDelete: {
    sure: {
      text: 'Are you sure you want to delete this group?'
    },
    successToast: 'The group is deleted',
  },

  userGroupAdminAddSuccessToast: 'The user is made a group admin',
  userGroupAdminRemoveSuccessToast: 'The user has been removed as group admin'
  
}