import { CustomRouteConfig } from '.'
import { things } from '@/things/config'
import { Route } from 'vue-router'
import { UserRole } from '@/graphql'

const routes: CustomRouteConfig[] = [
  {
    name: 'Franchise',
    path: '/f',
    component: () => import('@/views/franchise/index.vue'),
    meta: {
      requireAuth: true,
      requireRole: UserRole['Franchise'],
      title: ''

    },
    children: [
      /************
      * Root
      ************/
      {
        name: 'Franchise.Dashboard',
        path: 'dashboard',
        component: () => import('@/views/franchise/dashboard.vue'),
        meta: {
          layout: 'Admin',
          title: 'Startside'
        }
      },
      {
        name: 'Franchise.Companies',
        path: 'companies',
        component: () => import('@/views/franchise/companies.vue'),
        meta: {
          layout: 'Admin',
          title: 'Firmaer'
        }
      },
      {
        name: 'Franchise.Users',
        path: 'users',
        component: () => import('@/views/franchise/users.vue'),
        meta: {
          layout: 'Admin',
          title: 'Brugere'
        }
      },
      {
        name: 'Franchise.Payments',
        path: 'payments',
        component: () => import('@/views/franchise/payments.vue'),
        meta: {
          layout: 'Admin',
          title: 'Betalinger'
        }
      },
      {
        name: 'Franchise.Statistics',
        path: 'statistics',
        component: () => import('@/views/franchise/statistics/index.vue'),
        meta: {
          layout: 'Admin',
          title: 'Statistikker'
        },
        children: [
          {
            name: 'Franchise.Statistics.General',
            path: 'general',
            component: () => import('@/views/franchise/statistics/general.vue'),
            meta: {
              title: 'Generelt'
            }
          },
          {
            name: 'Franchise.Statistics.Points',
            path: 'points',
            component: () => import('@/views/franchise/statistics/points.vue'),
            meta: {
              title: 'Point'
            }
          },
          {
            name: 'Franchise.Statistics.FromCompanyCreated',
            path: 'from-company-created',
            component: () => import('@/views/franchise/statistics/fromCompanyCreated.vue'),
            meta: {
              title: 'Fra firma start'
            }
          },
          {
            name: 'Franchise.Statistics.Scenarios',
            path: 'scenarios',
            component: () => import('@/views/franchise/statistics/scenarios.vue'),
            meta: {
              title: 'Scenarier'
            }
          }
        ]
      },
      {
        name: 'Franchise.Settings',
        path: 'settings',
        component: () => import('@/views/franchise/settings/index.vue'),
        meta: {
          layout: 'Admin',
          title: 'Indstillinger'
        }
      },

      ...things.filter(thing => thing.moreInfoRoutePath).map(thing => ({
        name: `Franchise.${thing.type}`,
        path: thing.moreInfoRoutePath!,
        meta: {
          title: thing.routeTitle!,
          thingType: thing.type
        },
        component: () => import('@/things/thingRoute.vue'),
      }))
    ]
  }

]

export default routes
