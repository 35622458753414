import { debounce } from '@/utils/debounce'
import { defineStore } from 'pinia'
import { performanceToTime } from '@/utils/performanceToTime'

interface PayLoadingState {
  loading: Boolean
  loadingStates: { [loadingKey: string]: Boolean }
  loadingTimes: { [loadingKey: string]: number }

  globalLoadingStart: number | null
  globalLoadingLatest: number | null
  globalLoadingInitKey: string | null
  globalLoadingActive: boolean
}

export const usePayLoadingStore = defineStore({
  id: 'PayLoading',

  state: (): PayLoadingState => ({
    loading: false,
    loadingStates: {},
    loadingTimes: {},
    
    globalLoadingStart: null,
    globalLoadingLatest: null,
    globalLoadingInitKey: null,
    globalLoadingActive: false
  }),

  getters: {
    globalLoadingDebouce: (state) => debounce(() => {
      console.log('----------------------------------------')
      console.log('🔥 Init command:', state.globalLoadingInitKey)
      console.log('🔥 Time:', performanceToTime(state.globalLoadingLatest! - state.globalLoadingStart!))
      console.log('--------------------------------------------------------------------------------')
      
      state.globalLoadingActive = false
      state.globalLoadingInitKey = null
      state.globalLoadingStart = null,
      state.globalLoadingLatest = null
    }, 1500)
  },

  actions: {

    reset() {
      this.loadingStates = {}
      this.loadingTimes = {}
    },

    set(loadingKey: string, state: Boolean, data?: any) {
      this.setLoadingKey(loadingKey, state, data)
      this.setLoadingTime(loadingKey, state, data)
    },

    setGlobalLoading(loadingKey: string) {
      if(!this.loading && !this.globalLoadingActive) {
        this.globalLoadingActive = true
        this.globalLoadingInitKey = loadingKey
        this.globalLoadingStart = performance.now()
      }

      this.loading = Object.values(this.loadingStates).includes(true)

      if (!this.loading) {
        this.globalLoadingLatest = performance.now()
        this.globalLoadingDebouce()
      }
    },

    setLoadingKey(loadingKey: string, state: Boolean, data?: any) {
      this.loadingStates[loadingKey] = state
      this.setGlobalLoading(loadingKey)
    },

    setLoadingTime(loadingKey: string, state: Boolean, data?: any) {
      if (state) {
        this.loadingTimes[loadingKey] = performance.now()
        console.log(`🎬 ${loadingKey}`, data || '')
      }
      else {
        const loadingKeyTime = this.loadingTimes[loadingKey]
        delete this.loadingTimes[loadingKey]
        const nowPerformance = performance.now()
        const diffMs = nowPerformance - loadingKeyTime
        const result = performanceToTime(diffMs)

        console.log(`✅ ${loadingKey} ${result}`, data || '')
      }
    }
  }
})
